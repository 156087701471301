import urls from "@/utils/urls";
import axiosClient from "./axiosClient";
import { UploadFile } from "@/type/response/uploadFile";

const uploadApi = {
  getUploadFiles(fileId: string) {
    return axiosClient.get<UploadFile>(`${urls.GET_UPLOAD_FILES}/${fileId}`);
  },
  uploadFile(file: any) {
    const formData = new FormData();
    formData.append("files", file);
    return axiosClient.post<UploadFile[]>(`${urls.UPLOAD}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
};
export default uploadApi;
