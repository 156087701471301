export default function formatStringParamsFilterProducts(
  paramsFilter: URLSearchParams
) {
  const transformedParams: {
    pagination: any;
    filters: Record<string, string[]>;
    sort: string | null;
  } = {
    pagination: {},
    filters: {},
    sort: null,
  };

  paramsFilter.forEach((value, key) => {
    if (key !== "filterFlashSale") {
      if (key === "page" || key === "pageSize") {
        transformedParams.pagination[key] = value;
      } else if (key === "sortPrice") {
        transformedParams.sort = value;
      } else {
        if (value.split(",").length > 0) {
          transformedParams.filters = {
            ...transformedParams.filters,
            [key]: [
              ...(transformedParams.filters[key] || []),
              ...value.split(","),
            ],
          };
        } else {
          transformedParams.filters = {
            ...transformedParams.filters,
            [key]: [...(transformedParams.filters[key] || []), value],
          };
        }
      }
    }
  });

  let transformedQueryString = `&pagination[pageSize]=${
    transformedParams.pagination.pageSize
  }&pagination[page]=${transformedParams.pagination.page}&${Object.entries(
    transformedParams.filters
  )
    .map(([key, value]) =>
      value.map((item) => `filters[${key}]=${item}`).join("&")
    )
    .join("&")}`;

  if (transformedParams.sort !== null) {
    transformedQueryString += `&sort[0]=price_promotion:${transformedParams.sort}&sort[1]=price:${transformedParams.sort}`;
  }

  return transformedQueryString;
}
