interface IWaitingOrderProps {
  active?: boolean;
  height?: number;
  width?: number;
}

const WaitingOrder = (props: IWaitingOrderProps) => {
  const { active, height, width } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "32"}
      height={height || "32"}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M28.0002 10.6667V26C28.0002 27.1046 27.1047 28 26.0002 28H6.00016C4.89559 28 4.00016 27.1046 4.00016 26V10.6667M13.3335 16H18.6668M3.3335 10.6667H28.6668C29.7714 10.6667 30.6668 9.77124 30.6668 8.66667V6C30.6668 4.89543 29.7714 4 28.6668 4H3.3335C2.22893 4 1.3335 4.89543 1.3335 6V8.66667C1.3335 9.77124 2.22893 10.6667 3.3335 10.6667Z"
        stroke={active ? "#000000" : "#808080"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WaitingOrder;
