import { profileRank } from "./profile";

const getRankMemberShip = (nameRank: string): string => {
  const mapPage: { [key: string]: string } = {
    [profileRank.BRONZE]: "Thành viên Đồng",
    [profileRank.SILVER]: "Thành viên Bạc",
    [profileRank.GOLD]: "Thành viên Vàng",
    [profileRank.DIAMOND]: "Thành viên Kim Cương",
  };

  return mapPage[nameRank] || "";
};

export default getRankMemberShip;
