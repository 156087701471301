import urls from '@/utils/urls';
import axiosClient from './axiosClient';
import { BaseResponse } from '@/type/base/baseResponse';
import { BaseData } from '@/type/base/baseData';
import { AgentStoreResponseType, BrandType } from '@/type/response/brand';
import { MapType } from "@/type/response/map";

const brandApi = {
  get: () => {
    return axiosClient.get<BaseResponse<BaseData<BrandType>>>(
      `${urls.BRAND}?populate=%2A`
    );
  },

  getMap: () => {
    return axiosClient.get<BaseResponse<BaseData<MapType>>>(`${urls.MAP}`)
  },

  getAgentStore: () => {
    return axiosClient.get<BaseResponse<BaseData<AgentStoreResponseType>>>(`${urls.AGENT_STORE}?populate=deep,3`)
  }
};

export default brandApi;
