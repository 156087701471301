import Footer from '@/components/layout/Footer';
import Header from '@/components/layout/Header';
import useScrollToTop from "../routers/ScrollToTop";
import { Outlet, useOutletContext } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from "recoil";
import ChatWidget from "@/components/common/ChatWidget";
import constants from "@/utils/constants";
import loadingScreenState from "@/recoil/loadingScreenState";
import LoadingScreen from "@/components/common/LoadingScreen";
import { useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from "react";
import firstLoadingState from "@/recoil/firstLoadingState";
import ScrollTopTop from "@/components/common/ScrollToTop";

export type RootLayoutContextType = {
  setIsLoadingDone: (isLoadingDone: boolean) => void;
}

export default function RootLayout() {
  useScrollToTop();
  const isLoggedIn = localStorage.getItem(constants.ACCESS_TOKEN);
  const isShowLoadingScreen = useRecoilValue(loadingScreenState)
  const [isFirstLoading, setIsFirstLoading] = useRecoilState(firstLoadingState)

  const location = useLocation();
  const previousLocationRef = useRef(location);
  const [isLocationChanged, setIsLocationChanged] = useState(false)
  const [isLoadingDone, setIsLoadingDone] = useState(false);

  useEffect(() => {
    if (isFirstLoading || previousLocationRef.current.pathname !== location.pathname) {
      setIsLocationChanged(true)
      setIsFirstLoading(false)
      setIsLoadingDone(false)
    } else {
      setIsLocationChanged(false)
    }
    previousLocationRef.current = location;
  }, [location]);

  return (
    <div className={"container-fluid p-0 relative"}>
      {isShowLoadingScreen && isLocationChanged &&
        <LoadingScreen isLoadingDone={isLoadingDone} />
      }
      <div className={`${isShowLoadingScreen && isLocationChanged ? 'hidden' : 'block'}`}>
        <Header />
        <div className="page relative">
          <Outlet context={{ setIsLoadingDone } satisfies RootLayoutContextType} />
          <ScrollTopTop />
          {isLoggedIn && <ChatWidget />}
        </div>
        <Footer />
      </div>
    </div>
  );
}

export function useRootLayoutContext() {
  return useOutletContext<RootLayoutContextType>()
}