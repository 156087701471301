import { BaseResponse } from '@/type/base/baseResponse';
import axiosClient from './axiosClient';
import { BaseData } from '@/type/base/baseData';
import urls from '@/utils/urls';
import { BlogType, frequentlyQuestion } from '@/type/response/blog';

const blogsApi = {
  getAll(pageSize: string, page: string) {
    return axiosClient.get<BaseResponse<BaseData<BlogType>[]>>(
      `${urls.BLOGS}?pagination[pageSize]=${pageSize}&pagination[page]=${page}&populate=*`
    );
  },
  getById(id: string) {
    return axiosClient.get<BaseResponse<BaseData<BlogType>>>(
      `${urls.BLOGS}/${id}?populate=*`
    );
  },
  getBySlug(slug: string) {
    return axiosClient.get<BaseResponse<BaseData<BlogType>>>(
      `${urls.BLOG}/${slug}?populate[0]=deep`
    );
  },
  getFrequentlyQuestion() {
    return axiosClient.get<BaseResponse<BaseData<frequentlyQuestion>[]>>(
      `frequently-questions?pagination[pageSize]=${6}&pagination[page]=${1}`
    )
  }
};

export default blogsApi;
