import { BaseData } from "@/type/base/baseData";
import { UserAddressType } from "@/utils/AddressType";
import GHNApi from "@/api/GHNApi";
import { useState } from "react";
import BasicModal from "@/components/common/Modal";
import { TrackingItemGHNType } from "@/type/response/GHN";
import dayjs from "dayjs";
import Scrollbars from "rc-scrollbars";
import TrackingLog from "@/components/common/TrackingLog";
import Button from "@/components/common/Button";
import constants from "@/utils/constants";
import clsx from "clsx";
import formatNumberPhone from "@/utils/formatNumberPhone";

export interface TrackingItemUI {
  actionAt: string;
  logs: {
    statusName: string;
    address: string;
  }[];
}
interface IContentHeaderProps {
  orderCode: string;
  status: string;
  statusText?: string;
  reason?: string;
  shippingOrderID?: string;
  userAddress?: BaseData<UserAddressType>;
  showOrderCode?: boolean;
  hasOrderDetail?: boolean;
}
const ContentHeader = (props: IContentHeaderProps) => {
  const {
    orderCode,
    status,
    statusText,
    reason,
    shippingOrderID,
    userAddress,
    showOrderCode = true,
    hasOrderDetail = true,
  } = props;

  const [openModal, setOpenModal] = useState<boolean>(false);

  const [trackingLogsData, setTrackingLogsData] = useState<TrackingItemUI[]>(
    []
  );
  const address = userAddress?.id
    ? `${userAddress?.attributes?.address?.street}, ${userAddress?.attributes?.address?.ward?.name},
     ${userAddress?.attributes?.address?.district?.name}, ${userAddress?.attributes?.address?.provinceCity?.name}`
    : "";

  const handleGetOrderTracking = async () => {
    if (!shippingOrderID) return;
    // test order code = G8KMNUBG
    const orderTrackingRes = await GHNApi.postOrderTracking("G8KMNUBG");
    const { tracking_logs } = orderTrackingRes.data.data;
    const trackingLogs = tracking_logs as TrackingItemGHNType[];
    const trackingLogTemp: TrackingItemUI[] = [];

    let preActionAt =
      trackingLogs.length > 0
        ? dayjs(trackingLogs[0].action_at).format(
            constants.DATE_FORMAT_ddMMyyyy
          )
        : "";

    let preStatusName = "";

    for (const logItem of trackingLogs) {
      if (preStatusName !== logItem.status_name.toString()) {
        const currActionAt = dayjs(logItem.action_at).format(
          constants.DATE_FORMAT_ddMMyyyy
        );
        const lengthTrackingLogTemp = trackingLogTemp.length;

        if (currActionAt === preActionAt && lengthTrackingLogTemp > 0) {
          trackingLogTemp[0].logs.unshift({
            statusName: logItem.status_name,
            address: logItem.location.address,
          });
        } else {
          trackingLogTemp.unshift({
            actionAt: currActionAt,
            logs: [
              {
                statusName: logItem.status_name,
                address: logItem.location.address,
              },
            ],
          });

          preActionAt = currActionAt;
        }

        preStatusName = logItem.status_name.toString();
      }
    }
    setTrackingLogsData(trackingLogTemp);
    setOpenModal(true);
  };

  const handelCancelModal = () => {
    setOpenModal(false);
  };

  return (
    <div
      className={
        (clsx("flex flex-col gap-[12px] "),
        hasOrderDetail ? "p-[24px_16px] md:p-0" : "px-[16px] pt-[16px]")
      }
    >
      {showOrderCode && (
        <div>
          <p className="text-[14px] md:text-[16px] font-[400] leading-[16.1px] md:leading-[19.36px] text-black p-0 md:p-[16px]">
            Đơn hàng{" "}
            <span className="text-[14px] md:text-[16px] font-[400] md:font-[700] leading-[16.1px] md:leading-[19.36px]">
              {orderCode}
            </span>{" "}
            {statusText && statusText}
          </p>
          {shippingOrderID &&
            status !== constants.CONFIRMED &&
            status !== constants.PENDING_CONFIRMATION && (
              <p className="block md:hidden text-[14px] font-[400] leading-[16.94px] text-black mt-[6px]">
                {`Mã vận đơn: ${shippingOrderID}`}
              </p>
            )}
        </div>
      )}

      {reason && (
        <p className="p-0 md:p-[12px_16px] text-[14px] md:text-[16px] text-[#484848] leading-[20pxx] md:leading-[22.4px] font-[400] italic">
          {reason}
        </p>
      )}
      {(status === constants.COMPLETED ||
        status === constants.SHIPPING_DELAYED) && (
        <div
          className={clsx(
            "p-[16px] bg-[#FCF9F2] rounded-[4px] md:rounded-[8px] ml-0 md:ml-[16px]",
            hasOrderDetail ? "mb-[24px] md:mb-[24px]" : "mt-[24px] md:mt-0"
          )}
        >
          <p className="text-[12px] md:text-[16px] text-[#FF9900] font-[400] leading-[16.1px] md:leading-[19.36px]">
            Đánh giá sản phẩm kèm hình ảnh nhận ngay voucher 30K!
          </p>
        </div>
      )}
      {hasOrderDetail && (
        <>
          <div className="p-0 md:p-[24px_16px]">
            <p className="*:text-[14px] md:*:text-[16px] *:text-black *:leading-[16.94px] md:*:leading-[19.3px] mb-[8px]">
              <span className="font-[700]">
                {userAddress?.attributes.fullName}
              </span>
              {userAddress?.attributes.phone && (
                <span className="font-[400]">
                  {" "}
                  - {formatNumberPhone(userAddress?.attributes.phone)}
                </span>
              )}
            </p>
            <p className="text-[14px] md:text-[16px] text-black leading-[16.94px] md:leading-[19.3px] font-[400]">
              {address}
            </p>

            {shippingOrderID && (
              <>
                <div className="block md:hidden w-full border-[1px] border-solid border-[#F0F0F0] my-[16px]"></div>
                <div className="flex justify-start items-center mt-0 md:mt-[16px]">
                  <p className="text-[14px] md:text-[16px] text-black leading-[16.94px] md:leading-[19.36px] font-[400]">
                    Theo dõi đơn hàng{" "}
                    <span className="text-[14px] md:text-[16px] text-black leading-[16.94px] md:leading-[19.36px] font-[500]">
                      {shippingOrderID}
                    </span>
                  </p>
                  <div
                    className="cursor-pointer ml-[4px]"
                    onClick={handleGetOrderTracking}
                  >
                    <span className="text-[#FF9900] text-[14px] font-[400] leading-[16.94px]">
                      tại đây
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
      <BasicModal
        isModalOpen={openModal}
        closeIcon={null}
        footer={null}
        onCancel={handelCancelModal}
        maskClosable
        handleCancel={() => {}}
        handleOk={() => {}}
        styles={{
          content: {
            padding: "24px",
            borderRadius: "24px",
          },
        }}
      >
        <Scrollbars
          style={{ maxWidth: "100vw", height: "366px", cursor: "move" }}
          autoHide
          renderTrackVertical={(props) => (
            <div {...props} className="track-vertical z-[9999]" />
          )}
        >
          <div className="mr-[15px]">
            <div className="flex justify-start item-center gap-[16px] *:text-[14px] *:font-[400] *:leading-[16.94px] *:text-black">
              <span className="min-w-[90px] w-[90px]">Mã vận đơn</span>
              <span>{shippingOrderID}</span>
            </div>
            <div className="mt-[32px] flex flex-col gap-[8px]">
              {trackingLogsData?.map((log, logIndex) => (
                <TrackingLog trackingLog={log} key={logIndex} />
              ))}
            </div>
          </div>
        </Scrollbars>
        <div className="w-full flex justify-center items-center mt-[32px]">
          <Button
            className="w-full rounded-[24px] group/button"
            textClassName="text-[16px] font-[600] leading-[24px] text-white group-hover/button:text-[#FF9900]"
            onClick={handelCancelModal}
          >
            Xong
          </Button>
        </div>
      </BasicModal>
    </div>
  );
};

export default ContentHeader;
