import { useEffect } from "react";
import chatApi from "@/api/chatApi";
import queryString from "query-string";
import socket from "@/api/socket";
import { UserProfileType } from "@/type/response/user";
import constants from "@/utils/constants";
import ChatContent from "./ChatContent";
import { UserRoomResponseType } from "@/type/response/chat";
import { BaseData } from "@/type/base/baseData";
import { ProductDetailsType } from "@/type/response/productDetails";

interface IChatFrameProps {
  inPage?: boolean;
  closeChat?: () => void;
  advisedProduct?: BaseData<ProductDetailsType>;
}

const ChatFrame = ({ inPage = false, closeChat, advisedProduct }: IChatFrameProps) => {
  const dataProfile = localStorage.getItem(constants.PROFILE);
  const profile: UserProfileType = dataProfile ? JSON.parse(dataProfile) : {};

  useEffect(() => {
    let roomData: UserRoomResponseType = {
      id: -1,
      roomId: ""
    };

    const connectRoom = async () => {
      const utmSource = sessionStorage.getItem('utm_source') || 'google';
      roomData = await chatApi
        .getUserRoom(queryString.stringify({ utm_source: utmSource }))
        .then(res => res.data)
      localStorage.setItem(constants.ROOM_ID, roomData.id.toString())
      localStorage.setItem(constants.ROOM_UUID, roomData.roomId)
    }

    connectRoom()

    socket.auth = {
      username: profile.username,
      user_id: profile.id,
      room: roomData.roomId
    }
    socket.connect()

    return () => {
      socket.disconnect()
    }
  }, [])

  return (
    <ChatContent inPage={inPage} closeChat={closeChat} advisedProduct={advisedProduct} />
  )
}

export default ChatFrame