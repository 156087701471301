import { useNavigate } from "react-router-dom";
import AgencyInfoSection from "../AgencyInfoSection";
import routes from "@/utils/routes";

function PartnerBrand() {
  const navigate = useNavigate();
  return (
    <div className="p-[40px_16px] md:p-[96px_160px] flex flex-col items-center justify-center mt-[64px]">
      <h2 className="text-[36px] md:text-[48px] font-[600] m-0 text-center">
        Trở thành đối tác bán hàng chỉ 3 bước
      </h2>
      <div className="p-[16px] md:p-[32px] flex flex-col gap-[16px] md:gap-[32px] border-[1px] border-[#E6E6E6] rounded-[24px] w-full md:max-w-[65%] mt-[64px]">
        <AgencyInfoSection
          title="Cung cấp thông tin "
          content="Chủ động cung cấp các thông tin chi tiết của Đại Lý bằng cách nhấp vào nút Đăng Ký bên dưới."
        />
        <AgencyInfoSection
          title="Ký kết hợp đồng"
          content="So’Natural sẽ liên hệ với Đại Lý để lên lịch hẹn và ký hợp đồng đối tác."
        />
        <AgencyInfoSection
          title="Nhận hàng và bán "
          content="Đơn hàng được gửi đến tận kho nơi bạn bán hàng"
          isDevided={false}
        />
      </div>
      <button
        className="md:max-w-[200px] w-full p-[12px_20px] md:p-[16px_28px] rounded-[24px] bg-black text-[#F0F0F0] font-[600] text-[16px] hover:text-[#FF9900] duration-300 mt-[64px] mb-[96px]"
        onClick={() => navigate(`${routes.PARTNER}${routes.REGISTER}`)}
      >
        Đăng ký ngay
      </button>
    </div>
  );
}

export default PartnerBrand;
