import clsx from "clsx";
import { FieldError, RegisterOptions } from "react-hook-form";
import styled from "styled-components";

export interface IInputFieldProps {
  placeholder?: string;
  name: string;
  register?: any;
  errors?: FieldError;
  type?: string;
  validateSchema?: RegisterOptions;
  control?: any;
  bgInput?: string;
  textColor?: string;
  className?: string;
}

const InputStyled = styled.input`
  box-shadow: none !important;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: none !important;
    -webkit-text-fill-color: #484848;
    -webkit-box-shadow: 0 0 0px 1000px #f6f2ef inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  &:-webkit-autofill:not(:placeholder-shown) {
    border: none !important;
  }
`;
export default function InputField({
  placeholder,
  name,
  register,
  errors,
  type,
  validateSchema,
  control,
  className,
}: IInputFieldProps) {
  return (
    <>
      <InputStyled
        control={control}
        className={clsx(
          `w-full p-4 placeholder-gray-500 bg-[#F6F2EF]
            font-normal text-[13px] md:text-[16px] border-none remove-arrow`,
          className
        )}
        name={name}
        type={type}
        placeholder={placeholder}
        {...register(name, validateSchema)}
      />
      {errors && <p className="text-[13px] text-[#F04438]">{errors.message}</p>}
    </>
  );
}
