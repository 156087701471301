import routineApi from "@/api/routineApi";
import images from "@/assets/images";
import RoutineDescription from "@/components/pages/Routines/RoutineDescription";
import { BaseData } from "@/type/base/baseData";
import { RoutineType } from "@/type/response/routine";

import routes from "@/utils/routes";
import Breadcrumb from "@/components/common/Breadcrumb";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function RoutineDetails() {
  const { slug } = useParams<{ slug: string }>();
  const [routineDetail, setRoutineDetail] =
    useState<BaseData<RoutineType> | null>(null);
  if (!slug) {
    return <div>Không tồn tại routine chi tiết!</div>;
  }

  useEffect(() => {
    const fetchDetail = async () => {
      try {
        const res = await routineApi.getBySlug(slug);
        if (res?.status === 200 && res?.data?.data?.length) {
          setRoutineDetail(res?.data?.data[0]);
        }
      } catch (error) {
        console.error("Error fetching routine details:", error);
      }
    };
    fetchDetail();
  }, [slug]);

  const breadcrumbItems = [
    {
      href: routes.HOME,
      title: "Trang chủ",
    },
    {
      href: routes.ROUTINE,
      title: "Routine",
    },
    {
      href: routes.ROUTINE,
      title: routineDetail?.attributes?.name || "",
    },
  ];

  return (
    <div className="bg-[#f7f7f7]">
      <div className="p-[0_16px_0_16px] bg-[#f7f7f7]">
        <Breadcrumb items={breadcrumbItems} />
      </div>
      <div
        style={{
          backgroundImage: `url(${images.routineBannerDetail})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "120px",
          width: "100%",
        }}
      ></div>

      {routineDetail && (
        <RoutineDescription
          products={routineDetail?.attributes?.products?.data}
          key={1}
          id={0}
        />
      )}
    </div>
  );
}

export default RoutineDetails;
