/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/extensions */
import { BaseData } from '@/type/base/baseData';
import { ImageType } from '@/type/common/image';
import { Image } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { FreeMode, Navigation, Pagination, Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const SwiperStyle = styled.div`
  .swiper {
  }
  .swiper-pagination {
    margin-top: 16px;
    margin-bottom: 20px;
    position: relative;
    top: 0;
    left: 0;
  }

  .swiper-pagination-bullet {
    background-color: black !important;
    width: 5px;
    height: 5px;
  }

  .swiper-pagination-bullet-active {
    transition: width 0.3s;
    width: 18px;
    border-radius: 10px 10px;
    background-color: #d9d9d9 !important;
    top: 0 !important;
    z-index: 500;
  }
`;

interface DetailsCarouselComponentProps {
  imageMain?: BaseData<ImageType>;
  imageChildren?: BaseData<ImageType>[];
}

export default function DetailsCarouselComponent({
  imageMain,
  imageChildren,
}: DetailsCarouselComponentProps) {
  const [openModel, setOpenModel] = useState<boolean>(false);
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);

  const totalImage = [...(imageChildren || []), imageMain];
  const countImage =
    imageChildren?.length !== undefined ? imageChildren.length : 0;
  const remainingImages = countImage > 3 ? countImage - 3 : 0;

  const handleViewMore = () => {
    setOpenModel(true);
  };

  const getUrlImages = (totalImages: (BaseData<ImageType> | undefined)[]) => {
    if (!totalImages) return [];
    return totalImages?.map((image) => {
      if (image) {
        return image.attributes.url;
      } else {
        return '';
      }
    });
  };

  const handleRenderImageChildren = () => {
    return remainingImages !== 0
      ?
      <div className={`cursor-pointer relative`} >
        <Image
          src={imageChildren?.[3]?.attributes?.url}
          alt=""
          className="size-[90px] md:size-[118px] min-w-[90px] md:min-w-[118px] aspect-square rounded-[24px]"
        />
        <div
          className="absolute top-0 left-0 right-0 bottom-0 bg-[#00000060] aspect-square flex justify-center items-center cursor-pointer z-10 rounded-[24px]"
          onClick={handleViewMore}
        >
          <div className="font-[700] text-[24px] text-[#FFFFFF] uppercase leading-[33.6px] select-none">
            +{remainingImages}
          </div>
        </div>
      </div>

      : imageChildren?.map((image, index) => (
        <div
          className="details__carousel-image--item w-full aspect-square shadow-md cursor-pointer"
          key={index}
        >
          <Image
            src={image?.attributes.url || ''}
            alt=""
            className="w-full aspect-square object-cover"
            loading="lazy"
          />
        </div>
      ));
  };
  return (
    <>
      {/* for layout mobile */}
      <div className="block md:hidden">
        <SwiperStyle>
          <Swiper
            observer={true}
            observeParents={true}
            onSwiper={(swiper) => swiper.update()}
            pagination={{
              type: 'fraction',
            }}
            modules={[Pagination]}

          >
            {totalImage?.map((image, index) => (
              <SwiperSlide key={index}>
                <div>
                  <Image
                    src={image?.attributes.url}
                    alt={image?.attributes.caption}
                    className="w-full h-full object-cover"
                    loading="lazy"
                  />
                </div>
              </SwiperSlide>
            ))}
            <div className="sniper-controller flex justify-center items-center">
              <div className="sniper-pagination-2 mx-auto mt-3" />
            </div>
          </Swiper>
        </SwiperStyle>
      </div>

      {/* for layout desktop */}
      <div className="hidden md:block">
        <Swiper
          loop={true}
          spaceBetween={10}
          navigation={true}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper2 mb-[24px]"
        >
          {totalImage.map((image, index) => {
            return (
              <SwiperSlide key={index}>
                <Image
                  src={image?.attributes?.url}
                  alt=""
                  className="rounded-[24px]"
                  preview={{
                    maskClassName: "rounded-[24px]"
                  }}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
        <div className="flex gap-[24px] h-[90px] md:h-[118px]">
          <Swiper
            onSwiper={setThumbsSwiper}
            loop={true}
            spaceBetween={24}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper"
            breakpoints={{
              '1024': {
                slidesPerView: 1,
              },
              '1100': {
                slidesPerView: 2,
              },
              '1300': {
                slidesPerView: 3,
              }
            }}
          >
            {totalImage.slice(0, 3).map((image, index) => {
              return (
                <SwiperSlide key={index}>
                  <img
                    src={image?.attributes?.url}
                    alt=""
                    className="size-[90px] md:size-[118px] min-w-[90px] md:min-w-[118px] aspect-square cursor-pointer rounded-[24px] lg"
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
          {handleRenderImageChildren()}
        </div>
        {totalImage && (
          <Image.PreviewGroup
            preview={{
              visible: openModel,
              onVisibleChange: setOpenModel,
            }}
            items={getUrlImages(totalImage)}

          >

          </Image.PreviewGroup>
        )}
      </div>

    </>
  );
}
