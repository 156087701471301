import urls from "@/utils/urls";
import axiosClient from "./axiosClient";
import { BaseResponse } from "@/type/base/baseResponse";
import { BaseData } from "@/type/base/baseData";
import {
  FilterProductsResponseType,
  FilterProducts_Brands,
  FilterProducts_Lines,
  FilterProducts_ProgrammeType,
  FilterProducts_Textures,
} from "@/type/response/filterProducts";

const filterSkinProperties = {
  getAll: () => {
    return axiosClient.get<BaseResponse<BaseData<FilterProductsResponseType>>>(
      `${urls.FILTER_SKIN_PROPERTIES}`
    );
  },
};

const filterProgrammes = {
  getAll: (query?: string) => {
    return axiosClient.get<
      BaseResponse<BaseData<FilterProducts_ProgrammeType>[]>
    >(`${urls.FILTER_PROGRAMMES}?${query}`);
  },
};

const filterProductLines = {
  getAll: () => {
    return axiosClient.get<BaseResponse<BaseData<FilterProducts_Lines>>>(
      `${urls.FILTER_PRODUCT_LINES}`
    );
  },
};

const filterProductBrandsApi = {
  getAll: () => {
    return axiosClient.get<BaseResponse<BaseData<FilterProducts_Brands>>>(
      urls.FILTER_PRODUCT_BRANDS
    );
  },
};

const filterProductTexturesApi = {
  getAll: () => {
    return axiosClient.get<BaseResponse<BaseData<FilterProducts_Textures>>>(
      urls.FILTER_PRODUCT_TEXTURES
    );
  },
};

export {
  filterSkinProperties,
  filterProgrammes,
  filterProductLines,
  filterProductBrandsApi,
  filterProductTexturesApi,
};
