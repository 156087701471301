import svgs from "@/assets/svgs";
import { Image, Input, InputProps } from "antd";
import clsx from "clsx";
import { Controller, FieldError, RegisterOptions } from "react-hook-form";

interface INewInputFieldProps extends InputProps {
  isPassword?: boolean;
  label?: string;
  labelClassName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: any;
  name: string;
  rules?: RegisterOptions;
  errors?: FieldError;
  helptext?: string;
}

const NewInputField = (props: INewInputFieldProps) => {
  const {
    isPassword,
    label,
    labelClassName,
    className,
    control,
    name,
    rules,
    errors,
    helptext,
    ...rest
  } = props;

  if (!control) {
    return (
      <div className="flex flex-col gap-[0.6rem]">
        {!!label && (
          <label
            className={clsx(
              "font-[500] text-[14px] leading-[20px] text-[#000000]",
              labelClassName
            )}
          >
            {label}
          </label>
        )}
        <div className="border-b-[1px] border-solid border-[#808080]">
          <Input
            className={clsx(
              `w-full px-[14px] py-[10px] placeholder-[#818080]
            font-normal text-[16px] text-[#000000] leading-[24px] border-none remove-arrow`,
              className
            )}
            autoComplete="on"
            {...rest}
          />
        </div>
        {helptext && (
          <p className="text-[14px] text-[#667085] leading-[20px] font-[500]">
            {helptext}
          </p>
        )}
        {errors && (
          <p className="text-[14px] text-[#F04438]leading-[20px] font-[500]">
            {errors.message}
          </p>
        )}
      </div>
    );
  }

  if (isPassword) {
    return (
      <div className="flex flex-col gap-[6px]">
        {!!label && (
          <label
            className={clsx(
              "font-[500] text-[14px] leading-[20px] text-[#000000]",
              labelClassName
            )}
          >
            {label}
          </label>
        )}

        <div className="border-b-[1px] border-solid border-[#808080]">
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Input.Password
                className={clsx(
                  `w-full px-[14px] py-[10px] placeholder-[#818080]
                font-normal text-[16px] text-[#000000] leading-[24px] border-none remove-arrow custom-input`,
                  className
                )}
                iconRender={(visible) =>
                  visible ? (
                    <Image
                      src={svgs.showPassword}
                      alt="hide-password"
                      preview={false}
                    />
                  ) : (
                    <Image
                      src={svgs.hidePassword}
                      alt="show-password"
                      preview={false}
                    />
                  )
                }
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                ref={ref}
                autoComplete="on"
                {...rest}
              />
            )}
          />
        </div>
        {helptext && (
          <p className="text-[14px] text-[#667085] leading-[20px] font-[500]">
            {helptext}
          </p>
        )}
        {errors && (
          <p className="text-[14px] text-[#F04438] leading-[20px] font-[500]">
            {errors.message}
          </p>
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-[0.6rem]">
      {!!label && (
        <label
          className={clsx(
            "font-[500] text-[14px] leading-[20px] text-[#000000]",
            labelClassName
          )}
        >
          {label}
        </label>
      )}

      <div className="border-b-[1px] border-solid border-[#808080]">
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Input
              className={clsx(
                `w-full px-[14px] py-[10px] placeholder-[#818080]
            font-normal text-[16px] text-[#000000] leading-[24px] border-none remove-arrow`,
                className
              )}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
              autoComplete="on"
              {...rest}
            />
          )}
        />
      </div>

      {helptext && (
        <p className="text-[14px] text-[#667085] leading-[20px] font-[500]">
          {helptext}
        </p>
      )}
      {errors && (
        <p className="text-[14px] text-[#F04438] leading-[20px] font-[500]">
          {errors.message}
        </p>
      )}
    </div>
  );
};

export default NewInputField;
