import { BaseData } from '@/type/base/baseData';
import { BlogType } from '@/type/response/blog';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

interface BlogProps {
  blog: BaseData<BlogType>;
  isMain?: boolean | false;
  buttonViewMore?: boolean | true;
  buttonViewCenter?: boolean | true;
  buttonViewRight?: boolean | false;
  inSearchResult?: boolean;
}

export default function Blog({
  blog,
  isMain,
  buttonViewMore,
  buttonViewCenter,
  buttonViewRight,
  inSearchResult,
}: BlogProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (inSearchResult) {
    return (
      <Link to={blog.attributes.slug} className="flex gap-[16px] md:gap-[24px] max-w-[893px]">
        <div className="min-w-[120px] md:min-w-[240px] h-[77.5px] md:h-[155px] object-cover">
          <img
            src={blog?.attributes?.image?.data?.attributes?.url || ''}
            alt={blog?.attributes?.image?.data?.attributes?.caption || ''}
            className="w-full h-full object-cover object-center rounded-[24px]"
            loading="lazy"
          />
        </div>
        <div className="content flex justify-center items-start flex-col md:justify-start gap-[16px]">
          <div className="title">
            <h3 className="text-[16px] md:text-[18px] font-[600] leading-[19px] my-0 line-clamp-1 md:line-clamp-2 text-ellipsis">
              {blog?.attributes?.title || ''}
            </h3>
          </div>
          <div className="short-description">
            <p className="text-[14px] md:text-[16px] font-[400] line-clamp-3 text-ellipsis md:line-clamp-3">
              {blog?.attributes?.shortDescription || ''}
            </p>
          </div>
        </div>
      </Link>
    )
  }

  return (
    <>
      {isMain ? (
        <div className="md:grid md:grid-cols-12 md:gap-5 bg-white">
          <div className=" col-span-5">
            {/* image for mobile */}
            <Link to={`${blog.attributes.slug}`}>
              <div className={`w-full h-[200px] md:hidden`}>
                <img
                  src={blog?.attributes?.image?.data?.attributes?.url || ''}
                  alt={blog?.attributes?.image?.data?.attributes?.caption || ''}
                  className="w-full h-full object-cover object-center md:hidden"
                  loading="lazy"
                />
              </div>
            </Link>

            {/* for desktop */}
            <div className="w-full h-[275px] hidden md:block" onClick={() => navigate(`/blogs/${blog.attributes.slug}`)}>
              <img
                src={blog?.attributes?.image?.data?.attributes?.url || ''}
                alt={blog?.attributes?.image?.data?.attributes?.caption || ''}
                className="w-full h-full object-cover hidden md:block"
                loading="lazy"
              />
            </div>
          </div>

          <div className="content flex justify-center items-start flex-col col-span-7 md:justify-start px-4 my-4">
            <div className="title">
              <h3 className=" text-base text-[#484848] font-bold md:text-xl my-0">
                {blog?.attributes?.title || ''}
              </h3>
            </div>
            <div className="short-description mt-4">
              <p className=" text-xs font-normal line-clamp-2 text-ellipsis text-[#818080] md:text-base md:line-clamp-3">
                {blog?.attributes?.shortDescription || ''}
              </p>
            </div>

            {buttonViewMore && (
              <div
                className={`flex items-center ${buttonViewCenter && 'justify-center'
                  } ${buttonViewRight && 'justify-end'} w-full`}
              >
                <Link
                  to={`${blog.attributes.slug}`}
                  className="text-[#A77B5A] text-base hidden md:inline-block underline"
                >
                  {t('viewMoreBlog')}
                </Link>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-12 gap-4 bg-white px-2 py-4">
          <div className=" col-span-5">
            <Link to={`/blogs/${blog.attributes.slug}`}>
              <div className="w-full h-[66px] md:h-[222px]">
                {/* image for desktop */}
                <img
                  src={blog?.attributes?.image?.data?.attributes?.url || ''}
                  alt={blog?.attributes?.image?.data?.attributes?.caption || ''}
                  className="w-full h-full object-cover hidden md:block"
                  loading="lazy"
                />

                {/* image for mobile */}
                <img
                  src={blog?.attributes?.image?.data?.attributes?.url || ''}
                  alt={blog?.attributes?.image?.data?.attributes?.caption}
                  className="w-full h-full object-cover md:hidden"
                  loading="lazy"
                />
              </div>
            </Link>
          </div>
          <div className="content flex justify-center items-start gap-2 flex-col col-span-7 md:justify-start">
            <div className="topic">
              <h4 className=" text-xs font-normal text-[#000000] md:text-base my-0">
                {blog?.attributes?.blogCategory?.data?.attributes?.name || ''}
              </h4>
            </div>
            <Link to={`/blogs/${blog?.attributes.slug}`} className="title">
              <h3 className=" text-base text-[#484848] font-normal md:text-xl my-0">
                {blog?.attributes?.title || ''}
              </h3>
            </Link>
            <div className="short-description">
              <p className=" text-xs font-normal line-clamp-3 text-ellipsis text-[#818080] md:text-base">
                {blog?.attributes?.shortDescription || ''}
              </p>
            </div>

            {buttonViewMore && (
              <div
                className={`flex items-center ${buttonViewCenter && 'justify-center'
                  } ${buttonViewRight && 'justify-end'} w-full`}
              >
                <Link
                  to={`/blogs/${blog?.attributes.slug}`}
                  className="text-[#A77B5A] text-base hidden md:inline-block"
                >
                  {t('viewMoreBlog')}
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
