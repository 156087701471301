import images from "@/assets/images";
import { BaseData } from "@/type/base/baseData";
import { ProductsResponse } from "@/type/response/products";
import LabelPercent from "./LabelPercent";
import { getDiscountProduct } from "@/utils/discount";
import AddToCart from "@/assets/svgs/custom/AddToCart";
import LabelText, { ILabelTextProps } from "./LabelText";
import { useState } from "react";
import AddToCartModal from "../Modal/AddToCart";
import { BaseProductData } from "@/type/base/baseProduct";

export interface IThumbTypeProps extends ILabelTextProps {
  thumbType:
    | "primary"
    | "best-seller"
    | "flashsale"
    | "bill"
    | "outstock"
    | "chat"
    | "horizontal";
}

interface IThumbProps extends IThumbTypeProps {
  product: BaseData<ProductsResponse | BaseProductData>;
  showPercentDiscount?: boolean;
}

const Thumb = (props: IThumbProps) => {
  const {
    product,
    thumbType = "primary",
    thumbLabelType,
    levelTop,
    showPercentDiscount = true,
  } = props;

  const [isOpenAddToCartModal, setIsOpenAddToCartModal] = useState(false);

  const price = product?.attributes?.price;
  const pricePromo =
    thumbType === "flashsale"
      ? product?.attributes?.flashSalePrice
      : product?.attributes?.pricePromotion;

  const discountPercent = getDiscountProduct(price, pricePromo);

  if (thumbType === "chat") {
    return (
      <div className="max-w-[60px] md:max-h-[60px] w-full h-full relative box-border">
        <img
          src={
            product?.attributes?.avatar?.data?.attributes?.url ??
            images.productOne
          }
          alt=""
          className="object-cover object-center rounded-[8px] transition-opacity"
          height={"100%"}
          width={"100%"}
          loading="lazy"
        />
      </div>
    );
  }

  if (thumbType === "outstock") {
    return (
      <div className="group/thumb max-w-full max-h-full w-full h-full relative box-border">
        <img
          src={
            product?.attributes?.avatar?.data?.attributes?.url ??
            images.productOne
          }
          alt=""
          className="object-cover object-center rounded-[16px] transition-opacity"
          height={"100%"}
          width={"100%"}
          loading="lazy"
        />
        <div className="transition-opacity opacity-100 hover:bg-opacity-100 absolute top-0 left-0 right-0 bottom-0 bg-[#00000033] rounded-[16px]"></div>
        <div className="opacity-100 absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
          <p className="text-[18px] font-[400] text-center">Tạm hết</p>
        </div>
        <LabelPercent percent={discountPercent} />
      </div>
    );
  }

  if (thumbType === "bill") {
    return (
      <div className="w-[84px] h-[84px] md:w-[120px] md:h-[120px]">
        <img
          src={
            product?.attributes?.avatar?.data?.attributes?.url ??
            images.productOne
          }
          alt=""
          className="object-cover object-center rounded-[16px] transition-opacity"
          height={"100%"}
          width={"100%"}
          loading="lazy"
        />
      </div>
    );
  }

  if (thumbType === "flashsale") {
    return (
      <>
        <div className="group/thumb max-w-full max-h-full w-full h-full relative box-border border-[3px] border-[#F90] rounded-[19px]">
          <img
            src={
              product?.attributes?.avatar?.data?.attributes?.url ??
              images.productOne
            }
            alt=""
            className="object-cover object-center rounded-[16px] transition-opacity"
            height={"100%"}
            width={"100%"}
            loading="lazy"
          />
          <div className="hidden md:block transition-opacity opacity-0 group-hover/thumb:opacity-100 hover:bg-opacity-100 absolute top-0 left-0 right-0 bottom-0 bg-[#00000033] rounded-[16px]"></div>
          <div className="hidden opacity-0 group-hover/thumb:opacity-100 absolute top-0 left-0 right-0 bottom-0 md:flex items-center justify-center">
            <AddToCart onClick={() => setIsOpenAddToCartModal(true)} />
          </div>
          <LabelPercent percent={discountPercent} />
          {!!thumbLabelType && (
            <LabelText thumbLabelType={thumbLabelType} levelTop={levelTop} />
          )}
        </div>
        <AddToCartModal
          isOpenAddToCartModal={isOpenAddToCartModal}
          setIsOpenAddToCartModal={setIsOpenAddToCartModal}
          product={product}
          thumbType={thumbType}
        />
      </>
    );
  }

  if (thumbType === "horizontal") {
    return (
      <>
        <div className="group/thumb min-w-[88px] min-h-[88px] w-[88px] h-[88px] md:min-w-[135px] md:min-h-[135px] md:w-[135px] md:h-[135px] relative box-border">
          <img
            src={
              product?.attributes?.avatar?.data?.attributes?.url ??
              images.productOne
            }
            alt=""
            className="object-cover object-center rounded-[8px] md:rounded-[16px] transition-opacity group-hover/thumb:opacity-30"
            height={"100%"}
            width={"100%"}
            loading="lazy"
          />
          <div className="hidden group-hover/thumb:transition-opacity opacity-0 group-hover/thumb:opacity-100 hover:bg-opacity-100 absolute top-0 left-0 right-0 bottom-0 bg-[#00000033] rounded-[16px] md:flex items-center justify-center"></div>
          <div className="hidden opacity-0 group-hover/thumb:opacity-100 absolute top-0 left-0 right-0 bottom-0 md:flex items-center justify-center">
            <AddToCart onClick={() => setIsOpenAddToCartModal(true)} />
          </div>
          {showPercentDiscount && <LabelPercent percent={discountPercent} />}
          {!!thumbLabelType && (
            <LabelText thumbLabelType={thumbLabelType} levelTop={levelTop} />
          )}
        </div>
        <AddToCartModal
          isOpenAddToCartModal={isOpenAddToCartModal}
          setIsOpenAddToCartModal={setIsOpenAddToCartModal}
          product={product}
          thumbType={thumbType}
        />
      </>
    );
  }

  return (
    <>
      <div className="group/thumb max-w-full max-h-full w-full h-full relative box-border">
        <img
          src={
            product?.attributes?.avatar?.data?.attributes?.url ??
            images.productOne
          }
          alt=""
          className="object-cover object-center rounded-[16px] transition-opacity group-hover/thumb:opacity-30"
          height={"100%"}
          width={"100%"}
          loading="lazy"
        />
        <div className="hidden group-hover/thumb:transition-opacity opacity-0 group-hover/thumb:opacity-100 hover:bg-opacity-100 absolute top-0 left-0 right-0 bottom-0 bg-[#00000033] rounded-[16px] md:flex items-center justify-center"></div>
        <div className="hidden opacity-0 group-hover/thumb:opacity-100 absolute top-0 left-0 right-0 bottom-0 md:flex items-center justify-center">
          <AddToCart onClick={() => setIsOpenAddToCartModal(true)} />
        </div>
        <LabelPercent percent={discountPercent} />
        {!!thumbLabelType && (
          <LabelText thumbLabelType={thumbLabelType} levelTop={levelTop} />
        )}
      </div>
      <AddToCartModal
        isOpenAddToCartModal={isOpenAddToCartModal}
        setIsOpenAddToCartModal={setIsOpenAddToCartModal}
        product={product}
        thumbType={thumbType}
      />
    </>
  );
};

export default Thumb;
