import { Select, SelectProps } from "antd";
import clsx from "clsx";
import { Controller, FieldError, RegisterOptions } from "react-hook-form";

interface INewSelectFieldProps extends SelectProps {
  name: string;
  rules?: RegisterOptions;
  errors?: FieldError;
  helptext?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: any;
  containerClassName?: string;
  className?: string;
  labelClassName?: string;
  label?: string;
  isRequired?: boolean;
}

const NewSelectField = (props: INewSelectFieldProps) => {
  const {
    name,
    rules,
    errors,
    helptext,
    options,
    control,
    containerClassName,
    className,
    labelClassName,
    label,
    isRequired,
    ...rest
  } = props;
  if (!control)
    return (
      <div
        className={clsx("w-auto flex flex-col gap-[6px]", containerClassName)}
      >
        {!!label && (
          <label
            className={clsx(
              "font-[500] text-[14px] leading-[20px] text-[#484848]",
              labelClassName
            )}
          >
            {label}
          </label>
        )}

        <Select
          className={clsx(
            "py-[10px] pl-[14px] w-full font-[400] text-[14px] md:text-[14px] text-[#818080] leading-[20px] border-[#000] hover:!border-[#000] focus:border-[#000] focus:shadow-none",
            className
          )}
          {...rest}
        >
          {options?.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  return (
    <div
      className={clsx("w-auto flex flex-col gap-[0.6rem]", containerClassName)}
    >
      {!!label && (
        <label
          className={clsx(
            "font-[500] text-[14px] leading-[20px] text-[#484848]",
            labelClassName
          )}
        >
          {label}
        </label>
      )}
      <div className="flex flex-row border-b-[1px] border-solid border-[#808080]">
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Select
              className={clsx(
                "py-[10px] pl-[14px] w-full font-[400] text-[14px] placeholder-[#818080] text-[#000000] leading-[20px] border-[#000] hover:!border-[#000] focus:border-[#000] focus:shadow-none placeholder:text-[13px] placeholder:md:text-[14px] placeholder:text-[#818080]",
                className
              )}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
              {...rest}
            >
              {options?.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          )}
        />
      </div>
      {helptext && (
        <p className="text-[14px] text-[#667085] leading-[20px] font-[500]">
          {helptext}
        </p>
      )}
      {errors && (
        <p className="text-[14px] text-[#F04438] leading-[20px] font-[500] text-right">
          {errors.message}
        </p>
      )}
    </div>
  );
};

export default NewSelectField;
