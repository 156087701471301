import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { StyleProvider } from "@ant-design/cssinjs";
import * as Sentry from "@sentry/react";
import App from "./App";
import "antd/dist/reset.css";
import "./index.css";
import "./styles/styles.scss";
import "@/assets/scss/styles.scss";
import "@/assets/localization/i18n";
import { Toaster } from "react-hot-toast";
import svgs from "./assets/svgs";
import { ConfigProvider } from "antd";

Sentry.init({
  dsn: "",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", ""],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <StyleProvider hashPriority="high">
    <BrowserRouter>
      <RecoilRoot>
        <ConfigProvider
          theme={{
            token: {
              marginXS: 2,
            },
          }}
        >
          <App />
          <Toaster
            position="top-center"
            containerClassName="flex item-center"
            toastOptions={{
              className: "max-w-full w-full md:w-[1120px]",
              style: {
                border: "none",
                borderRadius: 24,
                padding: "12px 24px",
                color: "#FFFFFF",
                background: "#000000B2",
              },
              success: {
                icon: <img src={svgs.checkSuccess} width={18} />,
              },
              error: {
                icon: <img src={svgs.xError} width={18} />,
              },
              duration: 4000,
            }}
          />
        </ConfigProvider>
      </RecoilRoot>
    </BrowserRouter>
  </StyleProvider>
);
