import urls from '@/utils/urls';
import axiosClient from './axiosClient';
import { agencyRequestType } from "@/type/request/agency";

const agencyApi = {
  post(params: agencyRequestType) {
    return axiosClient.post(urls.AGENCIES, { data: params });
  },
};
export default agencyApi;

