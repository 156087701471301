import StarSkeleton from './star';

interface ProductSkeletonProps {
  showRating?: boolean | false;
  showButton?: boolean | false;
}

export default function ProductSkeleton({
  showButton,
  showRating,
}: ProductSkeletonProps) {
  return (
    <div className="flex flex-col justify-start items-start gap-[10px]">
      <div className="w-full h-[167px] bg-gray-300 animate-pulse"></div>
      <div className="flex flex-col justify-start items-start gap-1 w-full">
        <div className="w-full block h-[20px] bg-gray-300 animate-pulse"></div>
        <div className="w-full block h-[20px] bg-gray-300 animate-pulse"></div>
      </div>
      <div className="flex flex-col items-start justify-start gap-1 w-full">
        <div className="w-[40%] h-[10px] bg-gray-300 animate-pulse"></div>
        <div className="w-[40%] h-[10px] bg-gray-300 animate-pulse"></div>
      </div>
      <div className="flex justify-between items-end gap-3 w-full">
        <div className="flex flex-col items-start justify-start gap-1 flex-1">
          <div className="w-full h-[20px] bg-gray-300 animate-pulse"></div>
          <div className="w-full h-[20px] bg-gray-300 animate-pulse"></div>
        </div>
        <div className="w-full h-[20px] bg-gray-300 animate-pulse flex-1"></div>
      </div>
      {showRating && <StarSkeleton />}
      {showButton && (
        <div className="h-[40px] w-full bg-gray-300 animate-pulse"></div>
      )}
    </div>
  );
}
