import { BaseData } from "@/type/base/baseData";
import { ProductsResponse } from "@/type/response/products";
import Thumb, { IThumbTypeProps } from "./Thumb";
import Content, { IContentPageProps } from "./Content";
import { ILabelTextProps } from "./LabelText";
import { BaseProductData } from "@/type/base/baseProduct";
import clsx from "clsx";

interface IProductProps
  extends IThumbTypeProps,
    ILabelTextProps,
    IContentPageProps {
  product: BaseData<ProductsResponse | BaseProductData>;
  handleOnClick?: (
    product: BaseData<ProductsResponse | BaseProductData>
  ) => void;
  selectedProducts?: BaseData<ProductsResponse>[]; // for chat
  rootClassName?: string;
  showPercentDiscount?: boolean;
}

const Product = (props: IProductProps) => {
  const {
    product,
    handleOnClick,
    selectedProducts = [],
    rootClassName,
  } = props;

  if (props.thumbType === "chat") {
    const inSelectedProducts =
      selectedProducts?.findIndex((item) => item.id === product.id) !== -1;

    return (
      <div
        className={clsx(
          "flex items-start p-[8px] gap-[16px] border rounded-[8px] mt-[12px] w-full",
          inSelectedProducts
            ? "border-[#F90] cursor-pointer"
            : selectedProducts.length === 4
            ? "bg-[#E6E6E6] cursor-default"
            : "border-[#E6E6E6] cursor-pointer"
        )}
        onClick={() => handleOnClick?.(product)}
      >
        <Thumb {...props} />
        <Content {...props} />
      </div>
    );
  }

  if (props.thumbType === "horizontal") {
    return (
      <div
        className={clsx(
          rootClassName || "flex flex-row items-start gap-[16px]"
        )}
      >
        <Thumb {...props} />
        <Content showPriceHorizontal {...props} />
      </div>
    );
  }

  return (
    <div className={clsx(rootClassName || "flex flex-col gap-[8px]")}>
      <Thumb {...props} />
      <Content showPriceHorizontal {...props} />
    </div>
  );
};

export default Product;
