import authApi from "@/api/authApi";
import { FormRegisterSubmitType } from "@/type/request/form";
import routes from "@/utils/routes";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { Image, Modal } from "antd";
import { useEffect, useRef, useState } from "react";
import images from "@/assets/images";
import NewButtonComponent from "@/components/common/NewButton";
import NewInputField from "@/components/common/Formfield/NewInputField";
import NewSelectField from "@/components/common/Formfield/NewSelectField";
import {
  generateDays,
  generateMonths,
  generateYears,
} from "@/utils/generateDateList";
import { formValidation } from "@/utils/formValidation";
import constants from "@/utils/constants";
import { useForm } from "react-hook-form";
import clsx from "clsx";

interface IFormValues {
  fullName: string;
  phone: string;
  email: string;
  password: string;
  confirmPassword: string;
  dayOfBirth: string;
  monthOfBirth: string;
  yearOfBirth: string;
}

const yearData = generateYears(1920, new Date().getFullYear());
const monthData = generateMonths();
export default function Register() {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem(constants.ACCESS_TOKEN);
  useEffect(() => {
    if (isLoggedIn) {
      navigate(routes.HOME);
    }
  }, [isLoggedIn, navigate]);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    reset,
  } = useForm<IFormValues>({
    defaultValues: {
      fullName: "",
      password: "",
      confirmPassword: "",
      phone: "",
    },
    mode: "onChange",
  });

  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [dayData, setDayData] = useState<string[]>();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>("");
  const [timming, setTimming] = useState<number>(5);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [yearOfBirth, monthOfBirth, password] = watch([
    "yearOfBirth",
    "monthOfBirth",
    "password",
  ]);
  const [checkedPolicy, setCheckedPolicy] = useState<boolean>(false);
  const firstCheckedPolicy = useRef<boolean>(true);
  const [errorMsgPolicy, setErrorMsgPolicy] = useState<string>("");
  const onSubmit = (data: IFormValues) => {
    setLoading(true);
    const utmSource = sessionStorage.getItem("utm_source") || "google";

    const registerData: FormRegisterSubmitType = {
      full_name: data.fullName,
      phone: data.phone,
      username: data.email,
      password: data.password,
      email: data.email,
      utm_source: utmSource,
      birthday: `${data.yearOfBirth}-${data.monthOfBirth}-${data.dayOfBirth}`,
    };

    authApi
      .register(registerData)
      .then((resData) => {
        reset();
        toast.success(t("registerSuccess"));
        if (!!localStorage.getItem("openModelVoucher")) {
          setUserName(resData.data.user.fullName || resData.data.user.userName);
          setIsOpenModal(true);
        }
        navigate("/login");
        setLoading(false);
      })
      .catch((error) => {
        console.error("error", error);
        toast.error(t("registerFail"));
        setLoading(false);
      });
  };

  useEffect(() => {
    if (yearOfBirth && monthOfBirth) {
      setDayData(generateDays(Number(yearOfBirth), Number(monthOfBirth)));
    }
  }, [yearOfBirth, monthOfBirth]);

  useEffect(() => {
    if (timming === 0 && isOpenModal) {
      setIsOpenModal(false);
    }
    if (timming > 0 && isOpenModal) {
      const timer = setTimeout(() => setTimming(timming - 1), 1000);

      return () => clearTimeout(timer);
    }
  }, [timming, isOpenModal]);

  const handleContinueBuy = () => {
    const redirect = searchParams.get("redirect");
    if (redirect) {
      window.location.href = redirect;
      return;
    }
    navigate("/");
  };

  const handleTogglePolicy = () => {
    setCheckedPolicy(!checkedPolicy);
  };

  useEffect(() => {
    if (firstCheckedPolicy.current) {
      firstCheckedPolicy.current = false;
      return;
    }

    if (!checkedPolicy) {
      setErrorMsgPolicy("Vui lòng đồng ý với chính sách của chúng tôi");
    } else {
      setErrorMsgPolicy("");
    }
  }, [checkedPolicy]);

  return (
    <div className="bg-[#F7F7F7] ">
      <div className="flex flex-col lg:flex-row justify-between items-start gap-[8px] px-0 pt-[8px]">
        <div className="flex-1 flex flex-col justify-center items-center bg-[#FFFFFF] min-h-[1000px]">
          <div className="flex flex-col justify-center sm:w-[350px] md:w-full lg:w-[400px] py-[32px] px-[16px] lg:px-0">
            <h1 className="text-[#000000] text-center text-[20px] lg:text-[24px] font-[700] leading-[29.05px] uppercase mb-[32px] mt-0 px-[12px]">
              Nhận ngay{" "}
              <span className="text-[#FF9900] text-[20px] lg:text-[24px]">
                voucher 30k
              </span>{" "}
              khi đăng ký thành viên mới
            </h1>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col gap-[24px]"
            >
              <NewInputField
                control={control}
                name="fullName"
                label="Họ và tên bạn"
                placeholder="Nhập họ và tên"
                rules={formValidation.fullName}
                errors={errors.fullName}
              />
              <NewInputField
                control={control}
                name="phone"
                label="Nhập số điện thoại *"
                placeholder="Nhập số điện thoại"
                rules={formValidation.phone}
                errors={errors.phone}
              />
              <NewInputField
                control={control}
                name="email"
                label="Nhập Email *"
                placeholder="Nhập Email"
                rules={formValidation.email}
                errors={errors.email}
              />
              <div className="flex flex-col">
                <label className="font-[500] text-[16px] leading-[20px] text-[#484848]">
                  Sinh nhật của bạn *
                </label>
                <div className="flex gap-[8px]">
                  <NewSelectField
                    containerClassName="flex-1"
                    control={control}
                    name="yearOfBirth"
                    options={yearData
                      .map((item) => ({
                        value: item,
                        label: item,
                      }))
                      .reverse()}
                    placeholder="Năm"
                    rules={formValidation.yearOfBirth}
                    errors={errors.yearOfBirth}
                  />
                  <NewSelectField
                    containerClassName="flex-1"
                    control={control}
                    name="monthOfBirth"
                    options={monthData.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                    placeholder="Tháng"
                    rules={formValidation.monthOfBirth}
                    errors={errors.monthOfBirth}
                  />
                  <NewSelectField
                    containerClassName="flex-1"
                    control={control}
                    name="dayOfBirth"
                    options={dayData?.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                    placeholder="Ngày"
                    rules={formValidation.dayOfBirth}
                    errors={errors.dayOfBirth}
                  />
                </div>
              </div>
              <NewInputField
                control={control}
                isPassword
                name="password"
                label="Mật khẩu *"
                placeholder="Nhập mật khẩu"
                rules={formValidation.password}
                errors={errors.password}
                autoComplete="new-password"
              />
              <NewInputField
                control={control}
                isPassword
                name="confirmPassword"
                label="Nhập lại mật khẩu *"
                placeholder="Nhập lại mật khẩu"
                rules={formValidation.passwordConfirm(password)}
                errors={errors.confirmPassword}
              />

              <div className="flex flex-col justify-center items-start">
                <div
                  className="flex justify-start items-center cursor-pointer"
                  onClick={handleTogglePolicy}
                >
                  <div
                    className={clsx(
                      "w-[20px] h-[20px] rounded-full border-[1.5px] border-solid border-[#b0b0b0]",
                      checkedPolicy ? "manual-radio" : ""
                    )}
                  ></div>
                  <div className="ml-[8px]">
                    <p className="text-[#000000] text-[14px] md:text-[16px] font-[400]">
                      Tôi đã đọc và đồng ý với{" "}
                      <Link
                        replace
                        to={`/${routes.SUPPORT}/${routes.PRIVACY_POLICY}`}
                      >
                        <span className="text-[14px] md:text-[16px] font-[400] text-[#FF9900]">
                          chính sách bảo mật
                        </span>
                      </Link>
                    </p>
                  </div>
                </div>
                {errorMsgPolicy && (
                  <p className="text-[14px] text-[#F04438] leading-[20px] font-[500]">
                    {errorMsgPolicy}
                  </p>
                )}
              </div>

              <NewButtonComponent
                htmlType="submit"
                type={
                  isValid && checkedPolicy && !isLoading ? "primary" : "default"
                }
                text="Đăng ký"
                className="mt-[32px] mb-[24px]"
                disabled={!isValid || !checkedPolicy || isLoading}
              />
            </form>

            {/* <LoginAnother title="Đăng ký bằng tài khoản khác" /> */}

            <p className="text-[16px] font-[400] text-center">
              Bạn đã có tài khoản?
              <Link to={routes.LOGIN}>
                <span className="text-[#FF9900] text-[16px] font-[400] ml-[5px]">
                  Đăng nhập ngay
                </span>
              </Link>
            </p>
          </div>
        </div>
        <div className="flex-1 h-auto hidden lg:block bg-[#FFFFFF]">
          <Image
            src={images.bannerSignup}
            alt="banner-signup"
            preview={false}
            className="h-[1000px] object-cover"
            rootClassName="block"
          />
        </div>
      </div>
      <Modal
        open={isOpenModal}
        closeIcon={false}
        footer={false}
        centered
        maskClosable
        className="w-[387px]"
        styles={{
          content: {
            padding: "24px",
            borderRadius: "24px",
            display: "flex",
          },
        }}
      >
        <div className=" bg-[#FFFFFF] flex flex-col justify-center items-start gap-[32px]">
          <div className="w-full">
            <p className="text-center text-[16px] leading-[19.6px] font-[400] text-[#000000] ">
              Xin chào {userName}!
            </p>
            <p className="text-center text-[16px] leading-[19.6px] font-[400] text-[#000000] mt-[10px]">
              Đã thêm voucher{" "}
              <span className="text-[#E77435] font-[600]">NEW MENBER</span> vào
              ví, chúc bạn mua sắm vui vẻ!
            </p>
          </div>
          <div className="w-full flex flex-col justify-center items-center">
            <NewButtonComponent
              className="w-full"
              text="Tiếp tục mua sắm"
              onClick={handleContinueBuy}
            />
            <span className="text-[13px] md:text-[14px] text-[#FF9900] leading-[15.73px] font-[400] italic mt-[16px]">
              Đóng sau {timming}
            </span>
          </div>
        </div>
      </Modal>
    </div>
  );
}
