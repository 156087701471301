import images from "@/assets/images";
import OverviewBrand from "../OverviewBrand";

function CommitmentBrand() {
  return (
    <div className="flex items-center justify-center p-[40px_16px] md:p-[96px] bg-[#F0F0F0] ">
      <div className="md:grid grid-cols-2 gap-[64px] md:max-w-[1184px]">
        <img
          src={images.brand}
          alt="images"
          className="size-[560px] rounded-[24px] md:block hidden"
        />
        <div className="flex flex-col gap-[64px]">
          <div className="flex flex-col gap-[12px]">
            <p className="text-[#FF9900] font-[600] text-[16px] text-nowrap">
              Chiết khấu uy tín, nguồn hàng ổn định
            </p>
            <h2 className="text-[30px] md:text-[48px] font-[600] m-0">
              Cam kết chiết khấu cạnh tranh, nguồn hàng ổn định
            </h2>
            <p className="md:hidden block text-[14px] text-[#545454] mt-[12px]">
              We’re a 100% remote team spread all across the world. Join us!
            </p>
          </div>
          <div className="flex flex-col gap-[32px] md:gap-[48px]">
            <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-[32px]">
              <OverviewBrand numeric="200+" text="Cửa hàng trên toàn quốc" />
              <OverviewBrand numeric="600%" text="Tăng trưởng theo quý" />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-[32px]">
              <OverviewBrand numeric="10k" text="Đánh giá chất lượng" />
              <OverviewBrand numeric="200+" text="Lời mời hợp tác" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommitmentBrand;
