import svgs from "@/assets/svgs";

interface VoucherProps {
  title: string;
  subTitle?: string;
  note?: string;
  image?: string;
}

export default function Voucher({
  title,
  subTitle,
  note,
  image,
}: VoucherProps) {
  return (
    <div className="rounded-[16px] bg-[#FFFFFF] w-full h-full">
      <div className="py-[16px] md:py-[20px] px-[24px] flex gap-[16px]">
        <img
          src={image || svgs.voucherWeb}
          alt=""
          className="py-[5px] md:py-[8px] px-[2px] md:px-[3px] size-[32px] md:size-[42px] object-contain"
          loading="lazy"
        />

        <div className="flex-1 flex flex-col gap-[8px]">
          <div className="text-[16px] md:text-[20px] font-[500] line-clamp-1 text-ellipsis">
            {title}
          </div>

          <div className="h-[24px] md:h-[48px] text-[14px] md:text-[16px] font-[400] line-clamp-1 md:line-clamp-2 text-ellipsis text-[#545454]">
            {subTitle}
          </div>

          {note && (
            <div className="text-[11px] md:text-[12px] font-normal">{note}</div>
          )}
        </div>
      </div>
    </div>
  );
}
