/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import "@/assets/scss/swiper.scss";
import svgs from "@/assets/svgs";
import Voucher from "@/components/common/Voucher";
import VoucherSkeleton from "@/components/skeleton/common/voucher";
import useQueryMemberIncentive from "@/hooks/ReactQuery/memberIncentive.query";
import useWindowSize from "@/hooks/useWindowSize";
import { MemberIncentiveType } from "@/type/response/memberIncentive";
import { t } from "i18next";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

export default function VouchersMember() {
  const { memberIncentive, isLoading } = useQueryMemberIncentive();
  const size = useWindowSize();

  return (
    <>
      <div className="mx-auto px-[16px] md:px-[64px] lg:px-[160px] py-[24px] md:py-[32px] w-full md:max-w-[1440px] flex flex-col gap-[24px]">
        <div className="flex items-center gap-[4px]">
          <p className="text-[20px] md:text-[30px] font-[600] leading-[24px] md:leading-[42px] uppercase">
            {t("memberIncentivesTitle")}
          </p>
          <img
            src={svgs.arrowLeftBlack}
            className="rotate-180 size-[32px] md:size-[48px] p-[10px] md:p-[16px]"
          />
        </div>

        <div className="text-[14px] md:text-[16px] font-[400] text-black">
          {t("memberIncentivesSubTitle")}
        </div>

        {/* use for mobile */}

        <div className="relative">
          <Swiper
            observer={true}
            observeParents={true}
            onSwiper={(swiper) => swiper.update()}
            slidesPerView={3}
            spaceBetween={10}
            pagination={{
              el: ".sniper-pagination-3",
              clickable: true,
            }}
            navigation={size.width >= 1024}
            breakpoints={{
              "320": {
                slidesPerView: 1.25,
                spaceBetween: 20,
              },
              "768": {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              "1024": {
                slidesPerView: 3,
                slidesPerGroup: 2,
                spaceBetween: 20,
              },
            }}
            modules={[Pagination, Navigation]}
          >
            {isLoading ? (
              <>
                {Array.from({ length: 3 }).map((_, index) => (
                  <SwiperSlide key={index}>
                    <VoucherSkeleton />
                  </SwiperSlide>
                ))}
              </>
            ) : (
              <>
                {memberIncentive.map(
                  (voucher: MemberIncentiveType, index: number) => (
                    <SwiperSlide key={index}>
                      <Voucher
                        key={`${voucher.id}-${index}-member-incentive`}
                        title={voucher.title}
                        subTitle={voucher.description}
                        image={voucher?.image?.data?.attributes?.url}
                      />
                    </SwiperSlide>
                  )
                )}
              </>
            )}

            <div className="sniper-controller flex justify-center items-center">
              <div className="sniper-pagination-3 mt-3"></div>
            </div>
          </Swiper>
        </div>
      </div>
    </>
  );
}
