/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/button-has-type */
/* eslint-disable react/require-default-props */
import React from "react";
import classNames from "classnames";

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: string;
  outline?: boolean;
  loading?: boolean;
  textClassName?: string;
};

const Button: React.FC<Props> = ({
  variant = "success",
  outline = false,
  loading,
  type = "button",
  className,
  children,
  disabled,
  textClassName,
  ...rest
}) => {
  return (
    <button
      type={type}
      className={classNames(
        `btn d-flex justify-content-center align-items-center gap-2 duration-200 ease-linear`,
        outline ? `btn-outline-${variant}` : `btn-${variant}`,
        disabled ? `bg-[#EDEDED] text-[#848484] cursor-not-allowed` : "#000000",
        className
      )}
      disabled={loading || disabled}
      {...rest}
    >
      {loading && <span className="spinner-border spinner-border-sm" />}
      {!!children && <span className={textClassName}>{children}</span>}
    </button>
  );
};

export default Button;
