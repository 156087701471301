import React from "react";
import Breadcrumb from "@/components/common/Breadcrumb";
import RelatedBlogs from "@/components/pages/BlogDetails/RelatedBlog";
import useQueryBlogDetails from "@/hooks/ReactQuery/blogDetails.query";
// import formatText from '@/hooks/formatText';
import loadingScreenState from "@/recoil/loadingScreenState";
import routes from "@/utils/routes";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";

// Define types for the content
interface TextContent {
  type: "text";
  text: string;
}

interface LinkContent {
  type: "link";
  url: string;
  children: Content[];
}

interface HeadingContent {
  type: "heading";
  level: number;
  children: Content[];
}

interface ImageContent {
  type: "image";
  image: {
    ext: string;
    url: string;
    hash: string;
    mime: string;
    name: string;
  };
}

interface ParagraphContent {
  type: "paragraph";
  children: Content[];
}

type Content =
  | TextContent
  | LinkContent
  | HeadingContent
  | ImageContent
  | ParagraphContent;

const formatText = (item: any): React.ReactNode => {
  if (typeof item === "string") {
    return item;
  }

  switch (item.type) {
    case "text":
      return <span className="text-justify">{item.text}</span>;
    case "link":
      return (
        <a
          href={item.url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-[#4C4CFC] text-justify"
        >
          {item.children.map((child: Content, index: number) => (
            <React.Fragment key={index}>{formatText(child)}</React.Fragment>
          ))}
        </a>
      );
    case "heading":
      const HeadingTag = `h${item.level}` as keyof JSX.IntrinsicElements;
      return (
        <HeadingTag className="text-justify">
          {item.children.map((child: Content, index: number) => (
            <React.Fragment key={index}>{formatText(child)}</React.Fragment>
          ))}
        </HeadingTag>
      );
    case "image":
      return (
        <div className="w-full flex justify-center items-center">
          <img src={item.image.url} alt={item.image.name} loading="lazy" />
        </div>
      );
    case "paragraph":
      return (
        <p className="mb-[32px] text-justify">
          {item.children.map((child: Content, index: number) => (
            <React.Fragment key={index}>{formatText(child)}</React.Fragment>
          ))}
        </p>
      );
    default:
      return null;
  }
};

export default function BlogDetails() {
  const { slug } = useParams();
  const { blog, isLoading } = useQueryBlogDetails(slug as string);
  const handleSetIsLoading = useSetRecoilState(loadingScreenState);

  const breadcrumbItems = [
    {
      href: routes.HOME,
      title: "Trang chủ",
    },
    {
      href: routes.BLOGS,
      title: "Blog",
    },
    {
      href: `${routes.BLOGS}/${slug}`,
      title: blog?.attributes?.title,
    },
  ];

  useEffect(() => {
    handleSetIsLoading(true);
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Breadcrumb items={breadcrumbItems} />

      <div className="mx-4 md:max-w-[1280px] md:mx-auto md:px-[10%] lg:px-[244px]">
        <div className="font-bold text-[16px] text-ciment my-8 md:text-[23px] md:text-center">
          {blog?.attributes?.title}
        </div>
        {blog?.attributes?.content.map((item, index) => (
          <div key={index}>{formatText(item)}</div>
        ))}
      </div>

      <div className="my-8 mx-4 md:max-w-[1280px] md:mx-auto md:px-[120px]">
        <RelatedBlogs />
      </div>
    </div>
  );
}
