/* eslint-disable prettier/prettier */
import dayjs from 'dayjs';
import _ from 'lodash';
import { RcFile } from 'antd/es/upload';
import { PickerColumnItem } from 'antd-mobile/es/components/picker-view';
import enviroment from '../config';

const { baseUrl } = enviroment;
const fromSnakeToCamel = <T>(data: T): T => {
  if (_.isArray(data)) {
    return _.map(data, fromSnakeToCamel) as T;
  }

  if (_.isObject(data)) {
    return _(data)
      .mapKeys((_v: any, k: any) => _.camelCase(k))
      .mapValues((v: T) => fromSnakeToCamel(v))
      .value() as T;
  }

  return data;
};
export const getImage = (path: string) => {
  if (!path) {
    return '';
  }
  return path.split('?')[0];
};
export const lastItemInArray = (array: Array<any>, n: number): Array<any> => {
  if (array == null) {
    return [];
  }
  if (n == null) {
    return [array[array.length - 1]];
  }
  return array.slice(Math.max(array.length - n, 0));
};

export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

export const getBaseUrl = (): string => {
  return baseUrl;
};

export const addEllipsis = (text: string, maxLength: number) => {
  if (text.length <= maxLength) {
    return text;
  }
  return `${text.substring(0, maxLength - 3)}...`;
};

export const upperCaseFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};
export const monthYearColumn = () => {
  const arrayYear = Array.from({ length: 2901 }, (_, index) => index + 1900);
  const yearValue: PickerColumnItem[] = arrayYear.map((item) => {
    return { label: item, value: item.toString() };
  });
  return [
    [
      {
        label: upperCaseFirstLetter(dayjs('01', 'MM').format('MMMM')),
        value: '01',
      },
      {
        label: upperCaseFirstLetter(dayjs('02', 'MM').format('MMMM')),
        value: '02',
      },
      {
        label: upperCaseFirstLetter(dayjs('03', 'MM').format('MMMM')),
        value: '03',
      },
      {
        label: upperCaseFirstLetter(dayjs('04', 'MM').format('MMMM')),
        value: '04',
      },
      {
        label: upperCaseFirstLetter(dayjs('05', 'MM').format('MMMM')),
        value: '05',
      },
      {
        label: upperCaseFirstLetter(dayjs('06', 'MM').format('MMMM')),
        value: '06',
      },
      {
        label: upperCaseFirstLetter(dayjs('07', 'MM').format('MMMM')),
        value: '07',
      },
      {
        label: upperCaseFirstLetter(dayjs('08', 'MM').format('MMMM')),
        value: '08',
      },
      {
        label: upperCaseFirstLetter(dayjs('09', 'MM').format('MMMM')),
        value: '09',
      },
      {
        label: upperCaseFirstLetter(dayjs('10', 'MM').format('MMMM')),
        value: '10',
      },
      {
        label: upperCaseFirstLetter(dayjs('11', 'MM').format('MMMM')),
        value: '11',
      },
      {
        label: upperCaseFirstLetter(dayjs('12', 'MM').format('MMMM')),
        value: '12',
      },
    ],
    [...yearValue],
  ];
};
export const formatCurrency = (payload: string) => {
  let value = payload?.replace(/,/g, '') || '0'
  if (value === '-1') {
    return '';
  }
  if (value === null || value === undefined || value === '') {
    value = '0';
  }

  const nbr = Number(String(value).replace(/,/g, ''));

  return String(nbr).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};
export default fromSnakeToCamel;

/**
 * The `formatMoney` function formats a number into a string with abbreviations for
 * trillion, billion, million, and thousand.
 * @param {number} num - The `formatMoney` function takes a number as input and formats it as a
 * monetary value with abbreviations for thousands, millions, billions, and trillions. If the input
 * number is greater than or equal to 1000, it will be formatted with the appropriate abbreviation (K
 * for thousands, M
 * @returns The `formatMoney` function returns a formatted string representation of a number, with the
 * number potentially being abbreviated using abbreviations such as "T" for trillion, "B" for billion,
 * "M" for million, or "K" for thousand. If the absolute value of the input number is greater than or
 * equal to the corresponding abbreviation value, the number is divided by that value and formatted
 * accordingly
 */
export function formatMoney(num: number): string {
  const absNum = Math.abs(num);
  const abbreviations: Record<string, number> = {
    T: 1000000000000,
    B: 1000000000,
    M: 1000000,
    K: 1000
  };

  for (const key in abbreviations) {
    if (absNum >= abbreviations[key]) {
      const formattedNum = num / abbreviations[key];
      return formattedNum.toFixed(abbreviations[key] >= 1000 ? 0 : 1) + key;
    }
  }

  return num.toString(); // return as is if smaller than K
}