import svgs from "@/assets/svgs";
import { formatCurrency } from "@/utils/data";
import { Modal } from "antd";
import { Button } from "..";
import styled from "styled-components";

interface ModelVoucherProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  handleModelVoucher: (value: boolean) => void;
  title: string;
  subTitle: string;
  voucherValue: string;
}

const ModalStyled = styled(Modal)`
  .ant-modal-content {
    padding: 8px 21px 21px !important;
  }

  button {
    span {
      font-size: 14px;
    }
  }
`;

export default function ModelVoucher({
  open,
  setOpen,
  handleModelVoucher,
  title,
  subTitle,
  voucherValue,
}: ModelVoucherProps) {
  return (
    <ModalStyled
      title="Modal"
      centered
      open={open}
      onCancel={() => setOpen(false)}
      width={305}
      footer={null}
      className="modal-voucher"
    >
      <div className="flex flex-col justify-center items-center pt-4">
        <div className="flex flex-col justify-start p-4 bg-[#F7F7F7] w-[254px] relative">
          <p className="text-[14px] text-[#000000] mb-4 ">{title}</p>
          <p className="text-[12px] text-[#000000] ">{subTitle}</p>
          <p className="text-[24px] text-[#FF0055] font-medium ">
            {formatCurrency(voucherValue)}đ
          </p>

          <div className="absolute right-[10px] bottom-[10px]">
            <img
              src={svgs.sale}
              alt=""
              className=" w-82 h-73 object-cover object-center"
            />
          </div>
        </div>

        <Button
          type="button"
          data-te-ripple-init
          data-te-ripple-color="light"
          className="text-[14px] text-white x bg-[#000000] rounded-[100px] px-[16px] py-[8px] mt-6 rounded-full"
          onClick={() => {
            handleModelVoucher(false);
          }}
        >
          Nhận voucher
        </Button>
      </div>
    </ModalStyled>
  );
}
