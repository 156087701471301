import {
  filterProductBrandsApi,
  filterProductLines as filterProductLinesApi,
  filterProductTexturesApi,
  filterProgrammes as filterProgrammesApi,
  filterSkinProperties as filterSkinPropertiesApi,
} from "@/api/filterProductApi";
import { BaseData } from "@/type/base/baseData";
import { FilterProducts_SkinPropertiesType } from "@/type/response/filterProducts";
import { useQueries } from "@tanstack/react-query";

const MINUTES_TIME_STALE = 3;

export default function useFilterSkinPropertiesQuery() {
  const result = useQueries({
    queries: [
      {
        queryKey: ["filter_skinProperties"],
        queryFn: () => filterSkinPropertiesApi.getAll(),
        staleTime: 1000 * 60 * MINUTES_TIME_STALE,
      },
      {
        queryKey: ["filter_programmes"],
        queryFn: () => filterProgrammesApi.getAll(),
        staleTime: 1000 * 60 * MINUTES_TIME_STALE,
      },
      {
        queryKey: ["filter_lines"],
        queryFn: () => filterProductLinesApi.getAll(),
        staleTime: 1000 * 60 * MINUTES_TIME_STALE,
      },
      {
        queryKey: ["filter_brands"],
        queryFn: () => filterProductBrandsApi.getAll(),
        staleTime: 1000 * 60 * MINUTES_TIME_STALE,
      },
      {
        queryKey: ["filter_textures"],
        queryFn: () => filterProductTexturesApi.getAll(),
        staleTime: 1000 * 60 * MINUTES_TIME_STALE,
      },
    ],
  });

  const skinProperties = {
    data: (result[0].data?.data?.data ||
      []) as BaseData<FilterProducts_SkinPropertiesType>[],
    isLoading: result[0].isLoading,
    isFetched: result[0].isFetched,
  };

  const programmes = {
    data: (result[1].data?.data?.data ||
      []) as BaseData<FilterProducts_SkinPropertiesType>[],
    isLoading: result[1].isLoading,
    isFetched: result[1].isFetched,
  };

  const lines = {
    data: (result[2].data?.data?.data ||
      []) as BaseData<FilterProducts_SkinPropertiesType>[],
    isLoading: result[2].isLoading,
    isFetched: result[2].isFetched,
  };
  const brands = {
    data: (result[3].data?.data?.data ||
      []) as BaseData<FilterProducts_SkinPropertiesType>[],
    isLoading: result[3].isLoading,
    isFetched: result[3].isFetched,
  };

  const textures = {
    data: (result[4].data?.data?.data ||
      []) as BaseData<FilterProducts_SkinPropertiesType>[],
    isLoading: result[4].isLoading,
    isFetched: result[4].isFetched,
  };
  return { skinProperties, programmes, lines, brands, textures };
}
