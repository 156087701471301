import { Link } from "react-router-dom";
import images from "@/assets/images";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { useEffect, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import partnerApi from "@/api/partnerApi";
import { FAQPartnerType, FeedbackPartnerType } from "@/type/response/partner";
import { Loading } from "@/components";
import Breadcrumb from "@/components/common/Breadcrumb";
import routes from "@/utils/routes";
import QuestionPartner from "@/components/common/QuestionPartner";

export default function Partner() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [faqs, setFaqs] = useState<FAQPartnerType[]>([]);
  const [feedbacks, setFeedbacks] = useState<FeedbackPartnerType[]>([]);

  const breadcrumbItems = [
    {
      href: routes.HOME,
      title: "Trang chủ",
    },
    {
      href: routes.BRAND,
      title: "Thương hiệu",
    },
    {
      href: routes.PARTNER,
      title: "Trở thành đối tác",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      await partnerApi
        .getAll()
        .then((res) => res.data)
        .then((data) => data.data)
        .then((data) => {
          setFaqs(data.attributes.fAQ);
          setFeedbacks(data.attributes.feedbackFromPartners);
        })
        .catch((error) => {
          setError(error);
          setIsError(true);
        })
        .finally(() => setIsLoading(false));
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  // Mock console.log fix build because of no use
  console.log(error);
  console.log(isError);

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />

      <section className="section__heading mx-4 md:px-[120px] md:container md:max-w-[1280px] md:mx-auto">
        <div className="section__heading-title flex flex-col justify-center items-center gap-1">
          <h1 className="font-bold text-2xl text-piper-lolot uppercase leading-none">
            Trở thành đối tác{" "}
            <span className="hidden text-2xl font-normal text-piper-lolot uppercase leading-none md:inline-block">
              SO’NATURAL
            </span>
          </h1>
          <h1 className="text-2xl font-normal text-piper-lolot uppercase leading-none md:hidden">
            SO’NATURAL
          </h1>
        </div>
      </section>

      <section className="section__step mx-4 mt-4 mb-8 md:px-[120px] md:container md:max-w-[1280px] md:mx-auto">
        <div className="section__step-title text-center mb-4">
          <div className=" text-base font-normal text-piper-lolot">
            Chỉ trong{" "}
            <span className="font-bold text-xl text-[#ffd66d]">3 bước</span>
          </div>
        </div>

        <ul className="section__step-list my-4 md:grid md:grid-cols-3 md:gap-5">
          <div className="section__step-list--item border-b border-[#C6C6C6] last:border-b-0 py-4 first:py-0 first:pb-4 last:py-0 last:pt-4 md:border-b-0 md:bg-[#F6FDE8]">
            <div className="grid grid-cols-12 gap-2 md:px-[21px] md:py-[21px]  md:grid-cols-1">
              <div className="step__item-title col-span-5 text-piper-lolot font-bold text-xs md:text-center md:text-[21px] md:pb-4 md:border-b md:border-b-gray-300">
                Cung cấp thông tin
              </div>
              <div className="step__item-content col-span-7 font-normal text-xs text-[#484848] text-justify md:text-base md:leading-5 md:pt-2">
                Chủ động cung cấp các thông tin chi tiết của Đại Lý bằng cách
                nhấp vào nút Đăng Ký bên dưới.
              </div>
            </div>
          </div>

          <div className="section__step-list--item border-b border-[#C6C6C6] last:border-b-0 py-4 first:py-0 first:pb-4 last:py-0 last:pt-4 md:border-b-0 md:bg-[#F6FDE8] md:py-0">
            <div className="grid grid-cols-12 gap-2 md:px-[21px] md:py-[21px]  md:grid-cols-1">
              <div className="step__item-title col-span-5 text-piper-lolot font-bold text-xs md:text-center md:text-[21px] md:pb-4 md:border-b md:border-b-gray-300">
                Ký kết hợp đồng
              </div>
              <div className="step__item-content col-span-7 font-normal text-xs text-[#484848] text-justify md:text-base md:leading-5 md:pt-2">
                So’Natural sẽ liên hệ với Đại Lý để lên lịch hẹn và ký hợp đồng
                đối tác.
              </div>
            </div>
          </div>

          <div className="section__step-list--item border-b border-[#C6C6C6] last:border-b-0 py-4 first:py-0 first:pb-4 last:py-0 last:pt-4 md:border-b-0 md:bg-[#F6FDE8] md:py-0 md:last:pt-0">
            <div className="grid grid-cols-12 gap-2 md:px-[21px] md:py-[21px] md:grid-cols-1">
              <div className="step__item-title col-span-5 text-piper-lolot font-bold text-xs md:text-center md:text-[21px] md:pb-4 md:border-b md:border-b-gray-300">
                Nhận hàng và bán
              </div>
              <div className="step__item-content col-span-7 font-normal text-xs text-[#484848] text-justify md:text-base md:leading-5 md:pt-2">
                Đơn hàng được gửi đến tận kho nơi bạn bán hàng
              </div>
            </div>
          </div>
        </ul>

        <div className="section__step-action flex justify-center items-center">
          <button className="px-8 py-2 rounded-[100px] text-[#FFF2D2] bg-piper-lolot uppercase">
            <Link to={"register"}>Đăng kí</Link>
          </button>
        </div>
      </section>

      <section className="section__requirement-partner p-8 bg-[#F6FDE8] my-8 md:container md:max-w-[1040px] md:mx-auto md:bg-[#FBFBFB]">
        <div className="section__requirement-partner--title mb-4 text-[16px] font-normal text-piper-lolot uppercase md:text-xl md:mb-8">
          THÔNG TIN CẦN THIẾT KHI ĐĂNG KÝ
        </div>

        <ul className="section__requirement-partner--list grid grid-cols-1 gap-4 md:grid-cols-2">
          <div className="section__requirement-partner--item px-6 py-4 rounded-lg border border-piper-lolot md:border-[#DEDEDE]">
            <div className="requirement__partner-item-title font-bold text-sm md:h-12 md:line-clamp-2 md:text-ellipsis md:text-[16px]">
              Đối với hợp đồng ký với tư cách pháp nhân là Công Ty
            </div>

            <hr className="my-4 bg-piper-lolot w-full" />

            <ul className="requirement__partner-item-content list-disc pl-3">
              <li className="md:text-[16px] text-[10px] font-normal">
                Giấy Phép Đăng Ký Kinh Doanh (bản gốc)
              </li>
              <li className="md:text-[16px] text-[10px] font-normal">
                Giấy ủy quyền cho người đại diện ký thay (bản gốc)
              </li>
              <li className="md:text-[16px] text-[10px] font-normal">
                CMND/CCCD/Hộ chiếu người đại diện (bản gốc)
              </li>
              <li className="md:text-[16px] text-[10px] font-normal">
                Địa chỉ quán chính xác
              </li>
            </ul>
          </div>
          <div className="section__requirement-partner--item px-6 py-4 rounded-lg border border-piper-lolot md:border-[#DEDEDE]">
            <div className="requirement__partner-item-title font-bold text-sm md:h-12 md:line-clamp-2 md:text-ellipsis md:text-[16px]">
              Đối với hợp đồng ký với tư cách pháp nhân là Cá Nhân, Hộ Kinh
              Doanh
            </div>

            <hr className="my-4 bg-piper-lolot w-full" />

            <ul className="requirement__partner-item-content list-disc pl-3">
              <li className="md:text-[16px] text-[10px] font-normal">
                CMND/CCCD/Hộ chiếu (bản gốc)
              </li>
              <li className="md:text-[16px] text-[10px] font-normal">
                Giấy Đăng ký Hộ kinh doanh cá thể (nếu có)
              </li>
              <li className="md:text-[16px] text-[10px] font-normal">
                Địa chỉ cửa hàng chính xác
              </li>
            </ul>
          </div>
        </ul>
      </section>

      <section className="section__feedback-partner mx-4 my-8 md:px-[120px] md:container md:max-w-[1280px] md:mx-auto">
        <div className="section__feedback-partner--title font-normal text-[16px] uppercase text-left md:text-center">
          <h4>feedback từ đối tác</h4>
        </div>

        <div className="section__feedback-partner-slide my-6 md:px-8 relative">
          <Swiper
            observer={true}
            observeParents={true}
            onSwiper={(swiper) => swiper.update()}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 16,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 16,
                navigation: {
                  nextEl: ".sniper-button-next",
                  prevEl: ".sniper-button-prev",
                },
              },
            }}
            pagination={{
              el: ".sniper-pagination-2",
              clickable: true,
            }}
            modules={[Navigation, Pagination]}
            wrapperClass="items-start"
          >
            {feedbacks.map((feedback, index) => (
              <SwiperSlide key={index}>
                <div className="p-4 rounded-2xl bg-[#EDEDED] grid grid-cols-12 gap-4">
                  <div className="partner__logo w-[58px] h-[58px] col-span-3 md:col-span-3">
                    <img
                      src={
                        feedback?.image?.data?.attributes?.url ??
                        images.thumbnailBrandContact
                      }
                      alt=""
                      className="w-full h-full object-cover object-center"
                      loading="lazy"
                    />
                  </div>

                  <div className="partner__content text-[16px] font-light text-[#484848] col-span-9 md:col-span-9">
                    <div className="">{feedback.title}</div>
                    <div className="">{feedback.content}</div>
                  </div>
                </div>
              </SwiperSlide>
            ))}

            <div className="sniper-controller flex justify-center items-center">
              <div className="sniper-pagination-2 mx-auto mt-3" />
            </div>
          </Swiper>

          <div className="hidden md:block sniper-navigation">
            <div className="sniper-button-prev absolute top-1/2 translate-y-[-50%] left-0 z-50 select-none">
              <ChevronLeftIcon className="w-6 h-6 text-black cursor-pointer select-none" />
            </div>
            <div className="sniper-button-next absolute top-1/2 translate-y-[-50%] right-0 z-50 select-none">
              <ChevronRightIcon className="w-6 h-6 text-black cursor-pointer select-none" />
            </div>
          </div>
        </div>
      </section>

      <section className="section__question-partner mx-4 my-8 md:px-[120px] md:container md:max-w-[1280px] md:mx-auto">
        <div className="section__question-partner-title text-[16px] font-normal text-piper-lolot uppercase md:mb-4">
          CÂU HỎI THƯỜNG GẶP
        </div>

        <div className="section__question-partner-list grid grid-cols-1 gap-4 md:grid-cols-2">
          {faqs.map((faq, index) => (
            <QuestionPartner key={index} question={faq} />
          ))}
        </div>
      </section>
    </>
  );
}
