import { Modal, ModalProps } from 'antd';

interface IBasicModalProps {
  isModalOpen: boolean;
  showModal?: () => void;
  handleOk: () => void;
  handleCancel: () => void;
  children?: any;
}

const BasicModal = (props: IBasicModalProps & ModalProps) => {
  const {
    isModalOpen,
    showModal,
    handleCancel,
    handleOk,
    children,
    ...rest
  } = props

  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      closeIcon={null}
      {...rest}
    >
      {children}
    </Modal>
  );
};

export default BasicModal;