interface BirthDate {
  day: string | null;
  month: string | null;
  year: string | null;
}

export function parseBirthDate(birthday: string | null): BirthDate | null {
  if (!birthday) {
    return {
      day: null,
      month: null,
      year: null,
    };
  }

  const dateParts = birthday.split("-");

  if (dateParts.length !== 3) {
    console.error("Invalid date format. Expected format: YYYY-MM-DD");
    return null;
  }

  const [year, month, day] = dateParts;

  return {
    day,
    month,
    year,
  };
}
