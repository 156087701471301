import { BaseResponse } from "@/type/base/baseResponse";
import axiosClient from "./axiosClient";
import { BaseData } from "@/type/base/baseData";
import { UserAddressType } from "@/utils/AddressType";
import urls from "@/utils/urls";
import { UserAddressParamsType } from "@/type/request/userAddress";

const userAddressApi = {
  getAll(params?: string) {
    return axiosClient.get<BaseResponse<BaseData<UserAddressType>[]>>(
      `${urls.USER_ADDRESS}?${params}&sort=id:desc`
    );
  },

  createUserAddress(params: UserAddressParamsType) {
    return axiosClient.post(`${urls.USER_ADDRESS}`, {
      data: params,
    });
  },

  updateUserAddress(id: number, params: UserAddressParamsType) {
    return axiosClient.put(`${urls.USER_ADDRESS}/${id}`, {
      data: params,
    });
  },

  setUserAddressDefault(user_address_id: number) {
    return axiosClient.post(urls.USER_ADDRESS_DEFAULT, {
      user_address_id: user_address_id,
    });
  },
};

export default userAddressApi;
