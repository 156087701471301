import { BaseData } from "@/type/base/baseData";
import Product from "../NewProduct";
import { OrderDetailsResponse, OrderResponse } from "@/type/response/order";
import OrderBill from "../OrderBill";
import { formatPriceToVnd } from "@/utils/formatPriceToVnd";
import dayjs from "dayjs";
import HeaderOrder from "./HeaderOrder";
import { OrderStatusType } from "@/type/common/order";
import constants from "@/utils/constants";
import BasicModal from "../Modal";
import { useState } from "react";
import svgs from "@/assets/svgs";
import Button from "../Button";
import clsx from "clsx";
import orderApi from "@/api/orderApi";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import routes from "@/utils/routes";
import {
  addProductListToCart,
  CartState,
  IICartStateRecoil,
  IProductInCart,
} from "@/recoil/cartState";
import trackingApi from "@/api/trackingAPI";
import { useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import reasonCancel from "@/utils/reasonCancel";

import FeedbackModal from "@/components/pages/Orders/FeedbackModal";
import FinishFeedbackModal from "@/components/pages/Orders/FinishFeedbackModal";
import useWindowSize from "@/hooks/useWindowSize";
import { BillTextType } from "../BillText";
import FooterOrder from "./FooterOrder";

export type TypeBill = "total" | "discount" | "normal";

export interface BillItemProps {
  nameItem: string;
  valueItem: string;
  type: TypeBill;
}
export interface InfoAddressProps {
  customerFullName: string;
  customerPhone: string;
  shippingAddress: string;
  shippingMethod: string;
}
interface IOrderDetailWrapperProps {
  order: BaseData<OrderResponse>;

  handleRefreshData?: () => void;
  hasOrderDetail?: boolean;
  showBillDetail?: boolean;
  showFooter?: boolean;
  showOrderCode?: boolean;
  hasNavigateOrderDetail?: boolean;
}

const OrderDetailWrapper = (props: IOrderDetailWrapperProps) => {
  const { width } = useWindowSize();
  const {
    order,

    handleRefreshData,
    hasOrderDetail = true,
    showBillDetail = true,
    showOrderCode = true,
    hasNavigateOrderDetail = true,
  } = props;
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const setCart = useSetRecoilState<IICartStateRecoil>(CartState);

  const [openModalReason, setOpenModalReason] = useState<boolean>(false);
  const [openModalFeedback, setOpenModalFeedback] = useState<boolean>(false);
  const [selectedReason, setSelectedReason] = useState<number>();
  const [openFinishFeedback, setOpenFinishFeedback] = useState<boolean>(false);
  const temporaryTotal =
    Number(order?.attributes?.total || 0) +
    Number(order?.attributes?.discount || 0) +
    Number(order?.attributes?.subTotal || 0) -
    Number(order?.attributes?.transportFee || 0);
  const billData = [
    {
      nameItem: "Tổng cộng",
      valueItem: `${order.attributes?.orderDetails?.data?.length.toString()} sản phẩm`,
      type: "normal" as BillTextType,
    },
    {
      nameItem: "Thời gian đặt hàng",
      valueItem: dayjs(order?.attributes?.createdAt).format("DD/MM/YYYY"),
      type: "normal" as BillTextType,
    },
    {
      nameItem: "Tạm tính",
      valueItem: formatPriceToVnd(Number(temporaryTotal)).toString(),
      type: "normal" as BillTextType,
    },
    {
      nameItem: "Phí vận chuyển",
      valueItem: formatPriceToVnd(
        Number(order?.attributes?.transportFee || 0)
      ).toString(),
      type: "normal" as BillTextType,
    },
    {
      nameItem: "Giảm giá",
      valueItem: formatPriceToVnd(
        Number(order?.attributes?.discount || 0)
      ).toString(),
      type: "discount" as BillTextType,
    },
    {
      nameItem: "Voucher",
      valueItem: formatPriceToVnd(
        Number(order?.attributes?.subTotal)
      ).toString(),
      type: "discount" as BillTextType,
    },
    {
      nameItem: "Thành tiền",
      valueItem: formatPriceToVnd(Number(order.attributes?.total)).toString(),
      type: "total-bill" as BillTextType,
    },
  ];

  const handleCancelModalReason = () => {
    setOpenModalReason(false);
    if (!selectedReason) {
      setSelectedReason(undefined);
    }
  };

  const handelOpenModalReason = () => {
    setOpenModalReason(true);
  };

  const handleSelectedReason = (reasonIndex: number) => {
    setSelectedReason(reasonIndex);
  };

  const handleOke = async () => {
    if (selectedReason === undefined) return;

    const orderCancelRes = await orderApi.editOrder(order.id, {
      data: {
        status: constants.CANCELED,
        reason: reasonCancel[selectedReason],
      },
    });

    if (
      orderCancelRes.data.data &&
      orderCancelRes.data.data.id &&
      orderCancelRes.data.data.attributes.status === constants.CANCELED
    ) {
      setSelectedReason(undefined);
      toast.success("Hủy đơn hàng thành công!");
      navigate(`${routes.PROFILE}/${routes.ORDERS}/return-cancel`);
    } else {
      toast.success("Hủy đơn hàng không thành công!");
    }
    setOpenModalReason(false);
  };

  const handleRepurchase = async () => {
    const dataProducts = order.attributes.orderDetails.data.reduce(
      (acc: IProductInCart[], product: BaseData<OrderDetailsResponse>) => {
        const productData = product.attributes.product.data;
        const {
          shortName,
          name,
          price,
          pricePromotion,
          flashSalePrice,
          avatar,
          slug,
        } = productData.attributes;

        const productFormat = {
          id: productData.id,
          name: name || shortName,
          image: avatar.data.attributes.url,
          price,
          pricePromotion,
          flashSalePrice,
          slug,
        };

        return [...acc, productFormat];
      },
      []
    );

    setCart((prevCart: IICartStateRecoil) => ({
      ...prevCart,
      cartProducts: addProductListToCart(prevCart.cartProducts, dataProducts),
    }));

    const utmSource = sessionStorage.getItem("utm_source") || "google";
    await trackingApi.trackAddToCart(
      dataProducts.map((prod) => prod.id) as number[],
      utmSource
    );
    await trackingApi.updateProductEfficiency(
      dataProducts.map((prod) => prod.id) as number[],
      utmSource
    );

    toast.success(t("addCartSuccess"));
  };

  const handleCancelFeedback = () => {
    setOpenModalFeedback(false);
  };

  const startDate = dayjs(order.attributes.updatedAt);
  const endDate = dayjs(); // Ngày hiện tại

  const daysBetween = endDate.diff(startDate, "day");
  const isDisableFeedback = order?.attributes?.reviewStatus || daysBetween > 15;

  const handleOpenFeedback = () => {
    if (isDisableFeedback) return;

    if (width < 768) {
      navigate(`${routes.FEEDBACK_ORDER}/${order.attributes.orderCode}`, {
        state: {
          prePage: location.pathname,
          order: order,
        },
      });

      return;
    }
    setOpenModalFeedback(true);
  };

  const handleNavigateOrderDetail = () => {
    if (!hasNavigateOrderDetail) return;
    navigate(`${routes.ORDER_DETAIL}/${order.attributes.orderCode}`, {
      state: {
        prePage: location.pathname,
      },
    });
  };

  return (
    <>
      <div
        className={clsx(
          "flex flex-col gap-0 md:gap-[8px] md:bg-white",
          hasNavigateOrderDetail ? "cursor-pointer" : "",
          hasOrderDetail ? "bg-[#F7F7F7]" : ""
        )}
        onClick={handleNavigateOrderDetail}
      >
        <div className="mt-[8px] bg-[#FFFFFF] md:mt-0">
          <HeaderOrder
            orderCode={order?.attributes?.orderCode}
            status={order?.attributes?.status as OrderStatusType}
            reason={order?.attributes?.reason}
            userAddress={order?.attributes?.userAddress.data}
            shippingOrderID={order?.attributes?.shippingOrderId}
            showOrderCode={showOrderCode}
            hasOrderDetail={hasOrderDetail}
          />
        </div>
        <div
          className={clsx(
            "flex flex-col",
            hasOrderDetail
              ? "p-[16px] md:p-0 bg-[#FFFFFF] mt-[8px] md:mt-0"
              : "px-[16px]"
          )}
        >
          {order?.attributes?.orderDetails?.data.length > 0 &&
            !hasOrderDetail ? (
            <div className="my-[24px]">
              <Product
                product={
                  order?.attributes?.orderDetails?.data[0].attributes.product
                    .data
                }
                thumbType="horizontal"
                contentPage="feedback"
                showPercentDiscount={false}
                productInCart={
                  order?.attributes?.orderDetails?.data[0].attributes.quanity
                }
                showCompare
              />
              <div className="w-full border-[1px] border-solid border-[#B0B0B0] mt-[24px]"></div>
            </div>
          ) : (
            order?.attributes?.orderDetails?.data?.map((item, index) => (
              <div key={index} className="p-0 md:p-[0_16px_16px_16px]">
                <Product
                  product={item.attributes.product.data}
                  thumbType="horizontal"
                  contentPage="feedback"
                  productInCart={item.attributes.quanity}
                  showPercentDiscount={false}
                />
                {order.attributes.orderDetails.data.length - 1 !== index && (
                  <div className="w-full border-[1px] border-solid border-[#F0F0F0] my-[16px] md:mt-[16px] md:mb-0"></div>
                )}
              </div>
            ))
          )}
        </div>
        <div
          className={clsx(
            "w-full flex flex-col gap-[8px] ",
            hasOrderDetail
              ? "p-[16px] md:p-0 bg-[#FFFFFF] mt-[8px] md:mt-0"
              : "px-[16px] mb-[24px]"
          )}
        >
          <p className="text-[14px] md:text-[16px] text-black font-[700] leading-[16.94px] md:leading-[19.36px]">
            Phương thức thanh toán
          </p>
          <p className="text-[14px] md:text-[16px] text-black font-[400] leading-[16.94px] md:leading-[19.36px]">
            {order?.attributes?.paymentMethod}
          </p>
        </div>
        <div
          className={clsx(
            hasOrderDetail
              ? "p-[16px] md:p-0 bg-[#FFFFFF] mt-[8px] md:mt-0"
              : "px-[16px]"
          )}
        >
          <OrderBill
            orderBill={billData}
            showBillDetail={showBillDetail}
            hasOrderDetail={hasOrderDetail}
          />
        </div>
      </div>

      <FooterOrder
        statusOrder={order?.attributes?.status}
        handleCancel={handelOpenModalReason}
        handelFeedback={handleOpenFeedback}
        handelRepurchase={handleRepurchase}
        isDisableFeedback={isDisableFeedback}
        hasOrderDetail={hasOrderDetail}
      />

      <BasicModal
        isModalOpen={openModalReason}
        closeIcon={false}
        footer={null}
        handleCancel={handleCancelModalReason}
        handleOk={() => { }}
        className="w-full md:w-[500px]"
        styles={{
          content: {
            padding: "24px",
            borderRadius: "24px",
          },
        }}
      >
        <div className="flex flex-col gap-[16px]">
          <div>
            <img src={svgs.warning} alt="warning-icon" />
          </div>
          <p className="text-black text-[18px] font-[500] leading-[21.78px]">
            Hủy đơn hàng
          </p>
          <p className="text-[#808080] text-[13px] font-[400] leading-[15.73px]">
            Bạn chắc chắn muốn hủy đơn hàng chứ? Vui lòng cho chúng tôi biết lý
            do bạn hủy đơn hàng nhé!
          </p>
          <div className="flex flex-col gap-[8px]">
            {reasonCancel.map((reason, index) => (
              <div
                key={index}
                className="w-full md:w-fit flex justify-start items-center gap-[10px] cursor-pointer"
                onClick={() => handleSelectedReason(index)}
              >
                <div
                  className={clsx(
                    "min-w-[12px] w-[12px] h-[12px] border-[1px] border-solid rounded-full",
                    selectedReason === undefined
                      ? "border-black"
                      : selectedReason === index
                        ? "reason-radio-custom"
                        : "border-[#B0B0B0]"
                  )}
                ></div>
                <div className="w-full">
                  <p
                    className={clsx(
                      "text-[14px] font-[400] leading-[16.94px] ",
                      selectedReason === undefined
                        ? "text-black"
                        : selectedReason === index
                          ? "text-black"
                          : "text-[#B0B0B0]"
                    )}
                  >
                    {reason}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center items-center gap-[16px] mt-[24px]">
            <Button
              disabled={selectedReason === undefined}
              className="w-full rounded-[24px] bg-white py-[12px]"
              textClassName={clsx(
                "text-[14px] font-[600] leading-[16.94px]",
                selectedReason === undefined ? "text-[#B0B0B0]" : "text-black"
              )}
              onClick={handleOke}
            >
              Đồng ý
            </Button>
            <Button
              className="w-full rounded-[24px] py-[12px] group/button"
              textClassName="text-white text-[14px] font-[600] leading-[16.94px] group-hover/button:text-[#FF9900]"
              onClick={handleCancelModalReason}
            >
              Hủy
            </Button>
          </div>
        </div>
      </BasicModal>

      {openModalFeedback && (
        <FeedbackModal
          open={openModalFeedback}
          onClose={handleCancelFeedback}
          orderId={order.id}
          orderList={order.attributes.orderDetails.data}
          openFinishModal={() => {
            handleRefreshData?.();
            setOpenFinishFeedback(true);
            setOpenModalFeedback(false);
          }}
          status={order.attributes.status}
        />
      )}
      {openFinishFeedback && (
        <FinishFeedbackModal
          open={openFinishFeedback}
          handleCancel={() => setOpenFinishFeedback(false)}
          onClose={() => setOpenFinishFeedback(false)}
        />
      )}
    </>
  );
};
export default OrderDetailWrapper;
