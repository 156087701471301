import backgroundForgotPassword from "./background-forgotPassword.png";
import bannerOne from "./banner-1.png";
import bannerHomeTwo from "./banner-home-2.png";
import bannerHomeThree from "./banner-home-3.png";
import bannerHomeMbOne from "./banner-home-1-mb.png";
import bannerHomeMbTwo from "./banner-home-2-mb.png";
import bannerHomeMbThree from "./banner-home-3-mb.png";
import brandOne from "./brand-1.png";
import brandTwo from "./brand-2.png";
import brandThree from "./brand-3.png";
import brandContact from "./brand-contact.png";
import characterOne from "./character-1.png";
import characterTwo from "./character-2.png";
import defaultAvatar from "./defaultAvatar.png";
import eyeSvg from "./eye.svg";
import facebookImage from "./facebook.png";
import heroBrand from "./hero-brand.png";
import flagUsIcon from "./icFlagUS.png";
import flagIconVN from "./icFlagVN.png";
import imageMktProduct from "./image 85.png";
import imageMan from "./img-man.png";
import imageSearchTwo from "./img-search-2.png";
import imageSearchOne from "./img-search.png";
import imageBlogTwo from "./img2blog.png";
import imageBlogOne from "./imgblog.png";
import kolTwo from "./kol-2.png";
import kolCircle from "./kol-circle.png";
import logo from "./logo.png";
import map from "./map.png";
import productOne from "./product-1.png";
import imageRatting from "./rating-img.png";
import productTwo from "./Rectangle 21.png";
import routineOne from "./routine-1.png";
import routineTwo from "./routine-2.png";
import routineThree from "./routine-3.png";
import routineMbOne from "./routine-mb-1.png";
import routineMbTwo from "./routine-mb-2.png";
import routineMbThree from "./routine-mb-3.png";
import imageSectionTwo from "./section2-img.png";
import bronzeRank from "./bronzeRank.png";
import silverRank from "./silverRank.png";
import goldRank from "./goldRank.png";
import diamondRank from "./diamondRank.png";
import productThree from "./sp1.png";
import thumbnailOne from "./thumb-1.png";
import thumbnailTwo from "./thumb-2.png";
import thumbnailThree from "./thumb-3.png";
import thumbnailFour from "./thumb-4.png";
import thumbnailBrandContact from "./Thumb-brand-contact.png";
import imageTiktok from "./titok.png";
import userRatting from "./user-rating.png";
import voucherHome from "./voucher-home.png";
import voucher from "./vouncher.png";
import bannerHome from "./bannerHome.png";
import maintenance from "./maintenance-bg.png";
import noImage from "./no-image.png";
import section2 from "./section2-img.png";
import cartEmpty from "./image_cart-empty.png";
import iconDeliverySuccess from "./icon_delivery-success.png";
import iconDeliverySuccessActive from "./icon_delivery-success-active.png";
import iconDeliveryPending from "./icon_delivery-pending.png";
import iconDeliveryPendingActive from "./icon_delivery-pending-active.png";
import iconHistoryOrder from "./icon_history.png";
import iconHistoryOrderActive from "./icon_history-active.png";
import iconPendingOrder from "./icon_pending-order.png";
import iconPendingOrderActive from "./icon_pending-order-active.png";
import arrowRight from "./arrow-right.svg";
import arrowLeft from "./arrow-left.svg";
import arrowDown from "./arrow-down.svg";
import imageMan2 from "./image-man-2.png";
import imageMan3 from "./image-man-3.png";
import welcome1 from "./welcome-1.svg";
import welcome2 from "./welcome-2.svg";
import vnpay from "./vnpay.png";
import vnpayThumb1 from "./vnpay-thumb-1.png";
import vnpayThumb2 from "./vnpay-thumb-2.png";
import leaf from "./leaf.png";
import vnPay from "./vnPay.webp";
import forgotPwHelp1 from "./forgotPassword-help.png";
import forgotPwHelp2 from "./forgot-pw-help-2.png";
import iconChat from "./icon_chat.png";
import industryAndTrade from "./IndustryAndTrade.png";
import bannerSignup from "./banner-signup.png";
import brand from "./brand.jpg";
import partnerRegister from "./partner-register.png";
import routineBanner from "./routine-banner.png";
import routineMobileBanner from "./banner-brand-mobile.jpg";
import thunder from "./thunder.png";
import topBannerProduct from "./top-banner-product.png";
import routineBannerDetail from "./banner-routine-detail.png";
import notFound from "./404-image.jpg";
import CEO from "./CEO.jpg";
import Mission from "./mission.png";
import Vision from "./vision.png";
import Values from "./values.png";
import achievements1 from "./achievements-1.jpg";
import achievements2 from "./achievements-2.jpg";
import achievements3 from "./achievements-3.jpg";
import achievements4 from "./achievements-4.png";
import achievements5 from "./achievements-5.jpg";
const images = {
  logo,
  defaultAvatar,
  imageMan,
  imageSearchOne,
  imageSearchTwo,
  imageBlogOne,
  imageBlogTwo,
  imageRatting,
  imageSectionTwo,
  imageTiktok,
  bannerOne,
  bannerHomeTwo,
  bannerHomeThree,
  bannerHomeMbOne,
  bannerHomeMbTwo,
  bannerHomeMbThree,
  brandOne,
  brandTwo,
  brandThree,
  brandContact,
  characterOne,
  characterTwo,
  eyeSvg,
  facebookImage,
  heroBrand,
  flagUsIcon,
  flagIconVN,
  imageMktProduct,
  kolTwo,
  kolCircle,
  map,
  productOne,
  productTwo,
  productThree,
  routineOne,
  routineTwo,
  routineThree,
  routineMbOne,
  routineMbTwo,
  routineMbThree,
  silverRank,
  thumbnailOne,
  thumbnailTwo,
  thumbnailThree,
  thumbnailFour,
  thumbnailBrandContact,
  userRatting,
  voucherHome,
  bannerHome,
  backgroundForgotPassword,
  noImage,
  maintenance,
  section2,
  cartEmpty,
  iconDeliveryPending,
  iconDeliverySuccess,
  iconHistoryOrder,
  iconPendingOrder,
  iconDeliveryPendingActive,
  iconDeliverySuccessActive,
  iconHistoryOrderActive,
  iconPendingOrderActive,
  voucher,
  arrowRight,
  arrowLeft,
  arrowDown,
  imageMan2,
  imageMan3,
  welcome1,
  welcome2,
  bronzeRank,
  goldRank,
  diamondRank,
  vnpay,
  vnpayThumb1,
  vnpayThumb2,
  leaf,
  vnPay,
  forgotPwHelp1,
  forgotPwHelp2,
  iconChat,
  industryAndTrade,
  bannerSignup,
  brand,
  partnerRegister,
  routineBanner,
  routineMobileBanner,
  thunder,
  topBannerProduct,
  routineBannerDetail,
  notFound,
  CEO,
  Values,
  Mission,
  Vision,
  achievements1,
  achievements2,
  achievements3,
  achievements4,
  achievements5,
};

export default images;
