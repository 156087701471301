import { Outlet } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from "recoil";
import loadingScreenState from "@/recoil/loadingScreenState";
import { useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from "react";
import firstLoadingState from "@/recoil/firstLoadingState";

export default function ChatLayout() {
  const isShowLoadingScreen = useRecoilValue(loadingScreenState)
  const [isFirstLoading, setIsFirstLoading] = useRecoilState(firstLoadingState)

  const location = useLocation();
  const previousLocationRef = useRef(location);
  const [isLocationChanged, setIsLocationChanged] = useState(false)

  useEffect(() => {
    if (isFirstLoading || previousLocationRef.current.pathname !== location.pathname) {
      setIsLocationChanged(true)
      setIsFirstLoading(false)
    } else {
      setIsLocationChanged(false)
    }
    previousLocationRef.current = location;
  }, [location]);

  return (
    <div className={"container-fluid p-0 relative"}>
      <div className={`${isShowLoadingScreen && isLocationChanged ? 'hidden' : 'block'}`}>
        <Outlet />
      </div>
    </div>
  );
}
