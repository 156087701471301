import svgs from "@/assets/svgs";
import OrderDetailWrapper from "@/components/common/OrderDetailWrapper";
import ShippingStatus from "@/components/common/ShippingStatus";
import { useQueryOrders } from "@/hooks/ReactQuery/profile.query";
import useWindowSize from "@/hooks/useWindowSize";
import { orderStatus } from "@/utils/orderStatus";
import { Divider, Pagination, PaginationProps, Spin } from "antd";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

const TrackingOrder = () => {
  const { width } = useWindowSize();
  const { slugStatus } = useParams<string>();
  const [searchParams] = useSearchParams({
    page: "1",
    pageSize: "5",
  });
  const page = searchParams.get("page") || "1";
  const pageSize = searchParams.get("pageSize") || "5";

  const [currentPage, setCurrentPage] = useState<number>(Number(page));
  const [statusSelected, setStatusSelected] = useState<string>();

  const { orderList, isQueryOrdersLoading, informationPagination, refetch } =
    useQueryOrders(
      statusSelected !== ""
        ? `pagination[page]=${currentPage}&pagination[pageSize]=${pageSize}&${statusSelected
            ?.split("/")
            .map((item) => `filters[status]=${item}`)
            .join("&")}`
        : `pagination[page]=${currentPage}&pagination[pageSize]=${pageSize}`,
      slugStatus === "return-cancel"
    );

  const onChange: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
  };

  const handleChangeStatus = (status: string) => {
    setStatusSelected(status);
  };

  useEffect(() => {
    if (slugStatus) {
      const currentStatus = orderStatus[slugStatus];
      setStatusSelected(currentStatus ? currentStatus.key : "");
    } else {
      const currentStatus = orderStatus["waiting"];
      setStatusSelected(currentStatus ? currentStatus.key : "");
    }
    setCurrentPage(1);
  }, [slugStatus]);

  const handleRefreshData = () => {
    refetch();
  };
  if (isQueryOrdersLoading) {
    return (
      <div className="flex flex-col gap-[24px] md:gap-[32px] p-0 md:p-[24px_0_24px_24px]">
        <ShippingStatus
          status={slugStatus}
          handleChangeStatus={handleChangeStatus}
        />
        <div className="w-full bg-[#F7F7F7] md:bg-transparent h-[8px] md:h-fit mt-[-8px] md:mt-0">
          <Divider className=" hidden md:block h-[1px] bg-black m-0" />
        </div>
        <div className="h-[300px] w-full flex flex-col justify-center items-center">
          <Spin size="large" />
        </div>
      </div>
    );
  }

  if (!isQueryOrdersLoading && orderList?.length <= 0) {
    return (
      <div className="flex flex-col gap-[24px] md:gap-[32px] p-0 md:p-[24px_0_24px_24px]">
        <ShippingStatus
          status={slugStatus}
          handleChangeStatus={handleChangeStatus}
        />
        <div className="w-full bg-[#F7F7F7] md:bg-transparent h-[8px] md:h-fit mt-[-8px] md:mt-0">
          <Divider className=" hidden md:block h-[1px] bg-black m-0" />
        </div>
        <div className="min-h-[426px] w-full flex flex-col justify-center items-center">
          <div className="h-full flex flex-col justify-center items-center p-[10px]">
            <div className="w-[80px] h-[80px] mb-[24px]">
              <img
                src={svgs.noOrder}
                alt="no order"
                className="w-full h-full"
              />
            </div>
            <div>
              <p className="text-[16px] font-[400] leading-[22.4px]">
                Chưa có đơn hàng
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-[24px] md:gap-[32px] p-0 md:p-[24px_0_24px_24px]">
      <div>
        <ShippingStatus
          status={slugStatus}
          handleChangeStatus={handleChangeStatus}
        />
        <div className="w-full bg-[#F7F7F7] md:bg-transparent h-[8px] md:h-fit md:mt-[32px]">
          <Divider className="hidden md:block h-[1px] bg-black m-0" />
        </div>
      </div>
      <div className="flex flex-col gap-[8px] md:gap-0 bg-[#F7F7F7] mt-[-24px] md:mt-0 md:bg-white">
        {orderList?.map((order, index) => (
          <div key={index} className="w-full bg-white">
            <OrderDetailWrapper
              order={order}
              showBillDetail={width >= 768}
              showFooter={width >= 768}
              handleRefreshData={handleRefreshData}
              hasOrderDetail={width >= 768}
              hasNavigateOrderDetail={width < 768}
              showOrderCode
            />
            {orderList.length - 1 !== index && (
              <div className="hidden md:block w-full my-[24px] md:my-[32px] border-[1px] border-solid border-black"></div>
            )}
          </div>
        ))}
      </div>

      <div className="flex justify-center items-center my-8">
        <Pagination
          current={currentPage || 1}
          total={
            (informationPagination?.pageCount || 1) *
            (informationPagination?.pageSize || Number(pageSize))
          }
          pageSize={parseInt(pageSize) || Number(pageSize)}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default TrackingOrder;
