/**
 * Formats a date string into a more human-readable format.
 * @param dateString - The ISO date string (e.g., "2024-09-01T10:00:00.000Z")
 * @param locale - The locale for formatting (optional, default is "en-GB")
 * @param options - Intl.DateTimeFormat options to customize the format
 * @returns The formatted date string
 */
export function formatDate(
  dateString: string,
  locale: string = "en-GB",
  options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }
): string {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat(locale, options).format(date);
}
