import svgs from "@/assets/svgs";
import { UserProfileType } from "@/type/response/user";
import clsx from "clsx";
import constants from "@/utils/constants";

const ScrollTopTop = () => {
  const dataProfile = localStorage.getItem(constants.PROFILE);
  const profile: UserProfileType = dataProfile ? JSON.parse(dataProfile) : {};

  const scrollToTop = () => {
    const head = document.getElementById("header");
    head?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <div
      className={clsx(
        "fixed right-[16px] w-[48px] h-[48px] z-[100] cursor-pointer",
        profile?.id ? "md:bottom-[6rem] bottom-[12rem]" : "bottom-16"
      )}
      onClick={scrollToTop}
    >
      <img
        src={svgs.arrowUpCircle}
        alt="scroll-to-top-icon"
        className="w-full h-full"
      />
    </div>
  );
};

export default ScrollTopTop;
