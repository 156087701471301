import svgs from "@/assets/svgs";
import routes from "@/utils/routes";
import { useNavigate } from "react-router-dom";

export interface IProps {}

export default function Footer(_: IProps) {
  const navigate = useNavigate();

  return (
    <div className="relative bg-[#000000] text-[#F0F0F0] flex flex-col py-[40px] items-center gap-[32px]">
      <div className="max-w-[1120px] flex flex-col items-center w-full gap-[32px] py-[40px] md:py-auto px-[16px] md:px-auto">
        <div className="flex flex-col md:flex-row w-full justify-between items-start gap-[32px] md:gap-0">
          <div className="flex gap-[16px]">
            <img
              src={svgs.facebookWhiteDefaultIcon}
              alt=""
              width={32}
              height={32}
              className="w-[24px] md:w-[32px] aspect-square"
            />
            <img
              src={svgs.instagramWhiteDefaultIcon}
              alt=""
              width={32}
              height={32}
              className="w-[24px] md:w-[32px] aspect-square"
            />
            <img
              src={svgs.tiktokWhiteDefaultIcon}
              alt=""
              width={32}
              height={32}
              className="w-[24px] md:w-[32px] aspect-square"
            />
          </div>

          <div className="flex flex-col gap-[8px] md:gap-[16px]">
            <p className="text-[14px] md:text-[24px] font-[600]">
              Truyền thông
            </p>
            <p className="text-[14px] md:text-[24px] font-[600]">
              Tin tức, sự kiện
            </p>
            <p className="text-[14px] md:text-[24px] font-[600]">Tuyển dụng</p>
            <p className="text-[14px] md:text-[24px] font-[600]">
              Phát triển bền vững
            </p>
          </div>

          <div className="flex flex-col gap-[8px] md:gap-[16px]">
            <p
              className="text-[14px] md:text-[24px] font-[600] cursor-pointer mb-[8px]"
              onClick={() => navigate(routes.ABOUT_US)}
            >
              Về chúng tôi
            </p>
            <p
              className="text-[12px] md:text-[16px] font-[400] cursor-pointer"
              onClick={() => navigate(routes.BRAND)}
            >
              Câu chuyện thương hiệu
            </p>
            <p className="text-[12px] md:text-[16px] font-[400]">
              Hệ thống đại lý
            </p>
            <p className="text-[12px] md:text-[16px] font-[400]">
              Thành tích nổi bật
            </p>
          </div>

          <div className="flex flex-col gap-[8px] md:gap-[16px]">
            <p
              className="text-[14px] md:text-[24px] font-[600] cursor-pointer"
              onClick={() => navigate(routes.SUPPORT)}
            >
              Hỗ trợ
            </p>
            <p
              className="text-[12px] md:text-[16px] font-[400] cursor-pointer"
              onClick={() =>
                navigate(`${routes.SUPPORT}/${routes.FORGOT_PASSWORD_HELP}`)
              }
            >
              Đổi mật khẩu
            </p>
            <p
              className="text-[12px] md:text-[16px] font-[400] cursor-pointer"
              onClick={() =>
                navigate(`${routes.SUPPORT}/${routes.RETURN_POLICY}`)
              }
            >
              Chính sách đổi trả
            </p>
            <p
              className="text-[12px] md:text-[16px] font-[400] cursor-pointer"
              onClick={() => navigate(`${routes.SUPPORT}/${routes.VNPAY_HELP}`)}
            >
              Hướng dẫn thanh toán VNPAY-QR
            </p>
          </div>
        </div>

        <div className="border-b border-[#FFF] w-full"></div>

        <div className="flex flex-col md:flex-row justify-between w-full items-start gap-[8px] md:gap-[64px]">
          <div className="flex flex-col gap-[8px] md:gap-[4px] flex-1">
            <p className="text-[12px] md:text-[14px] font-[400]">
              Địa chỉ: 1/13 Giang Văn Minh, phương An Phú, quận 2, thành phố Thủ
              Đức, thành phố Hồ Chí Minh.
            </p>
            <p className="text-[12px] md:text-[14px] font-[400]">
              Mail: sale@sonatural.vn. Điện thoại: 0931982145 (zalo). Mã Số Thuế
              : 0315842546
            </p>
            <p className="text-[12px] md:text-[14px] font-[400]">
              Copyright © 2023 So'Natural Việt Nam Official
            </p>
          </div>

          {/* <figure className="w-[125px] h-[45px]">

            <img src={images.industryAndTrade} alt="" width={125} height={45} />
          </figure> */}
        </div>
      </div>
    </div>
  );
}
