import { useEffect, useState } from 'react'
import productApi from "@/api/productApi"
import { BaseData } from "@/type/base/baseData"
import { CommentType } from "@/type/common/product/comment"
import useWindowSize from "@/hooks/useWindowSize"
import { chunkArray } from "@/utils/chunkArray"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
import CustomerComment from "@/components/pages/ProductDetails/FeebackComment/CustomerComment"
import CommentItem from "@/components/pages/ProductDetails/FeebackComment/CommentItem"
import { useParams } from "react-router-dom"
import useProductDetailsQueryData from "@/hooks/ReactQuery/productDetails.query"
import routes from "@/utils/routes"
import Breadcrumb from "@/components/common/Breadcrumb"

const Comments = () => {
  const { slug: slugParams } = useParams<string>();

  const {
    product,
    productId,
  } = useProductDetailsQueryData(slugParams as string);

  const size = useWindowSize()

  const [isValidUser, setIsValidUser] = useState<boolean>(false)
  const [allComments, setAllComments] = useState<BaseData<CommentType>[]>([])

  const breadcrumbItems = size.width < 768
    ? [
      {
        href: routes.HOME,
        title: 'Trang chủ',
      },
      {
        title: '...'
      },
      {
        href: `${routes.PRODUCT}/${slugParams}`,
        title: product?.name || product?.shortName,
      },
    ]
    : [
      {
        href: routes.HOME,
        title: 'Trang chủ',
      },
      {
        href: routes.PRODUCT,
        title: 'Sản phẩm'
      },
      {
        href: `${routes.PRODUCT}/${slugParams}`,
        title: product?.name || product?.shortName,
      },
      {
        href: `${routes.PRODUCT}/${slugParams}`,
        title: product?.name || product?.shortName,
      },
    ];

  const commentChunk = chunkArray(allComments || [], 5)

  const fetchAllProductReviews = async () => {
    if (productId) {
      try {
        await productApi.getAllComments(Number(productId)).then(res => {
          setAllComments(res.data.data)
        })
      } catch (error) {
        console.log('Error at fetchProductReviews', error)
      }
    }
  }

  const checkValidUserToComment = async () => {
    if (productId) {
      try {
        const body = { product_id: productId as string }
        const isValid = await productApi.checkValidUserToComment(body).then(res => res.data)
        setIsValidUser(isValid)
      } catch (error) {
        console.log("Error at checkValidUserToComment", error)

      }
    }
  }

  useEffect(() => {
    fetchAllProductReviews()
    checkValidUserToComment()
  }, [productId])

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />

      <CustomerComment isValidUser={isValidUser} productId={productId as string} />

      <Swiper
        observer={true}
        observeParents={true}
        onSwiper={(swiper) => swiper.update()}
        slidesPerView={1}
        navigation={size.width >= 768}
        modules={[Navigation]}
        className="swiper-feedback"
      >
        {commentChunk.map((chunk, index) => {
          return (
            <SwiperSlide key={index}>
              {chunk?.map((feedback, index) => {
                return (
                  <CommentItem data={feedback} key={index} />
                )
              })}
            </SwiperSlide>
          )
        })}
      </Swiper>
    </>
  )
}

export default Comments