import urls from '@/utils/urls';
import axiosClient from './axiosClient';

const paymentApi = {
  post: (payment: any) => {
    return axiosClient.post(urls.PAYMENT_CRATE, payment);
  },
};

export default paymentApi;
