// import Product from "@/components/common/Product";
import ProductSkeleton from "@/components/skeleton/common/product";
import formatStringParamsFilterProducts from "@/utils/formatStringParamsFIlter";
import { Empty, Pagination } from "antd";
import queryString from "query-string";
import { useSearchParams } from "react-router-dom";
import "@/assets/scss/pagination.antd.scss";
import Product from "@/components/common/NewProduct";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  confirmFilter,
  filterCountProduct,
  isLoadingFilter,
} from "@/recoil/filterProduct";
import { useEffect, useState } from "react";
import { BaseData } from "@/type/base/baseData";
import { ProductsResponse } from "@/type/response/products";
import { ProductFlashSaleType } from "@/type/response/flashSale";
import { useRootLayoutContext } from "@/layouts/RootLayout";
import useProductsFilterQuery from "@/hooks/ReactQuery/product.filter.query";

interface IProductFilterComponentProps {
  isFilterFlashSale: boolean;
}

export default function ProductFilterComponent({
  isFilterFlashSale,
}: IProductFilterComponentProps) {
  const { setIsLoadingDone } = useRootLayoutContext();

  const [searchParams, setSearchParams] = useSearchParams({
    page: "1",
    pageSize: "15",
  });
  const setCountProduct = useSetRecoilState(filterCountProduct);
  const [confirmButton] = useRecoilState(confirmFilter);
  const setLoadingFilter = useSetRecoilState(isLoadingFilter);
  const page = searchParams.get("page") || "1";
  const pageSize = searchParams.get("pageSize") || "15";
  const [currentProducts, setCurrentProducts] = useState<
    BaseData<ProductsResponse>[] | BaseData<ProductFlashSaleType>[]
  >();
  const [currentTotalPagination, setCurrentTotalPagination] =
    useState<number>();
  const { productsFlashSale, products, isLoading, informationPagination } =
    useProductsFilterQuery(
      formatStringParamsFilterProducts(searchParams),
      isFilterFlashSale
    );

  const [currentFlashSale, setCurrentFlashSale] = useState<boolean>(false);

  useEffect(() => {
    setLoadingFilter(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setIsLoadingDone(!isLoading);

    if (isFilterFlashSale) {
      if (productsFlashSale === undefined) {
        setCountProduct(0);
        return;
      }
      setCountProduct(productsFlashSale?.length || 0);
    } else {
      setCountProduct(informationPagination?.total || 0);
      setCurrentTotalPagination(informationPagination?.total);
    }

    if (confirmButton) {
      if (isFilterFlashSale) {
        setCurrentProducts(productsFlashSale);
        setCurrentFlashSale(true);
      } else {
        setCurrentProducts(products);
        setCurrentFlashSale(false);
      }
    }
  }, [
    confirmButton,
    isLoading,
    isFilterFlashSale,
    informationPagination?.total,
    searchParams,
  ]);

  if (isLoading && !currentProducts?.length) {
    return (
      <div className="grid grid-cols-2 gap-[16px] md:grid-cols-4">
        {Array.from({ length: 12 }).map((_: any, index: number) => (
          <ProductSkeleton key={index} showButton showRating />
        ))}
      </div>
    );
  }

  if (currentFlashSale) {
    if (currentProducts?.length === 0)
      return <Empty description="Không có sản phẩm nào" />;

    return (
      <>
        <div className="grid grid-cols-2 gap-[24px] md:grid-cols-5">
          {currentProducts?.map((product) => (
            <Product
              key={product.id}
              product={product}
              thumbType="flashsale"
              contentPage="products"
            />
          ))}
        </div>
      </>
    );
  }

  if (currentProducts?.length === 0)
    return (
      <div className="min-h-[800px]">
        <Empty description="Không có sản phẩm nào" />
      </div>
    );

  return (
    <>
      <div className="grid grid-cols-2 gap-[24px] md:grid-cols-5">
        {currentProducts?.map((product) => (
          <Product
            key={product.id}
            product={product}
            contentPage="products"
            thumbType="primary"
          />
        ))}
      </div>
      {currentTotalPagination && (
        <div className="flex justify-center items-center my-8">
          <Pagination
            defaultCurrent={parseInt(page) || 1}
            total={currentTotalPagination || 1}
            pageSize={parseInt(pageSize) || 12}
            onChange={(page, pageSize) => {
              const newSearchParams = new URLSearchParams({
                ...queryString.parse(location.search),
                page: page.toString(),
                pageSize: pageSize.toString(),
              });
              setSearchParams(newSearchParams);
            }}
          />
        </div>
      )}
    </>
  );
}
