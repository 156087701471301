import searchApi from "@/api/searchApi";
import svgs from "@/assets/svgs";
import useWindowSize from "@/hooks/useWindowSize";
import { SearchBarType } from "@/type/response/searchBar";
import routes from "@/utils/routes";
import classNames from "classnames";
import clsx from "clsx";
import _ from "lodash";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";

interface ISearchInputProps {
  inDrawer?: boolean;
  setIsShowSearch?: (isShow: boolean) => void;
  setIsOpenSearchDrawer?: (isOpen: boolean) => void;
  searchDefault?: SearchBarType;
}

export default function SearchInput(props: ISearchInputProps) {
  const { inDrawer, setIsShowSearch, setIsOpenSearchDrawer, searchDefault } = props;
  const options = [] as any;
  const navigate = useNavigate();
  const size = useWindowSize();

  const [searchResult, setSearchResult] = React.useState<any>([]);
  const [input, setInput] = React.useState<string>("");
  const [isShowModelSearch, setIsShowModelSearch] =
    React.useState<boolean>(false);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const listRef = React.useRef<HTMLDivElement>(null);

  const handleBlur = () => {
    document.body.style.overflowY = "auto";
  };

  const handleFocus = () => {
    setIsShowModelSearch(true)
  }

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && !!searchResult.length) {
      navigate(`${routes.SEARCH_RESULT}?searchKey=${input}`);
      setIsShowSearch && setIsShowSearch(false)
      setIsOpenSearchDrawer && setIsOpenSearchDrawer(false)
      handleBlur();
    }
  };

  const handleSearch = (value: any) => {
    setInput(value?.target?.value || "");
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          queries: [
            {
              indexUid: "product",
              q: input || " ",
              facets: [],
              attributesToHighlight: ["*"],
              highlightPreTag: "<ais-highlight-0000000000>",
              highlightPostTag: "</ais-highlight-0000000000>",
              limit: 21,
              offset: 0,
            },
          ],
        }),
      };
      fetch("https://search.k-techvn.com/multi-search", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          data?.results?.[0]?.hits.forEach((element: any) => {
            if (
              !_.isEmpty(element?.name || element?.short_name) ||
              !_.isEmpty(element?.avatar) ||
              !_.isEmpty(element?.avatar)
            ) {
              options.push({
                id: element?.id || null,
                value: element?.name || element?.short_name || "",
                label: element?.name || element?.short_name || "",
                emoji: element?.avatar?.url || "",
                slug: element?.slug || "",
              });
            }
          });

          setSearchResult(options);
        });
    };

    fetchData();
  }, [input]);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (size.width > 768) {
        if (
          inputRef.current &&
          !inputRef.current.contains(event.target as Node) &&
          listRef.current &&
          !listRef.current.contains(event.target as Node)
        ) {
          handleBlur();
          setIsShowModelSearch(false);
          setIsShowSearch && setIsShowSearch(false)
          setIsOpenSearchDrawer && setIsOpenSearchDrawer(false)
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [size.width]);

  return (
    <>
      <div className={clsx(
        "relative rounded-[16px] bg-[#F0F0F0] px-[16px] py-[8px] flex items-center gap-[16px]",
        inDrawer ? "w-full" : "w-[366px]"
      )}
        ref={inputRef}
      >
        <div className="w-[24px] h-[24px]" onClick={handleSearch}>
          <img
            src={svgs.searchIcon}
            alt="logo"
            className="w-full h-[24px] object-fill"
            width={24}
          />
        </div>

        <input
          autoFocus
          // onBlur={handleBlur}
          onFocus={handleFocus}
          value={input}
          name="text"
          id="inputSearch"
          onChange={handleSearch}
          placeholder="Tìm kiếm Red Peel, máy massage..."
          className=" placeholder:text-[#68665F] text-[14px] font-normal bg-[#F0F0F0] h-[24px] md:h-[32px]"
          autoComplete="off"
          onKeyDown={handleKeyDown}
        />
        {/* <img
          src={svgs.iconDelete}
          alt=""
          className="w-[24px] h-[24px] cur"
          onClick={() => {
            setInput('');
          }}
        /> */}
      </div>
      <div
        className={clsx(
          "px-[16px] absolute left-0 top-[73px] md:top-[53px] bg-white border-spacing-1  overflow-auto touch-auto md:rounded-[16px] md:border border-[#F0F0F0] z-[1001] max-h-[450px]",
          inDrawer ? "w-full px-[16px]" : "w-[366px]",
          { 'hidden': size.width > 768 && !isShowModelSearch }
        )}
        ref={listRef}
      >
        <div className=" gap-3  flex-wrap ">

          {/* Category */}
          {!input.length && (
            <div
              className={classNames(
                `flex flex-wrap mt-4 gap-[8px] justify-start items-center relative `
              )}
            >

              {!!searchDefault?.promoCategory?.length &&
                searchDefault?.promoCategory.map((item, index) => (
                  <Link
                    to={`${routes.PRODUCT}?categories=${item?.category?.data?.id}`}
                    onClick={() => {
                      setInput("");
                      handleBlur();
                      setIsShowSearch && setIsShowSearch(false)
                      setIsOpenSearchDrawer && setIsOpenSearchDrawer(false)
                    }}
                    key={index}
                    className="text-[#000]"
                  >
                    <div className="flex whitespace-nowrap justify-center items-center px-[16px] py-[8px] rounded-[24px] bg-[#F0F0F0]">
                      <p className="text-[12px]">
                        {item?.category?.data?.attributes?.name || ""}
                      </p>
                    </div>
                  </Link>
                ))}
            </div>
          )}

          {!input.length ? (
            <div>
              <p className="text-[16px] font-[500] mt-[12px]">
                Sản phẩm
              </p>
              {!!searchDefault?.promoteProducts?.length &&
                searchDefault?.promoteProducts.slice(0, 2).map((item, index) => (
                  <Link
                    key={index}
                    to={`${routes.PRODUCT}/${item?.product?.data?.attributes?.slug}`}
                    onClick={async () => {
                      setInput("");
                      await searchApi.trackSearch(item.product.data.id);
                      handleBlur();
                      setIsShowSearch && setIsShowSearch(false)
                      setIsOpenSearchDrawer && setIsOpenSearchDrawer(false)
                    }}
                    className="text-[#000] hover:text-inherit"
                  >
                    <div className="flex items-center gap-[24px] mb-4 mt-4 ">
                      <img
                        src={
                          item?.product?.data?.attributes?.avatar?.data
                            ?.attributes?.url
                        }
                        alt=""
                        className="w-[32px] h-[32px]"
                        loading="lazy"
                      />

                      <div className="truncate ... w-full md:w-auto text-inherit">
                        {item?.product?.data?.attributes?.name}
                      </div>
                    </div>
                  </Link>
                ))}

              <div className="border-b border-[#F0F0F0] mx-[-16px]"></div>

              <p className="text-[16px] font-[500] mt-[12px]">
                Combo tiết kiệm
              </p>
              {!!searchDefault?.savingComboBlock?.length &&
                searchDefault?.savingComboBlock.slice(0, 2).map((item, index) => (
                  <Link
                    key={index}
                    to={`${routes.PRODUCT}/${item?.product?.data?.attributes?.slug}`}
                    onClick={async () => {
                      setInput("");
                      await searchApi.trackSearch(item.product.data.id);
                      handleBlur();
                      setIsShowSearch && setIsShowSearch(false)
                      setIsOpenSearchDrawer && setIsOpenSearchDrawer(false)
                    }}
                    className="text-[#000] hover:text-inherit"
                  >
                    <div className="flex items-center gap-[24px] mb-4 mt-4 ">
                      <img
                        src={
                          item?.product?.data?.attributes?.avatar?.data
                            ?.attributes?.url
                        }
                        alt=""
                        className="w-[32px] h-[32px]"
                        loading="lazy"
                      />

                      <div className="truncate ... w-full md:w-auto text-inherit text-left">
                        {item?.product?.data?.attributes?.name}
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          ) : (
            searchResult?.length > 0 &&
            searchResult?.map((item: any) => (
              <div>
                <Link
                  to={`${routes.PRODUCT}/${item.slug}`}
                  onClick={async () => {
                    setInput("");
                    await searchApi.trackSearch(item.id);
                    handleBlur();
                    setIsShowSearch && setIsShowSearch(false)
                    setIsOpenSearchDrawer && setIsOpenSearchDrawer(false)
                  }}
                  className="text-[#000] hover:text-inherit"
                >
                  <div className="flex items-center gap-[24px] mx-4 mb-4 mt-4 ">
                    <img
                      src={item?.emoji}
                      alt=""
                      className="w-[32px] h-[32px]"
                      loading="lazy"
                    />

                    <div className="truncate ... w-full md:w-auto text-inherit text-left">
                      {" "}
                      {item.value}
                    </div>
                  </div>
                </Link>
              </div>
            ))
          )}
        </div>


      </div>
    </>
  );
}
