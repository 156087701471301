import * as React from 'react';
import EyeHashIcon from '@/customIcons/EyeHashIcon';
import EyeIcon from '@/customIcons/EyeIcon';
import styled from "styled-components";

export interface IPasswordFieldProps {
  placeholder?: string;
  name: string;
  register?: any;
  errors?: any;
  validateSchema?: any;
  control?: any
}

const InputStyled = styled.input`
  box-shadow: none !important;

  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus
  {
    border: none !important;
    -webkit-text-fill-color: #484848;
    -webkit-box-shadow: 0 0 0px 1000px #F6F2EF inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  &:-webkit-autofill:not(:placeholder-shown) {
    border: none !important;
  }
`;
export default function PasswordField({
  placeholder,
  name,
  register,
  errors,
  validateSchema,
  control
}: IPasswordFieldProps) {
  const [visible, setVisible] = React.useState(false);

  return (
    <>
      <div className="relative">
        <InputStyled
          control={control}
          type={visible ? 'text' : 'password'}
          placeholder={placeholder}
          className="w-full p-4 bg-[#F6F2EF] text-[#484848] font-normal text-[13px] md:text-[16px] border-none placeholder:text-[#484848] remove-arrow"
          {...register(name, validateSchema)}
        />

        <div
          className="absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer"
          onClick={() => setVisible(!visible)}
        >
          {visible ? <EyeIcon /> : <EyeHashIcon />}
        </div>
      </div>
      {errors && <p className="text-[13px] text-red-500">{errors.message}</p>}
    </>
  );
}
