import { BaseData } from '@/type/base/baseData';
import { FilterProductBase } from '@/type/response/filterProducts';
import { MenuProps } from 'antd';

type MenuItem = Required<MenuProps>['items'][number];

export const convertToMenuItems = (
  data: BaseData<FilterProductBase>[],
  key: string
) => {
  return data.map((item) => {
    const menuItem = getItemMenuAntd(item.attributes.name, `${key}-${item.id}`);

    // if have children then use recursion covert to menu items
    return menuItem;
  });
};

export const getItemMenuAntd = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
};
