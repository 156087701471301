import Delivering from "@/assets/svgs/custom/Delivering";
import History from "@/assets/svgs/custom/History";
import Received from "@/assets/svgs/custom/Received";
import Return from "@/assets/svgs/custom/Return";
import WaitingOrder from "@/assets/svgs/custom/WaitingOrder";
import StatusItem from "./StatusItem";
import { orderStatus, StatusOrderType } from "@/utils/orderStatus";
import { useNavigate } from "react-router-dom";
import routes from "@/utils/routes";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Navigation, Pagination } from "swiper/modules";
import useWindowSize from "@/hooks/useWindowSize";

interface IShippingStatusProps {
  handleChangeStatus: (status: string) => void;
  status?: string;
}
const ShippingStatus = (props: IShippingStatusProps) => {
  const { handleChangeStatus, status = orderStatus["waiting"].code } = props;
  const navigate = useNavigate();
  const { width } = useWindowSize();

  const sizeIcon = width >= 768 ? 32 : 24;

  const statusItem = [
    {
      status: orderStatus["waiting"],
      iconStatus: (
        <WaitingOrder
          height={sizeIcon}
          width={sizeIcon}
          active={status === orderStatus["waiting"].code}
        />
      ),
    },
    {
      status: orderStatus["delivering"],
      iconStatus: (
        <Delivering
          height={sizeIcon}
          width={sizeIcon}
          active={status === orderStatus["delivering"].code}
        />
      ),
    },
    {
      status: orderStatus["received"],
      iconStatus: (
        <Received
          height={sizeIcon}
          width={sizeIcon}
          active={status === orderStatus["received"].code}
        />
      ),
    },
    {
      status: orderStatus["return-cancel"],
      iconStatus: (
        <Return
          height={sizeIcon}
          width={sizeIcon}
          active={status === orderStatus["return-cancel"].code}
        />
      ),
    },
    {
      status: orderStatus["history"],
      iconStatus: (
        <History
          height={sizeIcon}
          width={sizeIcon}
          active={status === orderStatus["history"].code}
        />
      ),
    },
  ];

  const handleClickStatus = (status: StatusOrderType | undefined) => {
    if (!status) {
      return;
    }
    handleChangeStatus(status.key);
    navigate(`${routes.PROFILE}/${routes.ORDERS}/${status.code}`);
  };
  return (
    <div className="w-full p-[16px] md:p-0">
      <Swiper
        className="status-order w-full"
        observer={true}
        observeParents={true}
        pagination={{
          el: ".sniper-pagination-3",
          clickable: true,
        }}
        breakpoints={{
          "320": {
            slidesPerView: 2.5,
            slidesPerGroup: 2,
            spaceBetween: 8,
          },
          "768": {
            slidesPerView: 2.5,
            slidesPerGroup: 2,
            spaceBetween: 4,
          },
          "1024": {
            slidesPerView: 3,
            slidesPerGroup: 2,
            spaceBetween: 8,
          },
          "1225": {
            slidesPerView: 4,
            slidesPerGroup: 2,
            spaceBetween: 0,
          },
          "1297": {
            slidesPerView: 5,
            spaceBetween: 0,
          },
        }}
        modules={[Pagination, Navigation]}
      >
        {statusItem.map((item, index) => (
          <SwiperSlide key={item.status.key} className="w-fit">
            <StatusItem
              nameStatus={item?.status?.name}
              iconStatus={item.iconStatus}
              active={status === item?.status?.code}
              lastItem={index === statusItem.length - 1}
              onClick={() => handleClickStatus(item.status)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ShippingStatus;
