import cardBlank from "./card-blank.svg";
import cartI from "./cart-i.svg";
import cartIcon from "./cart-icon.svg";
import contactOne from "./contact-1.svg";
import contactTwo from "./contact-2.svg";
import deliveryIcon from "./delivery-icon.svg";
import discountOne from "./discount-1.svg";
import discountTwo from "./discount-2.svg";
import discountThree from "./discount-3.svg";
import downArrow from "./down-arrow.svg";
import historyIcon from "./history-icon.svg";
import kolOne from "./kol-1.svg";
import kolThree from "./kol-3.svg";
import logo from "./logo.svg";
import passEye from "./passEye.svg";
import pickupIcon from "./pickup-icon.svg";
import quantityImage from "./quantity-img.svg";
import recivedIcon from "./recived-icon.svg";
import searchIcon from "./search-icon.svg";
import visaIcon from "./visa-icon.svg";
import voucherWeb from "./voucher-web.svg";
import youtubeIcon from "./youTube-icon.svg";
import zaloIcon from "./zalo-icon.svg";
import bar from "./bar.svg";
import warning from "./warning.svg";
import googleIcon from "./google.svg";
import chevron from "./chevron.svg";
import sale from "./sale.svg";
import iconDelete from "./iconDelete.svg";
import down from "./down.svg";
import increase from "./increase.svg";
import downArrowGray from "./down-arrow-gray.svg";
import downIcon from "./downIcon.svg";
import upIcon from "./up.svg";
import camera from "./camera.svg";
import filter from "./filter.svg";
import location from "./location.svg";
import forgotPwIcon from "./forgot-pw-icon.svg";
import fastShipping from "./fast-shipping.svg";
import userIcon from "./user-icon.svg";
import closeIcon from "./close.svg";
import facebookIcon from "./facebook.svg";
import loading1 from "./loading/sonatural_loading_icon1.svg";
import loading2 from "./loading/sonatural_loading_icon2.svg";
import loading3 from "./loading/sonatural_loading_icon3.svg";
import loading4 from "./loading/sonatural_loading_icon4.svg";
import loading5 from "./loading/sonatural_loading_icon5.svg";
import loading6 from "./loading/sonatural_loading_icon6.svg";
import returnIcon from "./return.svg";
import addImageChat from "./add-image-chat.svg";
import sendMessageChat from "./send-message-chat.svg";
import pickProducts from "./pick-products.svg";
import pickImage from "./pick-image.svg";
import shieldCheck from "./shield-check.svg";
import avatarDefault from "./avatar-default.svg";
import shoppingCartIcon from "./shopping-cart-icon.svg";
import facebookWhiteDefaultIcon from "./facebook-white-default.svg";
import instagramWhiteDefaultIcon from "./instagram-white-default.svg";
import tiktokWhiteDefaultIcon from "./tiktok-white-default.svg";
import arrowUpCircle from "./arrow-up-circle.svg";
import chatCircle from "./chat-circle-icon.svg";
import doubleChevronDown from "./doubleChevronDown.svg";
import chevronWhite from "./chevron-white.svg";
import twitter from "./twitter.svg";
import linkedin from "./linkedin.svg";
import dribbble from "./dribbble.svg";
import arrowRightWhite from "./arrow-right-white.svg";
import hidePassword from "./hide.svg";
import showPassword from "./show.svg";
import highlightCategories from "./highlight-categories.svg";
import arrowLeftBlack from "./arrow-left-black.svg";
import addCircleOrange from "./add-circle-orange.svg";
import truck from "./truck.svg";
import rotateLeft from "./rotate-left.svg";
import alertCircle from "./alert-circle.svg";
import rocket from "./rocket.svg";
import advise from "./advise.svg";
import cartProductDetail from "./cart-product-detail.svg";
import arrowRightBlack from "./arrow-right-black.svg";
import checkSuccess from "./check-success.svg";
import xError from "./x-error.svg";
import trash from "./trash.svg";
import doubleArrowDown from "./double-arrow-down.svg";
import fbGrey from "./facebook.-grey.svg";
import tiktok from "./tiktok.svg";
import plusCircle from "./plus-circle.svg";
import copy from "./copy.svg";
import noOrder from "./no-order.svg";

const svgs = {
  cardBlank,
  cartI,
  cartIcon,
  contactOne,
  contactTwo,
  deliveryIcon,
  discountOne,
  discountTwo,
  discountThree,
  downArrow,
  historyIcon,
  kolOne,
  kolThree,
  logo,
  passEye,
  pickupIcon,
  quantityImage,
  recivedIcon,
  searchIcon,
  visaIcon,
  voucherWeb,
  bar,
  warning,
  youtubeIcon,
  zaloIcon,
  googleIcon,
  chevron,
  sale,
  iconDelete,
  increase,
  down,
  downArrowGray,
  downIcon,
  upIcon,
  camera,
  filter,
  location,
  forgotPwIcon,
  fastShipping,
  userIcon,
  closeIcon,
  facebookIcon,
  loading1,
  loading2,
  loading3,
  loading4,
  loading5,
  loading6,
  returnIcon,
  addImageChat,
  sendMessageChat,
  pickProducts,
  pickImage,
  shieldCheck,
  avatarDefault,
  shoppingCartIcon,
  facebookWhiteDefaultIcon,
  instagramWhiteDefaultIcon,
  tiktokWhiteDefaultIcon,
  arrowUpCircle,
  chatCircle,
  doubleChevronDown,
  chevronWhite,
  twitter,
  linkedin,
  dribbble,
  arrowRightWhite,
  hidePassword,
  showPassword,
  highlightCategories,
  arrowLeftBlack,
  addCircleOrange,
  truck,
  rotateLeft,
  alertCircle,
  rocket,
  advise,
  cartProductDetail,
  trash,
  doubleArrowDown,
  arrowRightBlack,
  checkSuccess,
  xError,
  tiktok,
  fbGrey,
  plusCircle,
  copy,
  noOrder,
};

export default svgs;
