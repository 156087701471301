interface ILabelPercentProps {
  percent: number;
}

const LabelPercent = ({ percent }: ILabelPercentProps) => {
  if (!percent) return null;

  return (
    <div className="absolute top-[10px] left-[10px] rounded-full bg-white w-[32px] h-[32px] md:w-[40px] md:h-[40px] flex items-center justify-center">
      <p className="text-[10px] md:text-[12px] font-[400] leading-[14px] md:leading-[16.8px]">
        {percent}%
      </p>
    </div>
  )
}

export default LabelPercent