import routineApi from '@/api/routineApi';
import keysReactQuery from '@/utils/keys';
import { useQuery } from '@tanstack/react-query';

const MINUTES_STALE_TIME = 1;

export default function useQueryRoutineProduct(productId: string) {
  const { data, isLoading } = useQuery({
    queryKey: [keysReactQuery.ROUTINES_PRODUCT, productId],
    queryFn: () => routineApi.getByIdProduct(productId),
    staleTime: 1000 * 60 * MINUTES_STALE_TIME,
  });

  const routinesProduct = data?.data?.data[0]?.attributes?.products?.data || [];
  return { data, routinesProduct, isLoading };
}
