import { atom } from "recoil";

export interface IVoucherState {
  voucherId: number;
  amountDecrease: string;
  code: string;
  percentDecrease: string;
  status: string;
  title: string;
  minimumOrderAmount: string;
  condition: string;
}

const selectedVouchersState = atom<IVoucherState[]>({
  key: "selectedVouchersState",
  default: [],
});

export default selectedVouchersState;
