export default function VoucherSkeleton() {
  return (
    <div className="bg-white rounded-lg border border-[#FFFFFF] w-full">
      <div className="py-8 px-4 flex justify-center gap-6 items-center w-full">
        <div className=" w-16 h-16 object-cover object-center bg-gray-300 animate-pulse rounded-full"></div>

        <div className="w-full">
          <div className=" mb-1 h-[20px] w-full bg-gray-300 animate-pulse"></div>

          <div className="h-[20px] w-full bg-gray-300 animate-pulse mb-1"></div>

          <div className="h-[20px] w-full bg-gray-300 animate-pulse"></div>
        </div>
      </div>
    </div>
  );
}
