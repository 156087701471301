import urls from "@/utils/urls";
import axiosClient from "./axiosClient";

interface SendOTP {
  email: string | null;
}
interface VerifyOTP extends SendOTP {
  otp: string;
}

const otpApi = {
  sendOTP(params: SendOTP) {
    return axiosClient.post(urls.SEND_OTP, params);
  },
  verifyOTP(params: VerifyOTP) {
    return axiosClient.post(urls.VERIFY_OTP, params);
  },
  resendOTP(params: SendOTP) {
    return axiosClient.post(urls.RESEND_OTP, params);
  },
};
export default otpApi;
