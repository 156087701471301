import { Button, ButtonProps } from "antd";
import clsx from "clsx";
import { Link } from "react-router-dom";

interface INewButtonComponentProps extends ButtonProps {
  text: string;
  textClassName?: string;
  textColorClassName?: string;
  href?: string;
}
const NewButtonComponent = (props: INewButtonComponentProps) => {
  const { text, textClassName, textColorClassName, href, ...rest } = props;

  if (href) {
    return (
      <Link
        to={href}
        target="_blank"
        rel="noopener noreferrer"
        className={clsx(
          "h-auto",
          textClassName,
          textColorClassName || "text-inherit",
          rest.className
        )}
      >
        {text}
      </Link>
    );
  }
  return (
    <Button
      type={rest.type ?? "primary"}
      {...rest}
      className={clsx(
        "h-auto py-[10px] px-[16px] rounded-[24px]",
        rest.className
      )}
    >
      <p
        className={clsx(
          "h-auto text-[14px] font-[400] leading-[16.94px]",
          textClassName,
          textColorClassName || "text-inherit"
        )}
      >
        {text}
      </p>
    </Button>
  );
};

export default NewButtonComponent;
