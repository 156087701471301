/* eslint-disable import/extensions */
import urls from '@/utils/urls';
import axiosClient from './axiosClient';
import { BaseResponse } from "@/type/base/baseResponse";
import { BaseData } from "@/type/base/baseData";
import { SearchBarType } from "@/type/response/searchBar";

const searchApi = {
  get() {
    return axiosClient.get<BaseResponse<BaseData<SearchBarType>>>(urls.SEARCH_BAR);
  },

  trackSearch(productId: number) {
    return axiosClient.post(`${urls.PRODUCT}/${urls.TRACK_SEARCH}`, { "product_id": productId });
  }
};
export default searchApi;

export type SearchPayload = {
  input: string;
};
