import BasicModal from "@/components/common/Modal";
import { useEffect, useState } from "react";
import FormDeliveryInfo from "@/components/common/FormDeliveryInfo";
import { UserProfileType } from "@/type/response/user";
import userAddressApi from "@/api/userAddressApi";
import constants from "@/utils/constants";
import { useRecoilState, useSetRecoilState } from "recoil";
import userAddressState, {
  checkUpdateAddress,
  firstLoadAddressState,
  maskCloseableFormAddressState,
  selectedAddressState,
} from "@/recoil/userAddressState";
import useWindowSize from "@/hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
import routes from "@/utils/routes";
import DeliveryWrapper from "./DeliveryWrapper";

const NewDeliveryInformation = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const [openModalChangeInfo, setOpenModalChangeInfo] =
    useState<boolean>(false);

  const [openModalFormInfo, setModalFormInfo] = useState(false);

  const [addressData, setAddressData] = useRecoilState(userAddressState);
  const [selectedAddress, setSelectedAddress] =
    useRecoilState(selectedAddressState);
  const [firstLoadAddress, setFirstLoadAddress] = useRecoilState(
    firstLoadAddressState
  );
  const [checkUpdateAddressData] = useRecoilState(checkUpdateAddress);
  const setMaskCloseableAddressForm = useSetRecoilState(
    maskCloseableFormAddressState
  );
  const handleChangeAddress = () => {
    if (width >= 768) {
      setOpenModalChangeInfo(true);
      return;
    }

    navigate(routes.CHANGE_ADDRESS);
  };

  const handelCancelModalChangeInfo = () => {
    setOpenModalChangeInfo(false);
  };

  const handleOpenFormInfo = () => {
    if (width >= 768) {
      setModalFormInfo(true);
    } else {
      navigate(routes.PROVIDE_ADDRESS, {
        state: {
          typeAction: "create",
          fromPage: routes.CART,
        },
      });
    }
  };

  const handleCancelFormInfo = () => {
    setModalFormInfo(false);
    setMaskCloseableAddressForm(new Date().toString());
  };

  useEffect(() => {
    if (firstLoadAddress) return;

    const fetchData = async () => {
      const dataProfile = localStorage.getItem(constants.PROFILE);
      const profile: UserProfileType = dataProfile
        ? JSON.parse(dataProfile)
        : {};
      if (!profile) return;
      const userAddressRes = await userAddressApi.getAll(
        `filters[user]=${profile?.id}`
      );

      const userAddressData = userAddressRes.data.data;

      if (!userAddressData) return;

      const sortedAddresses = userAddressData.sort((a, b) => {
        if (a?.attributes?.isDefault) return -1;
        if (b?.attributes?.isDefault) return 1;
        return 0;
      });
      setSelectedAddress(sortedAddresses[0]);
      setAddressData(sortedAddresses);
    };

    fetchData();
    setFirstLoadAddress(true);
  }, []);

  useEffect(() => {
    if (!addressData.length) return;

    const currentIndexSelectedAddress = addressData.findIndex(
      (item) => item.id === selectedAddress?.id
    );

    if (currentIndexSelectedAddress !== -1) {
      setSelectedAddress(addressData[currentIndexSelectedAddress]);
    } else {
      setSelectedAddress(addressData[0]);
    }
  }, [checkUpdateAddressData]);

  const { provinceCity, district, ward, street } =
    selectedAddress?.attributes?.address || {};
  const addressString = `${street}, ${ward?.name}, ${district?.name}, ${provinceCity?.name}`;
  return (
    <div className="flex item-center justify-center">
      <div className="w-full p-[24px_16px] md:p-[32px_165px] bg-white max-w-[1440px]">
        <div className="md:max-w-[1120px] md:mx-auto flex flex-col gap-[16px] md:gap-[21px]">
          <p className="text-left text-black md:text-[#FF9900] text-[14px] md:text-[24px] font-[600] uppercase leading-[19.64px] md:leading-[29.05px]">
            Thông tin nhận hàng
          </p>

          <div className="flex justify-start gap-[16px] md:gap-[32px]">
            <p className="max-w-[150px] md:max-w-[300px] text-[#484848] md:text-black text-[14px] md:text-[16px] font-[500] leading-[16.94px]">
              {selectedAddress?.attributes?.fullName}
            </p>
            <div className="*:text-[#484848] md:*:text-black *:text-[14px] md:*:text-[16px] *:text-justify md:text-left *:font-[500] leading-[16.94px] md:*:leading-[19.36px]">
              <p>{selectedAddress?.attributes?.phone}</p>
              {provinceCity && <p>{addressString}</p>}
            </div>
          </div>
          {!addressData?.length ? (
            <div className="flex justify-end items-center">
              <p
                className="text-[#FF9900] text-[14px] font-[400] leading-[19.6px] no-underline md:underline underline-offset-[3px] cursor-pointer"
                onClick={handleOpenFormInfo}
              >
                Thêm địa chỉ giao hàng
              </p>
            </div>
          ) : (
            <div className="flex justify-end items-center">
              <p
                className="text-[#FF9900] text-[14px] font-[400] leading-[19.6px] no-underline md:underline underline-offset-[3px] cursor-pointer"
                onClick={handleChangeAddress}
              >
                Đổi địa chỉ
              </p>
            </div>
          )}
        </div>

        {width >= 768 && (
          <BasicModal
            isModalOpen={openModalChangeInfo}
            handleCancel={handelCancelModalChangeInfo}
            handleOk={() => {}}
            maskClosable
            footer={null}
            className="min-w-[640px] w-[640px] h-[536px]"
            rootClassName="delivery z-[1500]"
          >
            <DeliveryWrapper
              listItem={addressData}
              handleAddNewAddress={handleOpenFormInfo}
            />
          </BasicModal>
        )}

        {width >= 768 && (
          <BasicModal
            rootClassName="formDeliveryInfo hidden md:block"
            className="md:min-w-[70%] md:w-[70%] lg:min-w-[640px] lg:w-[640px]"
            isModalOpen={openModalFormInfo}
            handleCancel={handleCancelFormInfo}
            handleOk={() => {}}
            footer={null}
            maskClosable
          >
            <FormDeliveryInfo
              handleCancel={handleCancelFormInfo}
              typeAction="create"
            />
          </BasicModal>
        )}
      </div>
    </div>
  );
};

export default NewDeliveryInformation;
