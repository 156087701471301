import { Loading } from "@/components";
import Breadcrumb from "@/components/common/Breadcrumb";
import CommitmentBrand from "@/components/pages/Brand/CommitmentBrand";
import DistributorBrand from "@/components/pages/Brand/DistributorBrand";

import PartnerBrand from "@/components/pages/Brand/PartnerBrand";
import QuestionBrand from "@/components/pages/Brand/QuestionBrand";
import RequiredRegisterBrand from "@/components/pages/Brand/RequiredRegisterBrand";
import useBrandQuery from "@/hooks/ReactQuery/brand.query";
import loadingScreenState from "@/recoil/loadingScreenState";
import routes from "@/utils/routes";
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";

export default function Brand() {
  const { isLoading, isError, error } = useBrandQuery();
  const handleSetIsLoading = useSetRecoilState(loadingScreenState);

  const breadcrumbItems = [
    {
      href: routes.HOME,
      title: "Trang chủ",
    },
    {
      href: routes.BRAND,
      title: "Thương hiệu",
    },
  ];

  useEffect(() => {
    handleSetIsLoading(true);
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    // TODO: handle error
    return <div>{error?.message}</div>;
  }
  return (
    <>
      <Breadcrumb items={breadcrumbItems} />

      <section className="">
        <div className="p-[96px_160px] md:flex flex-col gap-[32px] items-center justify-center hidden">
          <h2 className="font-[600] text-[48px] m-0">
            Cùng nhau phát triển vững mạnh
          </h2>
          <p className="text-[#808080] text-[20px]">
            Ceranee hiện đang đồng hành cùng 4 đại lý và nhiều nhà phân phối lớn
            trên khắp đất nước
          </p>
        </div>
        <div className="p-[64px_0] md:hidden flex flex-col items-center justify-center">
          <p className="font-[600] text-[14px] text-[#FF9900]">Đại lý</p>
          <h2 className="font-[600] text-[30px] m-0 text-[#101828]">
            Đối tác bán hàng
          </h2>
          <p className="text-[#667085] text-[18px] text-center mt-[12px]">
            Learn more about the company and the team behind it.
          </p>
        </div>
        {/* slogan  */}
        <CommitmentBrand />
        {/* develop  */}
        <div className="border-t-[#F7F7F7] border-b-[#F7F7F7] border-t-[10px] border-b-[10px]">
          <DistributorBrand />
        </div>
        {/* become as a partner */}
        <PartnerBrand />
        {/* register */}
        <div className="bg-[#FF9900] p-[40px_16px] md:p-[96px_160px] flex flex-col items-center justify-center">
          <h2 className="text-[36px] md:text-[48px] font-[600] text-white m-0 text-center">
            Thông tin cần thiết khi đăng ký
          </h2>
          <div className="grid md:grid-cols-2 gap-[16px] md:gap-[32px] mt-[32px] md:mt-[64px]">
            <RequiredRegisterBrand
              title="Đối với hợp đồng ký với tư cách pháp nhân là Công Ty"
              content={[
                "Giấy Phép Đăng Ký Kinh Doanh (bản gốc)",
                "Giấy ủy quyền cho người đại diện ký thay (bản gốc)",
                "CMND/CCCD/Hộ chiếu người đại diện (bản gốc)",
                "Địa chỉ công ty và kho hàng chính xác",
              ]}
            />
            <RequiredRegisterBrand
              title="Đối với hợp đồng ký với tư cách pháp nhân là Cá Nhân, Hộ Kinh Doanh"
              content={[
                "CMND/CCCD/Hộ chiếu (bản gốc)",
                "Giấy Đăng ký Hộ kinh doanh cá thể (nếu có)",
                "Địa chỉ cửa hàng chính xác",
              ]}
            />
          </div>
        </div>
        {/* question */}
        <div className="p-[40px_16px] md:p-[96px_160px]">
          <div className="flex flex-col gap-[12px] justify-center items-center">
            <p className="text-[#FF9900] text-[16px] font-[600]">FAQs</p>
            <h2 className="text-[#101828] font-[600] text-[30px] m-0">
              Câu hỏi thường gặp
            </h2>
          </div>
          <div className="mt-[32px] md:mt-[64px] flex items-center justify-center">
            <div className="space-y-[24px] md:max-w-[1120px]">
              <QuestionBrand />
              <QuestionBrand />
              <QuestionBrand />
              <QuestionBrand isDevided={false} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
