import blogsApi from '@/api/blogsApi';
import { BaseData } from '@/type/base/baseData';
import { BlogType } from '@/type/response/blog';
import { useQuery } from '@tanstack/react-query';

export default function useQueryBlogDetails(slug: string) {
  const { data, isLoading, isFetched } = useQuery({
    queryKey: ['blogDetails', slug],
    queryFn: () => blogsApi.getBySlug(slug),
  });

  const blog: BaseData<BlogType> = data?.data?.data as BaseData<BlogType>;

  return { data, blog, isLoading, isFetched };
}
