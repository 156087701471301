import { Modal } from "antd";
import NewInputField from "../Formfield/NewInputField";
import { SubmitHandler, useForm } from "react-hook-form";
import { IFormInput } from "@/pages/Login";
import { useEffect, useState } from "react";
import authApi, { LoginPayload } from "@/api/authApi";
import toast from "react-hot-toast";
import constants from "@/utils/constants";
import { useTranslation } from "react-i18next";
import { formValidation } from "@/utils/formValidation";
import { Link, useNavigate } from "react-router-dom";
import routes from "@/utils/routes";
import NewButtonComponent from "../NewButton";

interface LoginModalProps {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}
function LoginModal({ isModalOpen, handleCancel, handleOk }: LoginModalProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    watch,
  } = useForm<IFormInput>({
    mode: "all",
  });
  const [identifier, password] = watch(["identifier", "password"]);
  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
    }
  }, [identifier, password]);
  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setIsLoading(true);
    try {
      const loginData: LoginPayload = {
        identifier: data.identifier,
        password: data.password,
      };

      await authApi.login(loginData).then((response) => {
        toast.success(t("loginSuccess"));

        localStorage.setItem(constants.ACCESS_TOKEN, response.data.jwt);
        handleOk();
        setTimeout(() => {
          localStorage.getItem("redirect")
            ? navigate(routes.CART)
            : navigate(routes.HOME);
          localStorage.removeItem("redirect");
        }, 1000);
      });

      await authApi.getUser().then((res) => {
        localStorage.setItem(constants.PROFILE, JSON.stringify(res.data));
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("error", error);
      toast.error(t("invalidEmailOrPassword"));
    }
  };
  return (
    <>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        styles={{
          content: {
            borderRadius: 24,
            padding: 32,
            border: "2px solid #E6E6E6",
            boxShadow: "0px 0px 10px 0px #0000000D",
          },
        }}
        className="w-[347px] z-[10000]"
        rootClassName="voucher-modal"
      >
        <h3 className="text-center font-bold text-[24px] leading-[27.6px] mb-[24px] m-0">
          Đăng nhập
        </h3>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-[24px]"
        >
          <NewInputField
            control={control}
            name="identifier"
            label="Email hoặc số điện thoại *"
            placeholder="Nhập Email hoặc số điện thoại"
            rules={formValidation.identifier}
            errors={errors.identifier}
          />
          <NewInputField
            isPassword
            control={control}
            name="password"
            label="Mật khẩu *"
            placeholder="Nhập mật khẩu"
            rules={formValidation.password}
            errors={errors.password}
            autoComplete={"new-password"}
          />
          <p
            className="text-end text-[16px] leading-[22.4px] cursor-pointer"
            onClick={() => navigate(routes.FORGOT_PASSWORD)}
          >
            Quên mật khẩu?
          </p>

          <NewButtonComponent
            htmlType="submit"
            type={isValid && !isLoading ? "primary" : "default"}
            text="Đăng nhập"
            className="w-full"
            disabled={!isValid && isLoading}
          />
        </form>
        {/* <LoginAnother title="Đăng nhập bằng tài khoản khác" /> */}
        <p className="text-[16px] font-[400] text-center mt-[32px]">
          Bạn chưa có tài khoản?
          <Link to={routes.REGISTER}>
            <span className="text-[#FF9900] text-[16px] font-[400] ml-[5px] text-nowrap">
              Đăng ký ngay
            </span>
          </Link>
        </p>
      </Modal>
    </>
  );
}

export default LoginModal;
