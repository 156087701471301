import urls from '@/utils/urls';
import axiosClient from './axiosClient';
import { BaseResponse } from '@/type/base/baseResponse';
import { BaseData } from '@/type/base/baseData';
import { PartnerType } from '@/type/response/partner';

const partnerApi = {
  getAll: () => {
    return axiosClient.get<BaseResponse<BaseData<PartnerType>>>(
      urls.PARTNER_PAGE
    );
  },
};

export default partnerApi;
