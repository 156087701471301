import { useState, useEffect, useRef } from 'react';

function UseMaxHeight(initialState: number) {
  const [maxHeight, setMaxHeight] = useState<number>(initialState);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function updateMaxHeight() {
      if (ref.current) {
        const { scrollHeight } = ref.current;
        setMaxHeight(scrollHeight);
      }
    }

    updateMaxHeight();
    window.addEventListener('resize', updateMaxHeight);
    return () => {
      window.removeEventListener('resize', updateMaxHeight);
    };
  }, [maxHeight]);

  return [ref, maxHeight] as const;
}

export default UseMaxHeight;
