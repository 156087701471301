import { useEffect, useState } from "react";
import productApi from "@/api/productApi";
import { BaseData } from "@/type/base/baseData";
import { ReviewType } from "@/type/common/product/review";
import useWindowSize from "@/hooks/useWindowSize";
import { Rate } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import clsx from "clsx";
import { chunkArray } from "@/utils/chunkArray";
import FeedbackItem from "@/components/pages/ProductDetails/FeebackComment/FeedbackItem";
import { useParams } from "react-router-dom";
import useProductDetailsQueryData from "@/hooks/ReactQuery/productDetails.query";
import routes from "@/utils/routes";
import Breadcrumb from "@/components/common/Breadcrumb";

const RATINGS = ["5 sao", "4 sao", "3 sao", "2 sao", "1 sao"];

const Feedbacks = () => {
  const { slug: slugParams } = useParams<string>();

  const { product, productId } = useProductDetailsQueryData(
    slugParams as string
  );

  const size = useWindowSize();

  const [allProductReviews, setAllProductReviews] = useState<
    BaseData<ReviewType>[]
  >([]);
  const [showedReviews, setShowedReviews] = useState<BaseData<ReviewType>[]>(
    []
  );

  const [ratingsCount, setRatingsCount] = useState<Record<string, number>>({
    "5": 0,
    "4": 0,
    "3": 0,
    "2": 0,
    "1": 0,
  });

  const breadcrumbItems =
    size.width < 768
      ? [
          {
            href: routes.HOME,
            title: "Trang chủ",
          },
          {
            title: "...",
          },
          {
            href: `${routes.PRODUCT}/${slugParams}`,
            title: product?.name || product?.shortName,
          },
        ]
      : [
          {
            href: routes.HOME,
            title: "Trang chủ",
          },
          {
            href: routes.PRODUCT,
            title: "Sản phẩm",
          },
          {
            href: `${routes.PRODUCT}/${slugParams}`,
            title: product?.name || product?.shortName,
          },
          {
            href: `${routes.PRODUCT}/${slugParams}`,
            title: product?.name || product?.shortName,
          },
        ];

  const feedbackChunks = chunkArray(showedReviews || [], 5);

  const handleFilterRating = (rating: number) => {
    console.log(rating);

    const filteredReviews =
      allProductReviews?.filter(
        (review) => review?.attributes?.rating === rating
      ) || [];

    setShowedReviews(filteredReviews);
  };

  const fetchAllProductReviews = async () => {
    if (productId) {
      try {
        await productApi
          .getAllReviews(Number(productId))
          .then((res) => {
            setAllProductReviews(res.data.data);
            setShowedReviews(res.data.data);
            return res.data.data;
          })
          .then((res) => {
            res.forEach((item) => {
              switch (item?.attributes?.rating) {
                case 5:
                default:
                  setRatingsCount({
                    ...ratingsCount,
                    "5": ratingsCount["5"]++,
                  });
                  break;
                case 4:
                  setRatingsCount({
                    ...ratingsCount,
                    "4": ratingsCount["4"]++,
                  });
                  break;
                case 3:
                  setRatingsCount({
                    ...ratingsCount,
                    "3": ratingsCount["3"]++,
                  });
                  break;
                case 2:
                  setRatingsCount({
                    ...ratingsCount,
                    "2": ratingsCount["2"]++,
                  });
                  break;
                case 1:
                  setRatingsCount({
                    ...ratingsCount,
                    "1": ratingsCount["1"]++,
                  });
                  break;
              }
            });
          });
      } catch (error) {
        console.log("Error at fetchProductReviews", error);
      }
    }
  };

  useEffect(() => {
    fetchAllProductReviews();
  }, [productId]);

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />

      <div className="p-[16px] md:p-[24px] flex items-start flex-col md:flex-row gap-[16px] md:gap-[71px] bg-[#F0F0F0]">
        <div className="flex flex-row md:flex-col gap-[8px] mdgap-[16px] items-center">
          <Rate
            disabled
            allowHalf
            defaultValue={Number(product?.rating?.toFixed(1)) || 5}
            className="text-[#FF9900] !text-[16px] md:hidden leading-[16px]"
          />
          <p className="text-[#FF9900] text-[16px] md:text-[30px] font-[500]">
            {product?.rating.toFixed(1) || 0}/5
          </p>
          <div className="flex flex-col gap-[4px]">
            <Rate
              disabled
              allowHalf
              defaultValue={Number(product?.rating?.toFixed(1)) || 5}
              className="text-[#FF9900] text-[16px] hidden md:block"
            />
            <p className="text-[14px] font-[400] text-[#545454]">
              {allProductReviews?.length} đánh giá
            </p>
          </div>
        </div>

        <div className="flex items-start gap-[16px] flex-wrap flex-1">
          <div
            onClick={() => setShowedReviews(allProductReviews)}
            className="flex items-center justify-center p-[10px_16px] md:p-[12px_20px] rounded-[8px] border border-[#FF9900] cursor-pointer w-[82px] md:w-[84px]"
          >
            <p className="text-[14px] font-[600] text-[#FF9900]">Tất cả</p>
          </div>

          {RATINGS.map((rating, index) => {
            return (
              <div
                key={index}
                className={clsx(
                  "flex items-center justify-center p-[10px_16px] md:p-[12px_20px] rounded-[8px] border w-[82px] md:w-[84px]",
                  ratingsCount[`${5 - index}`] > 0
                    ? "border-[#000000] cursor-pointer"
                    : "border-[#0000004D] pointer-events-none"
                )}
                onClick={() => handleFilterRating(5 - index)}
              >
                <p
                  className={clsx(
                    "text-[14px] font-[600]",
                    ratingsCount[`${5 - index}`] > 0
                      ? "text-[#000000]"
                      : "text-[#0000004D]"
                  )}
                >
                  {rating}
                </p>
              </div>
            );
          })}
        </div>
      </div>

      <Swiper
        observer={true}
        observeParents={true}
        onSwiper={(swiper) => swiper.update()}
        slidesPerView={1}
        navigation={size.width >= 768}
        modules={[Navigation]}
        className="swiper-feedback"
      >
        {feedbackChunks.map((chunk, index) => {
          return (
            <SwiperSlide key={index}>
              {chunk?.map((feedback, index) => {
                return <FeedbackItem data={feedback} key={index} />;
              })}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default Feedbacks;
