import useWindowSize from "@/hooks/useWindowSize";
import constants from "@/utils/constants";
import clsx from "clsx";

interface IFooterOrderProps {
  statusOrder: string;
  handleCancel?: () => void;
  handelRepurchase?: () => void;
  handelFeedback?: () => void;
  isDisableFeedback?: boolean;
  showFooter?: boolean;
  hasOrderDetail?: boolean;
}

const FooterOrder = (props: IFooterOrderProps) => {
  const {
    statusOrder,
    handelFeedback,
    handelRepurchase,
    handleCancel,
    isDisableFeedback = false,
    hasOrderDetail = true,
  } = props;

  const { width } = useWindowSize();

  if (width >= 768) {
    if (
      statusOrder === constants.CONFIRMED ||
      statusOrder === constants.PENDING_CONFIRMATION
    )
      return (
        <div className="w-full flex justify-center items-center px-[16px] md:px-0 py-[12px] md:py-[16px] mt-0 md:mt-[32px]">
          <span
            className="w-full md:w-fit text-[14px] md:text-[16px] text-black hover:text-[#FF9900] font-[600] leading-[19.4px] md:leading-[19.36px] cursor-pointer"
            onClick={handleCancel}
          >
            Hủy đơn hàng
          </span>
        </div>
      );

    if (statusOrder === constants.COMPLETED)
      return (
        <div className="flex justify-end items-center mt-[8px] px-[16px] md:px-0">
          <button
            className="w-full md:w-fit p-[12px_20px] text-[16px] font-[600] leading-[24px] bg-white text-black"
            onClick={(e) => {
              e.stopPropagation();
              handelRepurchase?.();
            }}
          >
            Mua lại
          </button>

          {!isDisableFeedback && (
            <button
              className={clsx(
                "w-full md:w-fit p-[12px_20px] text-[16px] font-[600] leading-[24px] rounded-[24px] bg-black text-white"
              )}
              onClick={handelFeedback}
            >
              Đánh giá
            </button>
          )}
        </div>
      );

    if (
      statusOrder === constants.CANCELED ||
      statusOrder === constants.RETURNED
    )
      return (
        <div className="flex justify-end items-center mt-[8px] px-[16px] md:px-0">
          <button
            className="w-full md:w-fit p-[12px_20px] text-[16px] font-[600] leading-[24px] bg-black text-white rounded-[24px]"
            onClick={(e) => {
              e.stopPropagation();
              handelRepurchase?.();
            }}
          >
            Mua lại
          </button>
        </div>
      );

    return null;
  }

  if (
    hasOrderDetail &&
    (statusOrder === constants.CONFIRMED ||
      statusOrder === constants.PENDING_CONFIRMATION)
  )
    return (
      <div className="bg-[#F7F7F7] py-[8px]">
        <div className="w-full flex justify-center items-center p-[16px_28px] md:p-0 bg-white md:mt-0">
          <button
            className="w-full md:w-fit text-[14px] md:text-[16px] text-black hover:text-[#FF9900] font-[600] leading-[19.4px] md:leading-[19.36px] cursor-pointer"
            onClick={handleCancel}
          >
            Hủy đơn hàng
          </button>
        </div>
      </div>
    );

  if (!hasOrderDetail && statusOrder === constants.COMPLETED)
    return (
      <div className="flex justify-end items-center mb-[16px] px-[16px] md:px-0">
        <button
          className="w-full md:w-fit p-[12px_20px] text-[16px] font-[600] leading-[24px] bg-white text-black"
          onClick={handelRepurchase}
        >
          Mua lại
        </button>

        {!isDisableFeedback && (
          <button
            className={clsx(
              "w-full md:w-fit p-[12px_20px] text-[16px] font-[600] leading-[24px] rounded-[24px] bg-black text-white"
            )}
            onClick={handelFeedback}
          >
            Đánh giá
          </button>
        )}
      </div>
    );

  if (
    !hasOrderDetail &&
    (statusOrder === constants.CANCELED || statusOrder === constants.RETURNED)
  )
    return (
      <div className="flex justify-end items-center mb-[16px] px-[16px] md:px-0">
        <button
          className="w-full md:w-fit p-[12px_20px] text-[16px] font-[600] leading-[16.94px] rounded-[24px] bg-black text-white"
          onClick={handelRepurchase}
        >
          Mua lại
        </button>
      </div>
    );

  return null;
};

export default FooterOrder;
