import urls from '@/utils/urls';
import axiosClient from './axiosClient';
import { BaseResponse } from '@/type/base/baseResponse';
import { BaseData } from '@/type/base/baseData';
import { MemberIncentiveResponseType } from '@/type/response/memberIncentive';

export const memberIncentiveApi = {
  getAll() {
    return axiosClient.get<BaseResponse<BaseData<MemberIncentiveResponseType>>>(
      `${urls.MEMBER_INCENTIVE}?populate=%2A`
    );
  },
};
