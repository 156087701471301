import images from "@/assets/images"
import svgs from "@/assets/svgs"
import { BaseData } from "@/type/base/baseData"
import { ReviewType } from "@/type/common/product/review"
import { Image, Rate } from "antd"
import dayjs from "dayjs"

interface IFeedbackItemProps {
    data: BaseData<ReviewType>
}

const FeedbackItem = (props: IFeedbackItemProps) => {
    const { data } = props

    const dataAttributes = data?.attributes
    const userAttributes = dataAttributes?.user?.data?.attributes
    const userImageUrl = userAttributes?.avatar?.data?.attributes?.url || svgs.avatarDefault

    return (
        <div className="flex items-start gap-[8px] md:[gap-[24px] p-[16px] md:p-[24px] border-b border-[#E6E6E6]">
            <img
                src={userImageUrl || svgs.avatarDefault}
                alt={`${images.userRatting} stars`}
                className="rounded-full aspect-square max-w-[40px] max-h-[40px] md:max-w-[90px] md:max-h-[90px] w-full h-full object-cover"
                width={90}
                height={90}
                loading="lazy"
            />
            <div className="flex flex-col gap-[8px] md:gap-[16px]">
                <div className="flex flex-col-reverse md:flex-col gap-[4px] md:gap-[8px]">
                    <Rate
                        value={dataAttributes.rating}
                        className="text-[#FF9900] text-[16px]"
                    />
                    <p className="text-[16px] font-[400]">
                        {userAttributes?.fullName}
                    </p>
                </div>
                <p className="text-[12px] md:text-[16px] font-[400] text-[#818080]">
                    {dayjs(dataAttributes.createdAt).format("DD.MM.YYYY")}
                </p>
                <p className="text-[14px] md:text-[16px] font-[400]">
                    {dataAttributes.comment}
                </p>
                <div className="flex items-center gap-[16px] flex-wrap">
                    {dataAttributes?.images?.data?.map((img, index) => {
                        return (
                            <Image
                                src={img.attributes.url}
                                alt="Image review"
                                className="rounded-[8px] max-w-[96px] max-h-[128px] w-full h-full aspect-[96/128] object-cover"
                                width={96}
                                height={128}
                                key={index}
                                loading="lazy"
                                preview={{
                                    maskClassName: "rounded-[8px]"
                                }}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default FeedbackItem