import orderApi from "@/api/orderApi";
import { useQuery } from '@tanstack/react-query';

const MINUTES_TIME_STALE = 1;

export default function useVoucherReview() {
    const { data, isLoading, isFetched } = useQuery({
        queryKey: ['voucherReview'],
        queryFn: () => orderApi.getVoucherReview(),
        staleTime: 1000 * 60 * MINUTES_TIME_STALE,
    });

    const voucherData = data?.data?.data?.attributes?.voucher?.data?.attributes

    const informationPagination = data?.data.meta.pagination;
    return { informationPagination, data, voucherData, isLoading, isFetched };
}
