interface IDeliveringProps {
  active?: boolean;
  height?: number;
  width?: number;
}
const Delivering = (props: IDeliveringProps) => {
  const { active, height, width } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "32"}
      height={height || "32"}
      viewBox="0 0 33 32"
      fill="none"
    >
      <g clip-path="url(#clip0_3596_7628)">
        <path
          d="M22.0833 21.3333V6C22.0833 4.89543 21.1878 4 20.0833 4H4.08325C2.97868 4 2.08325 4.89543 2.08325 6V19.3333C2.08325 20.4379 2.97868 21.3333 4.08325 21.3333H22.0833ZM22.0833 21.3333H29.4166C30.5212 21.3333 31.4166 20.4379 31.4166 19.3333V15.4951C31.4166 14.9647 31.2059 14.456 30.8308 14.0809L28.0024 11.2525C27.6273 10.8774 27.1186 10.6667 26.5882 10.6667H24.0833C22.9787 10.6667 22.0833 11.5621 22.0833 12.6667V21.3333ZM11.4166 24.6667C11.4166 26.5076 9.9242 28 8.08325 28C6.2423 28 4.74992 26.5076 4.74992 24.6667C4.74992 22.8257 6.2423 21.3333 8.08325 21.3333C9.9242 21.3333 11.4166 22.8257 11.4166 24.6667ZM28.7499 24.6667C28.7499 26.5076 27.2575 28 25.4166 28C23.5756 28 22.0833 26.5076 22.0833 24.6667C22.0833 22.8257 23.5756 21.3333 25.4166 21.3333C27.2575 21.3333 28.7499 22.8257 28.7499 24.6667Z"
          stroke={active ? "#000000" : "#808080"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3596_7628">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Delivering;
