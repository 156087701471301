import clsx from "clsx";

export interface ILabelTextProps {
  thumbLabelType?: "sale" | "top";
  levelTop?: number; // using with 'top' type
}

const LabelText = ({ thumbLabelType = "sale", levelTop }: ILabelTextProps) => {
  const typeColor = {
    sale: "bg-[#FF9900] text-[#FFF]",
    top: "bg-[#000000] text-[#FFF]",
  };

  const typeText = {
    sale: "Giá sốc",
    top: `Top ${levelTop}`,
  };

  return (
    <div
      className={clsx(
        "flex items-center justify-center px-[10px] py-[2px] rounded-[0_8px_0_16px] absolute left-[-1px] bottom-[-1px]",
        typeColor[thumbLabelType]
      )}
    >
      <p className="text-[11px] md:text-[13px] font-[600]">
        {typeText[thumbLabelType]}
      </p>
    </div>
  );
};

export default LabelText;
