import BackToHeader from "@/components/common/BackToHeader";
import OrderDetailWrapper from "@/components/common/OrderDetailWrapper";
import { useQueryOrderDetail } from "@/hooks/ReactQuery/profile.query";
import routes from "@/utils/routes";
import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const TrackingOrderDetail = () => {
  const location = useLocation();
  const { prePage } = location.state || {};
  const navigate = useNavigate();
  const { orderCode } = useParams<string>();
  const [paramOrderCode, setParamsOrderCode] = useState<string>("");

  const { orderDetail, isLoadingOrderDetail } = useQueryOrderDetail(
    paramOrderCode || ""
  );

  useEffect(() => {
    if (orderCode) setParamsOrderCode(orderCode);
  }, [orderCode]);

  const handelBackTo = () => {
    if (prePage) {
      navigate(prePage);
    } else {
      navigate(`${routes.PROFILE}/${routes.ORDERS}`, { replace: true });
    }
  };

  if (isLoadingOrderDetail)
    return (
      <div className="h-[300px] w-full flex flex-col justify-start items-start">
        <BackToHeader
          content={
            <>
              Chi tiết đơn hàng{" "}
              <span className="font-[700] text-[14px] leading-[16.1px] text-black">
                {orderCode}
              </span>
            </>
          }
          onBackTo={handelBackTo}
        />
        <div className="h-full w-full flex flex-col justify-center items-center">
          <Spin size="large" />
        </div>
      </div>
    );

  if (orderDetail === null)
    return (
      <div className="h-[300px] w-full flex flex-col justify-start items-start">
        <BackToHeader content={"Trở về"} onBackTo={handelBackTo} />
        <div className="h-full w-full flex flex-col justify-center items-center">
          <p className="text-[16px] font-[400] leading-[22.4px]">
            Không tìm thấy đơn hàng
          </p>
        </div>
      </div>
    );

  return (
    <div className="max-w-[768px] mx-auto">
      <BackToHeader
        content={
          <>
            Chi tiết đơn hàng{" "}
            <span className="font-[700] text-[14px] leading-[16.1px] text-black">
              {orderCode}
            </span>
          </>
        }
        onBackTo={handelBackTo}
      />

      <div>
        <OrderDetailWrapper
          order={orderDetail}
          showBillDetail
          showFooter
          showOrderCode={false}
          hasOrderDetail={true}
          hasNavigateOrderDetail={false}
        />
      </div>
    </div>
  );
};

export default TrackingOrderDetail;
