import {
  filterCountProduct,
  isLoadingFilter,
  loadingSpin,
} from "@/recoil/filterProduct";
import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";

interface FooterDrawerProps {
  handleConfirmFilter: () => void;
  handleRemoveFilter: () => void;
  statusButton: boolean;
}

export default function FooterDrawer({
  handleConfirmFilter,
  handleRemoveFilter,
  statusButton,
}: FooterDrawerProps) {
  const [countProduct] = useRecoilState(filterCountProduct);
  const [loadingFilter] = useRecoilState(isLoadingFilter);
  const [isLoadingSpin] = useRecoilState(loadingSpin);

  const [showCount, setShowCount] = useState(isLoadingSpin);
  useEffect(() => {
    if (showCount) setShowCount(false);
    const timer = setTimeout(() => {
      setShowCount(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, [loadingFilter, countProduct, isLoadingSpin]);
  return (
    <div className="flex justify-between items-center gap-5">
      <button
        disabled={!statusButton}
        onClick={handleRemoveFilter}
        className={`${
          !statusButton ? "opacity-60 select-none" : ""
        } py-2 bg-transparent text-black text-[14px] font-[600] leading-[16.94px] border-none border-[#FBF5F2] flex-1 md:text-[16px]`}
      >
        Bỏ chọn
      </button>

      <button
        disabled={!statusButton}
        onClick={handleConfirmFilter}
        className="disabled:opacity-60 disabled:hover:bg-white disabled:hover:text-piper-lolot select-none py-2 bg-black 
        text-[#FFFFFF] text-[14px] font-[600] leading-[16.94px] border border-piper-lolot flex-1  text-nowrap
        hover:text-[#ff9900] transition-all ease-linear duration-150 rounded-[24px] p-[10px_20px] "
      >
        {showCount ? (
          `Xem ${countProduct} kết quả`
        ) : (
          <Spin className="text-white" size="small" />
        )}
      </button>
    </div>
  );
}
