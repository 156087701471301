interface IReturnProps {
  active?: boolean;
  height?: number;
  width?: number;
}
const Return = (props: IReturnProps) => {
  const { active, height, width } = props;
  return (
    <svg
      width={width || "32"}
      height={height || "32"}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3596_7638)">
        <path
          d="M3.62817 14.4341H11.5561"
          stroke={active ? "#000000" : "#808080"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.9975 11.2625V8.09134C28.9975 6.33995 27.5778 4.92017 25.8264 4.92017H6.79935C5.04796 4.92017 3.62817 6.33995 3.62817 8.09134V27.1184C3.62817 28.8698 5.04796 30.2895 6.79935 30.2895H11.5561"
          stroke={active ? "#000000" : "#808080"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.0696 1.74878V8.09112"
          stroke={active ? "#000000" : "#808080"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.5562 1.74878V8.09112"
          stroke={active ? "#000000" : "#808080"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g clip-path="url(#clip1_3596_7638)">
          <path
            d="M15.458 17.8341V22.0841M15.458 22.0841H19.708M15.458 22.0841L18.7447 18.9958C19.506 18.2341 20.4478 17.6777 21.4822 17.3785C22.5167 17.0793 23.6101 17.047 24.6605 17.2846C25.7108 17.5223 26.6838 18.0221 27.4887 18.7375C28.2936 19.4529 28.9042 20.3606 29.2634 21.3758M31.0413 29.1674V24.9174M31.0413 24.9174H26.7913M31.0413 24.9174L27.7547 28.0058C26.9934 28.7674 26.0516 29.3238 25.0171 29.6231C23.9826 29.9223 22.8892 29.9546 21.8389 29.7169C20.7885 29.4793 19.8155 28.9794 19.0106 28.264C18.2057 27.5486 17.5952 26.641 17.2359 25.6258"
            stroke={active ? "#000000" : "#808080"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3596_7638">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.75)"
          />
        </clipPath>
        <clipPath id="clip1_3596_7638">
          <rect
            width="17"
            height="17"
            fill="white"
            transform="translate(14.7498 15.0007)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Return;
