import { OrderStatusType } from "@/type/common/order";
import ContentHeader from "./ContentHeader";
import { BaseData } from "@/type/base/baseData";
import { UserAddressType } from "@/utils/AddressType";

interface IHeaderOrderProps {
  status: OrderStatusType;
  orderCode: string;
  reason?: string; // case delay delivery
  shippingOrderID?: string;
  userAddress?: BaseData<UserAddressType>;
  showOrderCode?: boolean;
  hasOrderDetail?: boolean;
}

const statusTextMap: Record<string, string> = {
  "Xác nhận": "Xác nhận",
  "Chờ xác nhận": "đang chờ xác nhận",
  "Đang giao hàng": "đang giao hàng tới bạn",
  "Delay giao hàng": "delay giao hàng",
  Huỷ: "đã hủy",
  "Đã hoàn trả": "đã hoàn trả",
  "Đã hoàn thành": "đã giao thành công",
};

const HeaderOrder = (props: IHeaderOrderProps) => {
  const {
    status,
    reason,
    orderCode,
    userAddress,
    shippingOrderID,
    showOrderCode = true,
    hasOrderDetail = true,
  } = props;

  const statusText = statusTextMap[status] || "";

  return (
    <div>
      <ContentHeader
        orderCode={orderCode}
        statusText={statusText}
        status={status}
        reason={status === "Delay giao hàng" ? reason : undefined}
        shippingOrderID={shippingOrderID}
        userAddress={userAddress}
        showOrderCode={showOrderCode}
        hasOrderDetail={hasOrderDetail}
      />
      <div className="w-full hidden md:block border-[1px] border-solid border-[#F0F0F0] mb-[16px] md:mb-[8px] md:mt-0"></div>
    </div>
  );
};

export default HeaderOrder;
