import useWindowSize from "@/hooks/useWindowSize";
import { BaseData } from "@/type/base/baseData";
import { orderStatus } from "@/utils/profile";
import Scrollbars from "rc-scrollbars";
import { OrderDetailsResponse } from "@/type/response/order";
import Upload, { UploadChangeParam, UploadFile } from "antd/es/upload";
import { getBaseUrl } from "@/utils/data";
import { FileType, getBase64, MAX_COUNT_UPLOAD } from "@/utils/handleUpload";
import useVoucherReview from "@/hooks/ReactQuery/voucher.query";
import { useState } from "react";
// import { useProfileLayoutContext } from "@/layouts/ProfileLayout";
import { FeedbackRequestType } from "@/type/request/feedback";
import constants from "@/utils/constants";
import orderApi from "@/api/orderApi";
import feedbackApi from "@/api/feedbackApi";
import Product from "@/components/common/NewProduct";
import { Image, Rate } from "antd";
import styled from "styled-components";
import svgs from "@/assets/svgs";
import clsx from "clsx";

interface IFeedbackComponentProps {
  status: string;
  orderList: BaseData<OrderDetailsResponse>[];
  openFinishModal: () => void;
  orderId: number;
}
const UploadStyled = styled(Upload)`
  &.ant-upload-wrapper {
    display: flex;
    flex-direction: column-reverse;
    gap: 8px;
    .ant-upload {
      margin-left: auto;
      gap: 4px;
    }
    .ant-upload-list {
      display: flex;
      flex-wrap: wrap;
      .ant-upload-list-item-container {
        margin-inline-start: 4px;
        .ant-upload-list-item-name {
          width: 100px;
        }
      }
    }
  }
`;
const FeedbackComponent = (props: IFeedbackComponentProps) => {
  const { status, orderList, orderId, openFinishModal } = props;
  const { width } = useWindowSize();
  //   const { refetch } = width >= 768 ? useProfileLayoutContext() : {};

  const [fileList, setFileList] = useState<Record<number, UploadFile[]>>({});
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const [feedbackData, setFeedbackData] = useState<
    Record<number, FeedbackRequestType>
  >({});
  const { voucherData } = useVoucherReview();

  const initFeedbackData: FeedbackRequestType = {
    product: "",
    rating: 5,
    comment: "",
    status: true,
    image: [],
    order_id: 0,
  };

  const baseUrl = getBaseUrl();
  const accessToken = localStorage.getItem(constants.ACCESS_TOKEN);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChangeFiles = (
    orderId: number,
    productId: number,
    info: UploadChangeParam<UploadFile<any>>
  ) => {
    if (info.file.status === "done") {
      if (!feedbackData[productId]?.product) {
        setFeedbackData({
          ...feedbackData,
          [productId]: {
            ...initFeedbackData,
            product: String(productId),
            order_id: orderId,
            image: [String(info.file.response[0].id)],
          },
        });
      } else {
        setFeedbackData({
          ...feedbackData,
          [productId]: {
            ...feedbackData[productId],
            image: [
              ...feedbackData[productId].image,
              String(info.file.response[0].id),
            ],
          },
        });
      }
    }
    setFileList({ ...fileList, [productId]: info.fileList });
  };

  const handleOnChangeComment = (
    orderId: number,
    productId: number,
    comment: string
  ) => {
    if (!feedbackData[productId]?.product) {
      setFeedbackData({
        ...feedbackData,
        [productId]: {
          ...initFeedbackData,
          product: String(productId),
          order_id: orderId,
          comment: comment,
        },
      });
    } else {
      setFeedbackData({
        ...feedbackData,
        [productId]: {
          ...feedbackData[productId],
          comment: comment,
        },
      });
    }
  };

  const handleChangeRate = (
    orderId: number,
    productId: number,
    rate: number
  ) => {
    if (!feedbackData[productId]?.product) {
      setFeedbackData({
        ...feedbackData,
        [productId]: {
          ...initFeedbackData,
          product: String(productId),
          order_id: orderId,
          rating: rate,
        },
      });
    } else {
      setFeedbackData({
        ...feedbackData,
        [productId]: {
          ...feedbackData[productId],
          rating: rate,
        },
      });
    }
  };

  const isDisableSendFeedback = Object.values(feedbackData).some(
    (item) => item.rating === 0 && item.comment === ""
  );
  const handleFinish = async () => {
    if (isDisableSendFeedback) return;
    const promises = Object.entries(feedbackData).map(([_, data]) => {
      return feedbackApi
        .postReview({ data })
        .then((data) => ({ status: "fulfilled", data }))
        .catch((error) => ({ status: "rejected", reason: error }));
    });

    return Promise.allSettled(promises).finally(() => {
      orderApi.addReviewVoucher();
      //   onClose();
      openFinishModal();
      //   refetch();
    });
  };
  return (
    <div className="flex flex-col">
      {status === orderStatus.COMPLETED && voucherData?.code && (
        <p className="font-light text-[13px] md:text-[14px] text-[#000000] px-[26px] py-2 border border-[#000000] bg-[#F6FDE8] mb-3">
          {voucherData?.title || voucherData?.description}
        </p>
      )}
      {width >= 768 ? (
        <Scrollbars
          style={{ maxWidth: "100vw", height: width < 768 ? "300px" : "450px" }}
          autoHide={true}
          renderTrackVertical={(props) => (
            <div {...props} className="mr-[20px] track-vertical z-[9999]" />
          )}
        >
          <div className="mr-[35px]">
            {orderList.map((order, index) => {
              const productId = order?.attributes?.product?.data?.id;
              return (
                <div key={index}>
                  <div className="flex flex-col gap-[16px]" key={index}>
                    <Product
                      thumbType="horizontal"
                      contentPage="feedback"
                      product={order?.attributes?.product.data}
                      productInCart={order.attributes.quanity}
                      showPercentDiscount={false}
                    />

                    <div className="flex gap-[12px] items-center">
                      <p className="text-[13px] md:text-[16px] text-[#484848]">
                        Chất lượng sản phẩm
                      </p>
                      <Rate
                        defaultValue={5}
                        style={{
                          fontSize: "16px",
                          color: "#FF9900",
                          marginBottom: "2px",
                        }}
                        onChange={(value) =>
                          handleChangeRate(orderId, productId, value)
                        }
                      />
                    </div>

                    <textarea
                      rows={3}
                      maxLength={250}
                      className="resize-none p-[8px_16px] font-normal leading-[22.4px] text-left text-[#818080] 
                      placeholder-[#818080] border-none bg-[#F7F7F7] rounded-[8px_16px_8px_16px] focus:border-none focus:ring-0"
                      placeholder="Chia sẻ cảm nhận của bạn khi dùng sản phẩm bạn nhé!"
                      onChange={(e) =>
                        handleOnChangeComment(
                          orderId,
                          productId,
                          e.target.value
                        )
                      }
                    ></textarea>
                    <div className="flex justify-end">
                      <UploadStyled
                        name="files"
                        action={`${baseUrl}/api/upload`}
                        onRemove={(file) => {
                          const index = fileList[productId].indexOf(file);
                          const newFileList = fileList[productId].slice();
                          newFileList.splice(index, 1);
                          setFileList({
                            ...fileList,
                            [productId]: newFileList,
                          });
                        }}
                        headers={{
                          Authorization: `Bearer ${accessToken}`,
                          accept: "application/json",
                        }}
                        listType={"picture"}
                        maxCount={MAX_COUNT_UPLOAD}
                        onPreview={handlePreview}
                        onChange={(info) =>
                          handleChangeFiles(orderId, productId, info)
                        }
                        fileList={fileList[productId]}
                      >
                        {(fileList[productId]?.length || 0) <
                          MAX_COUNT_UPLOAD && (
                          <button className="flex items-center gap-[10px] rounded-[8px] border border-[#000000] p-[10px_20px]">
                            <img src={svgs.camera} loading="lazy" />
                            <span className="text-[14px] font-[400] leading-[16.94px] text-[#000000]">
                              Thêm ảnh
                            </span>
                          </button>
                        )}
                      </UploadStyled>
                      {previewImage && (
                        <Image
                          wrapperStyle={{ display: "none" }}
                          preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) =>
                              setPreviewOpen(visible),
                            afterOpenChange: (visible) =>
                              !visible && setPreviewImage(""),
                          }}
                          src={previewImage}
                          loading="lazy"
                        />
                      )}
                    </div>
                  </div>

                  <div className="border-[1px] border-solid border-[#808080] my-[24px]"></div>
                </div>
              );
            })}
          </div>
        </Scrollbars>
      ) : (
        <div>
          {orderList.map((order, index) => {
            const productId = order?.attributes?.product?.data?.id;
            return (
              <div key={index}>
                <div className="flex flex-col gap-[16px]" key={index}>
                  <Product
                    thumbType="horizontal"
                    contentPage="feedback"
                    product={order?.attributes?.product.data}
                    productInCart={order.attributes.quanity}
                    showPercentDiscount={false}
                  />

                  <div className="flex gap-[12px] items-center">
                    <p className="text-[14px] md:text-[16px] text-[#484848]">
                      Chất lượng sản phẩm
                    </p>
                    <Rate
                      defaultValue={5}
                      style={{
                        fontSize: "16px",
                        color: "#FF9900",
                        marginBottom: "2px",
                      }}
                      onChange={(value) =>
                        handleChangeRate(orderId, productId, value)
                      }
                    />
                  </div>

                  <textarea
                    rows={3}
                    maxLength={250}
                    className="resize-none text-[14px] md:text-[16px] p-[8px_16px] font-normal  leading-[16.94px] md:leading-[22.4px] text-left text-[#818080] 
                      placeholder-[#818080] border-none bg-[#F7F7F7] rounded-[8px_16px_8px_16px] focus:border-none focus:ring-0"
                    placeholder="Chia sẻ cảm nhận của bạn khi dùng sản phẩm bạn nhé!"
                    onChange={(e) =>
                      handleOnChangeComment(orderId, productId, e.target.value)
                    }
                  ></textarea>
                  <div className="flex justify-end">
                    <UploadStyled
                      name="files"
                      action={`${baseUrl}/api/upload`}
                      onRemove={(file) => {
                        const index = fileList[productId].indexOf(file);
                        const newFileList = fileList[productId].slice();
                        newFileList.splice(index, 1);
                        setFileList({
                          ...fileList,
                          [productId]: newFileList,
                        });
                      }}
                      headers={{
                        Authorization: `Bearer ${accessToken}`,
                        accept: "application/json",
                      }}
                      listType={"picture"}
                      maxCount={MAX_COUNT_UPLOAD}
                      onPreview={handlePreview}
                      onChange={(info) =>
                        handleChangeFiles(orderId, productId, info)
                      }
                      fileList={fileList[productId]}
                    >
                      {(fileList[productId]?.length || 0) <
                        MAX_COUNT_UPLOAD && (
                        <button className="flex items-center gap-[10px] rounded-[8px] border-[2px] md:border border-[#000000] p-[8px_16px] md:p-[10px_20px]">
                          <img
                            src={svgs.camera}
                            loading="lazy"
                            className="w-[22px] h-[18px] md:w-full md:h-full"
                          />
                          <span className="text-[14px] font-[400] leading-[16.94px] text-[#000000]">
                            Thêm ảnh
                          </span>
                        </button>
                      )}
                    </UploadStyled>
                    {previewImage && (
                      <Image
                        wrapperStyle={{ display: "none" }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) =>
                            !visible && setPreviewImage(""),
                        }}
                        src={previewImage}
                        loading="lazy"
                      />
                    )}
                  </div>
                </div>

                <div className="border-[1px] border-solid border-[#F0F0F0] md:border-[#808080] my-[16px] md:my-[24px]"></div>
              </div>
            );
          })}
        </div>
      )}

      <div className="flex justify-center items-center mt-[32px]">
        <button
          className={clsx(
            "w-full md:w-fit text-[14px] font-[600] leading-[16.94px] py-[12px] md:p-[10px_20px] rounded-[24px]",
            isDisableSendFeedback
              ? "border-[1px] border-solid border-[#E6E6E6] text-[#E6E6E6] cursor-not-allowed"
              : "bg-black text-white"
          )}
          onClick={handleFinish}
        >
          Gửi đánh giá
        </button>
      </div>
    </div>
  );
};

export default FeedbackComponent;
