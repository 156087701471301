import { useEffect, useState } from "react";
import voucherApi from "@/api/voucherApi";
import useWindowSize from "@/hooks/useWindowSize";
import { BaseData } from "@/type/base/baseData";
import { VouchersShopType } from "@/type/response/vouchers";
import { getProfile } from "@/utils/getProfile";
import toast from "react-hot-toast";

import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { UserVouchers } from "@/type/response/profile";
import { useSetRecoilState } from "recoil";
import selectedVouchersState, {
  IVoucherState,
} from "@/recoil/selectedVoucherState";
import LoginModal from "@/components/common/LoginModal";

interface IVouchersComponentProps {
  vouchers: BaseData<VouchersShopType>[];
}

const VouchersComponent = ({ vouchers }: IVouchersComponentProps) => {
  const size = useWindowSize();
  const profile = getProfile();
  const [allVouchers, setAllVouchers] = useState<BaseData<UserVouchers>[]>([]);
  const [takenVouchers, setTakenVouchers] = useState<number[]>([]);
  const setSelectedVoucher = useSetRecoilState<IVoucherState[]>(
    selectedVouchersState
  );

  useEffect(() => {
    const fetchVoucherUser = async () => {
      if (profile) {
        await voucherApi
          .getVoucherById(profile?.id)
          .then((res) => {
            if (res) {
              setAllVouchers(res?.data?.data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    fetchVoucherUser();
  }, [profile?.id]);
  const myVoucherList = allVouchers.filter(
    (item) =>
      item?.attributes?.status === "UNUSED" &&
      !item?.attributes?.voucher?.data?.attributes?.memberClass?.data?.id
  );

  const bestVoucher = myVoucherList.reduce((prev, current) => {
    const prevAmount = parseFloat(
      prev?.attributes?.voucher?.data?.attributes?.amountDecrease || "0"
    );
    const currentAmount = parseFloat(
      current?.attributes?.voucher?.data?.attributes?.amountDecrease || "0"
    );

    return currentAmount > prevAmount ? current : prev;
  }, myVoucherList[0]);

  useEffect(() => {
    const updateSelectedVoucher = async () => {
      if (bestVoucher) {
        const voucherData = bestVoucher?.attributes?.voucher?.data;
        const dataVoucher: IVoucherState = {
          voucherId: voucherData.id,
          amountDecrease: voucherData.attributes?.amountDecrease || "0",
          code: voucherData.attributes?.code || "",
          percentDecrease: voucherData.attributes?.percentDecrease || "",
          status: bestVoucher.attributes?.status || "",
          title: voucherData.attributes?.title || "",
          minimumOrderAmount: voucherData.attributes?.minimumOrderAmount || "0",
          condition: voucherData.attributes?.condition || "",
        };

        setSelectedVoucher([dataVoucher]);
      }
    };

    updateSelectedVoucher();
  }, [bestVoucher?.id]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const showModal = () => {
    setOpenModal(true);
  };
  const closeModal = () => {
    setOpenModal(false);
  };

  const myVoucherListUsed = allVouchers.filter(
    (item) =>
      item?.attributes?.status === "USED" &&
      !item?.attributes?.voucher?.data?.attributes?.memberClass?.data?.id
  );

  const usedVoucherIds = myVoucherListUsed.map(
    (item) => item?.attributes?.voucher?.data?.id
  );

  const dataVouchers = vouchers.filter(
    (item) => !usedVoucherIds.includes(item?.id)
  );

  const existingVouchers = vouchers.filter((voucher) =>
    myVoucherList.some(
      (myVoucher) => myVoucher.attributes.voucher.data.id === voucher.id
    )
  );

  const getVoucher = async (voucher: BaseData<VouchersShopType>) => {
    if (profile?.id) {
      const dataVoucher = {
        data: {
          user: {
            id: profile?.id,
          },
          voucher: {
            id: voucher?.id,
          },
        },
      };
      await voucherApi
        .addVoucherForUser(dataVoucher)
        .then((res) => {
          if (res) {
            toast.success("Đã áp dụng voucher");
            setTakenVouchers((prev) => [...prev, voucher?.id]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    const fetchVoucherUser = async () => {
      if (profile) {
        await voucherApi
          .getVoucherById(profile?.id)
          .then((res) => {
            if (res) {
              setAllVouchers(res?.data?.data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    fetchVoucherUser();
  }, [allVouchers?.length, getVoucher]);
  return (
    <>
      <Swiper
        observer={true}
        observeParents={true}
        onSwiper={(swiper) => swiper.update()}
        navigation={size.width >= 1350}
        modules={[Navigation]}
        width={size.width > 768 ? 300 : 250}
      >
        {dataVouchers?.map((voucher, index) => {
          const isVoucherTaken =
            existingVouchers.some(
              (existingVoucher) => existingVoucher.id === voucher.id
            ) || takenVouchers.includes(voucher.id);

          return (
            <SwiperSlide key={index}>
              <div className="w-[225px] md:w-[287px] p-[8px_16px] md:p-[16px_32px] flex flex-col rounded-[16px] gap-[8px] border border-[#E6E6E6] bg-[#FFFFFF] hover:border-[#E77435]">
                <p className="text-[14px] md:text-[16px] font-[400] md:font-[500] leading-[17px] md:leading-[22.4px] truncate">
                  {voucher?.attributes?.title}
                </p>
                <div className="flex justify-between">
                  <div className="p-[4px] rounded-[4px] bg-[#F8F2E2]">
                    <p className="uppercase text-[11px] md:text-[13px] font-[500] leading-[15.4px] md:leading-[18.2px]">
                      {voucher?.attributes?.code}
                    </p>
                  </div>
                  <button
                    className={`text-[11px] md:text-[13px] font-[600] leading-[15.4px] md:leading-[18.2px] ${
                      isVoucherTaken
                        ? "text-[#808080] cursor-not-allowed"
                        : "text-[#ff9900] cursor-pointer"
                    }`}
                    disabled={isVoucherTaken}
                    onClick={() => {
                      profile?.id ? getVoucher(voucher) : showModal();
                    }}
                  >
                    {isVoucherTaken ? "Đã lấy" : "Lấy mã"}
                  </button>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <LoginModal
        isModalOpen={openModal}
        handleCancel={closeModal}
        handleOk={closeModal}
      />
    </>
  );
};

export default VouchersComponent;
