/* eslint-disable import/extensions */
import axios from 'axios';
import { camelizeKeys } from 'humps';
import { message } from 'antd';
import enviroment from '../config';
import constants from '@/utils/constants';

const { baseUrl } = enviroment;


const axiosClient = axios.create({
  baseURL: `${baseUrl}/api/`,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

axiosClient.interceptors.request.use(
  (config) => {
    // const excludeApi = [urls.LOCAL, urls.REGISTER];
    // if (!excludeApi.includes(config.url ?? '')) {
    //   const accessToken = localStorage.getItem(constants.ACCESS_TOKEN);
    // }
    const accessToken = localStorage.getItem(constants.ACCESS_TOKEN);

    if (accessToken) {
      Object.assign(config.headers, { Authorization: `Bearer ${accessToken}` });
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => {
    if (response.data) {
      response.data = camelizeKeys(response.data);
    }
    return response;
  },
  (error) => {
    const { status } = error?.response;
    if (error.response.data) {
      // eslint-disable-next-line no-param-reassign
      error.response.data = camelizeKeys(error.response.data);
    }
    if (status === 401) {
      localStorage.removeItem(constants.ACCESS_TOKEN);
      window.location.href = `/Login?redirect=${window.location.pathname}`;
      message.info('Hết phiên truy cập, vui lòng đăng nhập lại');
    } else if (status === 400) {
      message.error(error.response.data.messageDetail);
    }
    return Promise.reject(error);
  }
);

export interface ISelectOptions {
  label: string;
  value: string | number;
  options?: ISelectOptions[];
}
export default axiosClient;
