import images from "@/assets/images";
import OTPPassword from "@/components/pages/ForgotPassword/OTPPassword";
import constants from "@/utils/constants";
import routes from "@/utils/routes";
import { Image } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function VerifyOTP() {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const navigate = useNavigate();

  const isLoggedIn = localStorage.getItem(constants.ACCESS_TOKEN);
  useEffect(() => {
    if (isLoggedIn) {
      navigate(routes.HOME);
    }
  }, [isLoggedIn, navigate]);
  if (isSuccess) {
    return (
      <>
        <div className="text-center py-20">
          <p className="text-xs pb-5">
            Thông tin đã được gửi về Email,
            <br /> làm theo hướng dẫn để đặt lại mật khẩu mới!
          </p>
          <Link to={routes.HOME} className="text-[#000000] font-bold underline">
            Về trang chủ
          </Link>
        </div>
      </>
    );
  }
  return (
    <div className="grid lg:grid-cols-2 gap-[8px] p-[48px_16px] lg:bg-[#F7F7F7] pt-[8px]">
      <OTPPassword setSuccess={setIsSuccess} />
      <div className="bg-white relative min-h-[300px] md:min-h-[500px] p-[36px_0_0_12px] flex items-center justify-center">
        <Image
          src={images.backgroundForgotPassword}
          alt="image"
          preview={false}
          className="w-[175px] md:w-[300px] lg:w-full lg:h-full
  "
        />
        <Image
          src={images.imageMan}
          alt="image"
          preview={false}
          rootClassName="absolute top-[30%] left-[42%] lg:top-[17%] lg:left-[50%]"
          className="h-[174px] md:h-[300px] lg:h-[353px]"
        />
      </div>
    </div>
  );
}

export default VerifyOTP;
