import BasicModal from "..";
import { Image } from "antd";
import images from "@/assets/images";
import { BaseData } from "@/type/base/baseData";
import { ProductsResponse } from "@/type/response/products";
import { useNavigate } from "react-router-dom";
import routes from "@/utils/routes";
import { IThumbTypeProps } from "../../NewProduct/Thumb";
import { formatPriceToVnd } from "@/utils/formatPriceToVnd";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import trackingApi from "@/api/trackingAPI";
import { useSetRecoilState } from "recoil";
import { addToCart, CartState, IICartStateRecoil } from "@/recoil/cartState";
import { useState } from "react";
import { BaseProductData } from "@/type/base/baseProduct";

interface IAddToCartModalProps extends IThumbTypeProps {
  isOpenAddToCartModal: boolean;
  setIsOpenAddToCartModal: (isOpen: boolean) => void;
  product: BaseData<ProductsResponse | BaseProductData>;
  useSpecialName?: boolean;
}

const AddToCartModal = (props: IAddToCartModalProps) => {
  const {
    isOpenAddToCartModal,
    setIsOpenAddToCartModal,
    product,
    thumbType,
    useSpecialName,
  } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const setCart = useSetRecoilState<IICartStateRecoil>(CartState);

  const [addCartQuantity, setAddCartQuantity] = useState(1);

  const infoProduct = product?.attributes;

  const isSale =
    (infoProduct?.pricePromotion != null &&
      infoProduct?.pricePromotion !== infoProduct?.price) ||
    thumbType === "flashsale";
  const price = infoProduct?.price;
  const pricePromo =
    thumbType === "flashsale"
      ? infoProduct?.flashSalePrice
      : infoProduct?.pricePromotion;

  const originPrice = formatPriceToVnd(parseInt(price));
  const salePrice = formatPriceToVnd(parseInt(pricePromo));

  const handleDecrease = () => {
    setAddCartQuantity((addCartQuantity) => addCartQuantity - 1);
  };

  const handleIncrease = () => {
    setAddCartQuantity((addCartQuantity) => addCartQuantity + 1);
  };

  const resetState = () => {
    setIsOpenAddToCartModal(false);
    setAddCartQuantity(1);
  };

  const handleAddCart = async (quantity?: number) => {
    const productFormat = {
      id: product?.id,
      name: infoProduct?.name || infoProduct?.shortName || "",
      image: infoProduct?.avatar?.data?.attributes?.url || "",
      price: infoProduct?.price || "",
      pricePromotion: infoProduct?.pricePromotion || "",
      flashSalePrice: infoProduct?.flashSalePrice || "",
      flashSale: thumbType === "flashsale",
      quantity,
      slug: infoProduct?.slug,
    };

    setCart((prev) => ({
      ...prev,
      cartProducts: addToCart(prev.cartProducts, productFormat),
    }));

    const utmSource = sessionStorage.getItem("utm_source") || "google";
    await trackingApi
      .trackAddToCart([product?.id as number], utmSource)
      .catch((e) => console.log(e));
    await trackingApi
      .updateProductEfficiency([product?.id as number], utmSource)
      .catch((e) => console.log(e));

    resetState();
    toast.success(t("addCartSuccess"));
  };

  return (
    <BasicModal
      isModalOpen={isOpenAddToCartModal}
      handleOk={() => setIsOpenAddToCartModal(false)}
      handleCancel={resetState}
      footer={null}
      width={399}
    >
      <div className="flex flex-col gap-4">
        <div className="flex gap-4">
          <div className="w-[30%] ">
            <Image
              src={
                product?.attributes?.avatar?.data?.attributes?.url ??
                images.productOne
              }
              alt=""
              className="w-full h-full object-cover object-center"
              loading="lazy"
            />
          </div>
          <div className="flex flex-col gap-1 flex-1">
            <p
              className="font-[500] line-clamp-2 text-ellipsis text-[#484848] text-[16px] cursor-pointer h-[50px]"
              onClick={() => navigate(`${routes.PRODUCT}/${infoProduct?.slug}`)}
            >
              {useSpecialName
                ? infoProduct?.shortName || infoProduct?.name
                : infoProduct?.name}
            </p>
            <div className="flex justify-between items-center text-[#FF9900]">
              {isSale ? (
                <span className="font-[500] text-[16px]">{salePrice}</span>
              ) : (
                <span className="font-[500] text-[16px]">{originPrice}</span>
              )}
            </div>
            <div className="flex items-center justify-between w-full text-[16px]">
              <p className="text-[#848484] text-[16px]">Số lượng:</p>
              <div className="flex justify-center items-center pt-2 md:pt-0 md:ml-2">
                {addCartQuantity == 1 ? (
                  <MinusIcon className="w-[18px] object-cover cursor-pointer select-none opacity-50" />
                ) : (
                  <div onClick={handleDecrease}>
                    <MinusIcon className="w-[18px]  object-cover cursor-pointer select-none" />
                  </div>
                )}
                <div className="justify-center items-center">
                  <div className="w-[50px] h-[30px] flex items-center justify-center">
                    {addCartQuantity}
                  </div>
                </div>
                <div onClick={handleIncrease}>
                  <PlusIcon className="w-[18px] object-cover cursor-pointer select-none" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          className="py-[12px] px-[20px] rounded-[24px] bg-[#000000]"
          onClick={() => handleAddCart(addCartQuantity)}
        >
          <p className="text-[14px] font-[600] text-[#F0F0F0]">
            {t("addToCart")}
          </p>
        </button>
      </div>
    </BasicModal>
  );
};

export default AddToCartModal;
