import { BaseResponse } from '@/type/base/baseResponse';
import axiosClient from './axiosClient';
import { ProductInformationType } from '@/type/response/productInformation';
import urls from '@/utils/urls';
import { BaseData } from '@/type/base/baseData';

const ProductInformationApi = {
  getId(id: string) {
    return axiosClient.get<BaseResponse<BaseData<ProductInformationType>[]>>(
      `${urls.PRODUCT_INFORMATION}?filters[products]=${id}&populate=description&fields[0]=value`
    );
  },
};

export default ProductInformationApi;
