import { useState } from "react";
import svgs from "@/assets/svgs";
import clsx from "clsx";
import BillText, { IBillTextProps } from "../BillText";
import useWindowSize from "@/hooks/useWindowSize";

interface IOrderBillProps {
  orderBill: IBillTextProps[];
  showBillDetail?: boolean;
  hasOrderDetail?: boolean;
}

const defaultKey = [2, 3, 4, 5];

const OrderBill = (props: IOrderBillProps) => {
  const { width } = useWindowSize();
  const { orderBill, hasOrderDetail = true } = props;
  const [readDefault, setReadDefault] = useState<number[] | undefined>(
    width >= 768 ? defaultKey : hasOrderDetail ? undefined : defaultKey
  );

  const handleToggleReadMore = () => {
    setReadDefault(readDefault === undefined ? defaultKey : undefined);
  };
  return (
    <div className="flex flex-col gap-[8px] pb-[16px] md:p-[16px] bg-transparent md:bg-[#F0F0F0] rounded-0 md:rounded-[8px]">
      {orderBill.map((item, index) => {
        const isItemHidden =
          readDefault !== undefined && readDefault.includes(index);
        return (
          <div
            key={index}
            className={clsx(
              isItemHidden
                ? `hidden opacity-0 duration-200`
                : `block opacity-100 duration-200`
            )}
          >
            <BillText
              nameItem={item.nameItem}
              valueItem={item.valueItem}
              type={item.type}
            />
          </div>
        );
      })}
      <div>
        <p className="text-[#FF9900] font-[400] text-[14px] text-center leading-[16.94px]">{`Vui lòng thanh toán ${
          orderBill[orderBill.length - 1]?.valueItem
        }  khi nhận hàng`}</p>
      </div>
      {hasOrderDetail && (
        <div className="w-full flex justify-end items-center hidden md:flex">
          <div
            className="flex justify-center items-center w-[32px] h-[32px] md:w-[48px] md:h-[48px] cursor-pointer hover:bg-[#0000001A] rounded-full"
            onClick={handleToggleReadMore}
          >
            <img
              src={svgs.arrowLeftBlack}
              className={clsx(
                "w-[8px] h-[16px] duration-300",
                readDefault === undefined ? "rotate-90" : "-rotate-90"
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderBill;
