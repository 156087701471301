import 'dayjs/locale/vi';
import AppRouter from './routers/AppRouter';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Scrollbars } from 'rc-scrollbars';

export default function App() {
  const queryClient = new QueryClient();
  return (
    <>
      <QueryClientProvider client={queryClient} >
        <Scrollbars
          style={{ maxWidth: "100vw", height: "100vh" }}
          autoHide
          renderTrackVertical={(props) => <div {...props} className="track-vertical z-[9999]" />}
        >
          <AppRouter />
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </Scrollbars>
      </QueryClientProvider>
    </>
  );
}
