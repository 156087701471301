import urls from '@/utils/urls';
import axiosClient from './axiosClient';
import { BaseResponse } from "@/type/base/baseResponse";
import { BaseData } from "@/type/base/baseData";
import { FAQChatType } from "@/type/response/faqChat";

const faqChatsApi = {
  getAll() {
    return axiosClient.get<BaseResponse<BaseData<FAQChatType>[]>>(urls.FAQ_CHATS)
  }
};
export default faqChatsApi;
