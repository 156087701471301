import { SubmitHandler, useForm } from "react-hook-form";
import "../assets/scss/styles.scss";
import { Image } from "antd";
import images from "@/assets/images";
import { formValidation } from "@/utils/formValidation";
import toast from "react-hot-toast";
import NewInputField from "@/components/common/Formfield/NewInputField";

import otpApi from "@/api/otp";
import { useNavigate } from "react-router-dom";
import routes from "@/utils/routes";
import { useEffect, useState } from "react";
import constants from "@/utils/constants";

interface IFormInput {
  email: string;
  // otpNumber: number
  newPassword: string;
  repeatedPassword: string;
}

export default function ForgotPassword() {
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm<IFormInput>();
  const isLoggedIn = localStorage.getItem(constants.ACCESS_TOKEN);
  useEffect(() => {
    if (isLoggedIn) {
      navigate(routes.HOME);
    }
  }, [isLoggedIn, navigate]);
  register("email", formValidation.email);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    console.log(isLoading);
  }, [isLoading]);
  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setIsLoading(true);
    await otpApi
      .sendOTP(data)
      .then((res) => {
        if (res?.status === 200) {
          toast.success("Mã OTP đã được gửi vào email của bạn");
          navigate(routes.VERIFY_OTP);
          localStorage.setItem("email", data?.email);
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("Có lỗi khi gửi OTP");
        setIsLoading(false);
      });
  };
  const RenderEnterMail = () => {
    return (
      <div className="bg-white lg:p-[32px_160px]">
        <h2 className="font-[600] text-[30px] text-center">
          Bạn quên mật khẩu?
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <NewInputField
            control={control}
            name="email"
            label="Email *"
            placeholder="nguyenvana@gmail.com"
            rules={formValidation.email}
            errors={errors.email}
          />
          <button
            className={`p-[12px_20px] text-[14px] font-[600] rounded-[24px] w-full lg:min-w-[400px] duration-300 mt-[32px] ${
              isLoading
                ? "bg-[#E6E6E6] text-[#818080] cursor-not-allowed"
                : "bg-black text-white hover:text-[#FF9900]"
            }`}
            disabled={isLoading}
          >
            Gửi OTP
          </button>
        </form>
      </div>
    );
  };

  return (
    <>
      <div className="grid lg:grid-cols-2 gap-[8px] p-[48px_16px] lg:bg-[#F7F7F7] pt-[8px]">
        <RenderEnterMail />
        <div className="bg-white relative min-h-[300px] md:min-h-[500px] p-[36px_0_0_12px] flex items-center justify-center">
          <Image
            src={images.backgroundForgotPassword}
            alt="image"
            preview={false}
            className="w-[175px] md:w-[300px] lg:w-full lg:h-full
  "
          />
          <Image
            src={images.imageMan}
            alt="image"
            preview={false}
            rootClassName="absolute top-[30%] left-[42%] lg:top-[17%] lg:left-[50%]"
            className="h-[174px] md:h-[300px] lg:h-[353px]"
          />
        </div>
      </div>
    </>
  );
}
