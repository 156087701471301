import svgs from "@/assets/svgs";
import { confirmFilter } from "@/recoil/filterProduct";
import { Dropdown, MenuProps, Space } from "antd";
import { useState } from "react";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";

interface SortComponentProps {
  handleSortChange: (optionsSortPrice: string) => void;
  isFilterFlashSale: boolean;
}

export default function SortComponent({
  handleSortChange,
  isFilterFlashSale,
}: SortComponentProps) {
  const [confirmButton] = useRecoilState(confirmFilter);
  const [searchParams] = useSearchParams();
  const [openOptionsSort, setOpenOptionsSort] = useState(false);
  const [selectedKey, setSelectedKey] = useState<string | null>(
    searchParams.get("sortPrice") === "asc"
      ? "asc"
      : searchParams.get("sortPrice") === "desc"
      ? "desc"
      : null
  );
  const [optionsSortPrice, setOptionsSortPrice] = useState<string>(
    selectedKey === "asc"
      ? "Giá thấp đến cao"
      : selectedKey === "desc"
      ? "Giá cao đến thấp"
      : "Sắp xếp theo"
  );

  const items: MenuProps["items"] = [
    {
      key: "asc",
      label: (
        <div className={selectedKey === "asc" ? "font-[700]" : ""}>
          Giá thấp đến cao
        </div>
      ),
      title: "Giá thấp đến cao",
    },
    {
      key: "desc",
      label: (
        <div className={selectedKey === "desc" ? "font-[700]" : ""}>
          Giá cao đến thấp
        </div>
      ),
      title: "Giá cao đến thấp",
    },
  ];
  const handleClick = (e: any) => {
    setOpenOptionsSort(false);

    // note : check key is not percent_desc or percent_asc because the features is not complete.
    if (e.key === "percent_desc" || e.key === "percent_asc") {
      toast.error("Chức năng chưa hoàn thiện");
    } else {
      setOptionsSortPrice(e.item.props.title);
      handleSortChange(e.key);
      setSelectedKey(e.key);
    }
  };

  if (isFilterFlashSale && confirmButton) return null;

  return (
    <Dropdown
      menu={{ items, onClick: (e) => handleClick(e) }}
      open={openOptionsSort}
      className="cursor-pointer select-none md:mb-4 px-0 md:px-[10px]"
    >
      <Space onClick={() => setOpenOptionsSort(!openOptionsSort)}>
        <div className="font-normal text-[14px] text-black md:text-[16px] flex items-center gap-[8px] justify-between">
          {optionsSortPrice}
        </div>
        {openOptionsSort ? (
          <img
            src={svgs.upIcon}
            alt="up"
            className="w-[11px] h-[11px] object-cover cursor-pointer"
          />
        ) : (
          <img
            src={svgs.downArrow}
            alt="downArrow"
            className=" object-cover cursor-pointer"
          />
        )}
      </Space>
    </Dropdown>
  );
}
