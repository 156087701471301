import svgs from "@/assets/svgs";
import { useState } from "react";

interface IQuestionBrandProps {
  isDevided?: boolean;
}
function QuestionBrand({ isDevided = true }: IQuestionBrandProps) {
  const [showMore, setShowMore] = useState<boolean>(false);
  return (
    <div>
      <div
        className="flex items-center justify-between cursor-pointer"
        onClick={() => setShowMore(!showMore)}
      >
        <p className="font-medium text-[18px]">
          Tại sao giá lại khác trên live stream?
        </p>

        <img
          src={svgs.chevron}
          alt="icon"
          className={`transform transition-transform duration-300 ${
            showMore ? "rotate-180" : ""
          } size-[32px]`}
        />
      </div>

      <div
        className={`overflow-hidden transition-all duration-500 ease-in-out ${
          showMore
            ? "max-h-[500px] mt-[24px] opacity-100"
            : "max-h-0 mt-0 opacity-0"
        }`}
      >
        <p className="text-[16px]">
          Tùy vào lượng hàng có sẵn, quốc gia xuất xứ của sản phẩm được bán trên
          web của chúng tôi có thể khác. Sản phẩm có thể có nguồn gốc từ Trung
          Quốc, Thái Lan, Hoa Kỳ và Việt Nam. Giá hiển thị đã bao gồm các khoản
          thuế. Giao hàng miễn phí cho tất cả đơn hàng{" "}
        </p>
      </div>
      {isDevided && <div className="border-[1px] border-black mt-[24px]"></div>}
    </div>
  );
}

export default QuestionBrand;
