import orderApi from "@/api/orderApi";
import { Loading } from "@/components";
import HistoryComponent from "@/components/common/History";
import ProductOrder from "@/components/common/OrderProduct";
import cartSelector, {
  CartSelectorType,
} from "@/recoil/selectors/cartSelector";
import { BaseData } from "@/type/base/baseData";
import { OrderResponse } from "@/type/response/order";
import { formatCurrency } from "@/utils/data";
import routes from "@/utils/routes";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

export default function OrderFail() {
  const navigate = useNavigate();
  const { totalNumberProducts, totalPrice } =
    useRecoilValue<CartSelectorType>(cartSelector);
  const { id } = useParams();

  const [dataOrder, setDataOrder] = useState<BaseData<OrderResponse>>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    orderApi
      .get(id ?? "")
      .then((res) => res.data)
      .then((data) => {
        setDataOrder(data.data);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          navigate(routes.NOT_FOUND);
        } else {
          console.log(error);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) return <Loading />;

  return (
    <div className="md:container md:max-w-[1280px] md:mx-auto">
      <section className="section__heading mx-4 my-8 md:mx-[120px]">
        <div className="section__heading-title text-[12px] text-[#484848] text-center md:text-[16px] font-normal md:text-left">
          <h3 className="section__heading-status--order font-bold my-0 text-red-700">
            Đặt hàng không thành công
          </h3>
        </div>
      </section>

      <section className="section__order mx-4 my-8 md:mx-[120px]">
        <div className="section__order-products">
          {dataOrder?.attributes.orderDetails.data.map((product) => {
            const productData = product.attributes.product.data.attributes;
            return (
              <ProductOrder
                name={productData.name}
                unitPrice={product.attributes.unitPrice}
                image={productData.avatar.data.attributes.url}
                quantity={product.attributes.quanity}
                key={product.id}
              />
            );
          })}
        </div>
        <div className="section__order-information my-8 md:mx-0">
          <div className="section__order-information-quantity md:text-[14px]">
            Số lượng:{" "}
            <span className="font-bold">{totalNumberProducts} sản phẩm</span>
          </div>
          <div className="section__order-information-price md:text-[14px]">
            Tổng cộng:{" "}
            <span className="font-bold text-[16px] md:text-[20px]">
              {formatCurrency(totalPrice.toString() ?? "0")} đ
            </span>
          </div>
        </div>
        <div className="section__order-continue-shopping text-center w-full">
          <button
            onClick={() => navigate(routes.CART)}
            className="text-[#000000] underline text-[14px] md:py-3 md:px-4 md:bg-piper-lolot md:text-white md:no-underline md:text-[16px] md:hover:opacity-70 md:duration-200"
          >
            Đặt lại đơn hàng
          </button>
        </div>
      </section>

      <section className="section__history-watched mx-4 my-8 md:mx-[120px]">
        <HistoryComponent
          productsHistory={JSON.parse(
            localStorage.getItem("productWatched") || "[]"
          )}
        />
      </section>
    </div>
  );
}
