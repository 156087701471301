import constants from "./constants";

export type StatusOrderType = {
  name: string;
  key: string;
  code: string;
};

export const orderStatus: Record<string, StatusOrderType> = {
  waiting: {
    name: "Chờ lấy",
    key: `${constants.PENDING_CONFIRMATION}/${constants.CONFIRMED}`,
    code: "waiting",
  },
  delivering: {
    name: "Đang giao",
    key: `${constants.SHIPPING_DELAYED}/${constants.SHIPPING}`,
    code: "delivering",
  },
  received: {
    name: "Đã nhận",
    key: constants.COMPLETED,
    code: "received",
  },
  "return-cancel": {
    name: "Đổi trả/hủy",
    key: `${constants.RETURNED}/${constants.CANCELED}`,
    code: "return-cancel",
  },
  history: {
    name: "Lịch sử",
    key: "",
    code: "history",
  },
};
