/* eslint-disable import/extensions */
import { BaseData } from '@/type/base/baseData';
import { ProductFaqs as ProductFaqsType } from '@/type/response/productFaqs';
import QuestionProduct from './QAItem';
import FAQProductSkeleton from '@/components/skeleton/common/productFaq';

interface QuestionsComponentProps {
  productFaqs: any;
  isLoading: boolean;
}

export default function QAComponent({ productFaqs, isLoading }: QuestionsComponentProps) {

  if (isLoading)
    return (
      <div>
        <div className="flex flex-col items-start justify-start gap-1">
          {Array.from({ length: 2 }).map((_: any, index: number) => (
            <FAQProductSkeleton key={index} />
          ))}
        </div>
      </div>
    );

  return (
    <div className="flex flex-col">
      <p className="text-[24px] font-[700] leading-[33.6px] text-center">
        Câu hỏi thường gặp
      </p>
      <ul className="w-full list-none">
        {productFaqs.map((item: BaseData<ProductFaqsType>, index: number) => {
          return <QuestionProduct hintQuestion={item.attributes} key={index} />;
        })}
      </ul>
    </div>
  );
}
