import ChevronDownSvg from "@/customIcons/ChevronDown";
import UseMaxHeight from "@/hooks/useMaxHeight";
import { ProductFaqs as ProductFaqsType } from '@/type/response/productFaqs';
import { useState } from "react";

interface QuestionProductProps {
  hintQuestion: ProductFaqsType;
}

export default function QuestionProduct({
  hintQuestion,
}: QuestionProductProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [ref, maxHeight] = UseMaxHeight(0);

  return (
    <li
      className={`border-b border-[#DEDEDE] border-opacity-100 py-[32px] md:text-[16px] cursor-pointer`}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="flex justify-between w-full">
        <div className={`text-[16px] md:text-[18px] font-[500] select-none line-clamp-2 text-ellipsis`}>
          {hintQuestion.title}
        </div>
        <div
          className={`${isOpen ? 'rotate-180' : ''
            } flex items-center justify-center duration-200 ease-linear`}
        >
          <ChevronDownSvg />
        </div>
      </div>
      <div
        className={`mt-2 transition-max-height duration-300 overflow-hidden md:text-[16px]`}
        style={{ maxHeight: isOpen ? `${maxHeight}px` : '0' }}
        ref={ref}
      >
        {hintQuestion.content}
      </div>
    </li>
  );
}
