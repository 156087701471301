import { BaseData } from "@/type/base/baseData";
import { UserAddressType } from "@/utils/AddressType";
import { atom } from "recoil";

const userAddressState = atom<BaseData<UserAddressType>[]>({
  key: "userAddressState",
  default: [],
});

export const checkUpdateAddress = atom<string>({
  key: "checkUpdateAddress",
  default: new Date().toString(),
});

export const selectedAddressState = atom<BaseData<UserAddressType> | null>({
  key: "selectedAddressState",
  default: null,
});

export const firstLoadAddressState = atom<boolean>({
  key: "firstLoadAddressState",
  default: false,
});

export const maskCloseableFormAddressState = atom<string>({
  key: "maskCloseableFormAddressState",
  default: new Date().toString(),
});

export default userAddressState;
