/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/button-has-type */
/* eslint-disable react/require-default-props */
import React, { PropsWithChildren } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Loading: React.FC<any> = () => {
  return (
    <div>
      <Box>
        <Skeleton height={400} borderRadius="false" />
      </Box>

      <div className="flex justify-center">
        <Box>
          <Skeleton height={45} borderRadius="false" width={1000} />
        </Box>
      </div>

      <Box>
        <Skeleton height={480} borderRadius="false" />
      </Box>

      <div className="flex justify-center">
        <Box>
          <Skeleton height={420} borderRadius="false" width={1000} />
        </Box>
      </div>

      <div className="flex justify-center">
        <Box>
          <Skeleton height={45} borderRadius="false" width={700} />
        </Box>
      </div>

      {/* list product */}
      <div className="flex flex-row justify-center">
        <Box>
          <Skeleton height={304} borderRadius="false" width={178} />
        </Box>

        <Box>
          <Skeleton height={304} borderRadius="false" width={178} />
        </Box>

        <Box>
          <Skeleton height={304} borderRadius="false" width={178} />
        </Box>

        <Box>
          <Skeleton height={304} borderRadius="false" width={178} />
        </Box>

        <Box>
          <Skeleton height={304} borderRadius="false" width={178} />
        </Box>
      </div>
    </div>

    // <div className="bg-black top-0 left-0 flex justify-center items-center absolute bg-red w-full h-full opacity-75">
    //   <div
    //     className="inline-block h-20 w-20 animate-spin rounded-full border-8 border-solid border-current  text-white border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
    //     role="status">
    //     <span
    //       className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
    //     >Loading...</span>
    //   </div>
    // </div>
  );
};

function Box({ children }: PropsWithChildren<unknown>) {
  return (
    <div
      style={{
        // border: '1px solid #ccc',
        display: 'block',
        lineHeight: 2,
        padding: '1rem',
        marginBottom: '0.5rem',
        width: '1280',
        height: '400',
      }}
    >
      {children}
    </div>
  );
}

export default Loading;
