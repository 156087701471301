import routineApi from '@/api/routineApi';
import { BaseData } from '@/type/base/baseData';
import { RoutineType } from '@/type/response/routine';
import keysReactQuery from '@/utils/keys';
import { useQuery } from '@tanstack/react-query';

const MINUTES_STALE_TIME = 30;

export default function useRoutineQuery() {
  const { data, isLoading, isFetched, isError, error } = useQuery({
    queryKey: [keysReactQuery.ROUTINES],
    queryFn: () => routineApi.getAll(),
    staleTime: 1000 * 60 * MINUTES_STALE_TIME,
  });

  const routines: BaseData<RoutineType>[] = data?.data?.data || [];

  return { data, isLoading, isFetched, isError, error, routines };
}
