const urls = {
  ENDPOINT: "/api/",
  LOCAL: "auth/local",
  REGISTER: "auth/local/register",
  HOME_PAGE: "home-page",
  PRODUCTS: "products",
  PRODUCT: "product",
  PRODUCT_INFORMATION: "description-products",
  CART: "carts",
  CART_ITEM: "carts-item",
  CATEGORY: "categories",
  DESCRIPTION: "description",
  FLASH_SALE: "flash-sales",
  SEARCH_BAR: "search-bar",
  MULTI_SEARCH: "multi-search",
  PRODUCT_FAQS: "product-faqs",
  BLOG: "blog",
  BLOGS: "blogs",
  BRAND: "brand-page",
  ROUTINE: "routines",
  ROUTINE_DETAILS: "routine",
  ORDER: "orders",
  ORDER_VER_2: "orders-ver-2",
  PAYMENT_CRATE: "create_payment_url",
  PARTNER_PAGE: "become-partner",
  USER: "users/me",
  UPDATE_USER: "user/me",
  PRODUCT_FILTER: "product-filter",
  FILTER_SKIN_PROPERTIES: "skin-attributes",
  FILTER_PROGRAMMES: "programmes",
  FILTER_PRODUCT_LINES: "product-lines",
  MEMBER_INCENTIVE: "member-incentive",
  FORGOT_PASSWORD: "auth/forgot-password",
  RESET_PASSWORD: "auth/reset-password",
  MEMBERSHIP_CLASSES: "membership-classes",
  USER_VOUCHERS: "user-vouchers",
  UPLOAD: "upload",
  REVIEWS: "reviews",
  VOUCHER_REVIEW: "voucher-review",
  ADD_REVIEW_VOUCHER: "add-review-voucher",
  CHECK_FLASH_SALES: "flash-sales/check",
  VARIATIONS: "variations",
  MAP: "map",
  FAQ_CHATS: "faq-chats",
  AGENT_STORE: "agent-store",
  TRACK_SEARCH: "track-search",
  TRACK_ADD_TO_CART: "cart/check-cart-activity",
  UPDATE_PRODUCT_EFFICIENCY: "/cart/update-product-efficiency",
  TRACK_REDIRECT: "track",
  GET_USER_ROOM: "room-chats/me",
  GET_ALL_ROOMS: "room-chats/allRoom",
  MESSAGES: "messages",
  GET_UPLOAD_FILES: "upload/files",
  MY_VOUCHER: "voucher/me",
  AGENCIES: "agencies",
  SEND_OTP: "otp/send-otp",
  VERIFY_OTP: "otp/verify-otp",
  RESEND_OTP: "otp/resend-otp",
  USER_ADDRESS: "user-addresses",
  USER_ADDRESS_DEFAULT: "user-address/set-default",
  GET_FEE: "order/fee",
  FILTER_PRODUCT_BRANDS: "brands",
  FILTER_PRODUCT_TEXTURES: "product-textures",
  VOUCHERS: "vouchers",
};
export default urls;
