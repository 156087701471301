export type BillTextType = "total-cart" | "total-bill" | "discount" | "normal";
export interface IBillTextProps {
  nameItem: string;
  valueItem: string;
  type: BillTextType;
}
const BillText = (props: IBillTextProps) => {
  const { nameItem, valueItem, type = "normal" } = props;

  if (type === "total-cart")
    return (
      <div className="flex justify-between items-center *:text-[#FF9900] *:text-[14px] md:*:text-[16px] *:font-[600]">
        <span className="leading-[16.94px] md:leading-[19.36px]">
          {nameItem}:
        </span>
        <span className="leading-[16.94px] md:leading-[19.36px]">
          {valueItem}
        </span>
      </div>
    );

  if (type === "total-bill")
    return (
      <div className="flex justify-between items-center *:text-[14px] md:*:text-[16px] *:font-[600]">
        <span className="text-black leading-[16.94px] md:leading-[19.36px]">
          {nameItem}:
        </span>
        <span className="text-[#FF9900] leading-[16.94px] md:leading-[19.36px]">
          {valueItem}
        </span>
      </div>
    );

  if (type === "discount")
    return (
      <div className="flex justify-between items-center *:text-[14px] md:*:text-[16px] *:font-[400]">
        <span className="text-black leading-[16.94px] md:leading-[19.36px]">
          {nameItem}:
        </span>
        <span className="text-[#FF9900] leading-[16.94px] md:leading-[19.36px]">
          - {valueItem}
        </span>
      </div>
    );

  return (
    <div className="flex justify-between items-center *:text-[14px] md:*:text-[16px] *:text-black *:font-[400]">
      <span className="leading-[16.94px] md:leading-[19.36px]">
        {nameItem}:
      </span>
      <span className="leading-[16.94px] md:leading-[19.36px]">
        {valueItem}
      </span>
    </div>
  );
};

export default BillText;
