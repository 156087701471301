import brandApi from '@/api/brandApi';
import {
  BrandAchievement,
  BrandBanner,
  BrandValue,
} from '@/type/response/brand';
import keysReactQuery from '@/utils/keys';
import { useQuery } from '@tanstack/react-query';

const MINUTES_STALE_TIME = 5;

export default function useBrandQuery() {
  const { data, isLoading, isFetched, isError, error } = useQuery({
    queryKey: [keysReactQuery.BRAND],
    queryFn: () => brandApi.get(),
    refetchOnWindowFocus: false,
    staleTime: MINUTES_STALE_TIME * 60 * 1000,
  });

  const banners: BrandBanner[] = data?.data?.data.attributes.banner ?? [];
  const brandValues: BrandValue[] =
    data?.data?.data.attributes.brandValue ?? [];
  const achievement: BrandAchievement = data?.data?.data.attributes
    .achievement as BrandAchievement;

  return {
    data,
    isLoading,
    isFetched,
    isError,
    error,
    banners,
    brandValues,
    achievement,
  };
}
