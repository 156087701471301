import { FAQPartnerType } from "@/type/response/partner";

interface QuestionPartnerProps {
  question: FAQPartnerType;
  inSearchResult?: boolean;
}

function QuestionPartner({ question, inSearchResult }: QuestionPartnerProps) {
  if (inSearchResult) {
    return (
      <div className="flex flex-col gap-[8px] p-[8px] px-[16px] md:p-[24px] md:px-[24px] border rounded-[16px] border-[#DEDEDE]">
        <p className="text-[13px] md:text-[18px] font-[700] leading-[18.2px]">
          {question.title}
        </p>
        <p className="text-[13px] md:text-[16px] font-[400] leading-[18.2px] text-ellipsis line-clamp-2">
          {question.content}
        </p>
      </div>
    )
  }

  return (
    <div className="min-h-[182px] border-[1px] border-[#808080] p-[24px] rounded-[24px] flex flex-col gap-[8px]">
      <p className="text-[#101828] font-medium text-[20px]">
        {question?.title}
      </p>
      <p className="text-[#667085] text-[16px]">{question?.content}</p>
    </div>
  );
}

export default QuestionPartner;
