import images from "@/assets/images";
import routes from "@/utils/routes";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="bg-[#f7f7f7] py-[8px]">
      <div className="bg-white">
        <div className="absolute top-[15%] md:top-[20%] xl:top-[50%] lg:left-[11%] left-[65%] lg:top[40%] translate-x-[-50%] translate-y-[-50%] w-[65%] md:w-[80%] lg:w-[20%]">
          <img
            src={images.notFound}
            alt="image-not-found"
            className="rotate-[-80.6deg] lg:rotate-[5.3deg] h-full w-full object-center"
          />
        </div>
        <div className="h-[90vh] relative">
          <div className="flex flex-col items-center justify-center h-full">
            <h2 className="m-0 text-[#FF9900] font-[600] text-[128px] leading-[154.91px]">
              404
            </h2>
            <p className="font-normal text-[20px] lg:text-[64px] leading-[77.54px]">
              Không tìm thấy trang
            </p>
            <button
              className="mt-[40px] p-[12px_20px] rounded-[24px] bg-black text-white font-[600] text-[14px] leading-[16.94px] min-w-[163px] hover:text-[#FF9900]"
              onClick={() => navigate(routes.HOME)}
            >
              Quay về trang chủ
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
