import { shippingDataState } from "@/recoil/shippingDataStae";
import { formatCurrency } from "@/utils/data";
import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useRecoilState } from "recoil";

export interface ICartFormProps {
  onValueChange: (val: number) => void;
  onShippingMethodChange: (val: number) => void;
}

interface ISelectionMethodProp {
  title: string;
  description: string;
  selected: boolean;
  onClick: () => void;
}

export function CartForm({
  onValueChange,
  onShippingMethodChange,
}: ICartFormProps) {
  const { control, setValue } = useForm();
  useEffect(() => {
    setValue("shippingMethod", 0);
    // setValue("paymentMethod", 0);
    onShippingMethodChange(0);
    // onValueChange(0);
  }, [control, onShippingMethodChange]);
  const [shippingData] = useRecoilState(shippingDataState);

  const SelectionMethod = ({
    title,
    description,
    selected,
    onClick,
  }: ISelectionMethodProp) => {
    return (
      <div
        onClick={onClick}
        className={`p-[16px] rounded-[16px] border-[1px] cursor-pointer xl:h-[80px] flex flex-col gap-[8px] ${
          selected ? "border-black" : "border-[#E6E6E6]"
        }`}
      >
        <h3 className="text-[16px] leading-[22.4px] m-0 font-normal">
          {title}
        </h3>
        <p className="text-[13px] text-[#545454] leading-[18.2px]">
          {description}
        </p>
      </div>
    );
  };

  return (
    <div>
      <div className="mb-9">
        <div className="font-bold text-[12px] md:text-[16px] mb-[24px]">
          Phương thức vận chuyển
        </div>
        <div className="flex flex-col gap-[16px]">
          <Controller
            name="shippingMethod"
            control={control}
            render={({ field }) => (
              <>
                {/* <SelectionMethod
                  title="Giao nhanh 2H"
                  description="Áp dụng giao 2H từ 8:30 - 5:30 từ thứ 2 tới thứ 6, khu vực HCM."
                  selected={field.value === 0}
                  onClick={() => {
                    field.onChange(0);
                    onShippingMethodChange(0);
                  }}
                /> */}
                <SelectionMethod
                  title="Giao hàng qua đối tác"
                  description="Sử dụng dịch vụ Giao Hàng Nhanh để giao hàng, nhận hàng từ 2 đến 5 ngày"
                  selected={field.value === 0}
                  onClick={() => {
                    field.onChange(0);
                    onShippingMethodChange(0);
                  }}
                />
              </>
            )}
          />
        </div>
      </div>

      <div className="md:mb-9">
        <div className="font-bold text-[12px] md:text-[16px] mb-[24px]">
          Phương thức thanh toán
        </div>
        <div className="flex flex-col gap-[16px]">
          <Controller
            name="paymentMethod"
            control={control}
            render={({ field }) => (
              <>
                <SelectionMethod
                  title="Thanh toán VPAY-QR"
                  description="Thanh toán nhanh bằng mã QR"
                  selected={field.value === 0}
                  onClick={() => {
                    field.onChange(0);
                    onValueChange(0);
                  }}
                />
                <SelectionMethod
                  title="Thanh toán khi nhận hàng"
                  description={`Phí giao hàng cố định ${
                    shippingData?.fee > 0
                      ? formatCurrency(shippingData?.fee.toString())
                      : "30k"
                  }`}
                  selected={field.value === 1}
                  onClick={() => {
                    field.onChange(1);
                    onValueChange(1);
                  }}
                />
              </>
            )}
          />
        </div>
      </div>
    </div>
  );
}
