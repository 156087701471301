import images from "@/assets/images";
import svgs from "@/assets/svgs";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import { Navigation, Pagination } from "swiper/modules";
import useWindowSize from "@/hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
import routes from "@/utils/routes";
interface MissionSectionProps {
  title: string;
  content: string[];
  image: string;
  isWhite?: boolean;
}

interface RegisterSectionProps {
  title: string;
  tag: string;
  description: string;
  index: number;
  isPartnerPage?: boolean;
}
function AboutUs() {
  const size = useWindowSize();
  const navigate = useNavigate();
  const contents = {
    mission: [
      "Chúng tôi cam kết mang đến những sản phẩm không chỉ hoàn hảo về mặt chức năng mà còn chạm đến cảm xúc của người dùng. Sự tinh tế được thể hiện qua từng chi tiết nhỏ, từ khâu thiết kế đến sản xuất, nhằm mang lại trải nghiệm vượt trội cho khách hàng.",
      "Song song đó, chúng tôi hiểu rằng thành công bền vững không đến từ những bước đi nhanh chóng mà từ sự kiên định và nỗ lực không ngừng nghỉ. Trên hành trình phát triển, chúng tôi luôn giữ vững giá trị cốt lõi, tiếp tục cải tiến và phát triển để vươn tới những đỉnh cao mới.",
      "Sự kết hợp giữa tinh tế và bền bỉ là nền tảng giúp chúng tôi xây dựng một thương hiệu đáng tin cậy, tạo ra những sản phẩm và dịch vụ chất lượng cao, góp phần nâng cao giá trị cho khách hàng và xã hội.",
    ],
    vision: [
      "Ceranee phân phối sản phẩm làm đẹp và chăm sóc sức khỏe hàng đầu Việt Nam, cung cấp giá trị thật cho khách hàng, phát triển bền vững cùng xã hội.",
      "Không chỉ tập trung vào lợi nhuận, Ceranee còn chú trọng phát triển bền vững, đồng hành cùng xã hội trong việc tạo ra những giá trị tích cực. Bằng cách luôn tiên phong trong việc áp dụng công nghệ hiện đại, chúng tôi đảm bảo rằng sản phẩm đến tay khách hàng không chỉ an toàn và hiệu quả mà còn thân thiện với môi trường.",
      "Ceranee cam kết luôn nỗ lực phát triển không ngừng, tiếp tục mang đến những sản phẩm và dịch vụ tốt nhất, góp phần xây dựng một cộng đồng khỏe mạnh và thịnh vượng hơn.",
    ],
    values: [
      "CHẤT: Chất lượng là nền tảng trong mọi sản phẩm và dịch vụ mà Ceranee cung cấp.",
      "TÍN : Giữ vững uy tín, xây dựng niềm tin vững chắc với khách hàng và đối tác.",
      "TÂM : Tận tâm trong từng hành động, mang lại giá trị thực sự cho khách hàng.",
      "NHÂN : Con người là trung tâm, Ceranee cam kết phát triển đội ngũ nhân sự với sự tôn trọng và trách nhiệm xã hội.",
      "BỀN VỮNG : Phát triển bền vững, bảo vệ môi trường và đóng góp tích cực cho cộng đồng.",
    ],
  };
  const sections = [
    {
      title: "Sứ mệnh",
      content: contents.mission,
      image: images.Mission,
      isWhite: false,
    },
    {
      title: "Tầm nhìn",
      content: contents.vision,
      image: images.Vision,
      isWhite: true,
    },
    {
      title: "Giá trị cốt lõi",
      content: contents.values,
      image: images.Values,
      isWhite: false,
    },
  ];

  const achievements = [
    {
      title: "Cosmopolitan Beauty Awards",
      description: "Best calming/smoothing serum 2023",
    },
    {
      title: "Allure Best of Beauty Awards",
      description: "Best liquid body wash 2022",
    },
    {
      title: "CEW Beauty Awards",
      description: "Best sensitive skin facial serum 2024",
    },
    {
      title: "Glamour Beauty Awards",
      description:
        "Among the top 49 best beauty products of 2023 according to Glamor readers",
    },
  ];
  const MissionSection = ({
    title,
    content,
    image,
    isWhite = false,
  }: MissionSectionProps) => {
    return (
      <div
        className={`p-[40px_16px] lg:p-[64px_160px] grid grid-cols-1 lg:grid-cols-2 gap-[64px] ${
          isWhite ? "bg-white lg:bg-[#f0f0f0]" : ""
        }`}
      >
        <div className="flex flex-col gap-[24px]">
          <h2 className="m-0 font-[600] text-[36px] lg:text-[48px] leading-[43.57px] lg:leading-[58.09px]">
            {title}
          </h2>
          <ul className="list-none flex flex-col gap-[24px]">
            {content.map((item, index) => (
              <li
                key={index}
                className="font-normal text-[16px] leading-[19.36px]"
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
        <div className="h-[544px] w-[534px] rounded-[24px] overflow-hidden lg:block hidden">
          <img src={image} alt="image" className="w-full h-full object-cover" />
        </div>
      </div>
    );
  };
  const RegisterSection = ({
    title,
    tag,
    description,
    index,
    isPartnerPage,
  }: RegisterSectionProps) => {
    return (
      <div
        className={`p-[40px_16px] lg:p-[64px_160px] ${
          index === 1 ? "bg-[#F0F0F0]" : "bg-white"
        } lg:bg-white`}
      >
        <p className="font-[600] text-[#FF9900] text-[16px] leading-[24px]">
          {title}
        </p>
        <p className="text-[30px] lg:text-[48px] font-[600] leading-[58.09px] mt-[12px]">
          {tag}
        </p>
        <p className="text-[#545454] text-[14px] font-normal leading-[16.94px] mt-[24px]">
          {description}
        </p>
        <button
          className="rounded-[24px] p-[10px_16px] bg-black text-white hover:text-[#FF9900] duration-300 w-full cursor-pointer mt-[64px]"
          onClick={() => {
            if (isPartnerPage) {
              navigate(`${routes.PARTNER}${routes.REGISTER}`);
            }
          }}
        >
          Xem thêm
        </button>
      </div>
    );
  };
  return (
    <div className="bg-[#f7f7f7] py-[8px] flex flex-col gap-[8px]">
      {/* title */}
      <div className="bg-white">
        <div className="flex flex-col items-center justify-center h-[100vh] gap-[24px] max-w-[1440px] lg:mx-auto">
          <p className="text-[20px] font-[600] leading-[24.2px] text-[#333333]">
            Về chúng tôi
          </p>
          <h1 className="font-[600] text-[48px] lg:text-[128px] leading-[58.09px] lg:leading-[154.91px] m-0">
            CERANEE
          </h1>
        </div>
      </div>
      {/* CEO */}
      <div className="bg-white">
        <div className="flex lg:flex-row flex-col gap-[64px] p-[48px_16px] lg:p-[64px_160px] max-w-[1440px] lg:mx-auto">
          <div className="flex flex-col gap-[24px] w-full lg:w-[331px]">
            <div
              className="h-[280px] rounded-[24px] overflow-hidden bg-center bg-cover"
              style={{ backgroundImage: `url(${images.CEO})` }}
            ></div>

            <div className="flex flex-col items-start">
              <p className="text-[18px] font-[600] leading-[28px]">
                Võ Nhựt Thi
              </p>
              <p className="text-[#FF9900] font-normal text-[16px] leading-[24px] mt-[4px]">
                Founder & CEO
              </p>
              <p className="text-[#808080] font-normal text-[14px] leading-[16.94px] mt-[16px]">
                <span className="underline cursor-pointer">Founder</span> tại{" "}
                <span className="underline cursor-pointer">Ceranee</span> và{" "}
                <span className="underline cursor-pointer">CEO</span>{" "}
                <span className="underline cursor-pointer">
                  {" "}
                  So Natural Việt Nam
                </span>
              </p>
              <div className="flex gap-[20px] mt-[24px]">
                <img
                  src={svgs.tiktok}
                  alt="icon-tiktok"
                  className="cursor-pointer"
                />
                <img
                  src={svgs.fbGrey}
                  alt="icon-fb"
                  className="cursor-pointer"
                />
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col gap-[24px]">
            <h2 className="m-0 text-[36px] lg:text-[48px] font-[600] leading-[43.57px] lg:leading-[58.09px]">
              “Tinh tế trong từng sản phẩm, bền bỉ trong từng bước đi”
            </h2>
            <ul className="flex flex-col gap-[24px] list-none">
              <li className="text-[16px] leading-[19.36px] font-normal">
                “Tại Ceranee , chúng tôi không chỉ hướng tới mục tiêu trở thành
                công ty phân phối sản phẩm làm đẹp và chăm sóc sức khỏe hàng đầu
                tại Việt Nam, mà còn cam kết mang đến những sản phẩm chất lượng
                và trải nghiệm vượt trội cho khách hàng.
              </li>
              <li className="text-[16px] leading-[19.36px] font-normal">
                Chúng tôi không ngừng đổi mới sáng tạo trong sản phẩm dịch vụ,
                đồng thời còn xây dựng một đội ngũ tài năng, nơi mỗi cá nhân đều
                có cơ hội tỏa sáng và cùng nhau kiến tạo những giá trị bền vững
                cho cộng đồng.”
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* Mission  */}
      <div className="bg-[#f0f0f0]">
        <div className="lg:max-h-[682px] overflow-hidden max-w-[1440px] mx-auto">
          {size?.width < 768 ? (
            <>
              {sections.map((section, index) => (
                <MissionSection
                  key={index}
                  title={section.title}
                  content={section.content}
                  image={section.image}
                  isWhite={section.isWhite}
                />
              ))}
            </>
          ) : (
            <Swiper
              direction={"vertical"}
              spaceBetween={30}
              centeredSlides={true}
              pagination={{
                clickable: true,
              }}
              speed={1200}
              freeMode={true}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="h-[682px] about-us"
            >
              {sections.map((section, index) => (
                <SwiperSlide className="animate-bounce-in">
                  <MissionSection
                    key={index}
                    title={section.title}
                    content={section.content}
                    image={section.image}
                    isWhite={section.isWhite}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      </div>
      {/* Component  */}
      <div className="bg-white">
        <div className="bg-[#f7f7f7] grid grid-cols-1 lg:grid-cols-2 gap-[8px] max-w-[1440px] lg:mx-auto">
          <RegisterSection
            title="Cổ đông"
            tag="Đối tác Ceranee"
            description="We’re a 100% remote team spread all across the world. Join us!"
            index={0}
          />
          <RegisterSection
            title="Đại lý"
            tag="Đại lý Ceranee"
            description="We’re a 100% remote team spread all across the world. Join us!"
            index={1}
            isPartnerPage
          />
        </div>
      </div>
      {/* achievements  */}
      <div className="bg-white">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-[8px] max-w-[1440px] lg:mx-auto p-[40px_16px] lg:p-[96px_160px]">
          <div className="flex flex-col gap-[20px]">
            <div className="flex flex-col gap-[12px]">
              <p className="text-[#E77435] font-[600] text-[16px] leading-[24px]">
                Thành tích
              </p>
              <h3 className="text-[36px] font-[600] leading-[44px] text-[#101828] m-0">
                Các thành tích đã đạt
              </h3>
            </div>
            <p className="text-[20px] text-[#475467] leading-[30px] font-normal">
              Chúng tôi tự hào với nhiều giải thưởng uy tín, khẳng định chất
              lượng và sự đổi mới trong ngành mỹ phẩm.
            </p>
            <ul className="flex flex-col gap-[20px]">
              {achievements.map((item, index) => (
                <li className="flex gap-[8px] items-start" key={index}>
                  <img src={svgs.plusCircle} alt="icon" className="mt-[3px]" />
                  <div className="flex flex-col gap-[8px]">
                    <p className="text-[18px] leading-[28px] font-[600] text-[#101828]">
                      {item?.title}
                    </p>
                    <p className="text-[#475467] font-normal text-[16px] leading-[24px]">
                      {item?.description}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col gap-[16px] lg:items-center items-end">
            <div className="flex gap-[16px] items-end">
              <div className="size-[160px] rounded-[16px] overflow-hidden">
                <img
                  src={images.achievements2}
                  alt="image"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="w-[160px] h-[240xp] rounded-[16px] overflow-hidden">
                <img
                  src={images.achievements1}
                  alt="image"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
            <div className="flex gap-[16px] justify-start">
              <div className="size-[192px] rounded-[16px] overflow-hidden">
                <img
                  src={images.achievements3}
                  alt="image"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="w-[160px] h-[240px] rounded-[16px] overflow-hidden">
                <img
                  src={images.achievements4}
                  alt="image"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="w-[168px] h-[140px] rounded-[16px] overflow-hidden lg:block hidden">
                <img
                  src={images.achievements5}
                  alt="image"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
