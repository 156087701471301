import images from "@/assets/images";
import ResetPasswordComponent from "@/components/pages/ForgotPassword/ResetPassword";
import constants from "@/utils/constants";
import routes from "@/utils/routes";
import { Image } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function ResetPassword() {
  const navigate = useNavigate();

  const isLoggedIn = localStorage.getItem(constants.ACCESS_TOKEN);
  useEffect(() => {
    if (isLoggedIn) {
      navigate(routes.HOME);
    }
  }, [isLoggedIn, navigate]);
  return (
    <div className="grid lg:grid-cols-2 gap-[8px] p-[48px_16px] lg:bg-[#F7F7F7] pt-[8px]">
      <ResetPasswordComponent />
      <div className="bg-white relative min-h-[300px] md:min-h-[500px] p-[36px_0_0_12px] flex items-center justify-center">
        <Image
          src={images.backgroundForgotPassword}
          alt="image"
          preview={false}
          className="w-[175px] md:w-[300px] lg:w-full lg:h-full
  "
        />
        <Image
          src={images.imageMan}
          alt="image"
          preview={false}
          rootClassName="absolute top-[30%] left-[42%] lg:top-[17%] lg:left-[50%]"
          className="h-[174px] md:h-[300px] lg:h-[353px]"
        />
      </div>
    </div>
  );
}

export default ResetPassword;
