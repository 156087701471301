/* eslint-disable import/extensions */
import { Variation } from "@/type/response/productInformation";
import routes from "@/utils/routes";
import { Link } from "react-router-dom";

interface VolumeComponentProps {
  variationProd: string | undefined;
  id?: string;
  variations: Variation[];
}

export default function VolumeComponent({
  variationProd,
  id,
  variations,
}: VolumeComponentProps) {
  return (
    <>
      <section className="section__volume flex flex-col gap-[8px] md:gap-[16px]">
        <p className="text-[13px] md:text-[16px] font-[400] leading-[18.2px] md:leading-[22.4px] text-[#484848]">
          {variationProd}
        </p>
        <div className="flex justify-start items-center">
          {variations.map((variation, index) => {
            if (variation.id.toString() === id) {
              return (
                <div
                  className="border border-[#000] rounded-[24px] p-[10px_16px] md:p-[12px_20px] flex items-center justify-center"
                  key={index}
                >
                  <p className="text-[14px] md:text-[16px] font-[600]">
                    {variation.attributeValue} {variation.productUnit}
                  </p>
                </div>
              );
            }
            return (
              <Link
                to={`${routes.PRODUCT}/${variation.slug}`}
                key={index}
                className="rounded-[24px] p-[12px_20px] flex items-center justify-center"
              >
                <p className="text-[14px] md:text-[16px] font-[600]">
                  {variation.attributeValue} {variation.productUnit}
                </p>
              </Link>
            );
          })}
        </div>
      </section>
    </>
  );
}
