import Blog from '@/components/common/Blog';
import Breadcrumb from "@/components/common/Breadcrumb";
import {
  BlogMainSkeleton,
  BlogSkeleton,
} from '@/components/skeleton/common/blog';
import useBlogsQuery from '@/hooks/ReactQuery/blogs.query';
import { useRootLayoutContext } from "@/layouts/RootLayout";
import loadingScreenState from "@/recoil/loadingScreenState";
import constants from '@/utils/constants';
import routes from "@/utils/routes";
import { Empty, Pagination } from 'antd';
import { useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from "recoil";

export default function Blogs() {
  const [searchParams, setSearchParams] = useSearchParams();
  let page = searchParams.get('page') || '1';
  const pageSize =
    searchParams.get('pageSize') || String(constants.PAGE_SIZE_BLOGS);
  const { isLoading, blogList, informationPagination } = useBlogsQuery(
    pageSize,
    page
  );
  const handleSetIsLoading = useSetRecoilState(loadingScreenState)
  const { setIsLoadingDone } = useRootLayoutContext();

  const breadcrumbItems = [
    {
      href: routes.HOME,
      title: 'Trang chủ',
    },
    {
      href: routes.BLOGS,
      title: 'Blog'
    },
    {
      title: 'Bảng tin'
    },
  ];

  useEffect(() => {
    if (!isLoading) {
      setIsLoadingDone(!isLoading)
    }
  }, [isLoading])

  useEffect(() => {
    handleSetIsLoading(true)
  }, [])

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />

      <section className="md:container md:max-w-[1280px] md:mx-auto">
        <div className="md:mx-[120px]">
          {isLoading ? (
            <BlogMainSkeleton />
          ) : (
            <>
              {blogList?.[0] && (
                <Blog
                  blog={blogList?.[0] || []}
                  buttonViewRight={true}
                  buttonViewMore={true}
                  isMain
                />
              )}
            </>
          )}
        </div>

        <hr className="hidden md:block bg-[#EDEDED] mx-[120px] my-8" />

        <div className="px-4 py-8 bg-[#F8F8F8] md:mx-[120px] md:px-0 md:py-0 md:bg-[#FFFFFF] mb-8">
          {isLoading ? (
            <>
              {Array.from({ length: 6 }).map((_: any, index: number) => (
                <BlogSkeleton key={index} />
              ))}
            </>
          ) : (
            <>
              {blogList?.length > 0 && (
                <div className="px-2 py-2 flex flex-col gap-4 md:px-8 md:py-8 md:gap-8">
                  {blogList?.map((blog, index) => (
                    <Blog blog={blog} buttonViewMore={true} key={index} />
                  ))}
                </div>
              )}
            </>
          )}
        </div>

        {blogList?.length === 0 && !isLoading && (
          <Empty description="Chưa có bài viết nào được đăng" />
        )}

        <div className="flex justify-center items-center my-8">
          <Pagination
            defaultCurrent={informationPagination?.page || 1}
            total={informationPagination?.total || 1}
            pageSize={informationPagination?.pageSize || 1}
            onChange={(page, pageSize) => {
              setSearchParams({
                page: String(page),
                pageSize: String(pageSize),
              });
            }}
          />
        </div>
      </section>
    </>
  );
}
