import { atom } from "recoil";
import constants from "@/utils/constants";
import productApi from "@/api/productApi";

export interface IProductInCart {
  id: string | number;
  name: string;
  image: string;
  price: string;
  pricePromotion: string | null;
  flashSalePrice: string | null;
  flashSale?: boolean | false;
  quantity?: number;
  slug: string;
}
export interface IProductInCartFee {
  id: number;
  name: string;
  image: string;
  price: string;
  pricePromotion: string | null;
  flashSalePrice: string | null;
  flashSale?: boolean | false;
  quantity?: number;
  slug: string;
}

export interface ICartProduct {
  id: string | number;
  quantity: number;
  data: IProductInCart;
}

export interface ICartProductFee {
  id: string | number;
  quantity: number;
  // data: IProductInCartFee;
}
export interface IICartStateRecoil {
  cartProducts: ICartProduct[];
}

const initialValue = {
  cartProducts: JSON.parse(
    localStorage.getItem(constants.CART) || "[]"
  ) as ICartProduct[],
};

export const CartState = atom({
  key: "cartState",
  default: initialValue as IICartStateRecoil,
});

export const addToCart = (cart: ICartProduct[], product: IProductInCart) => {
  const newCart = [...cart];
  const findIndex = cart.findIndex(
    (cartProduct) => cartProduct.id === product.id
  );

  if (findIndex >= 0) {
    newCart[findIndex] = {
      ...newCart[findIndex],
      quantity: newCart[findIndex].quantity + (product.quantity || 1),
    };
    localStorage.setItem(constants.CART, JSON.stringify(newCart));
    return newCart;
  }

  const cartSave = [
    {
      id: product.id,
      quantity: product.quantity || 1,
      data: product,
    },
    ...newCart,
  ];
  localStorage.setItem(constants.CART, JSON.stringify(cartSave));
  return cartSave;
};

export const addProductListToCart = (
  cart: ICartProduct[],
  products: IProductInCart[]
) => {
  const newCart = [...cart];
  products.forEach((product) => {
    const findIndex = newCart.findIndex(
      (cartProduct) => cartProduct.id === product.id
    );

    if (findIndex >= 0) {
      newCart[findIndex] = {
        ...newCart[findIndex],
        quantity: newCart[findIndex].quantity + 1,
      };
    } else {
      newCart.push({
        id: product.id,
        quantity: 1,
        data: product,
      });
    }
  });

  localStorage.setItem(constants.CART, JSON.stringify(newCart));
  return newCart;
};

export const deleteCartProduct = (
  cart: ICartProduct[],
  idProduct: number | string
) => {
  const newCart = cart.filter((cartProduct) => {
    return cartProduct.id !== idProduct;
  });

  localStorage.setItem(constants.CART, JSON.stringify(newCart));
  return newCart;
};

export const increaseCartProduct = (
  cart: ICartProduct[],
  idProduct: number | string
) => {
  const newCart = [...cart];
  const findIndex = newCart.findIndex(
    (cartProduct) => cartProduct.id === idProduct
  );
  newCart[findIndex] = {
    ...newCart[findIndex],
    quantity: newCart[findIndex].quantity + 1,
  };
  localStorage.setItem(constants.CART, JSON.stringify(newCart));
  return newCart;
};

export const decreaseCartProduct = (
  cart: ICartProduct[],
  idProduct: number | string
) => {
  const newCart = [...cart];
  const findIndex = newCart.findIndex(
    (cartProduct) => cartProduct.id === idProduct
  );
  if (newCart[findIndex].quantity == 1) {
    newCart.splice(findIndex, 1);
    localStorage.setItem(constants.CART, JSON.stringify(newCart));
    return newCart;
  }

  newCart[findIndex] = {
    ...newCart[findIndex],
    quantity: newCart[findIndex].quantity - 1,
  };
  localStorage.setItem(constants.CART, JSON.stringify(newCart));
  return newCart;
};

export const clearCart = () => {
  localStorage.setItem(constants.CART, "[]");
  return [];
};

export const updateProductCartData = async (
  cart: ICartProduct[],
  idProduct: number | string
) => {
  const newCart = [...cart];
  const findIndex = newCart.findIndex(
    (cartProduct) => cartProduct.id === idProduct
  );

  const flashSalePrice = await productApi
    .getById(String(idProduct))
    .then((res) => {
      return res.data.data.attributes.flashSalePrice;
    });

  newCart[findIndex] = {
    ...newCart[findIndex],
    data: {
      ...newCart[findIndex].data,
      flashSalePrice,
    },
  };

  localStorage.setItem(constants.CART, JSON.stringify(newCart));

  return newCart;
};
