import axiosClient from "./axiosClient";
import { BaseData } from "@/type/base/baseData";
import { BaseResponse } from "@/type/base/baseResponse";
import { UserVouchers } from "@/type/response/profile";
import { VouchersShopType } from "@/type/response/vouchers";
import urls from "@/utils/urls";

interface AddVoucherProps {
  data: {
    user: {
      id: number;
    };
    voucher: {
      id: number;
    };
  };
}
interface VoucherRespone {
  createdAt: Date;
  updatedAtupdatedAt: Date;
  status: string;
}

const voucherApi = {
  getAllVoucher() {
    return axiosClient.get<BaseResponse<BaseData<VouchersShopType>[]>>(
      `${urls.VOUCHERS}`
    );
  },
  addVoucherForUser(payload: AddVoucherProps) {
    return axiosClient.post<BaseResponse<BaseData<VoucherRespone>[]>>(
      `${urls.USER_VOUCHERS}`,
      payload
    );
  },
  getVoucherById(id: number) {
    return axiosClient.get<BaseResponse<BaseData<UserVouchers>[]>>(
      `${urls.USER_VOUCHERS}?filters[user]=${id}&populate=*`
    );
  },
};

export default voucherApi;
