import authApi from "@/api/authApi";
import otpApi from "@/api/otp";
import svgs from "@/assets/svgs";
import routes from "@/utils/routes";
import { Image } from "antd";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

interface OTPPasswordProp {
  setSuccess: (value: boolean) => void;
}
function OTPPassword({ setSuccess }: OTPPasswordProp) {
  const navigate = useNavigate();
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [timer, setTimer] = useState(60);
  const email = localStorage.getItem("email");
  const dataOTP = Number(otp.join(""));
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChange = (value: string, index: number) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      const nextInput = document.getElementById(`otp-${index + 1}`);
      nextInput?.focus();
    }
  };

  useEffect(() => {
    const countdown = setInterval(() => {
      if (timer > 0) {
        setTimer((prev) => prev - 1);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [timer, isLoading]);

  const resetTimer = async () => {
    if (email) {
      await otpApi.resendOTP({ email }).then((res) => {
        if (res.status === 200) {
          toast.success("Một mã OTP đã được gửi lại vào email của bạn");
        }
      });
      setTimer(60);
    }
  };

  const handleSendLink = async () => {
    await authApi.forgotPassword({ email }).then((res) => {
      if (res.status === 200) {
        setSuccess(true);
      }
    });
  };

  const handleSubmit = async () => {
    if (otp.some((digit) => digit === "")) {
      toast.error("Vui lòng nhập đủ 4 chữ số OTP.");
      return;
    }
    setIsLoading(true);

    if (email) {
      const data = {
        email,
        otp: dataOTP.toString(),
      };

      await otpApi
        .verifyOTP(data)
        .then((res) => {
          if (res.status === 200) {
            handleSendLink();
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error("OTP đã hết hạn, vui lòng nhập lại OTP mới");
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="bg-white lg:p-[32px_160px]">
      <div className="flex items-center justify-center">
        <div className="flex items-center gap-[8px]">
          <button onClick={() => navigate(routes.FORGOT_PASSWORD)}>
            <Image
              src={svgs.chevron}
              alt="icon"
              preview={false}
              width={48}
              className="rotate-90"
            />
          </button>
          <h2 className="font-[600] text-[30px]">Xác minh tài khoản</h2>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center gap-[32px]">
        <p className="text-[14px]">Nhập OTP được gửi qua email {email || ""}</p>
        <div className="flex justify-center gap-[16px]">
          {otp.map((value, index) => (
            <input
              key={index}
              id={`otp-${index}`}
              type="text"
              maxLength={1}
              value={value}
              onChange={(e) => handleChange(e.target.value, index)}
              className="size-[60px] rounded-[4px] border-[1px] border-black text-center text-[30px]"
            />
          ))}
        </div>
        <button
          className={`p-[12px_20px] text-[14px] font-[600] rounded-[24px] w-full lg:min-w-[400px] duration-300 mt-[32px] ${
            isLoading
              ? "bg-[#E6E6E6] text-[#818080] cursor-not-allowed"
              : "bg-black text-white hover:text-[#FF9900]"
          }`}
          disabled={isLoading}
          onClick={handleSubmit}
        >
          Nhập OTP
        </button>
        <p
          className={`text-[16px] text-[#FF9900] ${
            timer === 0 ? "cursor-pointer font-[600]" : ""
          }`}
          onClick={() => {
            if (timer === 0) {
              resetTimer();
            }
          }}
        >
          {timer === 0
            ? "Gửi lại OTP"
            : `Gửi lại OTP sau: 0:${timer.toString().padStart(2, "0")}`}
        </p>
      </div>
    </div>
  );
}

export default OTPPassword;
