import svgs from "@/assets/svgs";
import Breadcrumb from "@/components/common/Breadcrumb";
import QuestionPartner from "@/components/common/QuestionPartner";
import { useFrequentlyQuestions } from "@/hooks/ReactQuery/blogs.query";
import routes from "@/utils/routes";
import { useState } from "react";

interface Question {
  id: number;
  attributes: {
    title: string;
    content: { children: { text: string }[] }[];
  };
}

const BlogsQA = () => {
  const breadcrumbItems = [
    {
      href: routes.HOME,
      title: "Trang chủ",
    },
    {
      href: routes.BLOGS,
      title: "Blog",
    },
    {
      href: routes.BLOGS_QA,
      title: "Câu hỏi thường gặp",
    },
  ];

  const { questionList, isLoading } = useFrequentlyQuestions();
  const [query, setQuery] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");
  const [filterQuestion, setFilterQuestion] = useState<Question[]>([]);
  const [searchExecuted, setSearchExecuted] = useState<boolean>(false);
  // const [hovered, setHovered] = useState<boolean>(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setSearchExecuted(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (inputValue === "") {
      setInputValue("");
      setFilterQuestion(questionList);
      return;
    }
    if (e.key === "Enter") {
      setQuery(inputValue);
      const filterData = questionList.filter((item: Question) =>
        item.attributes.title.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilterQuestion(filterData);
      setSearchExecuted(true);
    }
  };
  console.log(filterQuestion);

  const renderQuestions = () => {
    const questionsToDisplay = query ? filterQuestion : questionList;
    return questionsToDisplay.map((faq: Question, index: number) => {
      const question = {
        id: faq.id,
        title: faq.attributes.title,
        content: faq.attributes.content[0]?.children[0]?.text || "",
      };
      return <QuestionPartner key={index} question={question} />;
    });
  };

  // const profileCard = () => {
  //   return (
  //     <div className="relative min-w-[300px] rounded-lg overflow-hidden shadow-lg">
  //       <img
  //         src="https://hoanghamobile.com/tin-tuc/wp-content/uploads/2024/04/anh-con-gai-1-1.jpg"
  //         alt="Profile Image"
  //         className="w-full h-auto"
  //       />
  //       <div className="absolute h-[200px] bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-gray-100/10 to-transparent backdrop-blur-md text-white">
  //         <div className="flex items-center justify-between">
  //           <h2 className="text-xl font-semibold">Võ Nhựt Thi</h2>
  //           <div
  //             className="bg-transparent p-[5px] hover:bg-white duration-300 rounded-full cursor-pointer"
  //             onMouseEnter={() => setHovered(true)}
  //             onMouseLeave={() => setHovered(false)}
  //           >
  //             {hovered ? (
  //               <img
  //                 src={svgs.chevron}
  //                 alt="icon"
  //                 className="rotate-[-90deg] size-[32px]"
  //               />
  //             ) : (
  //               <img
  //                 src={svgs.chevronWhite}
  //                 alt="icon"
  //                 className="rotate-[-90deg] size-[32px]"
  //               />
  //             )}
  //           </div>
  //         </div>
  //         <p className="text-[14px] font-medium">Founder & CEO</p>
  //         <p className="text-[14px] mt-1">
  //           Former co-founder of Opendoor. Early staff at Spotify and Clearbit.
  //         </p>
  //         <div className="flex justify-start space-x-2 mt-4">
  //           <a href="#" className="text-white hover:text-gray-400">
  //             <img src={svgs.twitter} alt="icon" className="size-[32px]" />
  //           </a>
  //           <a href="#" className="text-white hover:text-gray-400">
  //             <img src={svgs.linkedin} alt="icon" className="size-[32px]" />
  //           </a>
  //           <a href="#" className="text-white hover:text-gray-400">
  //             <img src={svgs.dribbble} alt="icon" className="size-[32px]" />
  //           </a>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />

      <section className="mx-4 mb-8 md:container md:max-w-[1280px] md:px-[120px] md:mx-auto">
        <div className="p-[48px_0] md:p-[96px_160px]">
          <div className="flex flex-col items-center justify-center gap-[32px]">
            <div className="flex flex-col items-center justify-center">
              <p className="text-[#FF9900] text-[14px] md:text-[16px] font-[600]">
                FAQs
              </p>
              <h2 className="text-[30px] md:text-[48px] font-[600] text-nowrap m-0">
                Bạn có câu hỏi?
              </h2>
            </div>
            <div className="flex items-center gap-[10px] p-[8px_16px] bg-[#F0F0F0] rounded-[16px]">
              <img src={svgs.searchIcon} alt="icon" className="size-[32px]" />
              <input
                placeholder="Tìm kiếm câu hỏi bạn cần giải đáp"
                className="bg-transparent h-full text-[14px] min-w-[250px]"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />
            </div>
            {searchExecuted && (
              <p className="text-[14px] leading-[16.94px]">
                Có {filterQuestion.length} kết quả tìm kiếm cho "{query}"
              </p>
            )}
          </div>
        </div>
      </section>
      <div className="bg-[#F0F0F0] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[16px] md:gap-[32px] p-[48px_16px] xl:p-[96px_160px]">
        {isLoading && <p>Loading...</p>}
        {!isLoading && renderQuestions()}
      </div>
      {/* <div className="md:hidden  p-[48px_8px]">
        <p className="text-[#FF9900] font-[500] text-[16px]">Cổ đông</p>
        <h2 className="text-[36px] font-bold">
          Some of people you'll be working with
        </h2>
        <p className="text-[#9999999] text-[16px]">
          We're a 100% remote team spread all across the world. Join us!
        </p>
        <button className="text-[#FF9900] text-[16px] font-[600] border-[#ccc] border-[1px] text-center w-full p-[12px_24px] rounded-[8px] mt-[24px]">
          button
        </button>
        <button className="text-white bg-black text-[16px] font-[600] border-[#ccc] border-[1px] text-center w-full p-[12px_24px] rounded-[8px] mt-[24px] hover:text-[#FF9900]">
          button
        </button>
        <div className="mt-[48px] flex items-center gap-[16px] overflow-x-auto">
          {profileCard()}
          {profileCard()}
          {profileCard()}
        </div>
      </div> */}
    </>
  );
};

export default BlogsQA;
