import urls from '@/utils/urls';
import axiosClient from './axiosClient';
import { MessageData, RoomChatResponseType, UserRoomResponseType } from "@/type/response/chat";
import { BaseResponse } from "@/type/base/baseResponse";
import { BaseData } from "@/type/base/baseData";

const chatApi = {
  getUserRoom(queryString?: string) {
    return axiosClient.get<UserRoomResponseType>(`${urls.GET_USER_ROOM}?${queryString}`)
  },

  getAllRooms() {
    return axiosClient.get<RoomChatResponseType[]>(urls.GET_ALL_ROOMS)
  },

  getMessages(queryString?: string) {
    return axiosClient.get<BaseResponse<BaseData<MessageData>[]>>(`${urls.MESSAGES}?${queryString}`)
  }
};
export default chatApi;
