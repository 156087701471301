interface IOverviewBrandProps {
  numeric: string;
  text: string;
}
function OverviewBrand({ numeric, text }: IOverviewBrandProps) {
  return (
    <div className="flex flex-col gap-[12px]">
      <h2 className="font-[600] text-[48px] md:text-[60px] m-0 text-[#FF9900]">
        {numeric}
      </h2>
      <p className="text-[18px] font-medium">{text}</p>
    </div>
  );
}

export default OverviewBrand;
