interface IIncreaseQuantity {
  active?: boolean;
}
const IncreaseQuantity = (props: IIncreaseQuantity) => {
  const { active } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="25"
      viewBox="0 0 23 25"
      fill="none"
    >
      <path
        d="M19.5 3.5H5.5C4.39543 3.5 3.5 4.39543 3.5 5.5V19.5C3.5 20.6046 4.39543 21.5 5.5 21.5H19.5C20.6046 21.5 21.5 20.6046 21.5 19.5V5.5C21.5 4.39543 20.6046 3.5 19.5 3.5Z"
        stroke={active ? "#000000" : "#B0B0B0"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 8.5V16.5"
        stroke={active ? "#000000" : "#B0B0B0"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 12.5H16.5"
        stroke={active ? "#000000" : "#B0B0B0"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IncreaseQuantity;
