import svg from "@/assets/svgs";
import Drawer from "@/components/common/Drawer";
import SearchInput from "@/components/common/SearchInput";
import searchState from "@/recoil/searchState";
import cartSelector from "@/recoil/selectors/cartSelector";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { NavList } from "./nav/NavBar";
import CartHeader from "../common/CartHeader";
import useWindowSize from "@/hooks/useWindowSize";
import routes from "@/utils/routes";
import constants from "@/utils/constants";
import NotificationHeader from "../common/NotificationHeader";
import svgs from "@/assets/svgs";
import { Drawer as DrawerAntd } from "antd";
import { useEffect, useState } from "react";
import clsx from "clsx";
import useIsMobile from "@/hooks/useIsMobile";
import searchApi from "@/api/searchApi";
import { SearchBarType } from "@/type/response/searchBar";

export default function Header() {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem(constants.ACCESS_TOKEN);
  const { width } = useWindowSize();
  const isMobile = useIsMobile();

  const [isShowSearch, setIsShowSearch] = useRecoilState(searchState);
  const [isOpenSearchDrawer, setIsOpenSearchDrawer] = useState(false);
  const [searchDefault, setSearchDefault] = useState<SearchBarType>();

  const { totalNumberProducts } = useRecoilValue(cartSelector);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!isMobile && isOpenSearchDrawer) {
      setIsShowSearch(false);
      setIsOpenSearchDrawer(false);
    }
  }, [isMobile]);

  useEffect(() => {
    const fetchData = async () => {
      await searchApi
        .get()
        .then((res) => {
          const dataSearch = res.data.data.attributes;
          setSearchDefault(dataSearch);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    fetchData();
  }, []);

  return (
    <>
      <div id="header"></div>
      <NotificationHeader
        className={clsx({ "relative z-[1001]": isOpenSearchDrawer })}
      />
      <div
        className={clsx(
          "sticky w-full flex justify-center top-[37px] bg-white z-[1001]"
        )}
      >
        <div
          className={
            "max-w-[1440px] px-[16px] md:px-[64px] lg:px-[160px] justify-center md:pb-4 w-full"
          }
        >
          <div className="header flex justify-between items-center py-[8px]">
            <div className="block md:hidden z-50 mr-7">
              <Drawer setIsOpenSearchDrawer={setIsOpenSearchDrawer} />
            </div>

            <div className="relative hidden md:block">
              {isShowSearch ? (
                <SearchInput
                  setIsShowSearch={setIsShowSearch}
                  setIsOpenSearchDrawer={setIsOpenSearchDrawer}
                  searchDefault={searchDefault}
                />
              ) : (
                <div className="mx-[16px] my-[8px] w-fit">
                  <div
                    className="w-[24px] h-[24px]"
                    onClick={() => setIsShowSearch(!isShowSearch)}
                  >
                    <img
                      src={svgs.searchIcon}
                      alt="logo"
                      className="h-full w-full object-fill cursor-pointer"
                    />
                  </div>
                </div>
              )}
            </div>

            <Link to={"/"} className="relative text-center flex-1">
              <p className="text-[#000] text-[24px] md:text-[32px] font-[600] md:font-[500] leading-[32px] md:leading-[48px] uppercase">
                CERANEE
              </p>
            </Link>

            <div className="flex items-center justify-end gap-[8px] md:gap-[24px]">
              {!isOpenSearchDrawer && (
                <div className="block md:hidden w-[24px] h-[24px] ">
                  <img
                    src={svg.searchIcon}
                    alt="search-icon"
                    className="h-full w-full"
                    onClick={() => {
                      setIsOpenSearchDrawer(true);
                      setIsShowSearch(!isShowSearch);
                    }}
                  />
                </div>
              )}

              {width >= 768 && location.pathname !== routes.CART ? (
                <>
                  <button
                    onClick={() => setOpen(!open)}
                    className="p-[5px] hover:bg-[#F0F0F0] hover:rounded-full duration-200 ease-linear w-[32px] h-[32px]"
                  >
                    <div className="relative">
                      <img
                        src={svg.cartIcon}
                        alt="cart"
                        width={23}
                        height={25}
                        style={{ color: "#000" }}
                      />

                      <div className="absolute bottom-[-3px] right-[-20%] w-[15.67px] h-[15.67px] bg-[#FF9900] text-[#FFF2D2] rounded-full md:pt-0 text-center flex justify-center items-center">
                        <div className="quantity__value text-[10px] align-center leading-[14px]">
                          {totalNumberProducts}
                        </div>
                      </div>
                    </div>
                  </button>
                  <DrawerAntd
                    onClose={() => setOpen(false)}
                    open={open}
                    width={686}
                    rootClassName="cart-header"
                    closable={false}
                  >
                    <CartHeader onClose={() => setOpen(false)} />
                  </DrawerAntd>
                </>
              ) : (
                <button
                  onClick={() => navigate(routes.CART)}
                  className="hover:bg-green-100 hover:rounded-full duration-200 ease-linear"
                >
                  <div className="w-[24px] h-[24px] relative">
                    <img
                      src={svg.cartIcon}
                      alt="cart"
                      className="h-full w-full"
                      style={{ color: "#000" }}
                    />

                    <div className="absolute bottom-[-3px] right-[-20%] w-[16px] h-[16px] bg-[#FF9900] text-[#FFF2D2] rounded-full md:pt-0 text-center flex justify-center items-center">
                      <div className="quantity__value text-[10px] align-center leading-[10px]">
                        {totalNumberProducts}
                      </div>
                    </div>
                  </div>
                </button>
              )}

              <button
                className="hidden md:flex w-[32px] h-[32px]"
                onClick={() => {
                  if (isLoggedIn)
                    navigate(`${routes.PROFILE}/${routes.PROFILE_MEMBERSHIP}`);
                  else navigate("/login");
                }}
              >
                <img
                  src={svg.userIcon}
                  alt="user icon"
                  height={32}
                  width={32}
                  className="h-full w-full"
                />
              </button>
            </div>
          </div>

          <div className="md:block">
            <div className="hidden md:block">
              <NavList />
            </div>
          </div>

          <DrawerAntd
            placement={"top"}
            closable={false}
            onClose={() => setIsOpenSearchDrawer(false)}
            open={isOpenSearchDrawer}
            rootStyle={{ top: "85px" }}
            styles={{ body: { padding: "24px 16px" } }}
          >
            <SearchInput
              inDrawer
              setIsShowSearch={setIsShowSearch}
              setIsOpenSearchDrawer={setIsOpenSearchDrawer}
              searchDefault={searchDefault}
            />
          </DrawerAntd>
        </div>
      </div>
    </>
  );
}
