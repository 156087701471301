import clsx from "clsx";
import { typeValueAddress, UserAddressType } from "@/utils/AddressType";
import { BaseData } from "@/type/base/baseData";
import BasicModal from "@/components/common/Modal";
import FormDeliveryInfo from "@/components/common/FormDeliveryInfo";
import { useState } from "react";
import useWindowSize from "@/hooks/useWindowSize";
import { selectedAddressState } from "@/recoil/userAddressState";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import routes from "@/utils/routes";

interface IDeliveryItemProps {
  item: BaseData<UserAddressType>;
  fromPage?: string;
}

const DeliveryItem = (props: IDeliveryItemProps) => {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const { item, fromPage } = props;
  const [selectedAddress, setSelectedAddress] =
    useRecoilState(selectedAddressState);
  const [openModalFormInfo, setModalFormInfo] = useState(false);
  const handleEditAddress = () => {
    if (width >= 768) {
      setModalFormInfo(true);
      return;
    }

    navigate(routes.PROVIDE_ADDRESS, {
      state: {
        typeAction: "edit",
        address: item,
        fromPage: fromPage,
      },
    });
  };

  const handleCancelFormInfo = () => {
    setModalFormInfo(false);
  };

  const handleSelectedAddress = (item: BaseData<UserAddressType>) => {
    setSelectedAddress(item);
  };

  const { provinceCity, district, ward, street } =
    item?.attributes?.address || {};
  const addressString = `${street}, ${ward?.name}, ${district?.name}, ${provinceCity?.name}`;

  const isDefault = item?.attributes?.isDefault;
  const isSelected = item.id === selectedAddress?.id;
  return (
    <div className="cursor-pointer">
      <div className="flex flex-col gap-[10px]">
        <div
          className="flex justify-start gap-[16px] cursor-pointer"
          onClick={() => handleSelectedAddress(item)}
        >
          <div className="min-w-[97px] w-[97px] md:min-w-[120px] md:w-[120px]">
            <p
              className={clsx(
                "text-[14px] md:text-[16px] font-[700] leading-[19.6px] md:leading-[22.4px]",
                isSelected ? "text-black" : "text-[#808080]"
              )}
            >
              {item.attributes.fullName}
            </p>
          </div>
          <div
            className={clsx(
              "*:text-[14px] md:*:text-[16px] *:font-[400] leading-[19.6px] md:*:leading-[22.4px]",
              isSelected ? "*:text-black" : "*:text-[#808080]"
            )}
          >
            <p>{item?.attributes?.phone}</p>
            <p className="text-justify line-clamp-2">{addressString}</p>
          </div>
        </div>
        <div className="w-full">
          <div className=" w-full flex justify-end items-center gap-[10px] md:gap-[8px]">
            {isDefault ? (
              <div className="bg-[#F0F0F0] p-[4px] rounded-[4px]">
                <p className="text-[#FF9900] text-[12px] md:text-[13px] font-[400] leading-[16.8px] md:leading-[18.2px]">
                  Mặc định
                </p>
              </div>
            ) : (
              item?.attributes?.type !== typeValueAddress[1] && (
                <div className="bg-[#F0F0F0] p-[4px] rounded-[4px]">
                  <p className="text-[#808080] text-[12px] md:text-[13px] font-[400] leading-[16.8px] md:leading-[18.2px]">
                    {item?.attributes?.type}
                  </p>
                </div>
              )
            )}

            <button
              className={clsx(
                "text-[12px] md:text-[13px] font-[400] leading-[18.2px] rounded-[4px] border border-solid p-[4px]",
                isSelected
                  ? "border-black text-black"
                  : "border-[#808080] text-[#808080]"
              )}
              onClick={handleEditAddress}
            >
              Chỉnh sửa
            </button>
          </div>
        </div>
      </div>
      {width > 768 && (
        <BasicModal
          rootClassName="formDeliveryInfo hidden md:block"
          className="md:min-w-[70%] md:w-[70%] lg:min-w-[640px] lg:w-[640px]"
          isModalOpen={openModalFormInfo}
          handleCancel={handleCancelFormInfo}
          handleOk={() => {}}
          footer={null}
          maskClosable
        >
          <FormDeliveryInfo
            handleCancel={handleCancelFormInfo}
            dataDeliveryInfo={item}
            typeAction="edit"
          />
        </BasicModal>
      )}
    </div>
  );
};

export default DeliveryItem;
