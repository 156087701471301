interface IRequiredRegisterBrandProps {
  title: string;
  content: string[];
}
function RequiredRegisterBrand({
  title,
  content,
}: IRequiredRegisterBrandProps) {
  return (
    <div className="p-[16px] md:p-[32px] border-[1px] border-[#E6E6E6] text-[#FFFFFF] rounded-[24px]">
      <p className="text-[16px] font-bold">{title}</p>
      <div className="border-[1px] border-[#E6E6E6] my-[32px]"></div>
      <ul className="flex flex-col gap-[24px]">
        {content &&
          content.length > 0 &&
          content.map((item, index) => (
            <li key={index} className="text-[16px]">
              {item}
            </li>
          ))}
      </ul>
    </div>
  );
}

export default RequiredRegisterBrand;
