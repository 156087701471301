import urls from '@/utils/urls';
import axiosClient from './axiosClient';
import { MessagesRequestType } from "@/type/request/messages";

const messagesApi = {
  post(params: MessagesRequestType) {
    return axiosClient.post(urls.MESSAGES, params);
  },
};
export default messagesApi;
