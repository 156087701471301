/* eslint-disable import/extensions */
import ProfileLayout from "@/layouts/ProfileLayout";
import RootLayout from "@/layouts/RootLayout";
import BlogDetails from "@/pages/BlogDetails";
import Blogs from "@/pages/Blogs";
import Brand from "@/pages/Brand";
import Cart from "@/pages/Cart";
import Home from "@/pages/Home";
import Login from "@/pages/Login";
import NotFound from "@/pages/NotFound";
import OrderFail from "@/pages/OrderFail";
import OrderSuccess from "@/pages/OrderSuccess";
import Partner from "@/pages/Partner";
import PartnerRegister from "@/pages/PartnerRegister";
import ProductDetail from "@/pages/ProductDetail";
import Products from "@/pages/Products";
import Membership from "@/pages/Profile/Membership";
import Register from "@/pages/Register";
import Routine from "@/pages/Routine";
import routes from "@/utils/routes";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import ForgotPassword from "@/pages/ForgotPassword";
import ResetPassword from "@/pages/ResetPassword";
import Vouchers from "@/pages/Profile/Vouchers";
import constants from "@/utils/constants";
import Support from "@/pages/Support";
import VNPayHelp from "@/pages/VNPayHelp";
import ForgotPasswordHelp from "@/pages/ForgotPasswordHelp";
import BlogsQA from "@/pages/BlogsQA";
import ReturnPolicy from "@/pages/ReturnPolicy";
import { useEffect } from "react";
import trackingApi from "@/api/trackingAPI";
import PrivacyPolicy from "@/pages/PrivacyPolicy";
import SearchResult from "@/pages/SearchResult";
import PartnerRegisterSuccess from "@/pages/PartnerRegisterSuccees";
import VerifyOTP from "@/pages/VerifyOTP";
import RoutineDetails from "@/pages/RoutineDetails";
import UpdateInfo from "@/pages/Profile/UpdateInfo";
import Chat from "@/pages/Chat";
import ChatLayout from "@/layouts/ChatLayout";
import ProvideAddress from "@/pages/ProvideAddress";
import ChangeAddress from "@/pages/ChangeAddress";
import Feedbacks from "@/pages/Feedbacks";
import Comments from "@/pages/Comments";
import VouchersCart from "@/pages/VoucherCart";
import AboutUs from "@/pages/AboutUs";
import TrackingOrder from "@/pages/Profile/TrackingOrder";
import TrackingOrderDetail from "@/pages/Profile/TrackingOrderDetail";
import Feedback from "@/pages/Profile/Feedback";
import AddressProfile from "@/pages/Profile/Address";

function RequireAuth() {
  const location = useLocation();
  const isLoggedIn = localStorage.getItem(constants.ACCESS_TOKEN);

  if (!isLoggedIn)
    return <Navigate to="/login" replace state={{ path: location.pathname }} />;

  return <Outlet />;
}

function AppRouter() {
  const location = useLocation();

  useEffect(() => {
    const trackingUsed = sessionStorage.getItem("tracking-used") || "false";
    const trackingCount = sessionStorage.getItem("tracking-count");

    const callTrackingRedirect = async () => {
      await trackingApi.track();
    };

    if (trackingUsed === "true") {
      if (trackingCount === "0") {
        callTrackingRedirect();
        sessionStorage.setItem("tracking-count", "1");
      } else {
        sessionStorage.setItem("tracking-used", "false");
      }
    } else {
      if (!trackingCount || trackingCount === "0") {
        callTrackingRedirect();
        sessionStorage.setItem("tracking-count", "1");
      }
    }
  }, [location]);

  return (
    <>
      <Routes location={location}>
        <Route path="/" element={<RootLayout />}>
          <Route path="*" element={<NotFound />} />
          <Route path={routes.VERIFY_OTP} element={<VerifyOTP />} />
          <Route path={routes.HOME} element={<Home />} />
          <Route path={routes.LOGIN} element={<Login />} />
          <Route path={routes.ABOUT_US} element={<AboutUs />} />
          <Route path={routes.REGISTER} element={<Register />} />
          <Route
            path={`${routes.ROUTINE}/:slug`}
            element={<RoutineDetails />}
          />
          <Route path={`${routes.VOUCHERSCART}`} element={<VouchersCart />} />

          <Route path={routes.PRODUCT} element={<Products />} />
          <Route path={routes.SEARCH_RESULT} element={<SearchResult />} />
          <Route path={`${routes.PRODUCT}/:slug`} element={<ProductDetail />} />
          <Route
            path={`${routes.PRODUCT}/:slug${routes.FEEDBACKS}`}
            element={<Feedbacks />}
          />
          <Route
            path={`${routes.PRODUCT}/:slug${routes.COMMENTS}`}
            element={<Comments />}
          />
          <Route path={`${routes.BLOGS}`} element={<Blogs />} />
          <Route path={`${routes.BLOGS}/:slug`} element={<BlogDetails />} />
          <Route
            path={`${routes.BLOGS}${routes.BLOGS_QA}`}
            element={<BlogsQA />}
          />
          <Route path={`${routes.ROUTINE}`} element={<Routine />} />
          <Route path={`${routes.PARTNER}`} element={<Partner />} />
          <Route
            path={`${routes.PARTNER}${routes.REGISTER}`}
            element={<PartnerRegister />}
          />
          <Route
            path={`${routes.PARTNER}${routes.REGISTER}${routes.SUCCESS}`}
            element={<PartnerRegisterSuccess />}
          />
          <Route path={`${routes.BRAND}`} element={<Brand />} />
          <Route path={`${routes.SUPPORT}`} element={<Support />} />
          <Route
            path={`${routes.SUPPORT}/${routes.RETURN_POLICY}`}
            element={<ReturnPolicy />}
          />
          <Route
            path={`${routes.SUPPORT}/${routes.PRIVACY_POLICY}`}
            element={<PrivacyPolicy />}
          />
          <Route
            path={`${routes.SUPPORT}/${routes.VNPAY_HELP}`}
            element={<VNPayHelp />}
          />

          <Route
            path={`${routes.SUPPORT}/${routes.FORGOT_PASSWORD_HELP}`}
            element={<ForgotPasswordHelp />}
          />
          <Route path={`${routes.CART}`} element={<Cart />} />
          <Route path={routes.FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={routes.RESET_PASSWORD} element={<ResetPassword />} />
          <Route path="/" element={<RequireAuth />}>
            <Route
              path={`${routes.ORDER_SUCCESS}/:id`}
              element={<OrderSuccess />}
            />
            <Route path={`${routes.ORDER_FAIL}/:id`} element={<OrderFail />} />
            <Route path={routes.PROFILE} element={<ProfileLayout />}>
              <Route
                path={routes.PROFILE_MEMBERSHIP}
                element={<Membership />}
              />
              <Route path={routes.VOUCHERS} element={<Vouchers />} />
              <Route path={routes.PROFILE_INFO} element={<UpdateInfo />} />
              <Route
                path={`${routes.ORDERS}/:slugStatus`}
                element={<TrackingOrder />}
              />
              <Route path={`${routes.ORDERS}`} element={<TrackingOrder />} />
              <Route
                path={routes.ADDRESS_PROFILE}
                element={<AddressProfile />}
              />
            </Route>
            <Route
              path={`${routes.ORDER_DETAIL}/:orderCode`}
              element={<TrackingOrderDetail />}
            />
            <Route
              path={`${routes.FEEDBACK_ORDER}/:orderCode`}
              element={<Feedback />}
            />
          </Route>
          <Route path={routes.PROVIDE_ADDRESS} element={<ProvideAddress />} />
          <Route path={routes.CHANGE_ADDRESS} element={<ChangeAddress />} />
        </Route>
        <Route path="/" element={<ChatLayout />}>
          <Route path="/" element={<RequireAuth />}>
            <Route path={routes.CHAT} element={<Chat />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default AppRouter;
