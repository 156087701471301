import images from "@/assets/images";
import Breadcrumb from "@/components/common/Breadcrumb";
import SupportImage from "@/components/common/Support/SupportImage";
import SupportText from "@/components/common/Support/SupportText";
import routes from "@/utils/routes";

const VNPayHelp = () => {
  const breadcrumbItems = [
    {
      href: routes.HOME,
      title: "Trang chủ",
    },
    {
      href: routes.VNPAY_HELP,
      title: "Hướng dẫn thanh toán VNPAY-QR",
    },
  ];

  const dataFirstText = [
    {
      content:
        "Nhanh chóng, tiện lợi, miễn phí giao dịch và nhiều ưu đãi độc quyền là những ưu điểm của hình thức thanh toán VNPAY-QR trên ví điện tử VNPAY.",
    },
    {
      content:
        "Chắc hẳn nhiều người đã biết về ví điện tử VNPAY là gì rồi, đây là một ứng dụng thanh toán trực tuyến, được phát triển bởi Công ty Cổ phần giải pháp thanh toán Việt Nam (VNPAY). Ví VNPAY cho phép người dùng thanh toán các giao dịch trực tuyến, mua sắm online, thanh toán hóa đơn, nạp thẻ điện thoại,... một cách nhanh chóng, tiện lợi.",
    },
    {
      content:
        "Hơn thế nữa, ví điện tử VNPAY hiện nay còn tích hợp thêm tính năng Thanh toán VNPAY-QR cực tiện lợi. Để hiểu rõ hơn về tính năng Thanh toán VNPAY-QR, chúng ta hãy cùng tìm hiểu một số ưu điểm nổi bật của tính năng này nhé.",
    },
  ];
  const dataWhatVNPY = [
    {
      content:
        "Khách hàng có thể quét mã VNPAY-QR trên ví VNPAY để thanh toán online trên website của các cửa hàng hoặc thanh toán trực tiếp tại hơn 200.000 điểm chấp nhận thanh toán trên toàn quốc. Mã VNPAY-QR cũng đã được hầu hết các cửa hàng trong nhiều lĩnh vực đa dạng sử dụng như giải trí, ẩm thực, thời trang, vận tải, du lịch, giáo dục, y tế, dịch vụ công… giúp người dùng dễ dàng tiếp cận và sử dụng.",
    },

    {
      content:
        "Bên cạnh những ưu điểm nổi trội như thanh toán nhanh, gọn, tiện lợi, tiết kiệm, an toàn, bảo mật thì thanh toán VNPAY-QR trên ví điện tử VNPAY còn thường xuyên nhận được ưu đãi. Mỗi ngày, hàng ngàn mã giảm giá VNPAY-QR độc quyền đang chờ đợi các khách hàng.",
    },
  ];

  const dataPaymentGuide = [
    {
      content: "Bước 1: Đăng nhập ví điện tử VNPAY. Chọn VNPAY-Q",
    },
    {
      content: "Bước 2: Quét VNPAY-QR để thanh toán",
    },
    {
      content:
        "Bước 3: Nhập thông tin, mã khuyến mại (nếu có), xác minh giao dịch và thanh toán",
    },
  ];

  const dataQuestion = [
    {
      content: {
        question:
          "Có thể thanh toán VNPAY-QR trên ví VNPAY tại những địa điểm nào?",
        answer:
          "Bạn có thể quét mã VNPAY-QR bằng ví VNPAY để thanh toán trực tuyến trên các website hoặc trực tiếp tại hơn 200.000 điểm chấp nhận thanh toán trên toàn quốc.",
      },
    },
    {
      content: {
        question: "Thanh toán VNPAY-QR trên ví VNPAY có bị tính phí không?",
        answer:
          "Các giao dịch thanh toán VNPAY-QR hoàn toàn không mất phí giao dịch.",
      },
    },
    {
      content: {
        question:
          "Làm thế nào để biết được các chương trình khuyến mại trên ví VNPAY?",
        answer:
          "Tại bước “Xác nhận giao dịch”, các mã giảm giá có thể áp dụng được luôn được Ví VNPAY cập nhật tại đây, khách hàng dễ dàng chọn mã và tận hưởng ưu đãi khi thanh toán hóa đơn. Ngoài ra, tại mục “Khuyến mại” thuộc phần “Thông báo”, các chương trình khuyến mại đang diễn ra trên Ví VNPAY sẽ thường xuyên được gửi tới khách hàng.",
      },
    },
    {
      content: {
        question:
          "Làm cách nào để kiểm tra hóa đơn đã được thanh toán thành công chưa?",
        answer:
          "Với các giao dịch đã được thực hiện trên Ví VNPAY: Hóa đơn sẽ được lưu tại mục “Giao dịch” thuộc phần “Thông báo” trên màn hình chính của Ví VNPAY.",
      },
    },
  ];

  const dataContact = [
    {
      content: "Hotline CSKH: 1900 5555 77 (8h - 22h từ thứ 2 đến chủ nhật)",
    },
    {
      content: "Email: hotrovnpay@vnpay.vn",
    },
  ];

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />

      <div className="wrapper my-[48px] md:my-[96px] md:px-[120px] md:container md:max-w-[1280px] md:mx-auto">
        <div className="w-full">
          <SupportText type="header" content="Hướng dẫn thanh toán vnpay-qr" />
          <div className="support-content mx-4 lg:mx-0">
            <SupportImage
              src={images.vnpayThumb1}
              className="w-full h-full md:w-[700px] md:h-[327px]"
            />
            <div className="flex flex-col gap-[8px]">
              {dataFirstText.map((item) => (
                <SupportText content={item.content} />
              ))}
            </div>

            <SupportText type="title" content="Thanh toán VNPAY-QR là gì?" />
            <SupportText
              content="VNPAY-QR là giải pháp thanh toán cho phép khách hàng sử dụng tính năng VNPAY-QR được tích hợp sẵn 
            trên ứng dụng ví điện tử VNPAY để quét mã QR thanh toán các hóa đơn dịch vụ. Với tính năng VNPAY-QR, khách hàng 
            có thể thanh toán dễ dàng, tiện lợi, mà không cần phải mang theo tiền mặt."
            />
            <SupportImage src={images.vnpayThumb2} />

            <SupportText
              type="title"
              content="Ưu điểm khi trải nghiệm thanh toán VNPAY-QR trên ví VNPAY"
            />

            <div className="flex flex-col gap-[8px]">
              {dataWhatVNPY.map((item) => (
                <SupportText content={item.content} />
              ))}
            </div>
            <SupportText
              type="title"
              content="Hướng dẫn thanh toán VNPAY-QR trên ví VNPAY với 3 bước đơn giản:"
            />
            <ul className="custom-list-item flex flex-col gap-[8px] ml-0 md:ml-[10px]">
              {dataPaymentGuide.map((item, index) => (
                <SupportText key={index} type="step" content={item.content} />
              ))}
            </ul>

            <SupportText
              type="title"
              content="Những câu hỏi thường gặp khi thanh toán VNPAY-QR trên Ví VNPAY:"
            />

            <ul className="custom-list-item flex flex-col gap-[8px] ml-0 md:ml-[10px]">
              {dataQuestion.map((item, index) => (
                <SupportText
                  key={index}
                  type="step"
                  content={{
                    answer: item.content.answer,
                    question: item.content.question,
                  }}
                />
              ))}
            </ul>

            <p className="font-[400] text-[#484848] text-[13px] lg:text-[16px] my-6 leading-[26.6px]">
              Nhanh tay tải ngay ứng dụng ví VNPAY trên{" "}
              <span className="font-[400] text-[#484848] text-[13px] lg:text-[16px] underline leading-[26.6px]">
                AppStore
              </span>{" "}
              hoặc{" "}
              <span className="font-[400] text-[#484848] text-[13px] lg:text-[16px] underline leading-[26.6px]">
                GooglePlay
              </span>
            </p>

            <p className="font-[400] text-[#484848] text-[13px] lg:text-[16px] mb-[4px]">
              Để được giải đáp thắc mắc, khách hàng vui lòng liên hệ:
            </p>
            <ul className="custom-list-item flex flex-col gap-[8px] ml-0 md:ml-[10px]">
              {dataContact.map((item, index) => (
                <SupportText key={index} content={item.content} type="step" />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default VNPayHelp;
