import urls from "@/utils/urls";
import axiosClient from "./axiosClient";
import { BaseResponse } from "@/type/base/baseResponse";
import { BaseData } from "@/type/base/baseData";
import { BlogType } from "@/type/response/blog";

const authApi = {
  login(params: LoginPayload) {
    return axiosClient.post(urls.LOCAL, params);
  },
  register(params: any) {
    return axiosClient.post(urls.REGISTER, params);
  },
  getUser() {
    return axiosClient.get<BaseResponse<BaseData<BlogType>[]>>(
      `${urls.USER}?populate=*`
    );
  },
  updateUser(params: any) {
    return axiosClient.put(urls.UPDATE_USER, params);
  },
  forgotPassword(params: any) {
    return axiosClient.post(urls.FORGOT_PASSWORD, params);
  },
  resetPassword(params: any) {
    return axiosClient.post(urls.RESET_PASSWORD, params);
  },
};
export default authApi;

export type LoginPayload = {
  identifier: string;
  password: string;
};
