import orderApi from "@/api/orderApi";
import { ICartProduct, ICartProductFee } from "@/recoil/cartState";
import { FeeOrderRequest } from "@/type/request/order";
import constants from "@/utils/constants";
import { formatCurrency } from "@/utils/data";
import routes from "@/utils/routes";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { IVoucherState } from "@/recoil/selectedVoucherState";
import { ConfigProvider, Modal } from "antd";
import { useQueryUserVouchers } from "@/hooks/ReactQuery/profile.query";
import React from "react";
import VoucherPopoverContent from "./VoucherPopoverContent";
import { BaseData } from "@/type/base/baseData";
import { UserVouchers } from "@/type/response/profile";
import toast from "react-hot-toast";
import useWindowSize from "@/hooks/useWindowSize";
import BillText, { BillTextType } from "@/components/common/BillText";
import cartSelector from "@/recoil/selectors/cartSelector";
import { selectedAddressState } from "@/recoil/userAddressState";
import { shippingDataState } from "@/recoil/shippingDataStae";
import selectedVouchersState from "@/recoil/selectedVoucherState";

export interface TotalCostProps {
  totalPrice: number;
  thrifty?: number;
  valueRadio?: number;
  shippingMethod?: number;
  cartInfo?: ICartProduct[];
  changeLoading?: (value: boolean) => void;
  isDataDeliveryInformationFull?: boolean;
  onSubmit: () => Promise<void>;
  isOutOfStock: boolean;
}

export const initVoucherState = {
  voucherId: -1,
  amountDecrease: "",
  code: "",
  percentDecrease: "",
  status: "",
  title: "",
  minimumOrderAmount: "",
};

export const TotalCost = React.memo(
  ({
    totalPrice,
    thrifty = 0,
    valueRadio,
    shippingMethod,
    cartInfo,
    isDataDeliveryInformationFull,
    isOutOfStock,
    onSubmit,
  }: TotalCostProps) => {
    const selectedAddress = useRecoilValue(selectedAddressState);
    const [shippingData, setShippingData] = useRecoilState(shippingDataState);
    const [selectedVoucher, setSelectedVoucher] = useRecoilState<
      IVoucherState[]
    >(selectedVouchersState);
    const [isShowBtn, setIsShowBtn] = useState<boolean>(true);
    // const [isShowVoucher, setIsShowVoucher] = useState<boolean>(false);
    const [voucher, setVoucher] = useState<IVoucherState[]>([]);

    const [isShowTextVoucher, setIsShowTextVoucher] = useState<boolean>(false);
    const checkAccessLogin = localStorage.getItem(constants.ACCESS_TOKEN);
    const [deliveryFee, setDeliveryFee] = useState<number>(0);
    const { allVouchers } = useQueryUserVouchers();
    const myVoucherList = allVouchers.filter(
      (item) => item?.attributes?.status === "UNUSED"
    );
    const { totalNumberProducts } = useRecoilValue(cartSelector);

    const size = useWindowSize();

    const navigate = useNavigate();
    const districtId = Number(
      selectedAddress?.attributes?.address?.district?.id
    );
    const wardCode =
      selectedAddress?.attributes?.address?.ward?.code?.toString();
    useEffect(() => {
      const FetchFeeDelivery = async () => {
        let formatArrayFee: FeeOrderRequest[] = [];
        cartInfo?.forEach((element: ICartProductFee) => {
          formatArrayFee.push({
            product_id: element.id,
            quantity: element.quantity,
          });
        });
        if (districtId && wardCode) {
          const dataFee = {
            shipping_method: "Giao hàng nhanh",
            items: formatArrayFee,
            shipping_data: {
              to_district_id: districtId,
              to_ward_code: wardCode,
            },
          };
          await orderApi.getFee(dataFee).then((res) => {
            if (res) {
              setShippingData(res?.data);
            }
          });
        }
      };
      FetchFeeDelivery();
      if (shippingData?.fee) {
        setDeliveryFee(valueRadio === 0 ? 0 : shippingData?.fee);
      }
    }, [valueRadio, shippingMethod, totalPrice, districtId, wardCode]);
    useEffect(() => {
      if (
        checkAccessLogin &&
        valueRadio !== undefined &&
        valueRadio >= 0 &&
        shippingMethod !== undefined &&
        shippingMethod >= 0 &&
        isDataDeliveryInformationFull
      ) {
        setIsShowBtn(false);
      }
    }, [checkAccessLogin, valueRadio, shippingMethod, deliveryFee]);

    const savedByVoucher = voucher.reduce((acc, cur) => {
      const discount = cur.percentDecrease
        ? (totalPrice * Number(cur.percentDecrease)) / 100
        : cur.amountDecrease
          ? Number(cur.amountDecrease)
          : 0;

      return acc + discount;
    }, 0);

    console.log("savedByVoucher", savedByVoucher);

    const showedTotalPrice = totalPrice - savedByVoucher + deliveryFee;

    const onChangeVoucher = (selected: BaseData<UserVouchers> | null) => {
      if (selected === null) {
        return;
      }

      const {
        amountDecrease,
        code,
        percentDecrease,
        status,
        title,
        minimumOrderAmount,
        condition,
      } = selected.attributes.voucher.data.attributes;

      const newVoucher: IVoucherState = {
        voucherId: selected.id,
        amountDecrease: amountDecrease,
        code: code,
        percentDecrease: percentDecrease,
        status: status,
        title: title,
        minimumOrderAmount: minimumOrderAmount,
        condition: condition,
      };

      setIsShowTextVoucher(true);

      setVoucher((prev) => {
        const hasOrVoucher = prev.some((voucher) => voucher.condition === "OR");

        if (condition === "OR") {
          return [newVoucher];
        }

        if (hasOrVoucher && condition === "AND") {
          return [newVoucher];
        }

        const isVoucherExists = prev.some(
          (voucher) => voucher.voucherId === newVoucher.voucherId
        );

        if (isVoucherExists) {
          return prev.filter(
            (voucher) => voucher.voucherId !== newVoucher.voucherId
          );
        }

        return [...prev, newVoucher];
      });
    };

    const handleCancleVoucher = () => {
      setVoucher([]);
    };
    const submitVoucher = () => {
      setSelectedVoucher(voucher);
      handleOk();
      toast.success("Đã áp dụng voucher");
    };
    const totalAmountDecrease = selectedVoucher?.reduce((acc, item) => {
      const amount = item?.amountDecrease ? Number(item.amountDecrease) : 0;
      return acc + amount;
    }, 0);
    const billTexts = [
      {
        name: "Tổng cộng",
        value: `${totalNumberProducts} sản phẩm`,
        type: "normal",
      },
      {
        name: "Tạm tính",
        value: `${formatCurrency(showedTotalPrice.toString() || "0")}đ`,
        type: "normal",
      },
      {
        name: "Giảm giá",
        value: `${formatCurrency(thrifty?.toString() || "0")}đ`,
        type: "discount",
      },
      {
        name: "Voucher thành viên",
        value: `${formatCurrency(totalAmountDecrease.toString()) || "0"}đ`,
        type: "discount",
      },
      {
        name: "Phí vận chuyển",
        value: `${formatCurrency(deliveryFee.toString())}đ`,
        type: "normal",
      },
    ];

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
      setIsModalOpen(true);
    };

    const handleOk = () => {
      setIsModalOpen(false);
    };

    const handleCancel = () => {
      setIsModalOpen(false);
    };

    return (
      <>
        <div className="md:text-[16px]">
          <div className="flex justify-between md:gap-2 md:mt-3">
            <div className="flex items-center md:items-start md:flex-col">
              <ConfigProvider
                theme={{
                  token: {
                    boxShadowSecondary: "",
                    borderRadiusLG: 0,
                    borderRadiusXS: 0,
                  },
                }}
              >
                <div className="flex flex-col gap-[8px]">
                  <div className="flex items-center gap-4">
                    <div
                      className="font-normal text-[14px] md:text-[16px] cursor-pointer underline"
                      onClick={() => {
                        if (size.width < 768)
                          navigate(`/${routes.VOUCHERSCART}`, {
                            state: { totalCost: totalPrice },
                          });
                        else {
                          showModal();
                        }
                      }}
                    >
                      Chọn voucher
                    </div>
                  </div>
                  {selectedVoucher && (
                    <p className="text-[14px] font-[600] leading-[16.94px] text-[#FF9900]">
                      {selectedVoucher?.map((item) => item?.code).join(" , ")}
                    </p>
                  )}
                </div>
                <Modal
                  open={isModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  footer={null}
                  styles={{
                    content: {
                      borderRadius: 24,
                      padding: 0,
                    },
                  }}
                  centered
                  closable={false}
                >
                  <div className="flex items-center justify-center">
                    <VoucherPopoverContent
                      onChangeVoucher={onChangeVoucher}
                      voucher={voucher}
                      myVoucherList={myVoucherList}
                      isShowTextVoucher={isShowTextVoucher}
                      submitVoucher={submitVoucher}
                      totalPrice={totalPrice}
                      closePopUp={handleOk}
                      cancleVouchers={handleCancleVoucher}
                    />
                  </div>
                </Modal>
              </ConfigProvider>
            </div>
          </div>
          <div className="flex flex-col gap-[16px] mt-[16px]">
            {billTexts.map((item, index) => (
              <BillText
                nameItem={item?.name}
                valueItem={item?.value}
                type={item?.type as BillTextType}
                key={index}
              />
            ))}
          </div>
          <div className="mt-[32px] md:flex flex-col gap-[16px] hidden">
            <div className="flex items-center justify-between">
              <h3 className="m-0 font-[600] text-[16px] leading-[19.36px] text-[#FF9900]">
                Tổng thanh toán:
              </h3>
              <h3 className="m-0 font-[600] text-[16px] leading-[19.36px] text-[#FF9900]">
                {formatCurrency(
                  (showedTotalPrice - totalAmountDecrease).toString() || "0"
                )}
                đ
              </h3>
            </div>
            <button
              className={`p-[12px_20px] rounded-[24px]  text-[14px] font-[600] leading-[16.94px] duration-300 ${isOutOfStock && !isShowBtn
                ? "bg-[#F0F0F0] text-[#808080] cursor-not-allowed"
                : "bg-black text-white hover:text-[#FF9900]"
                }`}
              type="submit"
              onClick={onSubmit}
              disabled={isOutOfStock}
            >
              Đặt hàng
            </button>
          </div>
        </div>
      </>
    );
  }
);
