import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import PartnerSkeleton from "@/components/skeleton/common/partner";
import useHomeQueryData from "@/hooks/ReactQuery/home.query";
import { useEffect, useState } from "react";

function DistributorBrand() {
  const { partners, isLoading } = useHomeQueryData();
  const [swiperKey, setSwiperKey] = useState(0);

  const duplicatepartners = () => {
    let showedPartners = [...partners];
    while (showedPartners.length < 10) {
      showedPartners = [...showedPartners, ...partners];
    }

    return showedPartners;
  };

  useEffect(() => {
    setSwiperKey((prevKey) => prevKey + 1);
  }, []);
  return (
    <div className="flex flex-col items-center justify-center p-[40px_16px] md:p-[96px_16px] md:gap-[64px] gap-[32px]">
      <div className="flex flex-col items-center justify-center">
        <h2 className="m-0 text-[16px] font-medium md:text-[48px] md:font-[600]">
          Nhà phân phối bền vững
        </h2>
      </div>
      <div className="w-full md:max-w-[70%]">
        <Swiper
          key={swiperKey}
          // slidesPerView={"auto"}

          loop={true}
          autoplay={{
            delay: 0,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          breakpoints={{
            "375": {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            "768": {
              slidesPerView: 2,
              // slidesPerGroup: 2,
              spaceBetween: 20,
            },
            "1024": {
              slidesPerView: 2,
              // slidesPerGroup: 3,
              spaceBetween: 100,
            },
          }}
          speed={5000}
          loopAddBlankSlides={true}
          loopPreventsSliding={true}
          className="swiper-partner"
        >
          {isLoading
            ? Array.from({ length: 10 }).map((_, index) => {
                return (
                  <SwiperSlide key={index}>
                    <PartnerSkeleton />
                  </SwiperSlide>
                );
              })
            : duplicatepartners().map((partner, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="m-4 flex flex-col lg:flex-row justify-center items-center gap-[8px] md:gap-[32px]">
                      <figure className="min-w-[80px] md:min-w-[120px] md:h-[120px] mb-4">
                        <img
                          src={partner?.avatar?.data?.attributes?.url}
                          alt={partner.name}
                          className="rounded-full w-full h-full my-2 object-cover"
                          loading="lazy"
                        />
                      </figure>
                      <div className="text-[14px] lg:text-[40px] font-[500] text-[#484848]">
                        {partner.name}
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
        </Swiper>
      </div>
    </div>
  );
}

export default DistributorBrand;
