import { FieldError, RegisterOptions } from "react-hook-form";
import styled from "styled-components";

export interface ISelectFieldProps {
  name: string;
  label?: string;
  register: any;
  errors?: FieldError;
  validateSchema?: RegisterOptions;
  options: any[];
  key?: string;
  hasValue?: boolean;
}

const SelectStyled = styled.select`
  border: 0;
  &:hover,
  &:focus {
    border: 0;
    box-shadow: none !important;
  }
`;

export default function SelectField({
  name,
  label,
  register,
  errors,
  validateSchema,
  options,
  key,
  hasValue,
}: ISelectFieldProps) {
  return (
    <>
      <div className="flex flex-col gap-1">
        <SelectStyled
          key={key}
          name={name}
          id={name}
          {...register(name, validateSchema)}
          className="w-full h-full text-[#484848] font-normal text-[13px] md:text-[16px] border-none p-4"
        >
          <option value={hasValue ? label : ""}>{label}</option>
          {options &&
            options?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
        </SelectStyled>
        {errors && <p className="text-[13px] text-red-500">{errors.message}</p>}
      </div>
    </>
  );
}
