const constants = {
  ACCESS_TOKEN: "access_token",
  CART: "cart",
  PROFILE: "profile",
  DATE_FORMAT_DDMM: "DD/MM",
  DATE_FORMAT_ddddDDMMyyyy: "dddd DD/MM/YYYY",
  DATE_FORMAT_ddMMyyyy: "DD/MM/YYYY",
  DATE_FORMAT_MMMMDDYYYY: "MM/DD/YYYY",
  DATE_FORMAT_yyyyMMdd: "YYYY-MM-DD",
  DATE_FORMAT_yyyyMMddHHmmss: "YYYY-MM-DD HH:mm:ss",
  DATE_FORMAT_yyyyMMddTHHmmss: "YYYY-MM-DDTHH:mm:ss",
  DATE_FORMAT_DDMMYYYYHHmm: "DD/MM/YYYY HH:mm",
  DATE_FORMAT_MMyyyy: "MM/YYYY",
  DATE_FORMAT_MMMM: "MMMM",
  DATE_FORMAT_yyyy: "YYYY",
  DATE_FORMAT_HHmm: "HH:mm",
  DATE_FORMAT_HHmmss: "HH:mm:ss",
  PAGE_SIZE_BLOGS: 5,
  MAX_SIZE_PRODUCT_WATCHED: 5,
  FOCUS_SEARCH: "focus_search",
  VIEWED_PRODUCTS: "viewed_products",
  ROOM_UUID: "room_uuid",
  ROOM_ID: "room_id",
  FIRST_LOAD: "first_load",
  PENDING_CONFIRMATION: "Chờ xác nhận",
  CONFIRMED: "Xác nhận",
  PROCESSING: "Đang xử lý",
  SHIPPING: "Đang giao hàng",
  SHIPPING_DELAYED: "Delay giao hàng",
  COMPLETED: "Đã hoàn thành",
  REFUNDED: "Đã hoàn tiền",
  RETURNED: "Đã hoàn trả",
  DONE: "Đã xong",
  CANCELED: "Huỷ",
  HISTORY: "Lịch sử",
};
export default constants;
