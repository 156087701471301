import { useEffect } from 'react';
import { useLocation } from 'react-router';

export default function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.getElementById('header')?.scrollIntoView()
  }, [pathname]);

  return null;
}
