import authApi from "@/api/authApi";
import uploadApi from "@/api/uploadApi";
import svgs from "@/assets/svgs";
import NewInputField from "@/components/common/Formfield/NewInputField";
import NewSelectField from "@/components/common/Formfield/NewSelectField";
import constants from "@/utils/constants";
import { formValidation } from "@/utils/formValidation";
import {
  generateDays,
  generateMonths,
  generateYears,
} from "@/utils/generateDateList";
import { parseBirthDate } from "@/utils/parseBirthday";
import routes from "@/utils/routes";
import { Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
interface IFormValues {
  fullName: string;
  phone: string;
  birthday: string;
  email: string;
  city: string;
  ward: string;
  street: string;
  district: string;
  houseNumber: number | null;
  yearOfBirth: string;
  monthOfBirth: string;
  dayOfBirth: string;
}

function UpdateInfo() {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  // const [listCity, setListCity] = React.useState<any>([]);
  const dataProfile = localStorage.getItem(constants.PROFILE);
  const profile = dataProfile ? JSON.parse(dataProfile) : "";
  const getBirthday = parseBirthDate(profile?.birthday);

  const monthOfBirth = getBirthday?.month;
  const yearOfBirth = getBirthday?.year;
  const dayOfBirth = getBirthday?.day;
  const yearData = generateYears(1920, new Date().getFullYear());
  const monthData = generateMonths();
  const [dayData, setDayData] = useState<string[]>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [avartUrl, setAvatarUrl] = useState<string>("");

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<IFormValues>({
    defaultValues: yearOfBirth
      ? {
        fullName: profile?.fullName || "",
        email: profile?.email || "",
        birthday: profile?.birthday || "",
        yearOfBirth: yearOfBirth || "",
        monthOfBirth: monthOfBirth || "",
        dayOfBirth: dayOfBirth || "",
        phone: profile?.phone || "",
        city: profile?.mainAddress?.provinceCity || "",
        district: profile?.mainAddress?.district || "",
        ward: profile?.mainAddress?.ward || "",
        street: profile?.mainAddress?.street || "",
        houseNumber: profile?.mainAddress?.houseNumber || null,
      }
      : {
        fullName: profile?.fullName || "",
        email: profile?.email || "",
        birthday: profile?.birthday || "",
        phone: profile?.phone || "",
        city: profile?.mainAddress?.provinceCity || "",
        district: profile?.mainAddress?.district || "",
        ward: profile?.mainAddress?.ward || "",
        street: profile?.mainAddress?.street || "",
        houseNumber: profile?.mainAddress?.houseNumber || null,
      },
    mode: "onChange",
  });
  const year = watch("yearOfBirth");
  const month = watch("monthOfBirth");
  useEffect(() => {
    if (year && month) {
      setDayData(generateDays(Number(year), Number(month)));
    }
  }, [year, month]);

  // const [city, district] = watch(["city", "district", "ward"]);
  // const _distric = listCity?.filter((n: any) => n?.Name === city) as any[];
  // const _ward = _distric?.[0]?.Districts?.filter(
  //   (n: any) => n.Name === district
  // );
  const onSubmit = async (data: IFormValues) => {
    const registerData = {
      full_name: data?.fullName || "",
      email: data?.email || "",
      birthday:
        `${data?.yearOfBirth}-${data?.monthOfBirth}-${data.dayOfBirth}` || "",
      phone: data?.phone || "",
      main_address: {
        province_city: data?.city || "",
        street: data?.street || "",
        ward: data?.ward || "",
        district: data?.district || "",
        house_number: data?.houseNumber || null,
      },
    };

    try {
      await authApi.updateUser(registerData).then((res) => {
        if (profile) {
          localStorage.setItem(constants.PROFILE, JSON.stringify(res?.data));
          toast.success(t("Cập nhật thành công"));
          setIsEdit(false);
          setTimeout(() => {
            localStorage.getItem("redirect") && navigate(routes.CART);
            localStorage.removeItem("redirect");
          }, 1000);
        } else {
          toast.success(t("Bạn vui lòng đăng nhập vào tài khoản"));
        }
      });
    } catch (error) {
      console.error("error", error);
      toast.error("cập nhật không thành công");
    }
  };

  useEffect(() => {
    fetch(
      "https://raw.githubusercontent.com/kenzouno1/DiaGioiHanhChinhVN/master/data.json"
    )
      .then((response) => response.json())
    // .then((data) => {
    //   setListCity(data);
    // });
  }, [isEdit]);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    if (fileInputRef.current?.files && fileInputRef.current.files.length > 0) {
      const file = fileInputRef.current.files[0];

      try {
        const response = await uploadApi.uploadFile(file);
        if (response.status === 200 && response.data) {
          setAvatarUrl(response.data[0]?.url);
          const newData = {
            avatar: { id: response?.data[0]?.id },
          };

          try {
            const res = await authApi.updateUser(newData);
            if (res) {
              toast.success("Đặt lại ảnh đại diện thành công");
            }
          } catch (updateError) {
            console.error("Update user failed:", updateError);
            toast.error("Không thể cập nhật ảnh đại diện");
          }
        } else {
          toast.error("Tải lên không thành công");
        }
      } catch (uploadError) {
        console.error("Upload failed:", uploadError);
        toast.error("Không thể tải lên ảnh");
      } finally {
        setIsModalOpen(false);
        setImageUrl(null);
      }
    } else {
      toast.error("Vui lòng chọn một tệp tin");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setImageUrl(null);
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const url = URL.createObjectURL(file);
      setImageUrl(url);
      showModal();
    }
  };
  return (
    <>
      <div className="p-[24px_16px] md:p-[24px_0_24px_24px]">
        <div className="flex items-center justify-center mt-[24px] mb-[32px] md:hidden">
          <div
            className={`size-[100px] bg-[#f7f7f7] rounded-full ${profile?.avatar ? "" : "pt-[15px]"
              } overflow-hidden relative`}
          >
            <img
              src={avartUrl || profile?.avatar?.url || svgs.avatarDefault}
              className={`w-full h-full rounded-full ${avartUrl || profile?.avatar ? "object-cover" : ""
                }`}
            />
            <div className="relative">
              <div
                className="absolute bottom-[0] left-[0] cursor-pointer bg-gradient-to-b from-[#99999900] to-[#00000080] p-[8px_32px_0_32px] w-full flex items-center justify-center h-[40px]"
                onClick={handleClick}
              >
                <span className="text-[14px] font-medium leading-[16px] text-white ">
                  Sửa
                </span>
              </div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-[24px] mt-[12px]">
            <h3 className="md:font-medium text-[16px] font-[600] md:text-[20px] leading-[24.2px] m-0">
              Thông tin cá nhân
            </h3>
            <NewInputField
              control={control}
              name="fullName"
              placeholder="Nhập họ và tên"
              disabled={!isEdit}
              errors={errors.fullName}
              rules={formValidation.fullName}
            />
            <NewInputField
              control={control}
              name="phone"
              placeholder="Nhập số điện thoại"
              disabled={!isEdit}
              errors={errors.phone}
              rules={formValidation.phone}
            />
            <NewInputField
              control={control}
              name="email"
              placeholder="Nhập email"
              disabled={!isEdit}
              errors={errors.email}
              rules={formValidation.email}
            />
            {!isEdit ? (
              <div className="grid grid-cols-3 gap-[16px]">
                <NewInputField
                  control={control}
                  name="yearOfBirth"
                  placeholder="Năm sinh"
                  disabled={!isEdit}
                />
                <NewInputField
                  control={control}
                  name="monthOfBirth"
                  placeholder="Tháng sinh"
                  disabled={!isEdit}
                />
                <NewInputField
                  control={control}
                  name="dayOfBirth"
                  placeholder="Ngày sinh"
                  disabled={!isEdit}
                />
              </div>
            ) : (
              <div className="grid grid-cols-3 gap-[16px]">
                <NewSelectField
                  containerClassName="flex-1"
                  control={control}
                  name="yearOfBirth"
                  placeholder="Năm"
                  options={yearData
                    .map((item) => ({
                      value: item,
                      label: item,
                    }))
                    .reverse()}
                  rules={formValidation.yearOfBirth}
                />

                <NewSelectField
                  containerClassName="flex-1"
                  control={control}
                  name="monthOfBirth"
                  placeholder="Tháng"
                  options={monthData.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  rules={formValidation.monthOfBirth}
                />
                <NewSelectField
                  containerClassName="flex-1"
                  control={control}
                  name="dayOfBirth"
                  options={dayData?.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  placeholder="Ngày"
                  rules={formValidation.dayOfBirth}
                />
              </div>
            )}
          </div>
          {/* <div className="flex flex-col gap-[24px]">
            <h3 className="font-[600] md:font-medium text-[16px] md:text-[20px] leading-[24.2px] m-0 mt-[48px]">
              Thông tin giao hàng
            </h3>
            {isEdit ? (
              <div className="grid grid-cols-2 md:grid-cols-3 gap-[16px]">
                <NewSelectField
                  containerClassName="flex-1"
                  control={control}
                  name="city"
                  placeholder="Tỉnh, thành phố"
                  errors={errors.city}
                  options={(() => {
                    const outputValue: { value: number; label: number }[] = [];
                    listCity.forEach((element: any) => {
                      outputValue.push({
                        value: element?.Name,
                        label: element?.Name,
                      });
                    });

                    return outputValue;
                  })()}
                  rules={formValidation.city}
                />
                <NewSelectField
                  containerClassName="flex-1"
                  control={control}
                  name="district"
                  errors={errors.district}
                  placeholder="Quận, Huyện"
                  options={(() => {
                    const outputValue: { value: number; label: number }[] = [];
                    const listDistrict = listCity?.filter(
                      (n: any) => n.Name === city
                    ) as any[];
                    listDistrict?.[0]?.Districts?.forEach((element: any) => {
                      outputValue.push({
                        value: element.Name,
                        label: element.Name,
                      });
                    });

                    return outputValue;
                  })()}
                  rules={formValidation.district}
                />
                <NewSelectField
                  containerClassName="flex-1"
                  control={control}
                  name="ward"
                  errors={errors.ward}
                  options={(() => {
                    const outputValue: { value: string; label: string }[] = [];

                    _ward?.[0]?.Wards?.forEach((element: any) => {
                      outputValue.push({
                        value: String(element.Name),
                        label: String(element.Name),
                      });
                    });

                    return outputValue;
                  })()}
                  placeholder="Xã, Phường"
                  rules={formValidation.ward}
                />
                <div className="md:hidden block mt-[7px]">
                  <NewInputField
                    control={control}
                    name="street"
                    placeholder="Ấp đường"
                    disabled={!isEdit}
                  />
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-2 md:grid-cols-3 gap-[16px]">
                <NewInputField
                  control={control}
                  name="city"
                  placeholder="Tỉnh, thành phố"
                  disabled={!isEdit}
                />
                <NewInputField
                  control={control}
                  name="district"
                  placeholder="Quận, Huyện"
                  disabled={!isEdit}
                />
                <NewInputField
                  control={control}
                  name="ward"
                  placeholder="Xã, Phường"
                  disabled={!isEdit}
                />
                <div className="md:hidden block">
                  <NewInputField
                    control={control}
                    name="street"
                    placeholder="Ấp đường"
                    disabled={!isEdit}
                  />
                </div>
              </div>
            )}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-[16px]">
              <div className="hidden md:block">
                <NewInputField
                  control={control}
                  name="street"
                  placeholder="Ấp đường"
                  disabled={!isEdit}
                />
              </div>
              <NewInputField
                control={control}
                name="houseNumber"
                placeholder="Số nhà/ số căn hộ"
                disabled={!isEdit}
                errors={errors.houseNumber}
              />
            </div>
          </div> */}
          {isEdit && (
            <div className="flex md:flex-row flex-col items-center justify-center mt-[32px] gap-[8px]">
              <button
                className="p-[12px_20px] text-[14px] font-[600] leading-[16.94px] max-w-[110px] rounded-[24px] text-nowrap md:block hidden"
                onClick={() => setIsEdit(false)}
              >
                Hủy
              </button>
              <button className="p-[16px_20px] md:p-[12px_20px] w-full bg-black text-white hover:text-[#FF9900] duration-300 text-[14px] font-[600] leading-[16.94px] md:w-[110px] rounded-[24px] text-nowrap">
                Hoàn tất
              </button>
              <button
                className="md:hidden p-[16px_20px] md:p-[12px_20px] w-full text-[14px] font-[600] leading-[16.94px] md:w-[110px] rounded-[24px] text-nowrap"
                onClick={() => setIsEdit(false)}
              >
                Hủy
              </button>
            </div>
          )}
        </form>
        {!isEdit && (
          <div className="flex items-center justify-center mt-[48px]">
            <button
              className="w-full p-[16px_20px] md:p-[12px_20px] border-[1px] border-black text-[14px] font-[600] leading-[16.94px] md:w-[110px] rounded-[24px] text-nowrap"
              onClick={() => setIsEdit(true)}
            >
              Chỉnh sửa
            </button>
          </div>
        )}
      </div>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Hủy"
        okText="Cập nhật"
        centered
      >
        {imageUrl && (
          <div className="flex items-center justify-center overflow-y-auto max-h-auto">
            <img
              src={imageUrl}
              alt="Uploaded"
              className="w-full h-full object-contain"
            />
          </div>
        )}
      </Modal>
    </>
  );
}

export default UpdateInfo;
