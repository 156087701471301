import orderApi from "@/api/orderApi";
import profileApi from "@/api/profileApi";
import { useQuery } from "@tanstack/react-query";

export function useQueryMembershipClasses() {
  const { data, isLoading, isFetched } = useQuery({
    queryKey: ["membershipClasses"],
    queryFn: () => profileApi.getMembershipClasses(),
  });

  const membershipClassesData = data?.data.data || [];

  return {
    data,
    membershipClassesData,
    isQueryMembershipClassesLoading: isLoading,
    isFetched,
  };
}

export function useQueryUserVouchers() {
  const { data, isLoading, isFetched } = useQuery({
    queryKey: ["userVouchers"],
    queryFn: () => profileApi.getUserVouchers(),
  });

  const allVouchers = data?.data?.data || [];

  return {
    data,
    allVouchers,
    isQueryUserVouchersLoading: isLoading,
    isFetched,
  };
}

export function useQueryOrders(
  searchParams: string,
  isUpdatedAtDesc?: boolean
) {
  const { data, isLoading, isFetched, refetch } = useQuery({
    queryKey: ["orders", searchParams],
    queryFn: () => orderApi.getOrders(searchParams, isUpdatedAtDesc),
  });

  const orderList = data?.data.data || [];
  const informationPagination = data?.data.meta.pagination;

  return {
    data,
    orderList,
    informationPagination,
    isQueryOrdersLoading: isLoading,
    isFetched,
    refetch,
  };
}

export function useQueryAllOrderStatus(userId: number) {
  const { data, isLoading, isFetched } = useQuery({
    queryKey: ["allOrderStatus"],
    queryFn: () => orderApi.getAllOrderStatus(userId),
  });

  const allOrderStatus = data?.data.data || [];

  return {
    data,
    allOrderStatus,
    isQueryAllOrderStatusLoading: isLoading,
    isFetched,
  };
}

export function useQueryOrderDetail(orderCode: string) {
  const { data, isLoading, isFetched } = useQuery({
    queryKey: ["orderDetail", orderCode],
    queryFn: () => orderApi.getOrderByCode(orderCode),
  });

  const orderDetail = data?.data.data[0] || null;

  if (orderCode === "" && !orderDetail)
    return {
      orderDetail: null,
      isLoadingOrderDetail: true,
      isFetched,
    };

  return {
    orderDetail,
    isLoadingOrderDetail: isLoading,
    isFetched,
  };
}
