import urls from '@/utils/urls';
import axiosClient from './axiosClient';
import { BaseResponse } from '@/type/base/baseResponse';
import { BaseData } from '@/type/base/baseData';
import { FlashSaleType } from '@/type/response/flashSale';

const flashSaleApi = {
  getAll(time: string) {
    return axiosClient.get<BaseResponse<BaseData<FlashSaleType>[]>>(
      `${urls.FLASH_SALE}?populate[products][populate][0]=avatar&filters[$and][0][start_time][$lte]=${time}&filters[$and][1][end_time][$gte]=${time}`,
      { method: 'GET' }
    );
  },
  checkFlashSales(body: { ids: number[] }) {
    return axiosClient.post<Record<"id", number>[]>(
      `${urls.CHECK_FLASH_SALES}`, body
    )
  }
};

export default flashSaleApi;
