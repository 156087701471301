import useQueryRoutineProduct from '@/hooks/ReactQuery/routineProduct.query';
import SectionVertical from '../../../common/SectionVertical';
import { useNavigate } from "react-router-dom";
import routes from "@/utils/routes";
import useWindowSize from "@/hooks/useWindowSize";

interface RoutinesComponentProps {
  // interface routines mock because don't have data api
  productId: string;
  hasShadow?: boolean
}

export default function RoutinesComponent({
  productId,
}: RoutinesComponentProps) {
  const { routinesProduct, isLoading } = useQueryRoutineProduct(productId);
  const navigate = useNavigate();
  const size = useWindowSize()

  if (isLoading) {
    return (
      <>
        <p className="product-detail__title uppercase text-piper-lolot mb-4 text-lg">
          Routine gợi ý
        </p>
        <div className="md:mx-0">
          <SectionVertical
            slidePreviewMobile={2}
            slidePreviewDesktop={3}
            products={[]}
            isLoading={isLoading}
          />
        </div>
      </>
    );
  }
  return (
    <>
      {routinesProduct.length > 0 && (
        <div className="flex flex-col gap-[16px] bg-[#FFF] p-[24px_16px] md:p-[unset]">
          <div className="flex flex-row justify-between">
            <p className="text-[18px] font-[400] leading-[25.2px]">
              Routine gợi ý
            </p>
            <p
              className="text-[18px] font-[400] leading-[25.2px] cursor-pointer"
              onClick={() => navigate(routes.ROUTINE)}
            >
              {"->"}
            </p>
          </div>
          <div className="md:mx-0">
            <SectionVertical
              slidePreviewMobile={2}
              slidePreviewDesktop={3}
              products={routinesProduct}
              isLoading={isLoading}
              thumbType="primary"
              contentPage="product-detail"
              showPriceHorizontal={size.width > 768}
            />
          </div>
        </div>
      )}
    </>
  );
}
