import dayjs from "dayjs";
import constants from "./constants";
import moment from "moment";

export function getTimeNow() {
  return dayjs().format(constants.DATE_FORMAT_HHmmss);
}

/**
 * The function `getRemainingDays` calculates the remaining time in days, hours, or minutes between a
 * target date and the current date, with an optional custom message for expired dates.
 * @param {string} dateString - The `dateString` parameter in the `getRemainingDays` function is a
 * string that represents a date in a specific format. This date is used to calculate the remaining
 * time until that date from the current date.
 * @param {string} [expiredText] - The `expiredText` parameter is a string that represents the text to
 * be displayed when the target date has already passed. If no `expiredText` is provided, the default
 * text "Đã hết hạn" will be returned.
 * @returns The function `getRemainingDays` returns a string that represents the remaining time between
 * the target date (specified by the `dateString` parameter) and the current date. The returned string
 * can be in different formats based on the remaining time:
 */
export function getRemainingDays(dateString: string, expiredText?: string): string {
  const targetDate = moment(dateString);
  const today = moment();
  const remainingDays = targetDate.diff(today, 'days', true);

  if (remainingDays < 1) {
    const remainingHours = targetDate.diff(today, 'hours', true);

    if (remainingHours <= 0) {
      return expiredText || "Đã hết hạn"
    }

    if (remainingHours < 1) {
      const remainingMinutes = targetDate.diff(today, 'minutes');
      const minutes = Math.round(remainingMinutes * 60);
      return `Còn ${minutes} phút`;
    } else {
      const hours = Math.floor(remainingHours);
      const minutes = Math.round((remainingHours - hours) * 60);
      return `Còn ${hours} giờ ${minutes < 10 ? '0' : ''}${minutes} phút`;
    }
  } else {
    return `Còn ${Math.ceil(remainingDays)} ngày`;
  }
}

/* This line of code is exporting a constant named `isoFormattedToday` that contains the current date
and time formatted in ISO 8601 format using the `moment` library. The format specified
`'YYYY-MM-DDTHH:mm:ss.SSS[Z]'` represents the date and time in the format of
`YYYY-MM-DDTHH:mm:ss.SSS[Z]`, where:
- `YYYY` represents the year
- `MM` represents the month
- `DD` represents the day
- `THH:mm:ss.SSS` represents the time in hours, minutes, seconds, and milliseconds
- `[Z]` represents the time zone offset in the format of Zulu time (UTC) */
export const isoFormattedToday = moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

/**
 * The function `compareDates` uses Moment.js to compare two date strings and returns -1 if the first
 * date is before the second, 1 if it's after, and 0 if they are equal.
 * @param {string} dateString - The `dateString` parameter is a string representing a date that you
 * want to compare with another date. It should be in a format that Moment.js can parse, such as
 * "YYYY-MM-DD".
 * @param {string} compareTo - The `compareTo` parameter in the `compareDates` function is a string
 * representing a date that you want to compare with the `dateString` parameter. The function uses
 * Moment.js to parse both date strings and then compares them to determine their relationship (before,
 * after, or equal). The function returns
 * @returns The `compareDates` function returns a number based on the comparison of two date strings.
 * It returns -1 if the first date is before the second date, 1 if the first date is after the second
 * date, and 0 if the two dates are the same.
 */
export const compareDates = (dateString: string, compareTo: string): number => {
  const date1 = moment(dateString);
  const date2 = moment(compareTo);

  // Use Moment.js comparison functions
  if (date1.isBefore(date2)) {
    return -1; // first date is before the second date
  } else if (date1.isAfter(date2)) {
    return 1; // first date is after the second date
  } else {
    return 0; // are same
  }
}