import { useState } from "react";

interface IAddToCartProps {
  onClick?: () => void;
}

const AddToCart = (props: IAddToCartProps) => {
  const { onClick } = props;
  const [isHover, setIsHover] = useState(false);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className="backdrop-blur-[2px] rounded-full transition cursor-pointer"
      onClick={onClick}
    >
      <g filter="url(#filter0_b_524_30559)">
        <circle
          cx="40"
          cy="40"
          r="40"
          fill="black"
          fillOpacity={isHover ? "1" : "0.2"}
        />
      </g>
      <path
        d="M56.118 50.8723L54.6641 33.0946C54.4757 30.791 52.5862 29.0192 50.318 29.0192H47.7891H30.3442H27.8153C25.547 29.0192 23.6575 30.791 23.4691 33.0946L22.0154 50.8723C21.8035 53.4635 23.81 55.6859 26.3615 55.6859H51.7718C54.3233 55.6859 56.3299 53.4635 56.118 50.8723Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.1333 37.5527V29.0194V26.8861C48.1333 24.6229 47.1782 22.4524 45.4777 20.8521C43.7775 19.2518 41.4714 18.3527 39.0667 18.3527C36.662 18.3527 34.3559 19.2518 32.6556 20.8521C30.9552 22.4524 30 24.6229 30 26.8861V29.0194V37.5527"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_b_524_30559"
          x="-10"
          y="-10"
          width="100"
          height="100"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_524_30559"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_524_30559"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default AddToCart;
