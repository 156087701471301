import formatText from "@/hooks/formatText";
import { Modal } from "antd";
import { useState } from "react";

interface DescriptionComponentProps {
  descriptions: any[];
}

export default function DescriptionComponent({
  descriptions,
}: DescriptionComponentProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      <div className="bg-[#FFF] md:bg-[unset] px-[16px] md:px-[24px] p-[24px] md:border md:border-[#B0B0B0] md:rounded-[32px]">
        <div className="flex flex-col gap-[24px]">
          <p className="text-[14px] md:text-[16px] font-[500] md:font-[600px]">
            Mô tả sản phẩm
          </p>
          <div className="text-ellipsis line-clamp-5">
            {descriptions.map((item, index) => (
              <span
                key={index}
                dangerouslySetInnerHTML={{ __html: formatText(item) }}
                className="*:text-[14px] *:font-[700] md:*:text-[18px] md:*:font-[500] *:p-0 *:m-0"
              ></span>
            ))}
          </div>
          <p
            className="text-[14px] font-[400] text-[#F90] text-center cursor-pointer"
            onClick={() => setIsModalOpen(true)}
          >
            Xem tất cả
          </p>
        </div>
      </div>

      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        width={743}
        centered
      >
        <div className="h-[450px] md:h-[700px] overflow-auto">
          {descriptions.map((item, index) => (
            <span
              key={index}
              dangerouslySetInnerHTML={{ __html: formatText(item) }}
            ></span>
          ))}
        </div>
      </Modal>
    </>
  );
}
