import BackToHeader from "@/components/common/BackToHeader";
import FeedbackComponent from "@/components/pages/Orders/Feedback";
import FinishFeedbackModal from "@/components/pages/Orders/FinishFeedbackModal";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Feedback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { prePage, order } = location.state || {};

  const [openFinishFeedback, setOpenFinishFeedback] = useState<boolean>(false);

  const handleBackTo = () => {
    navigate(prePage);
  };
  return (
    <>
      <BackToHeader content="Đánh giá" onBackTo={handleBackTo} />
      <div className="p-[24px_16px]">
        <FeedbackComponent
          orderList={order?.attributes?.orderDetails?.data}
          orderId={order?.id}
          status={order?.attributes?.status}
          openFinishModal={() => {
            setOpenFinishFeedback(true);
          }}
        />
      </div>

      {openFinishFeedback && (
        <FinishFeedbackModal
          open={openFinishFeedback}
          handleCancel={() => setOpenFinishFeedback(false)}
          onClose={() => setOpenFinishFeedback(false)}
        />
      )}
    </>
  );
};

export default Feedback;
