import images from "@/assets/images";
import Product from "@/components/common/NewProduct";
import useRoutineQuery from "@/hooks/ReactQuery/routine.query";
import routes from "@/utils/routes";
import Breadcrumb from "@/components/common/Breadcrumb";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import svgs from "@/assets/svgs";
import useWindowSize from "@/hooks/useWindowSize";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import loadingScreenState from "@/recoil/loadingScreenState";
import { BaseData } from "@/type/base/baseData";
import { RoutineProductType } from "@/type/response/routine";
import {
  addProductListToCart,
  CartState,
  IICartStateRecoil,
  IProductInCart,
} from "@/recoil/cartState";
import trackingApi from "@/api/trackingAPI";
import toast from "react-hot-toast";
import { useRootLayoutContext } from "@/layouts/RootLayout";

type HeaderRoutineSectionProps = {
  slug: string;
  title: string;
};
type RoutineSectionType = {
  products: BaseData<RoutineProductType>[];
};
export default function Routine() {
  const setCart = useSetRecoilState<IICartStateRecoil>(CartState);

  const { routines, isLoading } = useRoutineQuery();
  const navigate = useNavigate();
  const size = useWindowSize();
  const handleSetIsLoading = useSetRecoilState(loadingScreenState);
  const { setIsLoadingDone } = useRootLayoutContext();

  const breadcrumbItems = [
    {
      href: routes.HOME,
      title: "Trang chủ",
    },
    {
      href: routes.PRODUCT,
      title: "Sản phẩm",
    },
    {
      href: routes.ROUTINE,
      title: "Routine",
    },
  ];
  const addProductRoutineToCart = async (
    productList: BaseData<RoutineProductType>[]
  ) => {
    const dataProducts = productList.reduce(
      (acc: IProductInCart[], product: BaseData<RoutineProductType>) => {
        const productFormat = {
          id: product.id,
          name: product.attributes?.name || product.attributes?.shortName,
          image: product.attributes?.avatar?.data?.attributes?.url,
          price: product.attributes?.price,
          pricePromotion: product.attributes?.pricePromotion,
          flashSalePrice: product.attributes?.flashSalePrice,
          slug: product.attributes.slug,
        };

        return [...acc, productFormat];
      },
      []
    );

    setCart((prevCart: IICartStateRecoil) => ({
      ...prevCart,
      cartProducts: addProductListToCart(prevCart.cartProducts, dataProducts),
    }));
    toast.success("Thêm combo vào giỏ hàng thành công");
    const utmSource = sessionStorage.getItem("utm_source") || "google";
    await trackingApi.trackAddToCart(
      dataProducts.map((prod) => prod.id) as number[],
      utmSource
    );
    await trackingApi.updateProductEfficiency(
      dataProducts.map((prod) => prod.id) as number[],
      utmSource
    );
  };

  useEffect(() => {
    if (!isLoading) {
      setIsLoadingDone(!isLoading);
    }
  }, [isLoading]);

  useEffect(() => {
    handleSetIsLoading(true);
  }, []);
  const RoutineSection = ({ products }: RoutineSectionType) => {
    return (
      <div className="w-full bg-white">
        <div className="max-w-[1440px] px-[16px] md:px-[64px] lg:px-[160px] py-0 grid grid-cols-1 lg:grid-cols-[40%_60%] gap-[32px] mx-auto">
          <div className="hidden lg:block max-h-[564px]">
            <img
              src={routines[0]?.attributes?.avatar?.data?.attributes?.url}
              className="h-full w-full object-cover"
            />
          </div>

          <div>
            <Swiper
              modules={[Navigation, Pagination]}
              spaceBetween={size.width < 1024 ? 16 : 24}
              slidesPerView={size.width < 1024 ? 2 : 3}
              navigation={size.width >= 1024}
              pagination={
                size.width >= 1024
                  ? {
                      el: ".sniper-pagination-3",
                      dynamicBullets: false,
                      clickable: true,
                    }
                  : undefined
              }
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")}
              className="h-fit pt-[32px]"
            >
              {products?.map((item: any, index) => (
                <SwiperSlide key={item?.id} className="h-fit">
                  <Product
                    contentPage="routine"
                    routineProduct={item}
                    product={item}
                    thumbType={"primary"}
                    orderNumber={index + 1}
                  />
                </SwiperSlide>
              ))}

              {size.width < 1024 ? (
                <></>
              ) : (
                <div className="sniper-controller flex justify-center items-center">
                  <div className="sniper-pagination-3 mx-auto mt-3 w-fit" />
                </div>
              )}
            </Swiper>

            <div className="flex items-center justify-center py-[32px]">
              <button
                className="p-[10px_16px] leading-[16.94px] rounded-[24px] lg:bg-black lg:text-[#F0F0F0] font-[600] text-[14px] hover:text-[#FF9900] duration-300 min-w-[112px] bg-white border-[1px] border-black text-black"
                onClick={() => {
                  addProductRoutineToCart(products);
                }}
              >
                Thêm vào giỏ
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const HeaderRoutineSection = ({ slug, title }: HeaderRoutineSectionProps) => {
    return (
      <div
        className="flex items-center justify-between p-[12px_16px] bg-black lg:hidden"
        onClick={() => navigate(`${routes.ROUTINE}/${slug}`)}
      >
        <p className="text-[20px] font-[600] text-white">{title}</p>
        <img
          src={svgs.chevronWhite}
          alt="icon"
          className="size-[32px] rotate-[-90deg]"
        />
      </div>
    );
  };

  return (
    <>
      <div className="bg-[#f7f7f7]">
        <div className={`${size.width < 1024 ? "mt-[8px]" : ""}`}>
          <img
            src={
              size.width < 768
                ? images.routineMobileBanner
                : images.routineBanner
            }
            alt="banner"
            className="h-[500px] w-full bg-left"
          />
        </div>
        <Breadcrumb items={breadcrumbItems} />
        <div className="md:flex flex-col gap-[8px]">
          {routines &&
            routines?.map((item, index) => (
              <section key={index}>
                <HeaderRoutineSection
                  slug={item?.attributes?.slug}
                  title={item?.attributes?.title}
                />
                <RoutineSection products={item?.attributes?.products?.data} />
              </section>
            ))}
        </div>
      </div>
    </>
  );
}
