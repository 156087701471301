/* eslint-disable import/extensions */
/* eslint-disable import/order */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/require-default-props */
import "@/assets/scss/swiper.scss";
import { BaseData } from "@/type/base/baseData";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import NewProduct from "../NewProduct";
import { BaseProductData } from "@/type/base/baseProduct";
import { ProductsResponse } from "@/type/response/products";
import ProductSkeleton from "@/components/skeleton/common/product";
import useWindowSize from "@/hooks/useWindowSize";
import { FilterProducts_ProgrammeType } from "@/type/response/filterProducts";
import { IThumbTypeProps } from "../NewProduct/Thumb";
import { IContentPageProps } from "../NewProduct/Content";

interface SectionVerticalProps {
  className?: string;
  products: BaseData<ProductsResponse | BaseProductData>[];
  title?: string | undefined;
  slidePreviewMobile?: number;
  slidePreviewDesktop?: number;
  sortSold?: boolean;
  isLoading?: boolean;
  useSpecialName?: boolean;
  hasBanner?: boolean;
  showThumbLabel?: boolean;
  program?: BaseData<FilterProducts_ProgrammeType>;
  thumbType?: IThumbTypeProps["thumbType"];
  contentPage?: IContentPageProps["contentPage"];
  showPriceHorizontal?: boolean;
}

export default function SectionVertical({
  products,
  title,
  isLoading,
  hasBanner = false,
  showThumbLabel = false,
  program,
  slidePreviewMobile,
  slidePreviewDesktop,
  thumbType,
  contentPage,
  showPriceHorizontal,
}: SectionVerticalProps) {
  const size = useWindowSize();

  if (hasBanner) {
    return (
      <div className="my-[24px] md:my-[32px] w-full flex flex-col gap-[16px] md:gap-[32px]">
        {title !== undefined && (
          <div className="text-[20px] md:text-[28px] font-[600] text-[#000000] md:uppercase">
            {title}
          </div>
        )}
        <img
          src={program?.attributes?.banner?.data?.attributes?.url}
          alt=""
          className="border rounded-[24px_24px_0_0] h-[203px] md:h-[261px] w-full object-cover"
        />
        <Swiper
          observer={true}
          observeParents={true}
          onSwiper={(swiper) => swiper.update()}
          navigation={size.width >= 1024}
          pagination={{
            el: ".sniper-pagination-3",
            clickable: true,
          }}
          breakpoints={{
            "320": {
              slidesPerView: slidePreviewMobile || 2.5,
              spaceBetween: 11,
            },
            "768": {
              slidesPerView: slidePreviewMobile || 3,
              slidesPerGroup: 2,
              spaceBetween: 20,
            },
            "1024": {
              slidesPerView: slidePreviewDesktop || 6,
              slidesPerGroup: 1,
              spaceBetween: 16,
            },
          }}
          modules={[Pagination, Navigation]}
          wrapperClass="items-start"
        >
          {isLoading ? (
            <>
              {Array.from({ length: 10 }).map((_, index) => (
                <SwiperSlide key={index}>
                  <ProductSkeleton />
                </SwiperSlide>
              ))}
            </>
          ) : products?.length ? (
            showThumbLabel ? (
              <>
                {products
                  .slice(0, 3)
                  ?.map(
                    (
                      product: BaseData<ProductsResponse | BaseProductData>,
                      index
                    ) => (
                      <SwiperSlide key={product.id}>
                        {optionDiscountProduct(
                          product,
                          "top",
                          index + 1,
                          thumbType,
                          contentPage,
                          showPriceHorizontal
                        )}
                      </SwiperSlide>
                    )
                  )}
                {products
                  .slice(3)
                  ?.map(
                    (product: BaseData<ProductsResponse | BaseProductData>) => (
                      <SwiperSlide key={product.id}>
                        {optionDiscountProduct(
                          product,
                          undefined,
                          undefined,
                          thumbType,
                          contentPage,
                          showPriceHorizontal
                        )}
                      </SwiperSlide>
                    )
                  )}
              </>
            ) : (
              products?.map(
                (product: BaseData<ProductsResponse | BaseProductData>) => (
                  <SwiperSlide key={product.id}>
                    {optionDiscountProduct(
                      product,
                      undefined,
                      undefined,
                      thumbType,
                      contentPage,
                      showPriceHorizontal
                    )}
                  </SwiperSlide>
                )
              )
            )
          ) : null}

          <div className="sniper-controller flex justify-center items-center">
            <div className="sniper-pagination-3 mt-3"></div>
          </div>
        </Swiper>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-[16px] md:gap-[32px] group/section">
      {title !== undefined && (
        <div className="text-[20px] md:text-[28px] font-[600] text-[#000000] md:uppercase">
          {title}
        </div>
      )}
      <Swiper
        observer={true}
        observeParents={true}
        onSwiper={(swiper) => swiper.update()}
        navigation={size.width >= 1024}
        pagination={{
          el: ".sniper-pagination-3",
          clickable: true,
        }}
        breakpoints={{
          "320": {
            slidesPerView: slidePreviewMobile || 2,
            spaceBetween: 11,
          },
          "768": {
            slidesPerView: 3,
            slidesPerGroup: 2,
            spaceBetween: 20,
          },
          "1024": {
            slidesPerView: slidePreviewDesktop || 6,
            slidesPerGroup: 1,
            spaceBetween: 16,
          },
        }}
        modules={[Pagination, Navigation]}
        wrapperClass="items-start"
      >
        {isLoading ? (
          <>
            {Array.from({ length: 10 }).map((_, index) => (
              <SwiperSlide key={index}>
                <ProductSkeleton />
              </SwiperSlide>
            ))}
          </>
        ) : products?.length ? (
          showThumbLabel ? (
            <>
              {products
                .slice(0, 3)
                ?.map(
                  (
                    product: BaseData<ProductsResponse | BaseProductData>,
                    index
                  ) => (
                    <SwiperSlide key={product.id}>
                      {optionDiscountProduct(
                        product,
                        "top",
                        index + 1,
                        thumbType,
                        contentPage,
                        showPriceHorizontal
                      )}
                    </SwiperSlide>
                  )
                )}
              {products
                .slice(3)
                ?.map(
                  (product: BaseData<ProductsResponse | BaseProductData>) => (
                    <SwiperSlide key={product.id}>
                      {optionDiscountProduct(
                        product,
                        undefined,
                        undefined,
                        thumbType,
                        contentPage,
                        showPriceHorizontal
                      )}
                    </SwiperSlide>
                  )
                )}
            </>
          ) : (
            products?.map(
              (product: BaseData<ProductsResponse | BaseProductData>) => (
                <SwiperSlide key={product.id}>
                  {optionDiscountProduct(
                    product,
                    undefined,
                    undefined,
                    thumbType,
                    contentPage,
                    showPriceHorizontal
                  )}
                </SwiperSlide>
              )
            )
          )
        ) : null}

        <div className="sniper-controller flex justify-center items-center">
          <div className="sniper-pagination-3 mt-3"></div>
        </div>
      </Swiper>
    </div>
  );
}

function optionDiscountProduct(
  product: BaseData<ProductsResponse | BaseProductData>,
  thumbLabelType?: "top" | "sale",
  levelTop?: number,
  thumbType?: IThumbTypeProps["thumbType"],
  contentPage?: IContentPageProps["contentPage"],
  showPriceHorizontal?: boolean
) {
  if (thumbLabelType && levelTop) {
    return (
      <NewProduct
        product={product}
        thumbType={"best-seller"}
        thumbLabelType={"top"}
        levelTop={levelTop}
        contentPage={"home"}
        showPriceHorizontal={showPriceHorizontal}
      />
    );
  }

  return (
    <NewProduct
      product={product}
      thumbType={thumbType || "best-seller"}
      contentPage={contentPage || "home"}
      showPriceHorizontal={showPriceHorizontal}
    />
  );
}
