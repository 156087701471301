import { profileRank } from "@/utils/profile";

interface MemberCardProps {
  currentPoint: number;
  nextRankPoint: number;
  amountDecrease: number;
  currentRank: string;
}

function MemberCard({
  currentPoint,
  nextRankPoint,
  amountDecrease,
  currentRank,
}: MemberCardProps) {
  const currentPercent = (currentPoint / nextRankPoint) * 100;
  const getName = (nameRank: string): string => {
    const mapPage: { [key: string]: string } = {
      [profileRank.BRONZE]: "Thành viên Đồng",
      [profileRank.SILVER]: "Thành viên Bạc",
      [profileRank.GOLD]: "Thành viên Vàng",
      [profileRank.DIAMOND]: "Thành viên Kim Cương",
    };

    return mapPage[nameRank] || "";
  };

  return (
    <div className="bg-gradient-to-r from-[#444444] to-black rounded-[20px] w-full md:w-[444px] h-[190px] border-[1px] border-[#F0F0F0] shadow-[8px_10px_16px_#0000000D] p-[16px]">
      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col gap-[8px]">
          <h3 className="m-0 text-white font-[600] text-[16px] leading-[19.36px]">
            Ceranee member
          </h3>
          <span className="text-white text-[12px] font-[600] leading-[16.39px]">
            {getName(currentRank)}
          </span>
        </div>

        <div className="flex flex-col gap-[14px]">
          <div className="flex items-center justify-between">
            <p className="text-[12px] leading-[14.52px] text-white">
              Điểm tích lũy:
            </p>
            <p className="text-[12px] leading-[14.52px] text-white">
              Số tiền đã tiết kiệm được: {amountDecrease}
            </p>
          </div>
          <div className="flex items-center gap-[12px]">
            <div className="relative h-[8px] rounded-[8px] bg-white flex-1 overflow-hidden">
              <div
                className={`absolute bg-[#818080] h-full rounded-[8px]`}
                style={{ width: `${currentPercent}%` }}
              ></div>
            </div>
            <span className="font-medium text-white text-[14px] leading-[20px]">
              {`${currentPoint}/${nextRankPoint} điểm`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MemberCard;
