import images from "@/assets/images";
import svgs from "@/assets/svgs";
import { Swiper, SwiperSlide } from "swiper/react";
import useWindowSize from "@/hooks/useWindowSize";
import { Navigation } from "swiper/modules";

export default function PolicyComponent() {
  const size = useWindowSize();

  if (size.width < 768) {
    return (
      <div className="flex flex-col gap-[8px] p-[24px_0]">
        <div className="flex justify-between gap-[8px] flex-wrap">
          <div className="flex flex-col items-center min-w-[150px] gap-[8px]">
            <img src={svgs.truck} alt="" className="size-[30px]" />
            <div className="flex items-start gap-[4px]">
              <p className="text-[11px] font-[400] leading-[15.4px] text-wrap text-center">
                Miễn phí giao hàng <br />Thành phố Hồ Chí Minh
              </p>
              <img src={svgs.alertCircle} alt="" className="size-[20px]" />
            </div>
          </div>

          <div className="flex flex-col items-center min-w-[150px] gap-[8px]">
            <img src={images.vnpay} alt="" className="w-auto h-[30px]" />
            <div className="flex items-start gap-[4px]">
              <p className="text-[11px] font-[400] leading-[15.4px] text-wrap text-center">
                Thanh toán linh hoạt qua <br />VNPAY-QR
              </p>
              <img src={svgs.alertCircle} alt="" className="size-[20px]" />
            </div>
          </div>
        </div>

        <div className="flex justify-between gap-[8px] flex-wrap">
          <div className="flex flex-col items-center min-w-[150px] gap-[8px]">
            <img src={svgs.rotateLeft} alt="" className="size-[30px]" />
            <div className="flex items-start gap-[4px]">
              <p className="text-[11px] font-[400] leading-[15.4px] text-wrap text-center">
                Hỗ trợ đổi hàng trong vòng <br /><b>7 ngày</b>
              </p>
            </div>
          </div>

          <div className="flex flex-col items-center min-w-[150px] gap-[8px]">
            <img src={svgs.rocket} alt="" className="size-[30px]" />
            <div className="flex items-start gap-[4px]">
              <p className="text-[11px] font-[400] leading-[15.4px] text-wrap text-center">
                Giao nhanh trong 2 giờ
              </p>
              <img src={svgs.alertCircle} alt="" className="size-[20px]" />
            </div>
          </div>
        </div>

      </div>
    )
  }

  return (
    <Swiper
      observer={true}
      observeParents={true}
      onSwiper={(swiper) => swiper.update()}
      // navigation={{
      //   nextEl: '.sniper-button-next--flashSale',
      //   prevEl: '.sniper-button-prev--flashSale',
      //   disabledClass: 'swiper-button-disabled',
      //   hiddenClass: 'swiper-button-hidden',
      //   lockClass: 'swiper-button-lock',
      // }}
      navigation={size.width >= 1350}
      breakpoints={{
        '320': {
          slidesPerView: 2,
          spaceBetween: 11,
        },
        '768': {
          slidesPerView: 2,
          spaceBetween: 25,
        },
        '1300': {
          slidesPerView: 3,
        },
      }}
      modules={[Navigation]}

    >
      <SwiperSlide>
        <div className="flex flex-col items-center min-w-[150px] gap-[8px]">
          <img src={svgs.truck} alt="" className="size-[30px]" />
          <div className="flex items-start gap-[4px]">
            <p className="text-[14px] font-[400] leading-[19.6px] text-wrap text-center">
              Miễn phí giao hàng
            </p>
            <img src={svgs.alertCircle} alt="" className="size-[20px]" />
          </div>
        </div>
      </SwiperSlide>


      <SwiperSlide>
        <div className="flex flex-col items-center min-w-[150px] gap-[8px]">
          <img src={images.vnpay} alt="" className="w-auto h-[30px]" />
          <div className="flex items-start gap-[4px]">
            <p className="text-[14px] font-[400] leading-[19.6px] text-wrap text-center">
              Thanh toán qua VNPAY-QR
            </p>
            <img src={svgs.alertCircle} alt="" className="size-[20px]" />
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="flex flex-col items-center min-w-[150px] gap-[8px]">
          <img src={svgs.rotateLeft} alt="" className="size-[30px]" />
          <div className="flex items-start gap-[4px]">
            <p className="text-[14px] font-[400] leading-[19.6px] text-wrap text-center">
              Hỗ trợ đổi trả <b>7 ngày</b>
            </p>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="flex flex-col items-center min-w-[150px] gap-[8px]">
          <img src={svgs.rocket} alt="" className="size-[30px]" />
          <div className="flex items-start gap-[4px]">
            <p className="text-[14px] font-[400] leading-[19.6px] text-wrap text-center">
              Giao nhanh trong 2 giờ
            </p>
            <img src={svgs.alertCircle} alt="" className="size-[20px]" />
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}
