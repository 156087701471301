export function BlogSkeleton() {
  return (
    <div className="grid grid-cols-12 gap-4 bg-white px-2 py-2">
      <div className=" col-span-5">
        <div>
          <div className="w-full h-[66px] md:h-[222px]">
            {/* image for desktop */}
            <div className="w-full h-full object-cover hidden md:block bg-gray-300 animate-pulse" />

            {/* image for mobile */}
            <div className="w-full h-full object-cover md:hidden bg-gray-300 animate-pulse" />
          </div>
        </div>
      </div>
      <div className="content flex justify-center items-start gap-2 flex-col col-span-7 md:justify-start w-full">
        <div className="topic w-full">
          <div className="h-[20px] w-[20%] bg-gray-300 animate-pulse my-0"></div>
        </div>
        <div className="title w-full">
          <div className="h-[20px] w-full bg-gray-300 animate-pulse my-0"></div>
          <div className="h-[20px] w-full bg-gray-300 animate-pulse my-0"></div>
        </div>
        <div className="short-description w-full">
          <div className="h-[20px] w-full bg-gray-300 animate-pulse my-0"></div>
          <div className="h-[20px] w-full bg-gray-300 animate-pulse my-0"></div>
        </div>

        <div className={`flex items-center`}>
          <div className="h-[20px] w-[40px] bg-gray-300 animate-pulse my-0"></div>
        </div>
      </div>
    </div>
  );
}

export function BlogMainSkeleton() {
  return (
    <div className="md:grid md:grid-cols-12 md:gap-5 bg-white">
      <div className=" col-span-5">
        {/* image for mobile */}
        <div className={`w-full h-[200px] md:hidden`}>
          <div className="w-full h-full object-cover object-center md:hidden bg-gray-300 animate-pulse" />
        </div>

        {/* for desktop */}
        <div className="w-full h-[275px] hidden md:block">
          <div className="w-full h-full object-cover hidden md:block bg-gray-300 animate-pulse"></div>
        </div>
      </div>

      <div className="content flex justify-center items-start flex-col col-span-7 md:justify-start px-4 my-4 w-full">
        <div className="title w-full">
          <div className="h-[20px] w-full bg-gray-300 animate-pulse my-0"></div>
          <div className="h-[20px] w-full bg-gray-300 animate-pulse my-0"></div>
        </div>
        <div className="short-description mt-4 title w-full">
          <div className="h-[20px] w-full bg-gray-300 animate-pulse my-0"></div>
          <div className="h-[20px] w-full bg-gray-300 animate-pulse my-0"></div>
        </div>

        <div className={`flex items-center title w-full mt-2`}>
          <div className="h-[20px] w-[40px] bg-gray-300 animate-pulse my-0"></div>
        </div>
      </div>
    </div>
  );
}
