/* eslint-disable import/extensions */
import urls from '@/utils/urls';
import axiosClient from './axiosClient';
import { getUtmData } from "@/utils/tracking";
import queryString from "query-string";

const trackingApi = {
  track() {
    const utmData = getUtmData();
    const utmDataQueryString = queryString.stringify(utmData)

    return axiosClient.get(`${urls.TRACK_REDIRECT}?${utmDataQueryString}`)
  },

  trackAddToCart(productIds: number[], utmSource = 'google') {
    return axiosClient.post(urls.TRACK_ADD_TO_CART, { product_ids: productIds, utm_source: utmSource });
  },

  updateProductEfficiency(productIds: number[], utmSource = 'google') {
    return axiosClient.post(urls.UPDATE_PRODUCT_EFFICIENCY, { product_ids: productIds, utm_source: utmSource });
  }
};
export default trackingApi;
