import productApi from '@/api/productApi';
import { ProductDetailsType } from '@/type/response/productDetails';
import keysReactQuery from '@/utils/keys';
import { useQuery } from '@tanstack/react-query';

const STALE_MINUTES = 1;
const REFETCH_MINUTES = 2;

export default function useProductDetailsQueryData(slug: string) {
  const { data, isLoading, isFetched, error, isError } = useQuery({
    queryKey: [keysReactQuery.PRODUCT, slug],
    queryFn: () => productApi.getBySlug(slug),
    staleTime: STALE_MINUTES * 60 * 1000,
    refetchInterval: REFETCH_MINUTES * 60 * 1000,
    refetchOnWindowFocus: true,
  });

  const { data: variationQueryData, isLoading: isVariationsLoading } = useQuery({
    queryKey: ['variationsQuery', slug],
    queryFn: () => productApi.getVariations(slug),
    staleTime: STALE_MINUTES * 60 * 1000,
    refetchInterval: REFETCH_MINUTES * 60 * 1000,
    refetchOnWindowFocus: true,
  })

  const product: ProductDetailsType = data?.data?.data
    .attributes as ProductDetailsType;

  const price = Number(product?.price ?? 0);
  const pricePromo = Number(product?.pricePromotion);

  const percent: number = pricePromo
    ? Math.round(((price - Number(pricePromo)) / price) * 100)
    : 0;

  const rating: number = product?.rating || 0;
  const volumes: any = product?.variationValue;
  const unitVolume = product?.productUnit?.data?.attributes?.name;
  const variationProd = product?.variationProdAttribute?.data?.attributes?.name;
  const variationProducts = product?.variationProducts?.data ?? [];
  const variations = variationQueryData?.data || [];
  const productId = data?.data.data.id || '';
  const baseDataProduct = data?.data?.data;

  return {
    data,
    isLoading,
    isFetched,
    product,
    percent,
    rating,
    volumes,
    unitVolume,
    variationProd,
    variationProducts,
    error,
    isError,
    variations,
    isVariationsLoading,
    productId,
    baseDataProduct,
  };
}
