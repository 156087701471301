import categoryApi from "@/api/categoryApi";
import { useQuery } from "@tanstack/react-query";

export function useQueryCategoryById(productId: string | number) {
  const { data, isLoading, isFetched } = useQuery({
    queryKey: ["categoryById", productId],
    queryFn: () => categoryApi.getById(Number(productId)),
  });

  // const blog: BaseData<BlogType> = data?.data?.data as BaseData<BlogType>;

  return { data, isLoading, isFetched };
}

export function useQueryAllCategories(query?: string) {
  const { data, isLoading } = useQuery({
    queryKey: ["allCategories"],
    queryFn: () => categoryApi.getAll(query),
  });

  return { data: data?.data?.data || [], isLoading };
}
