/* eslint-disable react/jsx-props-no-spreading */

import { SubmitHandler, useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";
import authApi, { LoginPayload } from "@/api/authApi";
import constants from "@/utils/constants";
import { Link, useNavigate } from "react-router-dom";
import routes from "@/utils/routes";
import toast from "react-hot-toast";
import images from "@/assets/images";
import { Image } from "antd";
import NewInputField from "@/components/common/Formfield/NewInputField";
import { formValidation } from "@/utils/formValidation";
import NewButtonComponent from "@/components/common/NewButton";
import { useEffect, useState } from "react";
import clsx from "clsx";

interface IPasswordCredentialProps {
  id: string;
  name: string;
  iconURL: string;
  password: string;
  type: string;
}
export type IFormInput = {
  identifier: string;
  password: string;
};

export default function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [autoFillCheck, setAutoFillCheck] = useState<boolean>(false);
  const isLoggedIn = localStorage.getItem(constants.ACCESS_TOKEN);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  useEffect(() => {
    if (isLoggedIn) {
      navigate(routes.HOME);
    }
  }, [isLoggedIn, navigate]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    reset,
    watch,
  } = useForm<IFormInput>({
    mode: "all",
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setIsLoading(true);
    try {
      const loginData: LoginPayload = {
        identifier: data.identifier,
        password: data.password,
      };

      await authApi.login(loginData).then((response) => {
        toast.success(t("loginSuccess"));

        localStorage.setItem(constants.ACCESS_TOKEN, response.data.jwt);

        setTimeout(() => {
          localStorage.getItem("redirect")
            ? navigate(routes.CART)
            : navigate(routes.HOME);
          localStorage.removeItem("redirect");
        }, 1000);
      });

      await authApi.getUser().then((res) => {
        localStorage.setItem(constants.PROFILE, JSON.stringify(res.data));
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("error", error);
      toast.error(t("invalidEmailOrPassword"));
    }
  };

  useEffect(() => {
    if (isLoggedIn) return;
    if (navigator.credentials) {
      navigator.credentials
        .get({
          password: true,
          mediation: "optional",
        } as CredentialRequestOptions)
        .then((credential: Credential | null) => {
          if (credential) {
            if (credential?.id) {
              reset({
                identifier: credential?.id,
                password: (credential as IPasswordCredentialProps).password,
              });
              if (firstLoad) setFirstLoad(false);
            }
          } else {
            if (firstLoad) setFirstLoad(false);
          }
        })
        .catch((error) => {
          console.error("Credential API error:", error);
        });
    }
  }, []);

  const [identifier, password] = watch(["identifier", "password"]);
  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
    }
    if (autoFillCheck) {
      setAutoFillCheck(false);
    }
  }, [identifier, password]);

  return (
    <div className="bg-[#F7F7F7] ">
      <div className="w-full flex flex-col lg:flex-row justify-between items-start gap-[8px] py-[8px]">
        <div className="w-full flex-1 flex flex-col justify-center items-center bg-[#FFFFFF] min-h-[635px]">
          <div className="flex flex-col justify-center w-full md:w-full lg:w-[400px] py-[32px] px-[16px] lg:px-0">
            <div className="text-[#000000] text-center text-[20px] lg:text-[24px] font-[700] leading-[29.05px] uppercase mb-[32px] mt-0 px-[12px]">
              <p className="text-[#000000] text-center text-[20px] lg:text-[24px] font-[700] leading-[29.05px]">
                Chào bạn,
              </p>
              <p className="text-[#000000] text-center text-[20px] lg:text-[24px] font-[700] leading-[29.05px] mt-[4px] md:mt-[10px]">
                Đã lâu không gặp nhau!
              </p>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col gap-[24px]"
            >
              <NewInputField
                control={control}
                name="identifier"
                label="Email hoặc số điện thoại *"
                placeholder="Nhập Email hoặc số điện thoại"
                rules={formValidation.identifier}
                errors={errors.identifier}
              />
              <NewInputField
                isPassword
                control={control}
                name="password"
                label="Mật khẩu *"
                placeholder="Nhập mật khẩu"
                rules={formValidation.password}
                errors={errors.password}
                autoComplete={firstLoad ? "new-password" : "current-password"}
              />
              <div className="w-full flex justify-end">
                <Link to={routes.FORGOT_PASSWORD} className="w-fit">
                  <p className="text-[16px] text-[#000000] text-right font-[400] leading-[22.4px]">
                    Quên mật khẩu?
                  </p>
                </Link>
              </div>
              <div className="w-full flex justify-center items-center mt-[8px] mb-[24px]">
                <NewButtonComponent
                  htmlType="submit"
                  type={
                    (isValid && !isLoading) || autoFillCheck
                      ? "primary"
                      : "default"
                  }
                  text="Đăng nhập"
                  className="w-full"
                  disabled={!isValid && (isLoading || !autoFillCheck)}
                />
              </div>
            </form>
            {/* <LoginAnother title="Đăng nhập bằng tài khoản khác" /> */}
            <p className="text-[16px] font-[400] text-center">
              Bạn chưa có tài khoản?
              <Link to={routes.REGISTER}>
                <span className="text-[#FF9900] text-[16px] font-[400] ml-[5px]">
                  Đăng ký ngay!
                </span>
              </Link>
            </p>
          </div>
        </div>
        <div
          className={clsx(
            "flex-1 hidden h-[635px] self-start lg:block bg-[#FFFFFF]"
          )}
        >
          <Image
            src={images.bannerSignup}
            alt="banner-signup"
            preview={false}
            className="h-[635px] object-cover"
            rootClassName="block"
          />
        </div>
      </div>
    </div>
  );
}
