import orderApi from "@/api/orderApi";
import { Loading } from "@/components";
import HistoryComponent from "@/components/common/History";
import ProductOrder from "@/components/common/OrderProduct";
import useProductsQuery from "@/hooks/ReactQuery/products.query";
import { CartState, IICartStateRecoil, clearCart } from "@/recoil/cartState";
import viewedProductsState from "@/recoil/viewedProductsState";
import { BaseData } from "@/type/base/baseData";
import { OrderResponse } from "@/type/response/order";
import { formatCurrency } from "@/utils/data";
import routes from "@/utils/routes";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";

export default function OrderSuccess() {
  const [dataOrder, setDataOrder] = useState<BaseData<OrderResponse>>();
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();

  const setCart = useSetRecoilState<IICartStateRecoil>(CartState);

  let viewedProducts: any = [];
  let isQueryViewedProductLoading = false;

  const [viewedProduct] = useRecoilState(viewedProductsState);
  if (viewedProduct.length) {
    const viewedSearchParams = viewedProduct
      .map((item, index) => `filters[id][${index}]=${item}`)
      .join("&");
    const { products, isLoading } = useProductsQuery(viewedSearchParams);
    viewedProducts = products;
    isQueryViewedProductLoading = isLoading;
  }

  useEffect(() => {
    orderApi
      .get(id ?? "")
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        setDataOrder(data.data);
        if (data.data.attributes.paymentMethod === "VNPay") {
          setCart((prev) => ({ ...prev, cartProducts: clearCart() }));
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          navigate(routes.NOT_FOUND);
        } else {
          console.log(error);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  if (isLoading) return <Loading />;

  return (
    <div className="md:container md:max-w-[1280px] md:mx-auto">
      <section className="section__heading mx-4 my-8 md:mx-[120px]">
        <div className="section__heading-title text-[12px] text-[#484848] text-center md:text-[16px] font-normal md:text-left">
          <p className="section__heading-status--order my-0 text-[#484848] md:text-[#A77B5A] font-bold md:font-light">
            Đặt hàng thành công.
          </p>
          <p>
            Đơn hàng đang chờ xử lý, theo dõi chi tiết{" "}
            <Link
              to={`${routes.PROFILE}/${routes.ORDERS}/waiting`}
              className=" underline text-[#A77B5A]"
            >
              tại đây
            </Link>{" "}
            bạn nhé!
          </p>
          <div className="section__order-continue-shopping text-center w-full md:block hidden mt-5">
            <Link
              to={routes.HOME}
              className="text-[#000000] underline text-[14px]"
            >
              Tiếp tục mua sắm
            </Link>
          </div>
        </div>
      </section>

      <section className="section__order mx-4 my-8 md:mx-[120px]">
        <div className="section__order-products md:block hidden">
          {dataOrder?.attributes?.orderDetails?.data?.map((product, index) => (
            <ProductOrder
              name={
                product?.attributes?.product?.data?.attributes?.name ||
                product?.attributes?.product?.data?.attributes?.shortName
              }
              unitPrice={product?.attributes?.unitPrice}
              image={
                product?.attributes?.product?.data?.attributes?.avatar?.data
                  ?.attributes?.url
              }
              quantity={product?.attributes?.quanity}
              key={index}
            />
          ))}
        </div>
        <div className="section__order-information my-8 md:mx-0 md:block hidden">
          <div className="section__order-information-quantity md:text-[14px]">
            Số lượng:{" "}
            <span className="font-bold">
              {dataOrder?.attributes?.orderDetails?.data?.length} sản phẩm
            </span>
          </div>
          <div className="section__order-information-price md:text-[14px]">
            Tổng cộng:{" "}
            <span className="font-bold text-[16px] md:text-[20px]">
              {formatCurrency(dataOrder?.attributes.total.toString() ?? "0")} đ
            </span>
          </div>
        </div>
        <div className="section__order-continue-shopping text-center w-full md:hidden">
          <Link
            to={routes.HOME}
            className="text-[#000000] underline text-[14px]"
          >
            Tiếp tục mua sắm
          </Link>
        </div>
      </section>

      <section className="section__history-watched mx-4 my-8 md:mx-[120px]">
        <HistoryComponent
          productsHistory={viewedProducts}
          isLoading={isQueryViewedProductLoading}
        />
      </section>
    </div>
  );
}
