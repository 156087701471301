import Breadcrumb from "@/components/common/Breadcrumb";
import SupportText from "@/components/common/Support/SupportText";
import routes from "@/utils/routes";

const PrivacyPolicy = () => {
  const breadcrumbItems = [
    {
      href: routes.HOME,
      title: "Trang chủ",
    },
    {
      href: routes.RETURN_POLICY,
      title: "Chính sách bảo mật",
    },
  ];

  const dataOverview = [
    {
      content: `1.1. Chào mừng bạn đến với nền tảng So Natural Việt Nam được vận
                hành bởi Trách nhiệm hữu hạn So Natural Việt Nam. So Natural
                Việt Nam nghiêm túc thực hiện trách nhiệm của mình liên quan đến
                bảo mật thông tin theo các quy định về bảo vệ bí mật thông tin
                cá nhân của pháp luật Việt Nam và cam kết tôn trọng quyền riêng
                tư và sự quan tâm của tất cả Khách Hàng đối với website và ứng
                dụng di động của So Natural Việt Nam (“Nền tảng”). So Natural
                Việt Nam gọi chung các Nền tảng và các dịch vụ So Natural Việt
                Nam cung cấp là "Dịch Vụ". Khách hàng có nghĩa là người đăng ký
                tài khoản với So Natural Việt Nam để sử dụng các Dịch Vụ, bao
                gồm cả người mua (gọi chung "Khách Hàng").`,
    },
    {
      content: `1.2 "Dữ Liệu Cá Nhân" là thông tin dưới dạng ký hiệu, chữ viết,
                hình ảnh, âm thanh hoặc dạng tương tự trên môi trường điện tử
                gắn liền với một con người cụ thể hoặc giúp xác định một con
                người cụ thể. Dữ liệu cá nhân của Người Dùng do Người Dùng cung
                cấp cho So Natural Việt Nam để sử dụng Dịch Vụ.`,
    },
    {
      content: `Nếu So Natural Việt Nam thay đổi Chính sách bảo mật, So Natural
                Việt Nam sẽ thông báo cho Khách Hàng bao gồm cả thông qua việc
                đăng tải những thay đổi đó hoặc Chính sách bảo mật sửa đổi trên
                Nền tảng của So Natural Việt Nam. Trong phạm vi pháp luật cho
                phép, việc Khách Hàng tiếp tục sử dụng các Dịch Vụ hoặc Nền Tảng
                Khách Hàng được xem là đã đồng ý với các thay đổi trong Chính
                Sách Bảo Mật này.`,
    },
  ];

  const dataRule = [
    {
      content: `Họ tên, giới tính, địa chỉ thư điện tử (email), số điện thoại,
                địa chỉ;`,
    },
    {
      content: `Thông tin đăng nhập tài khoản bao gồm thông tin bất kỳ cần thiết
                để thiết lập tài khoản ví dụ như tên đăng nhập, mật khẩu đăng
                nhập, ngày, tháng, năm sinh, ID/địa chỉ đăng nhập;`,
    },
    {
      content: `Địa chỉ thanh toán và/hoặc giao nhận hàng hóa;`,
    },
    {
      content: `Hình ảnh hoặc âm thanh hoặc video của Khách Hàng;`,
    },
    {
      content: `Thông tin sử dụng và giao dịch, bao gồm lịch sử tìm kiếm, giao
                dịch, quảng cáo và nội dung hiển thị có tương tác với Nền Tảng,
                cũng như các sản phẩm và dịch vụ có liên quan của Khách Hàng;`,
    },
    {
      content: `Dữ liệu về vị trí, địa điểm;`,
    },
    {
      content: `Bất kỳ thông tin nào khác về Khách Hàng khi Khách Hàng đăng nhập
                để sử dụng Các Dịch Vụ hoặc Nền tảng của So Natural Việt Nam, và
                khi Khách Hàng sử dụng Các Dịch Vụ hoặc Nền tảng, cũng như thông
                tin về việc Khách Hàng sử dụng Các Dịch Vụ hoặc Nền tảng của So
                Natural Việt Nam như thế nào;`,
    },
    {
      content: `Dữ liệu tổng hợp về nội dung Khách Hàng sử dụng; và các thông
                tin khác có liên quan, ảnh hưởng (trực tiếp/gián tiếp) hoặc phát
                sinh từ/liên quan đến việc xác lập quan hệ giữa Khách Hàng và So
                Natural Việt Nam`,
    },
  ];

  const dataPurpose = [
    {
      content: `- Xử lý đơn hàng: xử lý các vấn đề liên quan đến đơn đặt hàng
                của Khách Hàng như: gọi điện/tin nhắn xác nhận việc đặt hàng,
                thông báo về trạng thái đơn hàng & thời gian giao hàng, xác nhận
                việc huỷ đơn hàng (nếu có).`,
    },
    {
      content: `- Cung cấp các dịch vụ cho Khách Hàng như: xác nhận lịch đặt hẹn
                dịch vụ, nhắc lịch bảo hành, chăm sóc khách hàng, tư vấn/hỗ trợ
                sau khi thực hiện dịch vụ.`,
    },
    {
      content: `- Gửi thư ngỏ/thư cảm ơn, giới thiệu sản phẩm mới, dịch vụ mới
                hoặc các chương trình khuyến mãi của So Natural Việt Nam.`,
    },
    {
      content: `- Tạo và duy trì tài khoản của Khách Hàng, bao gồm cả các chương
                trình khách hàng thân thiết hoặc các chương trình thưởng đi kèm
                với tài khoản của Khách Hàng.`,
    },
    {
      content: `- Dịch Vụ Chăm Sóc Khách Hàng: bao gồm các phản hồi cho các yêu
                cầu, khiếu nại và phản hồi của Khách Hàng.`,
    },
    {
      content: `- Cá Nhân Hóa: So Natural Việt Nam xử lý dữ liệu được thu thập
                để có một cái nhìn hoàn chỉnh hơn về một người tiêu dùng và từ
                đó cho phép So Natural Việt Nam phục vụ tốt hơn với sự cá nhân
                hóa mạnh hơn ở các khía cạnh, bao gồm nhưng không giới hạn:`,
      children: [
        {
          content: `Để cải thiện và cá nhân hóa trải nghiệm của Khách Hàng trên
                    website So Natural Việt Nam.vn/ứng dụng di động So Natural
                    Việt Nam.`,
        },
        {
          content: `Để cải thiện các tiện ích, dịch vụ, điều chỉnh chúng phù hợp
                    với các nhu cầu được cá thể hóa và đi đến những ý tưởng dịch
                    vụ mới.`,
        },
        {
          content: `Để phục vụ Khách Hàng với những giới thiệu, quảng cáo được
                    điều chỉnh phù hợp với sự quan tâm của Khách Hàng.`,
        },
      ],
    },
    {
      content: `- An Ninh: cho các mục đích ngăn ngừa các hoạt động phá hủy tài
                khoản Người Dùng hoặc các hoạt động giả mạo Khách Hàng.`,
    },
    {
      content: `- Theo yêu cầu của pháp luật: tùy quy định của pháp luật vào
                từng thời điểm, So Natural Việt Nam có thể thu thập, lưu trữ và
                cung cấp dữ liệu cá nhân của Khách Hàng theo yêu cầu của cơ quan
                nhà nước có thẩm quyền`,
    },
  ];

  const dataSharePerson = [
    {
      content: `- Các đối tác là bên cung cấp dịch vụ cho So Natural Việt Nam
                liên quan đến thực hiện đơn hàng và chỉ giới hạn trong phạm vi
                thông tin cần thiết cũng như áp dụng các quy định đảm bảo an
                ninh, bảo mật các thông tin cá nhân.`,
    },
    {
      content: `- Các đối tác là nhà sản xuất, nhà cung cấp, nhãn hàng, đơn vị
                tư vấn v.v,... liên quan đến việc cung cấp, phân phối sản phẩm,
                hỗ trợ, hướng dẫn, giải quyết khiếu nại, bồi thường, v.v,... cho
                Khách Hàng liên quan đến sử dụng sản phẩm, dịch vụ.`,
    },
    {
      content: `- So Natural Việt Nam có thể sử dụng dịch vụ từ một nhà cung cấp
                dịch vụ là bên thứ ba để thực hiện một số hoạt động liên quan
                đến website So Natural Việt Nam.vn, ứng dụng bán hàng So Natural
                Việt Nam và khi đó bên thứ ba này có thể truy cập hoặc xử lý các
                thông tin cá nhân trong quá trình cung cấp các dịch vụ đó. So
                Natural Việt Nam yêu cầu các bên thứ ba này tuân thủ mọi luật lệ
                về bảo vệ thông tin cá nhân liên quan và các yêu cầu về an ninh
                liên quan đến thông tin cá nhân.`,
    },
    {
      content: `- Các cơ quan Nhà nước có thẩm quyền theo quy định pháp luật.So
                Natural Việt Nam có thể tiết lộ các dữ liệu cá nhân nếu điều đó
                do luật pháp yêu cầu và việc tiết lộ như vậy là cần thiết một
                cách hợp lý để tuân thủ các quy định pháp luật.`,
    },
  ];

  const dataSecurity = [
    {
      content: `- Thông tin tài chính của Khách hàng sẽ được bảo vệ trong suốt
                quá trình giao dịch`,
    },
    {
      content: `- Mật khẩu sử dụng một lần (OTP) được gửi qua SMS để đảm bảo
                việc truy cập tài khoản được xác thực.`,
    },
    {
      content: `- Các nguyên tắc và quy định bảo mật thông tin trong ngành tài
                chính ngân hàng theo quy định của Ngân hàng nhà nước Việt Nam.`,
    },
    {
      content: `- Các nguyên tắc và quy định bảo mật thông tin trong ngành tài
                chính ngân hàng theo quy định của Ngân hàng nhà nước Việt Nam.`,
    },
    {
      content: `- Chính sách bảo mật giao dịch trong thanh toán áp dụng với
                Khách Hàng:`,
      children: [
        {
          content: `Đối với thẻ quốc tế: thông tin thẻ thanh toán của Khách Hàng
                mà có khả năng sử dụng để xác lập giao dịch không được lưu trên
                hệ thống. Đối Tác Cổng Thanh Toán sẽ lưu trữ và bảo mật.`,
        },
        {
          content: `Đối với thẻ nội địa (internet banking), So Natural Việt Nam
                chỉ lưu trữ mã đơn hàng, mã giao dịch và tên ngân hàng.`,
        },
      ],
    },
    {
      content: `So Natural Việt Nam cam kết đảm bảo thực hiện nghiêm túc các
                biện pháp bảo mật cần thiết cho mọi hoạt động thanh toán thực
                hiện trên website sonatural.vn`,
    },
  ];
  return (
    <>
      <Breadcrumb items={breadcrumbItems} />
      <div className="wrapper my-[48px] md:my-[96px] md:px-[120px] md:container md:max-w-[1280px] md:mx-auto">
        <div className="w-full">
          <SupportText
            type="header"
            content="Chính sách bảo mật"
            className="!text-[#000000]"
          />
          <div className="support-content mx-4 lg:mx-0">
            <SupportText
              type="title"
              content="1. GIỚI THIỆU"
              className="!text-[#000000]"
            />
            <ul className="list-none flex flex-col gap-[8px]">
              {dataOverview.map((item, index) => (
                <SupportText
                  key={index}
                  content={item.content}
                  className="font-[400]"
                />
              ))}
            </ul>
            <SupportText
              type="title"
              content="2. LOẠI DỮ LIỆU CÁ NHÂN ĐƯỢC THU THẬP VÀ XỬ LÝ"
            />
            <SupportText
              content="Khách Hàng đồng ý cung cấp dữ liệu cá nhân cho So Natural Việt Nam
              Tùy theo từng thời điểm cụ thể, theo quy định pháp luật, So
              Natural Việt Nam sẽ thu thập, xử lý một/một số/tất cả các dữ liệu
              cá nhân sau:"
              className="font-normal"
            />
            <ul className="custom-list-item flex flex-col gap-[8px] mt-[12px] ml-[10px]">
              {dataRule.map((item, index) => (
                <SupportText key={index} content={item.content} type="step" />
              ))}
            </ul>
            <SupportText
              type="title"
              content="3. MỤC ĐÍCH THU THẬP, XỬ LÝ DỮ LIỆU CÁ NHÂN"
            />
            <ul className="list-none grid gap-[8px]">
              {dataPurpose.map((item, index) => (
                <>
                  <SupportText key={index} content={item.content} />

                  {item?.children && (
                    <ul className="custom-list-item grid gap-[8px] ml-[24px]">
                      {item?.children?.map((children, indexItem) => (
                        <SupportText
                          key={indexItem}
                          content={children.content}
                          type="step"
                        />
                      ))}
                    </ul>
                  )}
                </>
              ))}
            </ul>
            <SupportText
              type="title"
              content="4. THỜI GIAN LƯU TRỮ THÔNG TIN"
            />
            <SupportText
              content="Thông tin cá nhân của Khách Hàng sẽ được lưu trữ cho đến khi Khách
              Hàng có yêu cầu hủy bỏ. Trong mọi trường hợp thông tin cá nhân của
              khách hàng sẽ được bảo mật trên máy chủ của So Natural Việt Nam."
              className="font-[500]"
            />
            <SupportText type="title" content="5. CHIA SẺ DỮ LIỆU CÁ NHÂN" />
            <SupportText
              content="So Natural Việt Nam sẽ không cung cấp thông tin cá nhân của Khách
              Hàng cho bất kỳ bên thứ ba nào, trừ một số hoạt động cần thiết
              dưới đây:"
            />
            <ul className="list-none grid gap-[8px]">
              {dataSharePerson.map((item, index) => (
                <SupportText key={index} content={item.content} />
              ))}
            </ul>
            <SupportText
              type="title"
              content="6. ĐỊA CHỈ CỦA ĐƠN VỊ THU THẬP VÀ QUẢN LÝ DỮ LIỆU CÁ NHÂN"
            />
            <h3>Công ty TNHH SO NATURAL VIỆT NAM</h3>
            <ul className="grid gap-[16px] list-none">
              <SupportText
                content="- Địa chỉ đăng ký kinh doanh: 15A Nguyễn Trung Trực, Phường 5,
                Quận Bình Thạnh, TP Hồ Chí Minh"
              />
              <SupportText content="- Văn phòng: 1 Giang Văn Minh, An Phú, Quận 2, TP THủ Đức" />
              <SupportText content="- Điện thoại văn phòng: 0931982145 (Zalo)" />
              <p className="font-[400] text-[#484848] text-[13px] text-justify lg:text-[16px] leading-[26.6px]">
                - Mail:{" "}
                <span className="font-bold text-[16px] underline">
                  sale@sonatural.vn
                </span>
              </p>
            </ul>
            <SupportText
              type="title"
              content="7. QUYỀN VÀ NGHĨA VỤ CỦA KHÁCH HÀNG"
            />
            <SupportText
              content="7.1. Yêu cầu truy cập, chỉnh sửa dữ liệu cá nhân"
              className="font-[400] md:text-[18px] my-[12px]"
            />
            <SupportText
              content="Khách Hàng có quyền truy cập để xem, tự kiểm tra, cập nhật, chỉnh
              sửa dữ liệu cá nhân của mình bằng cách đăng nhập vào tài khoản và
              chỉnh sửa thông tin cá nhân như: ảnh đại diện; tên; giới tính;
              ngày, tháng, năm sinh; số điện thoại; mật khẩu."
              className="font-[400]"
            />
            <SupportText
              content="7.2. Rút lại sự đồng ý, xóa dữ liệu"
              className="font-[400] md:text-[18px] my-[12px]"
            />
            <ul className="list-none grid gap-[16px]">
              <SupportText
                content="Khách Hàng có quyền rút lại sự đồng ý cho phép thu thập, sử dụng
                và/hoặc tiết lộ và/hoặc yêu cầu xóa dữ liệu cá nhân của mình mà
                So Natural Việt Nam đang lưu giữ hoặc kiểm soát và yêu cầu So
                Natural Việt Nam thực hiện bằng cách:"
                className="font-[400]"
              />
              <p className="font-[400] text-[#484848] text-[13px] text-justify lg:text-[16px] leading-[26.6px]">
                - Gửi email đến hợp thư điện tử:{" "}
                <span className="font-normal text-[13px] underline text-[#ff9900]">
                  sale@sonatural.vn
                </span>{" "}
                quản trị viên kiểm tra thông tin và liên lạc lại với Khách Hàng
                để xác nhận thông tin 1 lần nữa và quản trị viên chỉnh sửa/xóa
                thông tin cho Khách Hàng.
              </p>
              <SupportText
                content="- So Natural Việt Nam sẽ xử lý các yêu cầu này theo Chính Sách
                Bảo Mật cũng như quy định pháp luật có liên quan. Tuy nhiên,
                việc Khách Hàng rút lại sự cho phép của Khách Hàng đồng nghĩa
                với việc So Natural Việt Nam sẽ không thể tiếp tục cung cấp các
                Dịch vụ đến Khách Hàng và So Natural Việt Nam có thể cần phải
                chấm dứt mối quan hệ hiện tại giữa Khách Hàng và/hoặc hợp đồng
                mà Khách Hàng có với So Natural Việt Nam."
              />

              <SupportText
                content="- Khách Hàng có nghĩa vụ tự bảo vệ dữ liệu cá nhân của mình; Tôn
                trọng, bảo vệ dữ liệu cá nhân của người khác; Cung cấp đầy đủ,
                chính xác dữ liệu cá nhân khi đồng ý cho phép xử lý dữ liệu cá
                nhân."
              />
            </ul>
            <SupportText
              content="8. THÔNG TIN VỀ TRẺ EM"
              className="font-[500] md:text-[18px] my-[12px]"
            />
            <SupportText
              content="Các Dịch Vụ này không dành cho trẻ em. So Natural Việt Nam không
              cố tình thu thập hay lưu giữ bất kỳ dữ liệu cá nhân hay thông tin
              không nhận dạng cá nhân nào của bất kỳ trẻ em nào, bất kỳ phần nào
              của Nền tảng của So Natural Việt Nam hoặc Các Dịch Vụ khác cũng
              không dành cho trẻ em. Bố/mẹ hoặc người giám hộ của trẻ em vui
              lòng giám sát và đảm bảo thông tin cá nhân của trẻ mà mình đang
              giám hộ không đăng tải thông tin cá nhân cho So Natural Việt Nam.
              Trong trường hợp thông tin cá nhân của của trẻ em do quý phụ huynh
              giám hộ được cung cấp cho So Natural Việt Nam, Bố/mẹ hoặc người
              giám hộ theo đồng ý với việc xử lý thông tin của trẻ em, và đồng ý
              chịu sự điều chỉnh của Chính Sách này thay mặt cho người được giám
              hộ. So Natural Việt Nam sẽ khóa bất kỳ tài khoản nào chỉ được sử
              dụng bởi đối tượng trẻ em như vậy và sẽ gỡ và/hoặc xóa bất kỳ dữ
              liệu cá nhân nào mà So Natural Việt Nam cho là đã được gửi bởi trẻ
              em."
            />
            <SupportText
              content={"9. QUY ĐỊNH BẢO MẬT THÔNG TIN THANH TOÁN"}
              type="title"
            />
            <SupportText
              content="9.1. Cam kết bảo mật"
              className="font-[500] md:text-[18px] my-[12px]"
            />
            <SupportText
              content="- Hệ thống thanh toán thẻ được cung cấp bởi các đối tác cổng thanh
              toán (“Đối Tác Cổng Thanh Toán”) đã được cấp phép hoạt động hợp
              pháp tại Việt Nam. Theo đó, các tiêu chuẩn bảo mật thanh toán thẻ
              tại So Natural Việt Nam.vn và Ứng dụng bán hàng So Natural Việt
              Nam đảm bảo tuân thủ theo các tiêu chuẩn bảo mật ngành."
              className="font-[500]"
            />
            <SupportText
              content="9.2. Quy định bảo mật"
              className="font-[500] md:text-[18px] my-[12px]"
            />
            <ul className="grid gap-[16px] list-none">
              <li className="font-[500] text-[#484848] text-[13px] lg:text-[16px] text-justify">
                Chính sách giao dịch thanh toán bằng thẻ quốc tế và thẻ nội địa
                (internet banking) đảm bảo tuân thủ các tiêu chuẩn bảo mật của
                các Đối Tác Cổng Thanh Toán gồm:
              </li>
              {dataSecurity.map((item, index) => (
                <div key={index}>
                  <SupportText content={item.content} />
                  {item?.children && (
                    <ul className="custom-list-item ml-[24px] flex flex-col gap-[8px]">
                      {item?.children.map((children, indexItem) => (
                        <SupportText
                          key={indexItem}
                          content={children.content}
                          type="step"
                        />
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </ul>
            <SupportText content={"10. THÔNG TIN LIÊN HỆ"} type="title" />
            <SupportText
              content={`Trong trường hợp Khách Hàng có bất kỳ thắc mắc hoặc khiếu nại nào
              về Chính Sách này hoặc thực tế việc thu thập, quản lý thông tin cá
              nhân của So Natural Việt Nam, xin vui lòng liên hệ với So Natural
              Việt Nam bằng cách:`}
              className="mb-[12px]"
            />
            <ul className="list-none grid gap-[16px]">
              <li className="font-[600] text-[#484848] text-[13px] md:text-[16px] text-justify">
                Gọi điện thoại đến hotline: 0931982145
              </li>
              <li className="font-[600] text-[#484848] text-[13px] md:text-[16px] text-justify">
                Gửi thư điện tử đến địa chỉ email:{" "}
                <span className="text-[16px] font-bold underline">
                  sale@sonatural.vn
                </span>
              </li>
              <li className="font-[600] text-[#484848] text-[13px] md:text-[16px] text-justify">
                Chính Sách Bảo Mật Thông Tin Cá Nhân này có hiệu lực từ ngày
                04-06-2024
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
