import Blog from '@/components/common/Blog';
import { BlogSkeleton } from '@/components/skeleton/common/blog';
import useBlogsQuery from '@/hooks/ReactQuery/blogs.query';
import { Empty } from 'antd';

export default function RelatedBlogs() {
  const { blogList, isLoading } = useBlogsQuery('3', '1');

  return (
    <div className="">
      <div className="text-[16px] font-bold text-ciment uppercase my-8">
        Bài viết liên quan
      </div>

      {blogList?.length > 0 && !isLoading ? (
        <>
          {blogList?.map((item, index: number) => (
            <div key={index}>
              <Blog buttonViewMore blog={item} />
              <hr />
            </div>
          ))}
        </>
      ) : (
        <Empty description="Không có bài viết nào" />
      )}

      {isLoading && (
        <>
          {Array.from({ length: 3 }).map((_: any, index: number) => (
            <BlogSkeleton key={index} />
          ))}
        </>
      )}
    </div>
  );
}
