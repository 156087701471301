/* eslint-disable import/extensions */
import urls from "@/utils/urls";
import axiosClient from "./axiosClient";
import { CreatingCartType } from "@/type/local/cart";
import { BaseResponse } from "@/type/base/baseResponse";
import { Category } from "@/type/common/category";
import { BaseData } from "@/type/base/baseData";

const categoryApi = {
  getByPaging(
    sort: string,
    paginaionWithCount: boolean,
    pageIndex: number,
    pageSize: number,
    pageStart: string,
    paginationLimit: number,
    fields: string,
    populate: string,
    filter: any
  ) {
    const payload = {
      sort,
      "pagination[withCount]": paginaionWithCount,
      "pagination[page]": pageIndex,
      "pagination[pageSize]": pageSize,
      "pagination[start]": pageStart,
      "pagination[limit]": paginationLimit,
      fields,
      populate,
      filter,
    };
    return axiosClient.get<BaseResponse<Category>>(urls.CATEGORY, {
      params: { ...payload },
    });
  },
  create(payload: CreatingCartType) {
    return axiosClient.post(urls.CATEGORY, payload);
  },
  getById(id: number) {
    return axiosClient.get(`${urls.CATEGORY}/${id}`);
  },
  getAll(query?: string) {
    return axiosClient.get<BaseResponse<BaseData<Category>[]>>(
      `${urls.CATEGORY}?${query}&populate=deep,2`
    );
  },
};

export default categoryApi;
