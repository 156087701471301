import { useEffect, useState } from 'react'
import CommentItem from "../CommentItem"
import productApi from "@/api/productApi"
import { BaseData } from "@/type/base/baseData"
import { CommentType } from "@/type/common/product/comment"
import useWindowSize from "@/hooks/useWindowSize"
import CustomerComment from "../CustomerComment"
import { chunkArray } from "@/utils/chunkArray"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"

interface ICommentListProps {
    productId?: string;
    className?: string;
}

const CommentList = (props: ICommentListProps) => {
    const { productId, className } = props;

    const size = useWindowSize()

    const [isValidUser, setIsValidUser] = useState<boolean>(false)
    const [allComments, setAllComments] = useState<BaseData<CommentType>[]>([])

    const commentChunk = chunkArray(allComments || [], 5)

    const fetchAllProductReviews = async () => {
        if (productId) {
            try {
                await productApi.getAllComments(Number(productId)).then(res => {
                    setAllComments(res.data.data)
                })
            } catch (error) {
                console.log('Error at fetchProductReviews', error)
            }
        }
    }

    const checkValidUserToComment = async () => {
        if (productId) {
            try {
                const body = { product_id: productId }
                const isValid = await productApi.checkValidUserToComment(body).then(res => res.data)
                setIsValidUser(isValid)
            } catch (error) {
                console.log("Error at checkValidUserToComment", error)

            }
        }
    }

    useEffect(() => {
        fetchAllProductReviews()
        checkValidUserToComment()
    }, [])

    return (
        <div className={className}>
            <CustomerComment isValidUser={isValidUser} productId={productId} />

            {!!allComments?.length ? (
                <div className="md:hidden">
                    {allComments?.slice(0, 2).map((comment, index) => {
                        return (
                            <CommentItem data={comment} key={index} />
                        )
                    })}
                </div>
            ) : (
                <p className="p-[16px] md:hidden">
                    Chưa có bình luận cho sản phẩm này
                </p>
            )}

            {!!allComments.length ? (
                <Swiper
                    observer={true}
                    observeParents={true}
                    onSwiper={(swiper) => swiper.update()}
                    slidesPerView={1}
                    navigation={size.width >= 768}
                    modules={[Navigation]}
                    className="swiper-feedback hidden md:block"
                >
                    {commentChunk.map((chunk, index) => {
                        return (
                            <SwiperSlide key={index}>
                                {chunk?.map((comment, index) => {
                                    return (
                                        <CommentItem data={comment} key={index} />
                                    )
                                })}
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            ) : (
                <p className="p-[16px] hidden md:block">
                    Chưa có bình luận cho sản phẩm này
                </p>
            )}

        </div>
    )
}

export default CommentList