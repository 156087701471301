import svgs from "@/assets/svgs"
import { BaseData } from "@/type/base/baseData"
import { CommentType } from "@/type/common/product/comment"
import dayjs from "dayjs"

interface ICommentItemProps {
    data: BaseData<CommentType>,
}

const CommentItem = (props: ICommentItemProps) => {
    const { data } = props

    return (
        <div className="flex items-start gap-[16px] md:gap-[24px] p-[24px] border-b border-[#E6E6E6]">
            <img
                src={data?.attributes?.user?.data?.attributes?.avatar?.data?.attributes?.url || svgs.avatarDefault}
                className="rounded-full aspect-square max-w-[40px] max-h-[40px] md:max-w-[90px] md:max-h-[90px] w-full h-full object-cover"
                width={90}
                height={90}
                loading="lazy"
            />
            <div className="flex flex-col gap-[8px] md:gap-[16px]">
                <p className="text-[14px] md:text-[16px] font-[400]">
                    {data?.attributes?.user?.data?.attributes?.fullName}
                </p>
                <p className="text-[11px] md:text-[16px] font-[400] text-[#818080]">
                    {dayjs(data?.attributes?.createdAt).format("DD.MM.YYYY")}
                </p>
                <p className="text-[14px] md:text-[16px] font-[400]">
                    {data?.attributes?.comment}
                </p>
                {data?.attributes?.relyComment !== null && (
                    <div className="p-[16px] md:p-[16px_24px] rounded-[16px] bg-[#F0F0F0]">
                        <p className="text-[14px] md:text-[16px] font-[400] text-[#545454]">
                            {data?.attributes?.relyComment}
                        </p>
                    </div>
                )}
            </div>
        </div>
    )
}

export default CommentItem