import { TrackingItemUI } from "../OrderDetailWrapper/HeaderOrder/ContentHeader";

interface ITrackingLogProps {
  trackingLog: TrackingItemUI;
}

const TrackingLog = (props: ITrackingLogProps) => {
  const { trackingLog } = props;
  return (
    <div className="flex justify-start items-start gap-[16px]">
      <div className="min-w-[90px] w-[90px] flex justify-start items-center gap-[4px] text-nowrap">
        <div className="min-w-[6px] w-[6px] min-h-[6px] h-[6px] bg-black rounded-full"></div>
        <span className="text-[14px] font-[400] leading-[16.94px] text-black">
          {trackingLog.actionAt}
        </span>
      </div>
      <div className="flex flex-col gap-[8px] item-start">
        {trackingLog?.logs?.map((item, itemIndex) => (
          <div key={itemIndex} className="flex flex-col gap-[5px]">
            <p className="font-[700] text-[14px] leading-[16.94px] text-black">
              {item.statusName}
            </p>
            <p className="text-[14px] font-[400] leading-[16.94px] text-black text-wrap text-justify">
              {item.address}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrackingLog;
