import FeedbackList from "./FeedbackList";
import CommentList from "./CommentList";
import './FeedbackComment.scss'
import { useState } from "react";
import clsx from "clsx";
import { ProductDetailsType } from "@/type/response/productDetails";
import svgs from "@/assets/svgs";
import { useNavigate } from "react-router-dom";
import routes from "@/utils/routes";

interface IFeedbackCommentProps {
    productId?: string;
    product: ProductDetailsType;
}

const FeedbackComment = (props: IFeedbackCommentProps) => {
    const { productId, product } = props

    const [selectedTab, setSelectedTab] = useState<number>(1)

    const navigate = useNavigate()

    return (
        <div className="flex flex-col gap-[24px] py-[24px] md:py-0">
            <div className="flex items-center justify-between px-[16px] md:px-0 w-full">
                <div className="flex items-center">
                    <div
                        className={clsx(
                            "flex p-[12px_20px] md:p-[10px_20px] items-center justify-center rounded-[24px] cursor-pointer transition-all",
                            { "border border-[#000000]": selectedTab === 1 }
                        )}
                        onClick={() => setSelectedTab(1)}
                    >
                        <p className="text-[14px] md:text-[18px] font-[600] md:font-[400]">
                            Đánh giá
                        </p>
                    </div>

                    <div
                        className={clsx(
                            "flex p-[12px_20px] md:p-[10px_20px] items-center justify-center rounded-[24px] cursor-pointer transition-all",
                            { "border border-[#000000]": selectedTab === 2 }
                        )}
                        onClick={() => setSelectedTab(2)}
                    >
                        <p className="text-[14px] md:text-[18px] font-[600] md:font-[400]">
                            Bình luận
                        </p>
                    </div>
                </div>

                <div
                    className="p-[16px] md:hidden"
                    onClick={() => {
                        if (selectedTab === 1) {
                            navigate(`${routes.PRODUCT}/${product?.slug}${routes.FEEDBACKS}`)
                        } else {
                            navigate(`${routes.PRODUCT}/${product?.slug}${routes.COMMENTS}`)
                        }
                    }}
                >
                    <img
                        src={svgs.arrowLeftBlack}
                        alt=""
                        className="rotate-180 justify-self-end w-[8px] h-[16px]"
                    />
                </div>

            </div>

            {/* <div className="relative w-full overflow-hidden">
                <div
                    className={clsx(
                        "flex transition-transform duration-300 w-[200%]",
                        selectedTab === 1 ? "translate-x-0" : "-translate-x-1/2"
                    )}
                >
                    <div className="w-1/2">
                        <FeedbackList productId={productId} product={product} />
                    </div>
                    <div className="w-1/2">
                        <CommentList productId={productId} />
                    </div>
                </div>
            </div> */}

            {selectedTab === 1 ? (
                <FeedbackList
                    productId={productId}
                    product={product}
                // className="max-w-full sm:max-w-[450px] md:max-w-[500px] xl:max-w-[734px]"
                />
            ) : (
                <CommentList
                    productId={productId}
                // className="max-w-full sm:max-w-[450px] md:max-w-[500px] xl:max-w-[734px]"
                />
            )}
        </div>
    )
}

export default FeedbackComment