import { useState } from "react";
import { useProfileLayoutContext } from "@/layouts/ProfileLayout";
import { profileRank, profileRankId } from "@/utils/profile";
import MemberCard from "@/components/pages/Profile/MemberCard";
import { BaseData } from "@/type/base/baseData";
import { MembershipClass } from "@/type/response/profile";
import VoucherSkeleton from "@/components/skeleton/common/voucher";

interface VoucherProps {
  currentRank: string;
  availableRank: string;
  vouchers?: BaseData<MembershipClass>;
  isLoading?: boolean;
}

const Vouchers = ({
  currentRank,
  availableRank,
  vouchers,
  isLoading,
}: VoucherProps) => {
  if (isLoading) {
    return (
      <div className="p-4 bg-[#F7F7F7] flex flex-col justify-start items-start gap-4 xl:grid xl:grid-cols-2">
        {Array.from({ length: 4 }).map((_: any, key: number) => (
          <VoucherSkeleton key={key} />
        ))}
      </div>
    );
  }
  if (vouchers?.attributes?.vouchers?.data?.length === 0) {
    return (
      <div className="flex items-center justify-center">
        <p className="text-[14px] xl:text-[16px] font-medium">
          Không có vouchers khả dụng
        </p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 gap-[12px]">
      {vouchers &&
        vouchers?.attributes?.vouchers?.data?.length > 0 &&
        vouchers?.attributes?.vouchers?.data?.map((item, index) => (
          <div
            className={`flex flex-col gap-[8px] bg-white p-[16px_32px] rounded-[16px] border-[1px] border-[#E6E6E6] h-fit`}
            key={index}
          >
            <h3
              className={`m-0 font-medium text-[14px] xl:text-[16px] text-nowrap ${
                currentRank !== availableRank ? "text-[#545454]" : "text-black"
              }`}
            >
              {item?.attributes?.title}
            </h3>
            <p
              className={`uppercase text-[11px] xl:text-[13px] font-medium leading-[18.2px] p-[4px] rounded-[4px] w-fit ${
                currentRank !== availableRank
                  ? "bg-[#F0F0F0] text-[#545454]"
                  : "bg-[#F8F2E2] text-black"
              }`}
            >
              {item?.attributes?.code}
            </p>
          </div>
        ))}
    </div>
  );
};

function Membership() {
  const [activeTab, setActiveTab] = useState<number>(1);
  const {
    membershipClassesData,
    // isQueryMembershipClassesLoading,
    currentRank,
    nextRank,
    profile,
    allVouchers,
  } = useProfileLayoutContext() || {};

  const bronzeData = membershipClassesData.find(
    (item) => item.id === profileRankId.BRONZE
  );
  const silverData = membershipClassesData.find(
    (item) => item.id === profileRankId.SILVER
  );
  const goldData = membershipClassesData.find(
    (item) => item.id === profileRankId.GOLD
  );
  const diamondData = membershipClassesData.find(
    (item) => item.id === profileRankId.DIAMOND
  );

  const rankTabs = [
    {
      key: 1,
      name: "Hạng Đồng",
      children: (
        <Vouchers
          currentRank={currentRank?.name}
          availableRank={profileRank.BRONZE}
          vouchers={bronzeData}
          // isLoading={isQueryMembershipClassesLoading}
        />
      ),
    },
    {
      key: 2,
      name: "Hạng Bạc",
      children: (
        <Vouchers
          currentRank={currentRank?.name}
          availableRank={profileRank.SILVER}
          vouchers={silverData}
          // isLoading={isQueryMembershipClassesLoading}
        />
      ),
    },
    {
      key: 3,
      name: "Hạng Vàng",
      children: (
        <Vouchers
          currentRank={currentRank?.name}
          availableRank={profileRank.GOLD}
          vouchers={goldData}
          // isLoading={isQueryMembershipClassesLoading}
        />
      ),
    },
    {
      key: 4,
      name: "Hạng Kim Cương",
      children: (
        <Vouchers
          currentRank={currentRank?.name}
          availableRank={profileRank.DIAMOND}
          vouchers={diamondData}
          // isLoading={isQueryMembershipClassesLoading}
        />
      ),
    },
  ];
  let amountDecrease = 0;

  allVouchers.forEach((item) => {
    if (item?.attributes?.status === "USED") {
      amountDecrease += Number(
        item?.attributes?.voucher?.data?.attributes?.amountDecrease || 0
      );
    }
  });
  return (
    <div className="xl:p-[0_0_32px_24px] bg-[#f7f7f7] md:bg-transparent flex flex-col gap-[8px]">
      <div className="bg-white p-[24px_16px]  xl:p-[32px] gap-[24px] xl:ml-[111.5px] xl:mr-[247.5px] flex flex-col items-center justify-center">
        <MemberCard
          currentPoint={profile?.point}
          nextRankPoint={nextRank?.point}
          amountDecrease={amountDecrease}
          currentRank={currentRank?.name}
        />
        {nextRank?.point - profile?.point > 0 && (
          <p className="text-[14px] leading-[19.6px] text-nowrap">
            Chỉ còn {nextRank?.point - profile?.point} điểm nữa sẽ lên{" "}
            {nextRank?.name}, cố lên bạn ơi!
          </p>
        )}
      </div>
      <div className="bg-white p-[24px_16px] xl:p-0 flex flex-col gap-[16px] xl:min-h-[416px] rounded-bl-[16px] rounded-br-[16px]">
        <div className="overflow-x-auto no-scrollbar">
          <ul className="flex items-center justify-between w-full list-none">
            {rankTabs.map((item) => (
              <li className="flex flex-col" key={item.key}>
                <p
                  className={`p-[6px_10px] text-[14px] xl:text-[16px] leading-[19.36px] cursor-pointer text-nowrap ${
                    activeTab === item?.key
                      ? "font-[600] text-black"
                      : "text-[#545454] font-normal"
                  }`}
                  onClick={() => setActiveTab(item?.key)}
                >
                  {item?.name}
                </p>
              </li>
            ))}
          </ul>
        </div>
        <div className="p-[24px] opacity-[87%] bg-[#F7F7F7] flex-1">
          {rankTabs.map((item) => (
            <div key={item.key}>
              {item?.key === activeTab && item?.children}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Membership;
