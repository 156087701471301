import { BaseData } from "@/type/base/baseData";
import { BaseResponse } from "@/type/base/baseResponse";
import urls from "@/utils/urls";
import axiosClient from "./axiosClient";
import { MembershipClass, UserVouchers } from "@/type/response/profile";

const profileApi = {
  getMembershipClasses() {
    return axiosClient.get<BaseResponse<BaseData<MembershipClass>[]>>(
      `${urls.MEMBERSHIP_CLASSES}?populate=deep,2`
    );
  },

  getUserVouchers() {
    return axiosClient.get<BaseResponse<BaseData<UserVouchers>[]>>(
      `${urls.MY_VOUCHER}`
    );
  },
};

export default profileApi;
