import svgs from "@/assets/svgs";
import { BaseData } from "@/type/base/baseData";
import { OrderDetailsResponse } from "@/type/response/order";
import { Modal } from "antd";
import FeedbackComponent from "../Feedback";

interface IFeedbackModalProps {
  open: boolean;
  onClose: () => void;
  handleOk?: () => void;
  handleCancel?: () => void;
  status: string;
  orderList: BaseData<OrderDetailsResponse>[];
  openFinishModal: () => void;
  orderId: number;
}

const FeedbackModal = (props: IFeedbackModalProps) => {
  const {
    open,
    onClose,
    handleOk,
    handleCancel,
    status,
    orderList,
    openFinishModal,
    orderId,
  } = props;

  const onOk = () => {
    handleOk && handleOk();

    onClose();
  };

  const onCancel = () => {
    handleCancel && handleCancel();

    onClose();
  };

  return (
    <Modal
      rootClassName="feedback"
      open={open}
      onOk={onOk}
      width={720}
      onCancel={onCancel}
      centered
      footer={null}
      closeIcon={
        <img src={svgs.closeIcon} alt="close-icon" className="w-full h-full" />
      }
      styles={{
        content: {
          padding: "32px",
          borderRadius: "24px",
        },
      }}
    >
      <FeedbackComponent
        status={status}
        orderList={orderList}
        orderId={orderId}
        openFinishModal={() => {
          openFinishModal();
        }}
      />
    </Modal>
  );
};

export default FeedbackModal;
