import { BaseResponse } from './../type/base/baseResponse';
/* eslint-disable import/extensions */
import urls from '@/utils/urls';
import axiosClient from './axiosClient';
import { BaseData } from '@/type/base/baseData';
import { CategoriesTree, HomeType } from '@/type/response/home';

const homeApi = {
  get(sort: string, paginationWithCount: string) {
    const params = {
      sort,
      'pagination[WithCount]': paginationWithCount,
    };
    return axiosClient.get<BaseResponse<BaseData<HomeType>>>(
      `${urls.HOME_PAGE}?${new URLSearchParams(
        Object.entries(params).map(([key, value]) => [
          encodeURIComponent(key),
          encodeURIComponent(value),
        ])
      )}`
    );
  },
  getAll() {
    return axiosClient.get<BaseResponse<BaseData<HomeType>>>(urls.HOME_PAGE);
  },
  getCategoriesTree() {
    return axiosClient.get<CategoriesTree[]>('categories/tree')
  }
};
export default homeApi;

export type LoginPayload = {
  identifier: string;
  password: string;
};
