interface IAgencyInfoSectionProsps {
  isDevided?: boolean;
  title: string;
  content: string;
}
function AgencyInfoSection({
  isDevided = true,
  title,
  content,
}: IAgencyInfoSectionProsps) {
  return (
    <>
      <div className="grid grid-cols-[40%_60%] md:grid-cols-[25%_75%] gap-[8px]">
        <p className="font-bold text-[16px] md:text-nowrap">{title}</p>
        <p className="text-[#484848] text-[16px]">{content}</p>
      </div>
      {isDevided && (
        <div className="border-[1px] border-[#808080] w-full"></div>
      )}
    </>
  );
}

export default AgencyInfoSection;
