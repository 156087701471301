import { BaseData } from '@/type/base/baseData';
import { BaseProductData } from '@/type/base/baseProduct';
import { useTranslation } from 'react-i18next';
import SectionVertical from '../SectionVertical';

interface HistoryComponentProps {
  productsHistory: BaseData<BaseProductData>[];
  isLoading?: boolean;
}

export default function HistoryComponent({
  productsHistory,
  isLoading
}: HistoryComponentProps) {
  const { t } = useTranslation();
  return (
    <>
      {productsHistory.length > 0 && (
        <div className="grid grid-cols-12">
          <p className="col-span-12 text-[18px] font-[400] leading-[25.2px] mb-[24px] md:mb-[32px]">
            {t('watched')}
          </p>
          <div className="col-span-12 md:mx-0">
            <SectionVertical
              isLoading={isLoading}
              slidePreviewMobile={2}
              slidePreviewDesktop={5}
              products={productsHistory}
              thumbType="primary"
              contentPage="product-detail"
            />
          </div>
        </div>
      )}
    </>
  );
}
