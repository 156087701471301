export default function formatText(object: any) {
  let output = '';

  switch (object?.type) {
    case 'heading':
      output += `<h${object?.level}>`;
      if (object?.level && object?.children.length) {
        object?.children?.forEach((element: any) => {
          if (element?.type === "link") {
            output += `<a href=${element?.url} style="font: inherit; color: #556d51">${element?.children[0]?.text}</a>`;
          } else {
            output += `${element.text}`;
          }
        });
      } else {
        output += '';
      }
      output += `</h${object?.level}>`;
      break;

    case 'paragraph':
      output += `<p>`
      if (object?.children?.length) {
        object?.children?.forEach((element: any) => {
          if (element?.type === "link") {
            output += `<a href=${element?.url} style="font: inherit; color: #556d51; font-weight: bold">${element?.children[0]?.text}</a>`;
          } else {
            output += `${element.text}`;
          }
        });
      } else {
        output += '';
      }
      output += `</p>`
      break;

    case 'list':
      if (object?.format == 'unordered') {
        output += `<ul>`
        object?.children.forEach((element: any) => {
          output += `<li>`
          if (element?.children?.length) {
            element?.children?.forEach((elm: any) => {
              if (elm?.type === "link") {
                output += `<a href=${elm?.url} style="font: inherit; color: #556d51; font-weight: bold">${elm?.children[0]?.text}</a>`;
              } else {
                output += `${elm.text}`;
              }
            });
          } else {
            output += ``;
          }
          output += `</li>`
        })
        output += `</ul>`

      } else if (object?.format == 'ordered') {
        output += `<ol>`
        object?.children.forEach((element: any) => {
          output += `<li>`
          if (element?.children?.length) {
            element?.children?.forEach((elm: any) => {
              if (elm?.type === "link") {
                output += `<a href=${elm?.url} style="font: inherit; color: #556d51; font-weight: bold">${elm?.children[0]?.text}</a>`;
              } else {
                output += `${elm.text}`;
              }
            });
          } else {
            output += ``;
          }
          output += `</li>`
        })
        output += `</ol>`
      } else {
        // output += `< h3 > ${object?.children[0]?.text} </h3 > `
      }
      break;

    case 'image':
      if (object?.image) {
        output += `<img
          src=${object?.image?.url}
          alt="${object?.image?.alternativeText}"
          width="100%"
          className="w-full h-[360px] object-cover object-left md:h-[480px]"
          loading="lazy"
        />`;
      } else {
        output += ` `;
      }
      break;

    default:
      output += '';
  }

  return output;
}
