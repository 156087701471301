import { Popover } from "antd";
import { useState } from "react";
import ChatFrame from "./ChatFrame";
import svgs from "@/assets/svgs";
import useWindowSize from "@/hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
import routes from "@/utils/routes";

const ChatWidget = () => {
  const [open, setOpen] = useState(false);
  const size = useWindowSize();
  const navigate = useNavigate();

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
    // if (!newOpen) {
    //   handleOffSocket()
    // }
  };

  if (size.width < 768) {
    return (
      <div
        className="fixed bottom-32 right-[16px] w-[48px] h-[48px] z-[100] cursor-pointer"
        onClick={() => navigate(routes.CHAT)}
      >
        <img src={svgs.chatCircle} alt="chat-icon" className="w-full h-full" />
      </div>
    );
  }

  return (
    <Popover
      content={<ChatFrame closeChat={hide} />}
      trigger="click"
      placement="topRight"
      arrow={false}
      open={open}
      onOpenChange={handleOpenChange}
      rootClassName="fixed right-[16px] bottom-[16px] md:bottom-0 md:right-16 chat"
    >
      <div className="fixed bottom-8 right-[16px] w-[48px] h-[48px] z-[100] cursor-pointer">
        <img src={svgs.chatCircle} alt="chat-icon" className="w-full h-full" />
      </div>
    </Popover>
  );
};

export default ChatWidget;
