import Breadcrumb from "@/components/common/Breadcrumb";
import SupportText from "@/components/common/Support/SupportText";
import routes from "@/utils/routes";
import styled from "styled-components";

const TableStyle = styled.table`
  &,
  th,
  td {
    border: 2px solid #484848;
    padding: 10px 20px 30px;
    color: #484848;
    text-align: center;
  }

  th {
    font-weight: 700;
  }

  td {
    font-weight: 400;
  }
`;
const ReturnPolicy = () => {
  const breadcrumbItems = [
    {
      href: routes.HOME,
      title: "Trang chủ",
    },
    {
      href: routes.RETURN_POLICY,
      title: "Chính sách đổi trả",
    },
  ];

  const dataCaseReturn = [
    {
      content: `Sản phẩm mắc lỗi từ phía nhà sản xuất (hỏng hóc, đổ vỡ sản phẩm, bị lỗi kỹ thuật…).`,
    },
    {
      content: `Sản phẩm bị hư hỏng, trầy xước, nứt vỡ do quá trình vận chuyển của nhân viên giao hàng.`,
    },
    {
      content: `Sản phẩm đã hết hoặc gần hết thời hạn sử dụng <1 tháng).`,
    },
    {
      content: `Sản phẩm không đúng như yêu cầu của khách hàng do nhà bán hàng
                soạn sai sản phẩm.`,
    },
    {
      content: `Sản phẩm phải còn nguyên vỏ hộp, tem nhãn và chưa qua sử dụng.`,
    },
  ];

  const dataNotCaseReturn = [
    {
      content: `Sản phẩm quà tặng, các sản phẩm trong chương trình giảm giá đặc biệt.`,
    },
    {
      content: `Sản phẩm đã quá hạn đổi trả (7 ngày).`,
    },
    {
      content: `phẩm đã bị bóc tem nhãn, seal nếu có.`,
    },
    {
      content: `Sản phẩm khách đã thử hoặc qua sử dụng từ 1 lần trở lên.`,
    },
    {
      content: `Bao bì, vỏ hộp sản phẩm bị hư hỏng, trầy xước do lỗi từ phía
                khách hàng.`,
    },
    {
      content: `Sản phẩm không phải mua từ trang web So’Natural.`,
    },
  ];

  const dataReuturnAtHome = [
    {
      content: `Với các đơn hàng đã nhận hàng và thanh toán, So’Natural Việt Nam
                sẽ chuyển khoản cho khách hàng sau khi đã nhận được hàng khách
                trả.`,
    },
    {
      content: `Các đơn hàng khách hàng đã thanh toán online, So’Natural Việt
                Nam chuyển lại tiền vào tài khoản khách hàng cung cấp sau khi
                trao đổi thỏa thuận hoàn tiền.`,
    },
  ];
  return (
    <>
      <Breadcrumb items={breadcrumbItems} />
      <div className="wrapper my-[48px] md:my-[96px] md:px-[120px] md:container md:max-w-[1280px] md:mx-auto">
        <div className="w-full">
          <SupportText
            type="header"
            content="Hướng dẫn chính sách đổi trả"
            className="!text-[#000000]"
          />
          <div className="support-content mx-4 lg:mx-0">
            <SupportText content={"Chính sách đổi trả"} type="title" />
            <div className="flex justify-center lg:ml-[20px] md:block">
              <TableStyle className="w-[100%]">
                <tr className="h-[20px]">
                  <th className="w-[20%] "></th>
                  <th className="text-[13px] md:text-[14px] lg:text-[16px]">
                    Sản Phẩm lỗi (Từ phía nhà cung cấp)
                  </th>
                  <th className="text-[13px] md:text-[14px] lg:text-[16px]">
                    Sản Phẩm lỗi (Từ phía người sử dụng)
                  </th>
                </tr>
                <tr>
                  <td className="font-bold text-center text-[13px] lg:text-[16px]">
                    1 - 7 ngày
                  </td>
                  <td className="text-[13px] lg:text-[16px]">
                    Đổi mới/ Trả không thu phí
                  </td>
                  <td className="text-[13px] lg:text-[16px]">
                    Không hỗ trợ đổi trả
                  </td>
                </tr>
                <tr>
                  <td className="font-bold text-center text-[13px] lg:text-[16px]">
                    7 ngày trở đi{" "}
                  </td>
                  <td
                    colSpan={2}
                    className="text-center text-[13px] lg:text-[16px]"
                  >
                    Không hỗ trợ đổi trả
                  </td>
                </tr>
              </TableStyle>
            </div>

            <SupportText content={"Các trường hợp nhận đổi trả"} type="title" />

            <ul className="custom-list-item flex flex-col gap-[8px] ml-[10px]">
              {dataCaseReturn.map((item, index) => (
                <SupportText key={index} content={item.content} type="step" />
              ))}
            </ul>

            <SupportText
              content={"Các trường hợp không áp dụng đổi trả"}
              type="title"
            />
            <ul className="custom-list-item flex flex-col gap-[8px] ml-[10px]">
              {dataNotCaseReturn.map((item, index) => (
                <SupportText key={index} content={item.content} type="step" />
              ))}
            </ul>

            <SupportText content={"Cách thức đổi trả"} type="title" />

            <ul className="custom-list-item flex flex-col gap-[8px] ml-[10px]">
              <SupportText
                content={`So’Natural Việt Nam nhận đổi trả sản phẩm cho khách hàng trong
                vòng 7 ngày, tính kể từ ngày khách hàng nhận được sản phẩm từ
                bên giao hàng.`}
                type="step"
              />
              <SupportText
                content={`Khách hàng cần phải thông báo cho nhân viên So’Natural Việt Nam
                lý do đổi trả và địa chỉ, số điện thoại liên lạc chính xác để
                So’Natural Việt Nam có thể thực hiện quy trình đổi trả sản phẩm
                một cách nhanh chóng nhất theo yêu cầu của quý khách.`}
                type="step"
              />

              <div>
                <p className="flex flex-col md:flex-row font-bold text-[#484848] text-[14px] md:text-[17px] my-2 text-nowrap">
                  Lưu ý: &nbsp;
                  <SupportText
                    content={`Khách hàng có nhu cầu đổi trả sản phẩm chính xin vui lòng nhắn
                  tin qua mục Nhắn Tin tại trang web.`}
                    className="text-wrap"
                  />
                </p>
              </div>
              <SupportText
                content={`So’Natural Việt Nam quan tâm đến sự hài lòng của khách hàng và
                mong muốn nâng cao chất lượng dịch vụ, So’Natural Việt Nam nhận
                đổi trả sản phẩm miễn phí cho khách hàng theo đúng quy định nêu
                trên. Quý khách có thể chuyển hàng qua bưu điện & Liên hệ với
                So’Natural Việt Nam về sản phẩm đổi, mã bưu điện,… để So’Natural
                Việt Nam có thể xử lý và gửi hàng lại sớm nhất khi nhận được sản
                phẩm.`}
                type="step"
              />
            </ul>

            <SupportText
              content={"Phương thức hoàn tiền và phí xử lý"}
              type="title"
            />

            <SupportText content={`Trả hàng tại nhà`} className="font-[500]" />

            <ul className="custom-list-item flex flex-col gap-[8px] ml-[10px]">
              {dataReuturnAtHome.map((item, index) => (
                <SupportText key={index} content={item.content} type="step" />
              ))}

              <p className="flex flex-col md:flex-row font-bold text-[#484848] text-[14px] md:text-[17px] my-2 text-nowrap">
                Lưu ý: &nbsp;
                <SupportText
                  content={` Tất cả các đơn hàng đã thanh toán có sử dụng gift card,
                  So’Natural Việt Nam hỗ trợ chuyển mã sử dụng cho đơn hàng tiếp
                  theo.`}
                  className="text-wrap"
                />
              </p>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReturnPolicy;
