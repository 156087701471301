interface IHistoryProps {
  active?: boolean;
  height?: number;
  width?: number;
}
const History = (props: IHistoryProps) => {
  const { active, height, width } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "32"}
      height={height || "32"}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M16.0001 8.00008V16.0001L21.3334 18.6667M29.3334 16.0001C29.3334 23.3639 23.3639 29.3334 16.0001 29.3334C8.63628 29.3334 2.66675 23.3639 2.66675 16.0001C2.66675 8.63628 8.63628 2.66675 16.0001 2.66675C23.3639 2.66675 29.3334 8.63628 29.3334 16.0001Z"
        stroke={active ? "#000000" : "#808080"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default History;
