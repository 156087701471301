import constants from "@/utils/constants";
import { atom } from 'recoil';

const initialState = JSON.parse(localStorage.getItem(constants.VIEWED_PRODUCTS) || '[]')

export const viewedProductsState = atom<(string | number)[]>({
    key: 'viewedProductsState',
    default: initialState,
});

export const updateViewedProducts = (viewedProducts: (string | number)[], productId: string | number) => {
    const newViewedProducts = [...viewedProducts]

    const index = newViewedProducts.indexOf(productId);
    if (index !== -1) {
        // If productId exists, remove it from its current position
        newViewedProducts.splice(index, 1);
    }

    // Add productId to the beginning of the array
    newViewedProducts.unshift(productId);

    // Limit the array to 5 elements
    if (newViewedProducts.length > 5) {
        newViewedProducts.pop();
    }

    localStorage.setItem(constants.VIEWED_PRODUCTS, JSON.stringify(newViewedProducts))

    return newViewedProducts;
}

export default viewedProductsState;
