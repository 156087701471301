import { selector } from "recoil";
import { CartState, IICartStateRecoil } from "../cartState";

export type CartSelectorType = {
  totalNumberProducts: number;
  totalPrice: number;
  totalThrifty: number;
  productIdsInCart: number[];
};

const cartSelector = selector<CartSelectorType>({
  key: "cartSelector",
  get: ({ get }) => {
    const flashSaleProductIds = JSON.parse(
      localStorage.getItem("flashSaleProductIdsState") || "[]"
    );

    const cartProducts = get<IICartStateRecoil>(CartState).cartProducts;
    let totalNumberProducts = 0;

    for (let i = 0; i < cartProducts.length; i++) {
      totalNumberProducts += cartProducts[i].quantity;
    }
    const totalPrincipal = get<IICartStateRecoil>(
      CartState
    ).cartProducts.reduce((sum, product) => {
      const inFlashSales =
        flashSaleProductIds?.some(
          (item: { id: number }) => item.id === Number(product.data.id)
        ) || false;

      if (
        inFlashSales &&
        !product.data.flashSalePrice &&
        !product.data.pricePromotion
      ) {
        return sum + parseInt(product.data.price) * product.quantity;
      }

      return inFlashSales && product.data.flashSalePrice
        ? sum + parseInt(product.data.flashSalePrice) * product.quantity
        : product.data.pricePromotion
        ? sum + parseInt(product.data.pricePromotion) * product.quantity
        : sum + parseInt(product.data.price) * product.quantity;
    }, 0);

    const totalThrifty = get(CartState).cartProducts.reduce(
      (total, product) => {
        const inFlashSales =
          flashSaleProductIds?.some(
            (item: { id: number }) => item.id === Number(product.data.id)
          ) || false;

        if (inFlashSales && product.data.flashSalePrice) {
          const price =
            parseInt(product.data.price) -
            parseInt(product.data.flashSalePrice);
          return total + price * product.quantity;
        }
        if (
          product.data.pricePromotion !== null &&
          product.data.pricePromotion
        ) {
          const price =
            parseInt(product.data.price) -
            parseInt(product.data.pricePromotion.toString() || "0");
          return total + price * product.quantity;
        }
        return total;
      },
      0
    );

    const productIdsInCart = get(CartState).cartProducts.map((product) =>
      Number(product.data.id)
    );

    const totalPrice = totalPrincipal;

    return {
      totalNumberProducts,
      totalPrice,
      totalThrifty,
      productIdsInCart,
    };
  },
});

export default cartSelector;
