import productApi from "@/api/productApi";
import InputField from "@/components/common/Formfield/InputField";
import clsx from "clsx";
import { RegisterOptions, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

interface IFormValues {
  comment: string;
}

interface ICustomerCommentProps {
  productId?: string;
  isValidUser: boolean;
}

export default function CustomerComment({ productId }: ICustomerCommentProps) {
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<IFormValues>({
    defaultValues: {
      comment: "",
    },
  });

  const commentValidation: RegisterOptions = {
    required: t("commentNotEmpty"),
  };

  const handleSubmitComment = async (data: IFormValues) => {
    if (productId) {
      try {
        const commentData = {
          ...data,
          product_id: productId,
        };

        await productApi.commentProduct(commentData).then((res) => {
          console.log(res);
          toast.success("Thành công");
        });

        reset();
      } catch (error) {
        console.error("error", error);
        toast.error("Cần mua sản phẩm trước khi bình luận");
      }
    }
  };
  return (
    <div className="p-[16px] md:p-[24px] bg-[#F0F0F0]">
      <form
        className="w-full flex items-center gap-[16px] md:gap-[24px]"
        onSubmit={handleSubmit(handleSubmitComment)}
      >
        <InputField
          placeholder="Nhập bình luận của bạn"
          register={register}
          validateSchema={commentValidation}
          type="text"
          name="comment"
          errors={errors.comment}
          bgInput="EAEDE9"
          textColor="556D51"
          className="flex-1 w-full bg-[white] border rounded-[24px] border-[#B0B0B0] p-[10px_16px] text-[12px] md:text-[16px] font-[400]"
        />

        <button
          type="submit"
          className={clsx(
            "p-[12px_20px] md:p-[10px_20px] bg-[#E6E6E6] rounded-[24px]",
            errors.comment ? "bg-[#E6E6E6]" : "bg-[#000]"
          )}
        >
          <p className={clsx(
            "text-[14px] md:text-[18px] font-[600] md:font-[400] text-[#808080]",
            errors.comment ? "text-[#808080]" : "text-[#FFFFFF]"
          )}>
            Bình luận
          </p>
        </button>
      </form>
    </div>
  );
}
