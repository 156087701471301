import { useEffect, useRef, useState } from "react";

export default function useIsMobile(): boolean {
  const screenSize = useRef<number>(window.innerWidth);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      screenSize.current = window.innerWidth;
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobile;
}
