import svgs from "@/assets/svgs";
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import chatApi from "@/api/chatApi";
import queryString from "query-string";
import socket from "@/api/socket";
import { UserProfileType } from "@/type/response/user";
import constants from "@/utils/constants";
import { Image, Input, Spin, Upload, UploadFile } from "antd";
import { BaseData } from "@/type/base/baseData";
import { MessageData } from "@/type/response/chat";
import { getBaseUrl } from "@/utils/data";
import { UploadChangeParam } from "antd/es/upload";
import uploadApi from "@/api/uploadApi";
import useProductsQuery from "@/hooks/ReactQuery/products.query";
import { ProductsResponse } from "@/type/response/products";
import { BaseProductData } from "@/type/base/baseProduct";
import dayjs from "dayjs";
import productApi from "@/api/productApi";
import { useNavigate } from "react-router-dom";
import routes from "@/utils/routes";
import orderApi from "@/api/orderApi";
import { OrderResponse } from "@/type/response/order";
import socketEvent from "@/utils/socketEvent";
import useQueryFAQChats from "@/hooks/ReactQuery/faqChats.query";
import { FAQChatType } from "@/type/response/faqChat";
import messagesApi from "@/api/messagesAPI";
import { MessagesRequestType } from "@/type/request/messages";
import NewProduct from "../NewProduct";
import { formatPriceToVnd } from "@/utils/formatPriceToVnd";
import NotificationHeader from "../NotificationHeader";
import { ProductDetailsType } from "@/type/response/productDetails";

const { TextArea } = Input;
interface IChatContentProps {
  inPage?: boolean;
  closeChat?: () => void;
  advisedProduct?: BaseData<ProductDetailsType>;
}

type NewMessageType = {
  sender: {
    id: number;
    name: string;
  };
  content: string;
  imageUrl: string;
  product?: BaseData<ProductsResponse | BaseProductData | ProductDetailsType> | null;
  createdAt: Date;
  channel?: string;
  order: BaseData<OrderResponse> | null;
};

const ChatContent = ({ inPage = false, closeChat, advisedProduct }: IChatContentProps) => {
  const navigate = useNavigate();
  const baseUrl = getBaseUrl();
  const accessToken = localStorage.getItem(constants.ACCESS_TOKEN);

  const { data: FAQChatsData } = useQueryFAQChats();

  const [message, setMessage] = useState<string>("");
  const [messageList, setMessageList] = useState<BaseData<MessageData>[]>([]);
  const [showChooseFiles, setShowChooseFiles] = useState<boolean>(false);
  const [showPickProduct, setShowPickProduct] = useState<boolean>(false);
  const [isLoadingSendProducts, setIsLoadingSendProducts] = useState<boolean>(false)

  const [newMessages, setNewMessages] = useState<NewMessageType[]>([]);
  const [isChatReady, setIsChatReady] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<
    BaseData<ProductsResponse>[]
  >([]);

  const [searchProductInput, setSearchProductInput] = useState<string>("");

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const { products } = useProductsQuery(
    `filters[name][$containsi]=${searchProductInput}`
  );

  const dataProfile = localStorage.getItem(constants.PROFILE);
  const profile: UserProfileType = dataProfile ? JSON.parse(dataProfile) : {};

  const roomUuid = localStorage.getItem(constants.ROOM_UUID);
  const roomId = localStorage.getItem(constants.ROOM_ID);

  const utmSource = sessionStorage.getItem("utm_source") || "google";

  const handleSendMessage = (
    msg?: string,
    imageIds: string[] = [],
    imageUrl = "",
    product?: BaseData<ProductsResponse | BaseProductData>
  ) => {
    const content = msg !== undefined ? msg : message;

    socket.emit(socketEvent.PRIVATE_MESSAGE, {
      content: content,
      to: roomUuid,
      images: imageIds,
      product_id: product?.id,
      channel: utmSource === "google" ? "WEB" : "SOCIAL",
    });

    const newMsg = {
      sender: {
        id: profile.id,
        name: profile.username,
      },
      content: content,
      imageUrl,
      product,
      createdAt: new Date(),
      channel: utmSource === "google" ? "WEB" : "SOCIAL",
      order: null,
    };

    setNewMessages((prev) => [...prev, newMsg]);

    setMessage("");
  };

  const handleChangeFiles = (info: UploadChangeParam<UploadFile<any>>) => {
    if (info.file.status === "done") {
      handleSendMessage(
        "",
        [String(info.file.response[0].id)],
        info.file.response[0].url
      );
    }
    setShowChooseFiles(false);
    setShowPickProduct(false);
  };

  const handlePickProduct = (
    product: BaseData<ProductsResponse | BaseProductData>
  ) => {
    const findIndex = selectedProducts.findIndex(
      (item) => item?.id === product?.id
    );

    if (findIndex === -1 && selectedProducts.length === 4) {
      return;
    }

    setSelectedProducts((state) => {
      if (findIndex !== -1) {
        return state.filter((item) => item?.id !== product?.id);
      }

      return [...state, product as BaseData<ProductsResponse>];
    });
  };

  const handleSendProducts = () => {
    if (!isLoadingSendProducts) {
      setIsLoadingSendProducts(true);
      const sendProduct = (index: number) => {
        if (index >= selectedProducts.length) {
          handleCancelPickProducts();
          setIsLoadingSendProducts(false)
          return;
        }

        handleSendMessage("", [], "", selectedProducts[index]);

        setTimeout(() => {
          sendProduct(index + 1);
        }, 500);
      };

      sendProduct(0);
    }
  };

  const handleCancelPickProducts = () => {
    setSelectedProducts([]);
    setShowChooseFiles(false);
    setShowPickProduct(false);
  };

  const handleSendInstruction = async (faq: FAQChatType) => {
    const userMessageParams: MessagesRequestType = {
      data: {
        content: faq.question,
        media: null,
        room: roomId ?? "",
        sender: profile.id.toString(),
        mentioned_product: null,
        mentioned_order: null,
      },
    };

    const adminMessageParams: MessagesRequestType = {
      data: {
        content: faq.answer,
        media: null,
        room: roomId ?? "",
        sender: null,
        mentioned_product: null,
        mentioned_order: null,
      },
    };

    await messagesApi.post(userMessageParams);

    let newMsg = {
      sender: {
        id: profile.id,
        name: profile.username,
      },
      content: faq.question,
      imageUrl: "",
      product: null,
      createdAt: new Date(),
      order: null,
    };

    setNewMessages((prev) => [...prev, newMsg]);

    await messagesApi.post(adminMessageParams);

    newMsg = {
      sender: {
        id: -1,
        name: "admin",
      },
      content: faq.answer,
      imageUrl: "",
      product: null,
      createdAt: new Date(),
      order: null,
    };
    setNewMessages((prev) => [...prev, newMsg]);
  };

  useEffect(() => {
    if (!!advisedProduct?.id) {
      handleSendMessage("", [], "", advisedProduct);
      window.history.replaceState({}, '')
    }
  }, [advisedProduct])

  useEffect(() => {
    const handleConnect = () => {
      const timeout = setTimeout(() => {
        socket.emit(socketEvent.JOIN_MY_ROOM);

        console.log("Socket connected:", socket.id);
        setIsChatReady(true);
      }, 3000);

      return () => clearTimeout(timeout);
    };

    const handlePrivateMessage = async (message: any) => {
      const product = message?.product_id
        ? await productApi
          .getById(message?.product_id)
          .then((res) => res?.data?.data)
        : null;

      const imageUrl = !!message?.images?.length
        ? await uploadApi
          .getUploadFiles(message.images[0])
          .then((res) => res.data.url)
        : "";

      const order = message?.order_id
        ? await orderApi.get(message?.order_id).then((res) => res.data.data)
        : null;

      const newMsg = {
        sender: {
          id: message?.from === profile.id ? profile.id : -1,
          name: message?.from === profile.id ? profile.username : "admin",
        },
        content: message.content,
        imageUrl: imageUrl,
        product: product,
        createdAt: new Date(),
        channel: utmSource === "google" ? "WEB" : "SOCIAL",
        order,
      };
      setNewMessages((prev) => [...prev, newMsg]);
    };

    socket.on(socketEvent.CONNECT, handleConnect);
    socket.on(socketEvent.PRIVATE_MESSAGE, handlePrivateMessage);

    return () => {
      socket.off(socketEvent.CONNECT, handleConnect);
      socket.off(socketEvent.PRIVATE_MESSAGE, handlePrivateMessage);
    };
  }, [roomUuid]);

  useEffect(() => {
    if (isChatReady) {
      messagesEndRef.current!.scrollIntoView();
    }
  }, [messageList, newMessages, isChatReady]);

  useEffect(() => {
    const getMessageList = async () => {
      const messageListResponse = await chatApi
        .getMessages(
          queryString.stringify({
            "filters[room][room_id]": roomUuid,
            "sort[id]": "asc",
          })
        )
        .then((res) => res.data.data);

      setMessageList(messageListResponse);
    };

    getMessageList();
  }, [roomUuid]);

  if (!isChatReady) {
    return (
      <div className={clsx(
        "flex items-center justify-center",
        inPage ? "h-screen w-full overflow-x-hidden" : "h-[444px] w-[328px] md:h-[400px] md:w-[300px]"
      )}>
        <Spin />
      </div>
    );
  }

  return (
    <div className={clsx(
      "flex flex-col",
      inPage
        ? "h-screen w-full overflow-x-hidden"
        : "h-[444px] w-[328px] md:h-[500px] md:w-[300px]"
    )}>
      {/* Header */}
      {inPage && <NotificationHeader />}
      {inPage ? (
        <div className="sticky top-[37px] z-10 bg-[#FFF] flex items-center justify-between p-[16px] border-b boder-[#DEDEDE]">
          <div className="flex-1">
            <div
              className="size-[32px] p-[10px] mr-2 cursor-pointer"
              onClick={() => navigate(routes.HOME)}
            >
              <img src={svgs.arrowLeftBlack} alt="" className="w-full h-full" />
            </div>
          </div>
          <p className="text-[16px] font-[500] leading-[22.4px]">
            Tư vấn viên CERANEE
          </p>
          <div className="flex-1"></div>
        </div>
      ) : (
        <div className="flex items-center justify-between pb-[8px]">
          <p className="text-[14px] font-[500] leading-[22.4px]">
            Tư vấn viên CERANEE
          </p>
          <div
            className="w-[14px] h-[14px] mr-2 cursor-pointer"
            onClick={closeChat}
          >
            <img src={svgs.closeIcon} alt="" className="w-full h-full" />
          </div>
        </div>
      )}
      {!inPage && <div className="border-b border-[#DEDEDE] mx-[-16px]"></div>}

      {/* Modal Choose Product */}
      <div
        className={clsx(
          "flex flex-col flex-1 relative justify-end",
          { "h-[calc(100vh-37px-65px-55px)]": inPage }
          // showChooseFiles && showPickProduct ? " overflow-y-hidden" : "overflow-y-auto"
        )}
      >
        <div
          className={clsx(
            "w-full absolute z-20 overflow-y-auto bg-[#FFFFFF]",
            showChooseFiles && showPickProduct ? "" : "hidden",
            inPage ? "h-full p-[0_16px]" : "h-[370px] md:h-[425px]"
          )}
        >
          <div className="sticky top-0 w-full z-30 bg-[#FFF] py-[16px]">
            <div className="px-[16px] py-[8px] flex items-center gap-[10px] bg-[#F0F0F0] rounded-[16px]">
              <img src={svgs.searchIcon} width={24} height={24}></img>
              <input
                type="text"
                className="text-[14px] font-[400] bg-transparent flex-1 border-none focus:outline-none focus:border-[transparent] leading-[24px] p-0"
                style={{ boxShadow: "unset" }}
                value={searchProductInput}
                onChange={(e) => setSearchProductInput(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-col items-center px-[4px] bg-[#FFFFFF]">
            {!!products?.length ? (
              products?.map((product) => (
                <NewProduct
                  key={product.id}
                  product={product}
                  thumbType={"chat"}
                  contentPage={"chat"}
                  handleOnClick={handlePickProduct}
                  selectedProducts={selectedProducts}
                />
              ))
            ) : (
              <p className="text-[14px]">Không có sản phẩm cần tìm</p>
            )}
          </div>
          <div className="sticky bottom-0 w-full z-30 bg-[#FFF] py-[16px]">
            <div className="flex items-center justify-between">
              <p className="text-[14px] font-[500] leading-[19.6px]">
                Đã chọn: {selectedProducts?.length}/4
              </p>
              <div className="flex items-center justify-end gap-[16px]">
                <p
                  className="text-[14px] font-[500] leading-[19.6px] text-[#F90] cursor-pointer"
                  onClick={handleCancelPickProducts}
                >
                  Huỷ
                </p>
                <p
                  className={clsx(
                    "text-[14px] font-[500] leading-[19.6px] cursor-pointer",
                    isLoadingSendProducts || !selectedProducts.length
                      ? "text-[#808080] pointer-events-none cursor-wait select-none"
                      : "text-[#000]"
                  )}
                  onClick={(e) => {
                    if (isLoadingSendProducts || !selectedProducts.length) {
                      e.preventDefault(); // Prevent click if disabled
                      return;
                    }
                    handleSendProducts();
                  }}
                  aria-disabled={isLoadingSendProducts || !selectedProducts.length}
                >
                  Gửi
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={clsx(
          "relative h-[370px] md:h-[425px] overflow-y-auto",
          inPage ? "h-full p-[8px_16px]" : "h-[370px] md:h-[425px]"
        )}>
          {/* FAQ chat */}
          <div className={clsx(
            "flex flex-col gap-[16px]",
            inPage ? "pb-[24px]" : "pt-[8px] pb-[28px]"
          )}>
            {FAQChatsData?.data?.data?.length &&
              FAQChatsData?.data?.data.map((faq, index) => (
                <p
                  key={index}
                  className={clsx(
                    "text-[#000000] underline cursor-pointer",
                    inPage ? "text-[16px]" : "text-[14px]"
                  )}
                  onClick={() => handleSendInstruction(faq.attributes)}
                >
                  {faq?.attributes?.question}
                </p>
              ))}
          </div>
          <div className={clsx("text-center")}>
            <a
              href="https://www.facebook.com/sonatural.vn"
              className="text-[14px] text-[#1F88C3] underline"
              target="_blank"
            >
              Chat qua Messenger
            </a>
          </div>
          {/* Chat content */}
          <div className="flex flex-col pb-[8px]">
            {/* Old messages */}
            {messageList.map((message, index) => {
              const isSameDay =
                index === 0 ||
                !dayjs(message.attributes.createdAt).isSame(
                  messageList?.[index - 1]?.attributes?.createdAt,
                  "day"
                );
              const isSameSender =
                index === 0 ||
                message?.attributes?.sender?.data?.id ===
                messageList?.[index - 1]?.attributes?.sender?.data?.id;
              const isUserSend =
                message?.attributes?.sender?.data?.id === profile?.id;

              const messageContent = () => {
                if (message?.attributes?.mentionedProduct?.data?.id) {
                  return (
                    <NewProduct
                      product={message?.attributes?.mentionedProduct?.data}
                      thumbType={"chat"}
                      contentPage={"chat"}
                      handleOnClick={() => navigate(`${routes.PRODUCT}/${message?.attributes?.mentionedProduct?.data?.attributes?.slug}`)}
                    />
                  );
                }

                if (message?.attributes?.media?.data?.[0]?.attributes?.url) {
                  return (
                    <Image
                      src={
                        message?.attributes?.media?.data?.[0]?.attributes?.url
                      }
                      alt=""
                      loading="lazy"
                    />
                  );
                }

                return (
                  <p
                    className={clsx(
                      "text-[14px]",
                      isUserSend ? "text-[#FFFFFF]" : "text-[#000000]"
                    )}
                  >
                    {message?.attributes?.content}
                  </p>
                );
              };

              return (
                <>
                  {isSameDay && (
                    <div className="text-center py-[24px]">
                      {dayjs(message.attributes.createdAt)
                        .format("HH:mm DD-MM-YYYY")
                        .split(" ")
                        .map((time, index) => (
                          <p key={index} className="text-[14px] text-[#C6C6C6]">
                            {time}
                          </p>
                        ))}
                    </div>
                  )}
                  {message?.attributes?.mentionedOrder?.data?.id ? (
                    <div
                      className={clsx(
                        "w-full flex gap-[16px] border border-[#E0DEDE] bg-[#FFFFFF] shadow-[0px_4px_6px_0px_#0000000D] rounded-[8px] p-[8px] cursor-pointer",
                        isSameSender ? "mt-[8px]" : "mt-[24px]"
                      )}
                      onClick={() =>
                        navigate(`${routes.PROFILE}/${routes.ORDERS_HISTORY}`)
                      }
                    >
                      {message?.attributes?.mentionedOrder?.data?.attributes?.orderDetails?.data?.map(
                        (order, index) => {
                          if (index === 0)
                            return (
                              <Image
                                key={index}
                                src={order?.attributes?.product?.data?.attributes?.avatar?.data?.attributes?.url}
                                width={60}
                                height={60}
                                className="object-fit rounded-[8px]"
                              />
                            );
                        }
                      )}
                      <div className="flex flex-col gap-[4px]">
                        <p className="text-[14px] leading-[16px]">
                          Mã đơn hàng:{" "}
                          {message?.attributes?.mentionedOrder?.data?.attributes?.orderCode}
                        </p>
                        <p className="text-[14px] leading-[16px]">
                          Tổng cộng: {formatPriceToVnd(Number(message?.attributes?.mentionedOrder?.data?.attributes?.total))}
                        </p>
                        <p className="text-[14px] font-[400] leading-[16px] text-[#F90]">
                          {message?.attributes?.mentionedOrder?.data?.attributes?.status}
                        </p>
                      </div>
                    </div>
                  ) : message?.attributes?.mentionedProduct?.data?.id ? (
                    <NewProduct
                      product={message?.attributes?.mentionedProduct?.data}
                      thumbType={"chat"}
                      contentPage={"chat"}
                      handleOnClick={() => navigate(`${routes.PRODUCT}/${message?.attributes?.mentionedProduct?.data?.attributes?.slug}`)}
                    />
                  ) : (
                    <div
                      className={clsx(
                        "p-[10px] rounded-[8px] w-fit",
                        isSameSender ? "mt-[8px]" : "mt-[24px]",
                        isUserSend
                          ? "rounded-br-none bg-[#000000] self-end"
                          : "rounded-bl-none bg-[#E6E6E6]"
                      )}
                      key={index}
                    >
                      {messageContent()}
                    </div>
                  )}
                </>
              );
            })}

            {/* New messages */}
            {!messageList.length ||
              (!!newMessages?.length &&
                dayjs().diff(
                  messageList?.[messageList.length - 1]?.attributes?.createdAt,
                  "day"
                ) > 1 && (
                  <div className="text-center py-[24px]">
                    {dayjs()
                      .format("HH:mm DD-MM-YYYY")
                      .split(" ")
                      .map((time, index) => (
                        <p key={index} className="text-[14px] text-[#C6C6C6]">
                          {time}
                        </p>
                      ))}
                  </div>
                ))}
            {newMessages.map((message, index) => {
              const isSameSender =
                message?.sender?.id === newMessages?.[index - 1]?.sender?.id;
              const isUserSend = message.sender.id === profile.id;

              const messageContent = () => {
                if (!!message?.imageUrl) {
                  return (
                    <>
                      <Image src={message?.imageUrl} alt="" loading="lazy" />
                      <p
                        className={clsx(
                          "text-[14px]",
                          isUserSend ? "text-[#FFFFFF]" : "text-[#000000]"
                        )}
                      >
                        {message.content}
                      </p>
                    </>
                  );
                }

                return (
                  <p
                    className={clsx(
                      "text-[14px]",
                      isUserSend ? "text-[#FFFFFF]" : "text-[#000000]"
                    )}
                  >
                    {message.content}
                  </p>
                );
              };

              if (message?.product?.id) {
                return (
                  <NewProduct
                    key={message?.product?.id}
                    product={message?.product}
                    thumbType={"chat"}
                    contentPage={"chat"}
                    handleOnClick={() => navigate(`${routes.PRODUCT}/${message?.product?.attributes?.slug}`)}
                    selectedProducts={selectedProducts}
                  />
                );
              }

              return message?.order ? (
                <div
                  className={clsx(
                    "w-full flex gap-[16px] border border-[#E0DEDE] bg-[#FFFFFF] shadow-[0px_4px_6px_0px_#0000000D] rounded-[8px] p-[8px] cursor-pointer",
                    isSameSender ? "mt-[10px]" : "mt-[24px]"
                  )}
                  onClick={() =>
                    navigate(`${routes.PROFILE}/${routes.ORDERS_HISTORY}`)
                  }
                >
                  {message?.order.attributes?.orderDetails?.data.map(
                    (order, index) => {
                      if (index === 0)
                        return (
                          <Image
                            key={index}
                            src={order?.attributes?.product?.data?.attributes?.avatar?.data?.attributes?.url}
                            width={60}
                            height={60}
                            className="object-fit rounded-[8px]"
                          />
                        );
                    }
                  )}
                  <div className="flex flex-col gap-[4px]">
                    <p className="text-[14px] leading-[16px]">
                      Mã đơn hàng:{" "}
                      {message?.order?.attributes?.orderCode}
                    </p>
                    <p className="text-[14px] leading-[16px]">
                      Tổng cộng: {formatPriceToVnd(Number(message?.order?.attributes?.total))}
                    </p>
                    <p className="text-[14px] font-[400] leading-[16px] text-[#F90]">
                      {message?.order?.attributes?.status}
                    </p>
                  </div>
                </div>
              ) : (
                <div
                  className={clsx(
                    "p-[10px] rounded-[8px] w-fit",
                    isSameSender ? "mt-[10px]" : "mt-[24px]",
                    isUserSend
                      ? "rounded-br-none bg-[#000000] self-end"
                      : "rounded-bl-none bg-[#E6E6E6]"
                  )}
                  key={index}
                >
                  {messageContent()}
                </div>
              );
            })}
          </div>

          <div ref={messagesEndRef}></div>
        </div>
      </div>

      {/* Chat Ations */}
      <div className={clsx(
        "flex flex-col justify-end relative",
        { "p-[0px_16px_8px] sticky bottom-0 bg-[#FFF]": inPage }
      )}>
        {/* Modal choose files and products */}
        <div
          className={clsx(
            "flex flex-col items-start p-[16px] gap-[8px] bg-[#FFFFFFB2] w-fit rounded-[24px] backdrop-blur-[5px]",
            "absolute -top-[120px] z-10",
            !showChooseFiles && "hidden"
          )}
        >
          <div
            className="flex items-center gap-[8px] py-[8px] cursor-pointer"
            onClick={() => {
              setShowPickProduct(!showPickProduct);
              // setShowChooseFiles(false)
            }}
          >
            <img
              src={svgs.pickProducts}
              alt=""
              className="h-[24px] w-[24px]"
              loading="lazy"
            />
            <p className="text-[14px]">Sản phẩm</p>
          </div>
          <Upload
            name="files"
            action={`${baseUrl}/api/upload`}
            headers={{
              Authorization: `Bearer ${accessToken}`,
              accept: "application/json",
            }}
            onChange={(info) => handleChangeFiles(info)}
            showUploadList={false}
          >
            <div className="flex items-center gap-[8px] py-[8px] cursor-pointer">
              <img src={svgs.pickImage} alt="" className="h-[24px] w-[24px]" />
              <p className="text-[14px]">Ảnh</p>
            </div>
          </Upload>
        </div>
        <div className="flex items-center justify-start pt-[8px] gap-[10px] relative">
          <div
            className="w-[32px] h-[32px] cursor-pointer"
            onClick={() => setShowChooseFiles(!showChooseFiles)}
          >
            <img
              src={svgs.addImageChat}
              alt=""
              className={clsx("w-full h-full transition-transform")}
            />
          </div>

          <TextArea
            rows={1}
            className="resize-none border-none bg-[#F0F0F0] py-[8px] px-[16px] rounded-[16px] leading-[17px]"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            variant="borderless"
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                if (message.trim() !== "") {
                  handleSendMessage();
                }
              }
            }}
            placeholder="Aa"
          />
        </div>
      </div>
    </div>
  );
};

export default ChatContent;
