export const TRACKING_KEYS = ['utm_advertisement', 'utm_campaign', 'utm_medium', 'utm_source']

export const getUtmData = () => {
  // List of desired query parameter keys

  // Initialize an empty object for the post data
  const postData: Record<string, string> = {};

  // Loop through the keys and retrieve the values from sessionStorage
  TRACKING_KEYS.forEach(key => {
    // Check if the parameter exists in sessionStorage
    const value = sessionStorage.getItem(key);

    // If the value exists, add it to the postData object
    if (value) {
      postData[key] = value;
    }
  });

  return postData;
}