import { BaseResponse } from "@/type/base/baseResponse";
import axiosClient from "./axiosClient";
import { BaseData } from "@/type/base/baseData";
import urls from "@/utils/urls";
import { RoutineType } from "@/type/response/routine";

const routineApi = {
  getAll() {
    return axiosClient.get<BaseResponse<BaseData<RoutineType>[]>>(
      `${urls.ROUTINE}?&populate=deep,3`
    );
  },
  getByIdProduct(id: string) {
    return axiosClient.get<BaseResponse<BaseData<RoutineType>[]>>(
      `${urls.ROUTINE}?populate[products][populate][0]=avatar&filters[products]=${id}`
    );
  },
  getBySlug(slug: string) {
    return axiosClient.get<BaseResponse<BaseData<RoutineType>[]>>(
      `${urls.ROUTINE_DETAILS}/${slug}?populate[products][populate][0]=avatar`
    );
  },
};

export default routineApi;
