import { UserType } from "@/type/response/user";

export function getProfile(): UserType | null {
  const profileData = localStorage.getItem("profile");

  if (!profileData) {
    return null;
  }

  try {
    const profile: UserType = JSON.parse(profileData);
    return profile;
  } catch (error) {
    console.error("Failed to parse profile data:", error);
    return null;
  }
}
