import routes from "@/utils/routes";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

interface INotificationHeader {
  className?: string;
}

const NotificationHeader = (props: INotificationHeader) => {
  const { className } = props;
  const navigate = useNavigate();

  return (
    <div
      className={clsx(
        "flex w-full py-[8px] justify-center items-center bg-[#F0F0F0] cursor-pointer z-[1001] sticky top-0",
        className
      )}
      onClick={() => navigate(routes.REGISTER)}
    >
      <p className="text-[#000] text-[14px] font-[400] line-clamp-1 text-ellipsis">
        Đăng ký thành viên nhận ngay{" "}
        <span className="text-[#000] text-[14px] font-[700]">voucher 30K</span>{" "}
        🎁
      </p>
    </div>
  );
};

export default NotificationHeader;
