import svgs from "@/assets/svgs";
import { Button } from "@/components/common";
import routes from "@/utils/routes";
import { Modal } from "antd";
import { Link } from "react-router-dom";

interface IFinishFeedbackModalProps {
  open: boolean;
  onClose: () => void;
  handleCancel?: () => void;
}

const FinishFeedbackModal = (props: IFinishFeedbackModalProps) => {
  const { open, onClose } = props;

  return (
    <Modal
      rootClassName="finsihFeedback"
      open={open}
      width={720}
      onCancel={onClose}
      centered
      className="text-center md:w-[387px] md:h-[175px]"
      closeIcon={
        <div className="w-[36px] h-[36px]">
          <img src={svgs.closeIcon} className="w-[16px] h-[16px]" />
        </div>
      }
      footer={null}
      styles={{
        content: {
          padding: "40px 16px 24px",
          borderRadius: "24px",
        },
      }}
    >
      <div className="w-full mb-[32px]">
        <p className="text-[16px] text-[#000000] leading-[19.36px] font-[400] text-center">
          Cảm ơn bạn đã đánh giá sản phẩm.
        </p>
        <p className="flex justify-center items-center gap-[4px] text-[16px] text-[#000000] leading-[19.36px] font-[400]">
          Voucher đã thêm vào ví. Kiểm tra
          <Link to={`${routes.PROFILE}/${routes.VOUCHERS}`}>
            <p className="text-[16px] text-[#FF9900] leading-[19.36px] font-[400]">
              tại đây
            </p>
          </Link>
        </p>
      </div>
      <Link to={"/"}>
        <Button
          className="rounded-[24px] group/button p-[12px_20px]"
          textClassName="text-[14px] font-[600] leading-[16.94px] group-hover/button:text-[#FF9900]"
        >
          Tiếp tục mua sắm
        </Button>
      </Link>
    </Modal>
  );
};

export default FinishFeedbackModal;
