export const generateYears = (startYear: number, endYear: number): number[] =>
  Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);

export const generateMonths = (): string[] =>
  Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));

export const generateDays = (year: number, month: number): string[] => {
  const daysInMonth = new Date(year, month, 0).getDate();
  return Array.from({ length: daysInMonth }, (_, i) =>
    (i + 1).toString().padStart(2, '0')
  );
};
