import images from '@/assets/images';
import { formatCurrency } from '@/utils/data';

interface IProductOrderProps {
  name: string;
  image: string;
  unitPrice: string;
  quantity: number | string;
}

export default function ProductOrder({
  name,
  unitPrice,
  image,
  quantity,
}: IProductOrderProps) {
  return (
    <div className="product__order flex justify-start items-start gap-4 py-4 border-b border-[#D9D9D9]">
      <div className="product__order-image w-[75px] h-auto border border-[#D9D9D9]">
        <img
          src={image ?? images.productThree}
          alt=""
          className="w-full h-full object-cover object-center"
          loading="lazy"
        />
      </div>

      <div className="product__information w-full flex flex-col gap-1 text-[12x]">
        <div className="product__information-name">
          <h3 className="my-0 font-normal md:text-[16px]">
            {name}
            <span className="text-[#A77b5a]"> x {quantity}</span>
          </h3>
        </div>

        <div className="product__information-price font-bold">
          {formatCurrency(unitPrice)}đ
        </div>
      </div>
    </div>
  );
}
