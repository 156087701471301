import svgs from "@/assets/svgs";
import clsx from "clsx";
import { ReactNode } from "react";

interface IBackToHeaderProps {
  content: ReactNode;
  onBackTo?: () => void;
  showIconBackTo?: boolean;
  className?: string;
}
const BackToHeader = (props: IBackToHeaderProps) => {
  const { content, onBackTo, showIconBackTo = true, className } = props;
  return (
    <div className={clsx("w-full p-[8px_16px] bg-[#F7F7F7]", className)}>
      <div className="flex justify-start items-center gap-[8px] bg-[#F7F7F7]">
        {showIconBackTo && (
          <div
            className="w-[32px] h-[32px] p-[10px_13px] cursor-pointer"
            onClick={() => onBackTo?.()}
          >
            <img
              src={svgs.arrowRightBlack}
              className="w-full h-full rotate-180"
            />
          </div>
        )}
        <div className="py-[8px]">
          <p className="text-black text-[14px] font-[500] leading-[16.1px]">
            {content}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BackToHeader;
