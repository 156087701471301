const keysReactQuery = {
  ROUTINES: 'routines',
  ROUTINES_PRODUCT: 'routinesProduct',
  ROUTINE: 'routine',
  HOME: 'home',
  PRODUCT: 'product',
  BLOGS: 'blogs',
  PRODUCT_INFORMATION: 'productInformation',
  PRODUCT_FAQS: 'productFaqs',
  BRAND: 'brand',
  FLASH_SALE: 'flashSale',
};

export default keysReactQuery;
