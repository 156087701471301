import { Divider } from "antd";
import clsx from "clsx";

interface IStatusItemProps {
  className?: string;
  nameStatus?: string;
  iconStatus?: React.ReactNode;
  active?: boolean;
  lastItem?: boolean;
  onClick: () => void;
}

const StatusItem = (props: IStatusItemProps) => {
  const { className, nameStatus, iconStatus, active, lastItem, onClick } =
    props;
  return (
    <div className="w-full flex justify-between items-center gap-[8px] ">
      <div
        className={clsx(
          "w-fit h-[62px] flex flex-col justify-center items-center cursor-pointer",
          className
        )}
        onClick={onClick}
      >
        {iconStatus}
        <p
          className={clsx(
            "text-[14px] md:text-[16px] leading-[19.6px] md:leading-[22.4px] text-nowrap text-center",
            active ? "text-black font-[500]" : "text-[#808080] font-[400]"
          )}
        >
          {nameStatus}
        </p>
      </div>
      {!lastItem && (
        <div className="w-[54px] xl:w-[80.25px]">
          <Divider className="w-full h-[1px] bg-[#808080]" />
        </div>
      )}
    </div>
  );
};

export default StatusItem;
