import urls from '@/utils/urls';
import axiosClient from './axiosClient';
import { BaseResponse } from '@/type/base/baseResponse';
import { BaseData } from '@/type/base/baseData';
import { UploadFile } from "antd";
import { FeedbackRequestType } from "@/type/request/feedback";
import { FeedbackResponseType } from "@/type/response/feedback";

const feedbackApi = {
    uploadFile(payload: any) {
        return axiosClient.post<UploadFile[]>(
            `${urls.UPLOAD}`, payload, {
            headers: { "Content-Type": "multipart/form-data" }
        })
    },
    postReview(payload: { data: FeedbackRequestType }) {
        return axiosClient.post<BaseResponse<BaseData<FeedbackResponseType>>>(
            `${urls.REVIEWS}`, payload
        )
    }
};

export default feedbackApi;
