import images from "@/assets/images";
import Breadcrumb from "@/components/common/Breadcrumb";
import QuickFilterComponent from "@/components/common/QuickFilter";
import FilterComponent from "@/components/pages/Products/Filter";
import ProductFilterComponent from "@/components/pages/Products/ProductFilter";
import SortComponent from "@/components/pages/Products/Sort";
import loadingScreenState from "@/recoil/loadingScreenState";
import decodeUseId from "@/utils/decodeUseId";
import routes from "@/utils/routes";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";

const breadcrumbItems = [
  {
    href: routes.HOME,
    title: "Trang chủ",
  },
  {
    href: routes.PRODUCT,
    title: "Sản phẩm",
  },
];

export default function Products() {
  const [searchParams, setSearchParams] = useSearchParams({
    page: "1",
    pageSize: "15",
    sortPrice: "asc",
  });
  const [isFilterFlashSale, setIsFilterFlashSale] = useState(false);

  const handleSetIsLoading = useSetRecoilState(loadingScreenState);

  function handleFilterChange(params: Record<string, string[] | string>) {
    let paramsFormat = formatParamsFilter(params);
    const newSearchParams =
      Object.keys(paramsFormat).length === 0
        ? {
          page: "1",
          pageSize: searchParams.get("pageSize") || "15",
          sortPrice: searchParams.get("sortPrice") || "asc",
        }
        : {
          page: "1",
          pageSize: searchParams.get("pageSize") || "15",
          sortPrice: searchParams.get("sortPrice") || "asc",
          ...paramsFormat,
        };

    setSearchParams(
      new URLSearchParams(queryString.stringify(newSearchParams))
    );
  }

  function handleSortChange(optionsSortPrice: string) {
    const newSearchParams = new URLSearchParams({
      ...queryString.parse(location.search),
      sortPrice: optionsSortPrice,
    });
    setSearchParams(newSearchParams);
  }
  useEffect(() => {
    handleSetIsLoading(true);

    if (!!queryString.parse(location.search)["filterFlashSale"]) {
      setIsFilterFlashSale(true);
    }
  }, []);

  return (
    <>
      <div className="h-[50px] md:h-[90px] w-full">
        <img
          src={images.topBannerProduct}
          className="w-full h-full object-cover"
        />
      </div>
      <Breadcrumb items={breadcrumbItems} />

      <div className="flex flex-col gap-[8px] bg-[#F7F7F7]">
        <div className="w-full py-[8px] bg-[#FFFFFF]">
          <QuickFilterComponent />
        </div>
        <div className="w-full bg-white py-[32px]">
          <div className="mx-4 md:container md:max-w-[1440px] md:mx-auto px-[16px] md:px-[64px] lg:px-[160px]">
            <div className="md:gap-10 bg-white">
              <div className="flex justify-between items-center mb-[24px] md-[32px] py-0 md:py-[8px]">
                <FilterComponent
                  handleChangeFilter={handleFilterChange}
                  layoutDesktop
                  isFilterFlashSale={isFilterFlashSale}
                  handleSetIsFilterFlashSale={(isFilter: boolean) =>
                    setIsFilterFlashSale(isFilter)
                  }
                />
                <SortComponent
                  handleSortChange={handleSortChange}
                  isFilterFlashSale={isFilterFlashSale}
                />
              </div>

              <ProductFilterComponent
                isFilterFlashSale={isFilterFlashSale}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function formatParamsFilter(params: Record<string, string[] | string>) {
  const result: Record<string, string[] | string> = {};

  for (const key in params) {
    if (typeof params[key] === "string") {
      result[key] = params[key];
    } else if (params[key].length > 0) {
      result[key] = (params[key] as string[]).map((value) =>
        decodeUseId(value)
      );
    }
  }

  return result;
}
