import urls from "@/utils/urls";
import axiosClient from "./axiosClient";
import {
  AddReviewVoucherType,
  GetFeeResponesType,
  OrderResponse,
  VoucherReviewType,
} from "@/type/response/order";
import { BaseResponse } from "@/type/base/baseResponse";
import { BaseData } from "@/type/base/baseData";
import {
  EditOrderRequest,
  GetFeeRequest,
  OrderRequest,
} from "@/type/request/order";

const orderApi = {
  post: (orderData: OrderRequest) => {
    return axiosClient.post(`${urls.ORDER_VER_2}`, orderData);
  },
  get: (id: string) => {
    return axiosClient.get<BaseResponse<BaseData<OrderResponse>>>(
      `${urls.ORDER}/${id}?populate[order_details][populate][product][populate][0]=avatar`
    );
  },
  getOrders(paramsFilter?: string, isUpdatedDesc?: boolean) {
    return axiosClient.get<BaseResponse<BaseData<OrderResponse>[]>>(
      `${
        urls.ORDER
      }?populate=deep,4&fields[0]=order_code&fields[1]=status&fields[2]=total&fields[3]=reason
      &fields[4]=review_status&fields[5]=discount&fields[6]=transport_fee&fields[7]=payment_method
      &fields[8]=sub_total&fields[9]=customer_full_name&fields[10]=customer_phone&fields[11]=shipping_address
      &fields[12]=shipping_method&fields[13]=shipping_order_id&${
        isUpdatedDesc ? "sort=updatedAt:desc" : "sort=createdAt:desc"
      }&${paramsFilter}`
    );
  },
  getAllOrderStatus(userId: number) {
    return axiosClient.get<BaseResponse<BaseData<OrderResponse>[]>>(
      `${urls.ORDER}?populate=deep,0&filters[user]=${userId}&fields[0]=status`
    );
  },
  getVoucherReview() {
    return axiosClient.get<BaseResponse<BaseData<VoucherReviewType>>>(
      `${urls.VOUCHER_REVIEW}?populate=*`
    );
  },
  addReviewVoucher() {
    return axiosClient.post<AddReviewVoucherType>(`${urls.ADD_REVIEW_VOUCHER}`);
  },
  editOrder(id: number, orderData: EditOrderRequest) {
    return axiosClient.put<BaseResponse<BaseData<OrderResponse>>>(
      `${urls.ORDER}/${id}`,
      orderData
    );
  },
  getFee(data: GetFeeRequest) {
    return axiosClient.post<GetFeeResponesType>(`${urls.GET_FEE}`, data);
  },

  getOrderByCode(orderCode: string) {
    return axiosClient.get<BaseResponse<BaseData<OrderResponse>[]>>(
      `${urls.ORDER}?populate=deep,4&filters[order_code]=${orderCode}&fields[0]=order_code&fields[1]=status&fields[2]=total&fields[3]=reason
      &fields[4]=review_status&fields[5]=discount&fields[6]=transport_fee&fields[7]=payment_method
      &fields[8]=sub_total&fields[9]=customer_full_name&fields[10]=customer_phone&fields[11]=shipping_address
      &fields[12]=shipping_method&fields[13]=shipping_order_id`
    );
  },
};

export default orderApi;
