import { BaseData } from '@/type/base/baseData';
import { BaseResponse } from '@/type/base/baseResponse';
import urls from '@/utils/urls';
import axiosClient from './axiosClient';
import { ProductFaqs } from '@/type/response/productFaqs';

const productFaqsApi = {
  getId(id: string) {
    return axiosClient.get<BaseResponse<BaseData<ProductFaqs>[]>>(
      `${urls.PRODUCT_FAQS}?filters[products]=${id}`
    );
  },
};

export default productFaqsApi;
