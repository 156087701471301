const PAGE_SIZE = 12
const MAX_PAGE_SIZE = 5
const MAX_REVIEW_PAGES = 5
const CURRENT_PAGE = 1
const BASE_FILTER_PARAMS = `populate[0]=avatar&populate[1]=variation_prod_attribute&populate[2]=skin_properties&populate[3]=product_unit&pagination[pageSize]=${PAGE_SIZE}`
const SORT_KEYS: { [key: string]: string } = {
    'price:desc': 'Giá cao đến thấp',
    'price:asc': 'Giá thấp đến cao'
}

export const product = {
    PAGE_SIZE,
    MAX_PAGE_SIZE,
    MAX_REVIEW_PAGES,
    CURRENT_PAGE,
    BASE_FILTER_PARAMS,
    SORT_KEYS
}
