interface IDecreaseQuantityProps {
  active?: boolean;
}

const DecreaseQuantity = (props: IDecreaseQuantityProps) => {
  const { active } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="25"
      viewBox="0 0 23 25"
      fill="none"
    >
      <path
        d="M17.5 3.5H3.5C2.39543 3.5 1.5 4.39543 1.5 5.5V19.5C1.5 20.6046 2.39543 21.5 3.5 21.5H17.5C18.6046 21.5 19.5 20.6046 19.5 19.5V5.5C19.5 4.39543 18.6046 3.5 17.5 3.5Z"
        stroke={active ? "#000000" : "#B0B0B0"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 12.5H14.5"
        stroke={active ? "#000000" : "#B0B0B0"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DecreaseQuantity;
