import axios from "axios";
import env from "../config";

const config = {
  headers: {
    "Content-Type": "application/json",
    Token: env.environment.ghn_token,
  },
};

const urlGetAddress =
  "https://online-gateway.ghn.vn/shiip/public-api/master-data";

const urlOrderTracking =
  "https://fe-online-gateway.ghn.vn/order-tracking/public-api/client/tracking-logs";

const GHNApi = {
  getProvinceGHN() {
    return axios.get(`${urlGetAddress}/province`, config);
  },
  getDistrictGHN(province_id: string) {
    return axios.get(`${urlGetAddress}/district`, {
      ...config,
      params: {
        province_id: province_id,
      },
    });
  },
  getWardGHN(district_id: string) {
    return axios.get(`${urlGetAddress}/ward`, {
      ...config,
      params: {
        district_id: district_id,
      },
    });
  },

  postOrderTracking(orderCode: string) {
    return axios.post(
      `${urlOrderTracking}`,
      {
        order_code: orderCode,
      },
      config
    );
  },
};

export default GHNApi;
