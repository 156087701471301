import { useSearchParams } from 'react-router-dom';

export default function getSearchParams(): Record<string, string[]> {
  const [searchParams] = useSearchParams();
  let result: Record<string, string[]> = {};
  searchParams.forEach((value, key) => {
    if (key === 'page' || key === 'pageSize' || key === 'sortPrice') {
      result = {
        ...result,
      };
    } else {
      result = {
        ...result,
        [key]: [...(result[key] || []), `${key}-${value}`],
      };
    }
  });

  return result;
}
