import images from "@/assets/images";
import svgs from "@/assets/svgs";
import Breadcrumb from "@/components/common/Breadcrumb";
import SupportItem from "@/components/common/Support/SupportItem";
import routes from "@/utils/routes";

const Support = () => {
  const breadcrumbItems = [
    {
      href: routes.HOME,
      title: "Trang chủ",
    },
    {
      title: "Hướng dẫn",
    },
  ];

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />
      <div className="w-full bg-[#F7F7F7] py-[8px]">
        <div className="flex flex-col justify-center items-center wrapper md:w-full md:mx-auto bg-[#FFFFFF]">
          <div className=" md:max-w-[1280px] py-[48px] md:py-[96px]">
            <p className="text-[14px] md:text-[16px] leading-[20px] md:leading-[24px] text-[#FF9900] font-[600] text-center">
              Support
            </p>
            <p className="text-center font-[600] text-[#000000] text-[30px] md:text-[48px] leading-[36.31px] md:leading-[60px] my-[12px]">
              Bạn cần hỗ trợ?
            </p>
            <div className="mx-[16px] lg:mx-0 mt-[60px] md:mt-[40px] grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 items-center justify-between gap-[24px]">
              <SupportItem
                icon={svgs.forgotPwIcon}
                content="Quên mật khẩu"
                linkTo={routes.FORGOT_PASSWORD_HELP}
                iconClassName="w-[30px] md:w-[42px] h-[30px] md:h-[42px]"
              />
              <SupportItem
                icon={images.vnpay}
                content=" Hướng dẫn thanh toán online"
                linkTo={routes.VNPAY_HELP}
                iconClassName="h-[30px] w-[30px] md:h-[42px] w-auto"
              />
              <SupportItem
                icon={svgs.returnIcon}
                content="Chính sách đổi trả"
                linkTo={routes.RETURN_POLICY}
                iconClassName="w-[30px] md:w-[42px] h-[30px] md:h-[42px]"
              />
              <SupportItem
                icon={svgs.shieldCheck}
                content="Chính sách bảo mật"
                linkTo={routes.PRIVACY_POLICY}
                iconClassName="w-[30px] md:w-[42px] h-[30px] md:h-[42px]"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
