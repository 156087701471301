import Breadcrumb from "@/components/common/Breadcrumb";
import routes from "@/utils/routes";
import { useLocation, useNavigate } from "react-router-dom";

export default function PartnerRegisterSuccess() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { formData } = state;

  const breadcrumbItems = [
    {
      href: routes.HOME,
      title: 'Trang chủ',
    },
    {
      href: routes.BRAND,
      title: 'Thương hiệu'
    },
    {
      href: `${routes.PARTNER}${routes.REGISTER}`,
      title: 'Trở thành đại lý'
    },
  ];

  return (
    <>
      <Breadcrumb items={breadcrumbItems} />

      <section className="section__partner-register mx-4 my-8 md:w-[640px] md:mx-auto">
        <div className="section__partner-register--title mb-[64px] text-center font-[400] text-[16px] leading-[22.4px] text-[#484848]">
          Cảm ơn Đại Lý {formData.name} đã để lại thông tin.<br />
          Bộ phận hỗ trợ sẽ kết nối với bạn ngay.<br />
          <br />
          Thời gian kết nối: trong vòng 48h<br />
          (8:30 đến 17:30, thứ 2 - thứ 7)
        </div>

        <p
          className="text-center font-[400] text-[16px] leading-[22.4px] text-[#000000] cursor-pointer"
          onClick={() => navigate(routes.BRAND)}
        >
          Quay về trang thương hiệu
        </p>
      </section>
    </>
  );
}
