import ChatFrame from "@/components/common/ChatWidget/ChatFrame"
import { BaseData } from "@/type/base/baseData";
import { ProductDetailsType } from "@/type/response/productDetails";
import { useLocation } from "react-router-dom"


const Chat = () => {
  const location = useLocation();
  const { baseDataProduct } = location.state || {};

  return (
    <ChatFrame inPage advisedProduct={baseDataProduct as BaseData<ProductDetailsType>} />
  )
}

export default Chat