import { BaseData } from "@/type/base/baseData";
import { RoutineProductType } from "@/type/response/routine";
import images from "@/assets/images";
import routes from "@/utils/routes";
import { Link } from "react-router-dom";

interface IRoutineDescriptionProps {
  products: BaseData<RoutineProductType>[];
  id: number;
}

const RoutineDescription = ({ products }: IRoutineDescriptionProps) => {
  return (
    <div className="p-4 bg-white">
      <div className="max-w-[1440px] px-[16px] md:px-[64px] lg:px-[160px] py-0 mx-auto">
        {products?.map((dataProduct, index) => (
          <div className="flex flex-col">
            <div
              className={`product-box product-category-box flex ${
                (index + 1) % 2 === 0 ? "flex-row-reverse" : "flex-row"
              } gap-8`}
              key={index}
            >
              <div className="flex flex-col gap-2 justify-center w-[40%] items-center">
                <Link to={`${routes.PRODUCT}/${dataProduct?.id ?? ""}`}>
                  <div className="size-[156px]">
                    <img
                      src={
                        dataProduct.attributes?.avatar?.data?.attributes?.url ??
                        images.productOne
                      }
                      alt="cart"
                      className="w-full h-full object-cover"
                      loading="lazy"
                    />
                  </div>
                </Link>
                <div className="line-clamp-2 text-ellipsis">
                  <Link to={`/product/${dataProduct?.id ?? ""}`}>
                    <p className="text-[#484848] text-[11px] font-bold uppercase text-ellipsis mb-2 md:mb-0 text-center">
                      {dataProduct?.attributes.shortName ||
                        dataProduct?.attributes.name}
                    </p>
                  </Link>
                </div>
              </div>

              <div className="product-box-text pt-4 flex-1 flex items-center justify-center">
                <div className="md:items-start md:gap-1 md:grid grid-cols-12">
                  <div className="col-span-9">
                    <div className="block bg-color-base">
                      <span className="text-[#000] text-wrap text-md text-ellipsis line-clamp-5 text-[11px]">
                        {dataProduct?.attributes.shortDescription}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {(index + 1) % 2 === 0 ? (
              <div className="relative border-b my-6 ml-[9%] w-11/12 font-bold text-[36px]">
                <span className="absolute -left-10 -bottom-4 text-[38px] text-[#808080] h-fit">
                  {index + 1}
                </span>
              </div>
            ) : (
              <div className="relative border-b my-6 w-11/12 font-bold text-[36px]">
                <span className="absolute -right-10 -bottom-4 text-[38px] text-[#808080] h-fit">
                  {index + 1}
                </span>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RoutineDescription;
