import { memberIncentiveApi } from '@/api/memberIncentive';
import { MemberIncentiveType } from '@/type/response/memberIncentive';
import { useQuery } from '@tanstack/react-query';

export default function useQueryMemberIncentive() {
  const { data, isLoading, isFetched } = useQuery({
    queryKey: ['memberIncentive'],
    queryFn: () => memberIncentiveApi.getAll(),
  });

  const memberIncentive: MemberIncentiveType[] =
    data?.data?.data?.attributes?.memberIncentive || [];

  return { data, memberIncentive, isLoading, isFetched };
}
